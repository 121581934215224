import { z } from 'zod';
import { varNameSchema } from '../../var.zod';
import { varConditionSchema_v3 } from './conditional.v3.zod';
import { localVarCfgSchema_v3 } from './local.v3.zod';
import { varpConfigBase_v3 } from './var-provider.v3.zod';

const varWithIdSchema_v3 = varConditionSchema_v3.extend({
    id: z.string().optional(),
});

export const assignmentSchema_v3 = z.discriminatedUnion(
    'inputType',
    [
        varWithIdSchema_v3.extend({
            /** the assignment input value will come from a var */
            inputType: z.literal('var'),
            inputVar: z.string(),
            inputVal: z.union([ z.string(), z.number() ]).optional(),
        }),
        varWithIdSchema_v3.extend({
            /** the assignment input will be a constant string */
            inputType: z.literal('constStr'),
            inputVal: z.string(),
            inputVar: z.string().optional(),
        }),
        varWithIdSchema_v3.extend({
            /** the assignment input will be a constant number */
            inputType: z.literal('constNum'),
            inputVal: z.number(),
            inputVar: z.string().optional(),
        }),
        varWithIdSchema_v3.extend({
            /** the current value will be cleared (set to null) */
            inputType: z.literal('clear'),
            inputVar: z.string().optional(),
            inputVal: z.union([ z.string(), z.number() ]).optional(),
        }),
    ]
);

const varStatusSchema_v3 = z.union([
    z.literal('ok'),
    z.literal('pending'),
    z.instanceof(Error),
]);

export const comparativeProviderSchema_v3 = varpConfigBase_v3.extend({
    type: z.literal('comparative'),
    inputs: z.string().array(),
    attrs: z.object({
        /**
         * if set, then all assignment's 'varName' will match the var name here
         */
        targetVar: varNameSchema,
        /**
         * the initial / default state for a variable
         */
        initialState: localVarCfgSchema_v3.extend({
            status: varStatusSchema_v3,
        }).omit({ name: true }),
        /**
         * whether to use the `initialState` as a fallback value if all other assignments for the variable fail.
         * @default true
         */
        initialAsFallback: z.boolean(),
        assignments: assignmentSchema_v3.array(),
    }),
});
