export const messages = {
    serialNumber: {
        addNew: 'Add New Serial Number Format',
        addToken: 'Add a token to begin',
        align: {
            hint: (values: { tokenLength: number }) => {
                return `If the length of the token's content is greater/less than the token length (${values.tokenLength}), then characters will be added/removed as needed, respectively.`;
            },
            label: 'Alignment / Trim',
        },
        alignments: {
            left: {
                desc: 'Align the first character of content to the first character of the token',
                text: 'Left',
            },
            right: {
                desc: 'Align the last character of the content to the last character of the token',
                text: 'Right',
            },
        },
        inputValues: 'Input Values:',
        instances: 'Instances',
        regex: 'Regular Expression',
        selectToken: 'Select or add a token to begin',
        test: 'Preview Format',
        token: {
            encoding: {
                label: 'Encoding / Characters',
            },
            encodings: {
                alphabetics: 'Alphabetics (26)',
                alphanumerics: 'Alphanumerics (36)',
                numerics: 'Numerics (10)',
                safeAlphabetics: 'Safe Alphabetics (22)',
                safeAlphanumerics: 'Safe Alphanumerics (32)',
            },
            length: 'Token Length',
            name: 'Token Name',
            type: 'Token Type',
            types: {
                datetime: {
                    desc: 'A token whose contents are determined by a date / time',
                    format: 'Format',
                    formats: {
                        h: 'Hour (24)',
                        j: 'Julian Date',
                        m: 'Month',
                        md: 'Day of Month',
                        n: 'Minute',
                        q: 'Quarter',
                        s: 'Second',
                        wd: 'Day of Week (Sunday)',
                        wdm: 'Day of Week (Monday)',
                        y: 'Year (Single Digit)',
                        yd: 'Day of Year (Normalized for leap years)',
                        yw: 'Week of Year',
                        yy: 'Year (Two Digit)',
                        yyyy: 'Year (Full)',
                    },
                    text: 'Date / Time',
                },
                fixed: {
                    content: 'Token Content',
                    desc: 'Hardcoded token that never changes',
                    text: 'Fixed text',
                },
                input: {
                    desc: 'Accepts an arbitrary input at serial number creation',
                    fill: {
                        hint: 'Character to add if the content length is less than the desired token length',
                        label: 'Fill Character',
                    },
                    text: 'Input',
                },
                seq: {
                    chars: {
                        hint: 'Determines how the sequence value gets represented',
                        label: 'Encoding / Characters',
                    },
                    desc: 'A token that increments with every new serial number created',
                    disabledAlign: 'Right alignment is only allowed for sequence tokens',
                    disabledFill: 'Fill character is determined by the first encoding character',
                    end: {
                        hint: 'End',
                        label: 'Maximum sequence value',
                    },
                    grouping: {
                        hint: 'The tokens whose values determine a distinct sequence.',
                        label: 'Sequence Group Tokens',
                    },
                    inc: {
                        hint: 'How much should each new serial number increase the sequence value by',
                        label: 'Increment By',
                    },
                    start: {
                        hint: 'Minimum sequence value',
                        label: 'Start',
                    },
                    text: 'Sequence',
                    wrap: {
                        label: 'Restart sequence after reaching End value',
                    },
                },
            },
            unqName: 'Token name must be unique',
        },
        tokenHelp: 'A serial number format is defined by a set of one or more "tokens", each of which can be configured to represent a different part of the serial number',
        tokensLabel: 'Serial Number Format tokens:',
        unique: {
            label: 'Duplicate Value Handling:',
        },
        uniqueness: {
            globallyUnique: {
                text: 'Unique among all formats',
            },
            nonUnique: {
                text: 'Non Unique',
            },
            unique: {
                text: 'Unique among this format',
            },
        },
        validationPattern: {
            desc: 'A {regexpLink} pattern to validate the entire serial number',
            failed: 'Failed Validation',
            label: 'Validation Pattern',
        },
    },
    titles: {
        instances: {
            pageBannerSubtitle: 'Serial Numbers generated from this format',
        },
        nav: 'Serial Numbers',
        noName: 'Unnamed Serial Number Format',
        pageBannerSubtitle: 'Create and View Serial Number Formats',
        pageBannerTitle: 'Serial Number Formats',
    },
};
