import { validateAcls } from '@redviking/argonaut-core-ui/src/auth';

export function validateFileSelectAcl (): boolean {
    // todo: change to check acl when it is created
    return validateAcls([ 'file_select' ]);
}

export function validateFileInsertAcl (): boolean {
    // todo: change to check acl when it is created
    return validateAcls([ 'file_insert' ]);
}

export function validateFileUpdateAcl (): boolean {
    // todo: change to check acl when it is created
    return validateAcls([ 'file_update' ]);
}

export function validateFileDeleteAcl (): boolean {
    // todo: change to check acl when it is created
    return validateAcls([ 'file_delete' ]);
}
