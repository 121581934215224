import {
    AddLinkDocument,
    GetAllLinksDocument,
    OrderBy,
    UpdateLinkDocument,
} from 'types/db';
import { Route } from 'vue-router';
import { gqlClient } from '@redviking/argonaut-core-ui/src/util/gql-client';
import cloneDeep from 'lodash.clonedeep';
import { Notify } from 'src/notifications';
import { SaveResult } from 'types/routes';
import { v4 as uuidv4 } from 'uuid';
import { EntityTypeMap } from '../../types/entity';
import { accessor } from '@redviking/argonaut-core-ui/src/store';
import { validateLinkAdminAcl } from './link.validations';
import {
    type EntityModifyParameters,
    type ExtendedEntityParameters,
} from 'src/components/EntityDetail';

type Link = EntityTypeMap['link'];

export const sampleLink: Link = {
    id: uuidv4(),
    group_name: '',
    name: '',
    description: '',
    url: '',
    shared: true,
};

export function upperCaseFirstCharacter (str: string): string {
    return `${str}`.charAt(0).toUpperCase() + `${str}`.slice(1);
}

export function validate (link: Link): boolean {
    if (!link.name) {
        Notify.error('Name is required');
        return false;
    }
    if (!link.group_name) {
        Notify.error('Group Name is required');
        return false;
    }
    if (link.url) {
        try {
            // eslint-disable-next-line unused-imports/no-unused-vars
            const url = new URL(link.url);
        } catch (e) {
            Notify.error(`Url is not valid ${e.message}`);
            return false;
        }
    } else {
        Notify.error('Url is required');
        return false;
    }
    return true;
}

export async function getLinkEntity (to: Route): Promise<{ entity: Link }> {
    if (to.query.mode === 'create') {
        return { entity: { ...cloneDeep(sampleLink), id: uuidv4() } };
    }
    const { links } = await gqlClient.request({
        document: GetAllLinksDocument,
        variables: {
            filter: {
                id: { _eq: to.params.linkId },
            },
            colsToOrderBy: {
                group_name: OrderBy.Asc,
                name: OrderBy.Asc,
            },
        },
    });

    if (links[0]) {
        return {
            entity: links[0],
        };
    } else {
        const err = new Error('Unknown Link');
        Notify.error(err);
        throw err;
    }
}

export async function saveLinkEntity (payload: ExtendedEntityParameters<'link'>): Promise<void | SaveResult> {

    const entity = payload.entity;

    // Give visual feedback that while the user may have the link_update ACL, certain links cannot be updated
    if (!validateLinkAdminAcl() && entity.created_by_id !== accessor.auth.currentUser?.id) {
        return Notify.error('Permission denied: Only admins can edit links they did not create');
    }

    if (!validateLinkAdminAcl() && entity.shared === true) {
        return Notify.error('Permission denied: Only admins can make links shared');
    }

    if (validate(entity)) {
        const data = await gqlClient.request({
            document: UpdateLinkDocument,
            variables: {
                linkId: entity.id,
                link: {
                    group_name: upperCaseFirstCharacter(entity.group_name),
                    name: upperCaseFirstCharacter(entity.name),
                    description: entity.description,
                    url: entity.url,
                    shared: entity.shared,
                },
            },
        });
        if (Number(data?.updateResults?.affected_rows) !== 1) {
            throw new Error('Failed to save Link');
        }

        return {
            status: 'success',
            route: {
                name: 'home',
            },
            newEntity: {
                ...entity,
            },
        };
    } else {
        return { status: 'error' };
    }
}

export async function createLinkEntity (payload: EntityModifyParameters<'link'>): Promise<SaveResult | void> {
    const entity = payload.entity;
    entity.group_name = upperCaseFirstCharacter(entity.group_name);
    entity.name = upperCaseFirstCharacter(entity.name);
    if (validate(entity)) {
        await gqlClient.request({
            document: AddLinkDocument,
            variables: {
                link: [ entity ],
            },
        });
        return {
            status: 'success',
            route: {
                name: 'home',
            },
        };
    } else {
        return { status: 'error' };
    }
}
