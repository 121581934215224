
import { Ref, computed, defineComponent, ref, watch } from 'vue';
import { FabBannerBtn } from 'src/components/FabBannerBtn';
import {
    DeleteLinkDocument,
    GetAllLinksQueryVariables,
    OrderBy,
    useGetAllLinksQuery,
} from '@redviking/argonaut-core-ui/types/db';
import {
    validateLinkAdminAcl,
    validateLinkDeleteAcl,
    validateLinkInsertAcl,
    validateLinkSelectAcl,
    validateLinkUpdateAcl,
} from 'src/links/link.validations';
import _uniqBy from 'lodash.uniqby';
import { v4 as uuidv4 } from 'uuid';
import { accessor } from '@redviking/argonaut-core-ui/src/store';
import { Notify } from 'src/notifications';
import { gqlClient } from '@redviking/argonaut-core-ui/src/util/gql-client';

type LinkHeader = {
    type: 'header',
    key: string,
    name: string,
};

type LinkItem = {
    id: string,
    type: 'item',
    key: string,
    name: string,
    url: string,
    shared: boolean,
    created_by_id: string,
};

export default defineComponent({
    components: {
        FabBannerBtn,
    },
    setup () {
        const checkAdminAcl = computed(() => validateLinkAdminAcl());
        const checkInsertAcl = computed(() => validateLinkInsertAcl());
        const checkDeleteAcl = computed(() => validateLinkDeleteAcl());
        const checkUpdateAcl = computed(() => validateLinkUpdateAcl());
        const checkSelectAcl = computed(() => validateLinkSelectAcl());
        const drawer: Ref<boolean> = ref(false);
        const deleteModal = ref(false);
        const linkToDelete: Ref<LinkItem | null> = ref(null);

        const {
            result: allLinksResult,
            refetch: refetchAllLinks,
            loading: loadingLinks,
        } = useGetAllLinksQuery(computed(() => ({
            filter: {},
            colsToOrderBy: {
                group_name: OrderBy.Asc,
                name: OrderBy.Asc,
            },
            offset: 0,
        } as GetAllLinksQueryVariables)), computed(() => ({
            debounce: 250,
            enabled: Boolean(accessor.auth.gqlToken),
        })));

        const links: Ref<(LinkHeader | LinkItem)[]> = computed(() => {
            const linkResults = allLinksResult.value?.links || [];
            const uniqueGroups = _uniqBy(linkResults || [], 'group_name').map(i => i.group_name);
            let results: (LinkHeader | LinkItem)[] = [];
            uniqueGroups.forEach(groupName => {
                results.push({ key: uuidv4(), type: 'header', name: groupName });
                const items = linkResults.filter(b => b.group_name === groupName);
                results = results.concat(items.map(b => ({
                    id: b.id,
                    key: uuidv4(),
                    type: 'item',
                    name: b.name,
                    url: b.url,
                    shared: b.shared,
                    created_by_id: b.created_by_id || '',
                })));
            });
            return results;
        });

        watch(computed(() => accessor.auth.currentUser), () => {
            refetchAllLinks();
            if (drawer.value) {
                drawer.value = false;
            }
        });

        const checkOwner = (link: LinkItem | null): boolean => {
            if (!link) {
                return false;
            }
            return accessor.auth.currentUser?.id === link?.created_by_id;
        };

        return {
            checkAdminAcl,
            checkInsertAcl,
            checkDeleteAcl,
            checkUpdateAcl,
            checkSelectAcl,
            checkOwner,
            loadingLinks,
            links,
            linkToDelete,
            drawer,
            deleteModal,
            haveNoLinks: computed(() => !loadingLinks.value && links.value.length === 0),
            getUuidv4 () {
                return uuidv4();
            },
            gotoExternalUrl (href: string) {
                try {
                    window.open(new URL(href));
                } catch (e) {
                    Notify.error(`Url is not valid ${e.message}`);
                    return false;
                }
            },
            promptToDeleteBoomark (link: LinkItem): void {
                linkToDelete.value = link;
                deleteModal.value = true;
            },
            async deleteLink (): Promise<void> {
                try {
                    if (!linkToDelete.value || !linkToDelete.value.id) {
                        return Notify.error('No Link to delete');
                    }
                    if (!checkAdminAcl || !checkOwner(linkToDelete.value)) {
                        return Notify.error('Permission denied: Not an admin or the creator of the Link');
                    }
                    await gqlClient.request({
                        document: DeleteLinkDocument,
                        variables: { linkId: linkToDelete.value?.id },
                    });
                    deleteModal.value = false;
                    refetchAllLinks();
                } catch (e) {
                    return Notify.error(e);
                }
            },
        };
    },
});
