/* eslint-disable max-lines */
import { AuthorLike } from '../../types/entity';
import { ArgoTimestampFormatters } from './i18n';

const tsFormatters: ArgoTimestampFormatters = {
    date: new Intl.DateTimeFormat('en', {
        day: 'numeric',
        month: 'numeric',
        year: '2-digit',
    }),
    long: new Intl.DateTimeFormat('en', {
        day: 'numeric',
        hour: 'numeric',
        hourCycle: 'h12',
        minute: 'numeric',
        month: 'short',
        timeZoneName: 'short',
        weekday: 'short',
        year: 'numeric',
    }),
    ms: new Intl.DateTimeFormat('en', {
        day: 'numeric',
        fractionalSecondDigits: 3,
        hour: 'numeric',
        hourCycle: 'h23',
        minute: 'numeric',
        month: 'numeric',
        second: 'numeric',
        year: '2-digit',
    }),
    short: new Intl.DateTimeFormat('en', {
        day: 'numeric',
        hour: 'numeric',
        hourCycle: 'h12',
        minute: 'numeric',
        month: 'numeric',
        year: '2-digit',
    }),
    time: new Intl.DateTimeFormat('en', {
        hour: 'numeric',
        hourCycle: 'h12',
        minute: 'numeric',
        second: 'numeric',
    }),
    utc: new Intl.DateTimeFormat('en', {
        day: 'numeric',
        fractionalSecondDigits: 3,
        hour: 'numeric',
        hourCycle: 'h23',
        minute: 'numeric',
        month: 'numeric',
        second: 'numeric',
        timeZone: 'UTC',
        timeZoneName: 'short',
        year: '2-digit',
    }),
};

/**
 * Translations that can be used by multiple pages
 */
export const enMessages = {
    tsFormatters,
    userToString<U extends AuthorLike> (values: { user?: U | null }) {
        let displayName = '';
        if (values.user) {
            if ('firstName' in values.user && values.user.firstName && values.user.lastName) {
                displayName = `${values.user.firstName} ${values.user.lastName}`;
            } else if (displayName.length === 0 && 'username' in values.user) {
                displayName = values.user.username;
            }
        }
        return displayName;
    },
};
