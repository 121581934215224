export const messages = {
    desc: 'Publish a variable value to an MQTT topic',
    errors: {
        _other: 'Config is invalid',
        condition: 'Publish condition is invalid',
        payload: 'Payload content is invalid',
        topic: 'Topic is invalid',
    },
    payload: {
        payload: {
            label: 'Content',
        },
        publishResultVar: {
            hint: 'Variable will be set to the current timestamp on success, or an error message on failure',
            label: 'Store the result of the publish as a variable (optional)',
        },
        title: 'Payload',
        topic: {
            hint: 'The topic to publish the payload to',
            label: 'Topic',
            placeholder: 'an/example/topic',
        },
    },
    sendBehavior: {
        condition: {
            prefix: 'Only publish the payload when',
            suffix: 'the following comparisons are satisfied:',
        },
        qos: {
            label: 'QOS (Quality of Service)',
        },
        retain: {
            label: 'Retain',
        },
        title: 'Send Behavior',
    },
    title: 'MQTT Publish',
};
