import { userIsAdmin, validateAcls } from '@redviking/argonaut-core-ui/src/auth';

export function validateUserSelectAcl (): boolean {
    return validateAcls([ 'user_select' ]);
}

export function validateUserInsertAcl (): boolean {
    return validateAcls([ 'user_insert' ]);
}

export function validateUserUpdateAcl (): boolean {
    return validateAcls([ 'user_update' ]);
}

export function validateUserDeleteAcl (): boolean {
    return validateAcls([ 'user_delete', 'user_update' ]);
}

export function validateUserRoleSelectAcl (): boolean {
    return validateAcls([ 'user_role_select' ]);
}

export function validateUserRoleEdit (): boolean {
    return validateAcls([ 'user_role_insert', 'user_role_delete', 'user_role_update' ]);
}

export function validateUserContextSelect (): boolean {
    return validateAcls([ 'user_context_select' ]);
}

export function validateUserContextEdit (): boolean {
    return validateAcls([ 'user_context_insert', 'user_context_delete', 'user_context_update' ]);
}

export function validateUserAuthEdit (): boolean {
    return validateAcls([ 'auth_provider_record_insert', 'auth_provider_record_delete', 'auth_provider_record_update' ]);
}

export function isAdmin (): boolean {
    return userIsAdmin();
}
