export const messages = {
    link: {
        addNew: 'Add New Link',
        cancel: 'Cancel',
        confirmDelete: 'Are you sure you want to delete this Link?',
        create: 'Create Link',
        delete: 'Delete Link',
        description: 'Description',
        edit: 'Edit Link',
        groupName: 'Group Name',
        inspect: 'Inspect Link',
        name: 'Link Name',
        noneFound: 'No Links Found',
        notShared: 'Not Shared',
        notifySaved: 'Link Saved!',
        search: 'Search Links by Name or Description',
        shared: 'Shared',
        url: 'URL',
    },
    titles: {
        link: {
            nav: 'Links',
            noName: 'Unnamed Link',
            pageBannerSubtitle: 'Browse and Manage Links',
            pageBannerTitle: 'Links',
        },
    },
};
