import { z } from 'zod';
import { createMaterialProviderAttrs_v3, createMaterialProviderSchema_v3, materialModelSchema_v3 } from '../../v3/var-providers/create-material.v3.zod';

export const materialModelSchema_v5 = materialModelSchema_v3.omit({
    attributes: true,
}).extend({
    /** keyed by attribute id, value is formatted name for UI */
    attributes: z.record(z.string(), z.object({
        name: z.string(),
        schema_id: z.string(),
    })),
});

export const createMaterialProviderAttrs_v5 = createMaterialProviderAttrs_v3.omit({
    materialModels: true,
}).extend({
    materialModels: z.array(materialModelSchema_v5),
});

export const createMaterialProviderSchema_v5 = createMaterialProviderSchema_v3.omit({
    attrs: true,
}).extend({
    attrs: createMaterialProviderAttrs_v5,
});
