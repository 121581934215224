import { getThemeColor } from '../util/theme';
import { ThemeColors } from '../vue-plugins';

export enum NotifyLevel {
    Error = 'error',
    Warning = 'warn',
    Success = 'win',
    Info = 'info',
    Debug = 'debug',
    /** for excessive debug messages... It's a real log level. {@link https://docs.npmjs.com/misc/config#loglevel No, really!} */
    Silly = 'silly',
}
export class ArgoNotification {
    /** importance level / notification type */
    level: NotifyLevel;
    message: string | null;

    /** if not specified, one will be inferred from the level */
    icon?: string;

    /** if not specified, one will be inferred from the level */
    color?: string;

    meta?: unknown;

    /** whether the notification has been opened in the dialog view */
    seen: boolean;

    timestamp: Date;

    // eslint-disable-next-line class-methods-use-this
    private levelIcon (level: NotifyLevel): string {
        switch (level) {
            case NotifyLevel.Warning: {
                return 'mdi-alert';
            }
            case NotifyLevel.Error: {
                return 'mdi-alert-octagon';
            }
            case NotifyLevel.Success: {
                return 'mdi-check';
            }
            case NotifyLevel.Info: {
                return 'mdi-information';
            }
            case NotifyLevel.Debug: {
                return 'mdi-bug';
            }
            case NotifyLevel.Silly: {
                return 'mdi-tag-faces';
            }
            default: {
                return '';
            }
        }
    }

    // eslint-disable-next-line class-methods-use-this
    private levelColor (level: NotifyLevel): string {
        switch (level) {
            case NotifyLevel.Warning: {
                return getThemeColor('warning');
            }
            case NotifyLevel.Error: {
                return getThemeColor('error');
            }
            case NotifyLevel.Success: {
                return getThemeColor('success');
            }
            case NotifyLevel.Info: {
                return getThemeColor('info');
            }
            case NotifyLevel.Debug: {
                return getThemeColor('debug');
            }
            case NotifyLevel.Silly: {
                return `hsl(${Math.floor(Math.random() * 16777215) % 360}, ${(Math.floor(Math.random() * 16777215) % 30) + 70}%, 50%)`;
            }
            default: {
                return '';
            }
        }
    }

    constructor (opts: NotificationOptions) {
        this.level = opts.level;
        this.message = opts.message;
        this.icon = opts.icon ? opts.icon : this.levelIcon(opts.level);
        if (opts.color) {
            this.color = getThemeColor(opts.color as Parameters<typeof getThemeColor>[0], 'base', true) || opts.color;
        } else {
            const levelColor = this.levelColor(opts.level);
            this.color = getThemeColor(levelColor as keyof ThemeColors, 'base', true) || levelColor;
        }
        this.meta = opts.meta;

        this.seen = false;
        this.timestamp = new Date();
    }
}
type NotificationOptions = Omit<ArgoNotification, 'seen' | 'timestamp'>;
