import { VarOrConst } from '@redviking/argonaut-util/types/mes/var-provider-base';

/**
 * for things that use `VarOrConst` that requires a value, this validates it
 */
export function varOrConstIsSet (x: VarOrConst) {
    if (x.type === 'const') {
        return x.val !== null && (typeof x.val === 'number' || Boolean(x.val));
    } else {
        return Boolean(x.var);
    }
}

/**
 * for each of the args that can be a var or a const, return the full list of vars used, omitting blanks and sorting.
 *
 * great for computing `inputs` on a varp
 */
export function organizeUsedVars (...args: (string | VarOrConst)[]): string[] {
    const result: string[] = [];
    for (const arg of args) {
        if (typeof arg === 'string') {
            if (arg) {
                result.push(arg);
            }
        } else if (arg.type === 'var' && arg.var) {
            result.push(arg.var);
        }
    }
    return result.sort();
}
