import { z } from 'zod';
import { varNameSchema } from '../../var.zod';
import { varConditionSchema_v3 } from './conditional.v3.zod';
import { varOrConstSchema_v3, varpConfigBase_v3 } from './var-provider.v3.zod';

export const timerProviderSchema_v3 = varpConfigBase_v3.extend({
    type: z.literal('timer'),
    attrs: z.object({
        resetCond: varConditionSchema_v3,
        /**
         * when this condition is met, the timer will be paused
         * @default null
         */
        pauseCond: z.union([ varConditionSchema_v3, z.null() ]),
        /**
         * @default 'down'
         */
        dir: z.enum([ 'up', 'down' ]),
        /**
         * in seconds
         * @default type: 'const', val: 0
         */
        minVal: varOrConstSchema_v3(),
        /**
         * in seconds
         * @default type: 'const', val: 60
         */
        maxVal: varOrConstSchema_v3(),
        /**
         * this variable will increment or decrement (depending on the value of `dir`)
         * for each second tick that occurs while the timer is not paused.
         */
        outputVar: varNameSchema,
    }),
});
