import { z } from 'zod';
import { ArgoAttributeSchemaDefinitionTypeEnum } from '../db';

const attributeSettingConfigurationType = z.enum([ 'none', 'limit', 'range' ]);

export type AttributeSettingConfigurationType = z.infer<typeof attributeSettingConfigurationType>;

const baseAttributeSettingConfiguration = z.object({
    strict: z.boolean(),
    type: attributeSettingConfigurationType,
});

const rangeAttributeSettingOperator = z.enum([ 'max', 'min' ]);

export type RangeAttributeSettingOperator = z.infer<typeof rangeAttributeSettingOperator>;

const rangeAttributeSettingConfiguration = baseAttributeSettingConfiguration.extend({
    type: z.literal('range'),
    min: z.object({
        value: z.number(),
    }),
    max: z.object({
        value: z.number(),
    }),
});

export type RangeAttributeSettingConfiguration = z.infer<typeof rangeAttributeSettingConfiguration>;

const noneAttributeSettingConfiguration = baseAttributeSettingConfiguration.extend({
    type: z.literal('none'),
});

export type NoneAttributeSettingConfiguration = z.infer<typeof noneAttributeSettingConfiguration>;

const limitAttributeSettingOperator = z.union([ rangeAttributeSettingOperator, z.literal('exactly') ]);

export type LimitAttributeSettingOperator = z.infer<typeof limitAttributeSettingOperator>;

const limitAttributeSettingConfiguration = baseAttributeSettingConfiguration.extend({
    type: z.literal('limit'),
    amount: z.number(),
    operator: limitAttributeSettingOperator,
});

export type LimitAttributeSettingConfiguration = z.infer<typeof limitAttributeSettingConfiguration>;

export const attributeSettingConfiguration = z.union([ rangeAttributeSettingConfiguration, noneAttributeSettingConfiguration, limitAttributeSettingConfiguration ]);

// This is the type def that code gen reads from to know the shape of the config of an attribute setting
export type AttributeSettingConfiguration = z.infer<typeof attributeSettingConfiguration>;

export type AttributeSettingForAttributeLinker = {
    config: AttributeSettingConfiguration;
    attributeSchema: {
        id: string;
        name: string;
        description: string;
        properties: {
            id: string;
            name: string;
            type: ArgoAttributeSchemaDefinitionTypeEnum;
        }[];
    }
}

export const keyedRangeAttributeSettingOperators: {
    text: string;
    value: RangeAttributeSettingOperator;
}[] = [
    {
        value: 'max',
        text: 'At most',
    },
    {
        value: 'min',
        text: 'At least',
    },
];

export const keyedLimitAttributeSettingOperators: {
    text: string;
    value: LimitAttributeSettingOperator;
}[] = [
    ...keyedRangeAttributeSettingOperators,
    {
        text: 'Exactly',
        value: 'exactly',
    },
];
