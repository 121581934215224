import { cellCfgAttrsBaseSchema_v3, cellCfgBaseSchema_v3 } from './cell-base.v3.zod';

import z from 'zod';
import { baseComparisonSchema_v3, baseOperandSchema_v3, varConditionSchema_v3 } from '../var-providers/conditional.v3.zod';
import { ProcessProcessStatusEnum } from '../../../../db';

const processOperandSchema_v3 = baseOperandSchema_v3.extend({
    type: z.literal('processId'),
    processId: z.string(),
});

const processStatusOperandSchema_v3 = baseOperandSchema_v3.extend({
    type: z.literal('processStatus'),
    expectedStatus: z.enum([
        ProcessProcessStatusEnum.Completed,
        ProcessProcessStatusEnum.Incomplete,
        ProcessProcessStatusEnum.Pending,
    ]),
});

const materialFilterComparisonSchema_v3 = baseComparisonSchema_v3.extend({
    type: z.literal('processStatus'),
    operands: z.tuple([
        processOperandSchema_v3,
        processStatusOperandSchema_v3,
    ]),
});

export const materialLookupAttrsSchema_v3 = cellCfgAttrsBaseSchema_v3.extend({
    matClassId: z.string(),
    /**
     * when a material result is selected, data will be saved to these local vars
     */
    outputVars: z.object({
        materialId: z.string(),
        sn: z.string(),
        udfs: z.array(z.object({
            udfColumnId: z.string(),
            varName: z.string(),
        })),
    }),
    udfColsToShow: z.array(z.object({
        udfColumnId: z.string(),
        udfName: z.string(),
    })),
    /**
     * all these get ANDed together
     */
    filterConditions: z.array(z.object({
        boolOperation: z.enum([ 'and', 'or' ]),
        comparisons: z.array(materialFilterComparisonSchema_v3),
    })),
    /**
     * the cell will initially perform its query as soon as it can.
     * this condition controls when it re-runs the query.
    */
    refreshCondition: varConditionSchema_v3,
});

export const materialLookupCellSchema_v3 = cellCfgBaseSchema_v3.extend({
    type: z.literal('materialLookup'),
    attrs: materialLookupAttrsSchema_v3,
});
