/* eslint-disable function-paren-newline */
import { z } from 'zod';
import { DeployParamKind, deployParamSchema } from '@redviking/argonaut-util/src/mes/deployParam.zod';
import { varOrConstSchema_v3 } from '../var-providers/var-provider.v3.zod';

const sparkplugCfgSchema_v3 = z.object({
  nodeId: z.string(),
  version: z.string(),
  groupId: z.string(),
  deviceId: z.string(),
});

export const sparkplugAppletNodeCfgManualSchema_v3 = sparkplugCfgSchema_v3.extend({
    deviceId: varOrConstSchema_v3(z.string()),
    type: z.literal('manual'),
});

const sparkplugAppletNodeCfgDeployParamSchema_v3 = deployParamSchema(z.object({
    deployParamKind: z.literal(DeployParamKind.sparkplugNodeCfg),
    deployParamId: z.string(),
    type: z.literal('deployParam'),
}));

export const sparkplugAppletNodeCfgSchema_v3 = z.union([
    sparkplugAppletNodeCfgManualSchema_v3.strict(),
    sparkplugAppletNodeCfgDeployParamSchema_v3,
]);
