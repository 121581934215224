import { cellCfgAttrsBaseSchema_v3, cellCfgBaseSchema_v3 } from './cell-base.v3.zod';

import z from 'zod';
import { varOrConstSchema_v3 } from '../var-providers/var-provider.v3.zod';
import { varConditionSchema_v3 } from '../var-providers/conditional.v3.zod';

const pageRuleBase_v3 = z.object({
    action: z.string(),
    condition: varConditionSchema_v3,
});

/**
 * advances to the next page that is not disabled
 *
 * - if all pages are disabled do not switch and change page state to `error`
 * - if the page that is ready to be advanced is the last page advance to the first page if it is not disabled. If it is find the next page that isn't
 */
const advanceAutoPageRuleSchema_v3 = pageRuleBase_v3.extend({
    action: z.literal('advance'),
});

/**
 * advances to the page with the given cell name.
 *
 * - if the target page is disabled do not switch and change the pages state to `warn`
 */
const advanceToPageRuleSchema_v3 = pageRuleBase_v3.extend({
    action: z.literal('advanceTo'),
    pageNumber: z.number(),
});

/**
 * Disables the page, prevents the page from being activated.
 *
 * - if any of the rules are true, disable the page
 * - if a currently visible page is disabled, nothing happens. Only future attempts to activate a disabled page will be rejected.
 */
const disablePageRuleSchema_v3 = pageRuleBase_v3.extend({
    action: z.literal('disable'),
});

const pageDisplayRuleEnumSchema_v3 = z.enum([ 'success', 'error', 'warning', 'info', 'default' ]);

/**
 * applies an appearance to the page in the list.
 *
 * evaluated whenever, and the first state rule to match is applied.
 */
const pageDisplayRuleSchema_v3 = pageRuleBase_v3.extend({
    action: z.literal('display'),
    status: pageDisplayRuleEnumSchema_v3,
});

/**
 * Aggregation of all of the rules that attempt to advance the page
 *
 * The first condition that is true will be applied
 */
const advancePageRuleSchema_v3 = z.discriminatedUnion('action', [
    advanceAutoPageRuleSchema_v3,
    advanceToPageRuleSchema_v3,
]);
export type AdvancePageRule_v3 = z.infer<typeof advancePageRuleSchema_v3>;

export const pageCfgSchema_v3 = z.object({
    rules: z.object({
        advanceRules: z.array(advancePageRuleSchema_v3),
        disableRules: z.array(disablePageRuleSchema_v3),
        displayRules: z.array(pageDisplayRuleSchema_v3),
    }),
    pageName: varOrConstSchema_v3(z.string()),
    hideWhenDisabled: z.boolean(),
    id: z.string(),
});

export const pagedResetConditionSchema_v3 = z.object({
    /**
     * Condition on when to reset the page sequence
     */
    condition: varConditionSchema_v3,
    /**
     * Array of vars to clear the value of during a sequence reset.
     *
     * Ending result of vars in this array will be `{status: 'ok', val: null}`
     */
    vars: z.array(z.string()),
});

export const pagedAttrsSchemaDef_v3 = cellCfgAttrsBaseSchema_v3.extend({
    transition: z.enum([ 'slide-x', 'slide-y' ]),
    /** the variable to output the current page */
    currentPageVar: z.string(),
    resetConditions: z.array(pagedResetConditionSchema_v3),
    btnSize: z.enum([ 'small', 'medium', 'large', 'x-large', 'hidden' ]).default('medium'),
    pages: z.array(pageCfgSchema_v3),
});

export const pagedCellSchema_v3 = cellCfgBaseSchema_v3.extend({
    type: z.literal('paged'),
    attrs: pagedAttrsSchemaDef_v3,
    modifiers: z.record(z.enum([
        'transition',
        'btnSize',
        'background',
        'borderColor',
    ]), z.string().optional()).optional(),
});
