import { ArgoFile } from 'types';

export function argoFilePreviewToBase64DataUrl (fileIcon: Pick<ArgoFile, 'preview_type' | 'preview_subtype' | 'preview'>) {
    if (fileIcon) {
        return `data:${fileIcon.preview_type}/${fileIcon.preview_subtype};base64,${fileIcon.preview}`;
    }
    return '';
}
export function argoFileContentToBase64DataUrl (fileIcon: Pick<ArgoFile, 'content_type' | 'content_subtype' | 'content'>) {
    if (fileIcon) {
        return `data:${fileIcon.content_type}/${fileIcon.content_subtype};base64,${fileIcon.content}`;
    }
    return '';
}

export function getFileAsBase64 (file: File): Promise<string | null> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = function onload () {
            if (reader.result instanceof ArrayBuffer) {
                reject(new Error('Array Buffers Not Supported'));
            } else {
                resolve(reader.result);
            }
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
    });
}


export function getPreviewIconForFile (base64DataUrl?: string): string {
    const filetype = base64DataUrl?.substring(base64DataUrl.indexOf(':'), base64DataUrl.indexOf('/')) || '';

    switch (filetype) {
        case 'text':
            return 'mdi-format-size';
        case 'model':
            return 'mdi-video-3d';
        case 'image':
            return 'mdi-file-image-outline';
        default:
            return 'mdi-file-outline';
    }
}

export function getMimeTypeFromDataUrl (dataUrl: string) {
    return dataUrl.slice(5, dataUrl.indexOf(';'));
}

/**
 *
 * @param inpImage Image input in base64 data url format
 * @param maxDimensionalLength length of the longest dimension to constrain on
 * @returns Returns a base 64 data url for the image constrained by whichever axis meets the given constraint amount
 */
export function constrainToDimension (inpImage: string, maxDimensionalLength: number): Promise<string> {
    return new Promise((yah) => {
        const img = new Image();
        const mimeType = getMimeTypeFromDataUrl(inpImage);
        const aCanvas = document.createElement('canvas');

        img.onload = async () => {
            const ogHeight = img.height;
            const ogWidth = img.width;

            const scalingRatio = maxDimensionalLength / Math.max(ogHeight, ogWidth);

            aCanvas.width = ogWidth * scalingRatio;
            aCanvas.height = ogHeight * scalingRatio;

            await aCanvas.getContext('2d')?.drawImage(img, 0, 0, Math.floor(ogWidth * scalingRatio), Math.floor(ogHeight * scalingRatio));
            yah(aCanvas.toDataURL(mimeType));
        };
        img.src = inpImage;

    });
}

export function resizeImage (inpImage: string, height: number, width: number, quality?: number): Promise<string> {
    return new Promise((yah) => {
        const img = new Image();
        const mimeType = inpImage.slice(5, inpImage.indexOf(';'));
        img.src = inpImage;
        const aCanvas = document.createElement('canvas');

        img.onload = async () => {
            aCanvas.width = width;
            aCanvas.height = height;

            await aCanvas.getContext('2d')?.drawImage(img, 0, 0, width, height);
            yah(aCanvas.toDataURL(mimeType, quality));
        };
    });
}
