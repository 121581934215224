import {
    ArgoAttributeDataConstraint,
    ArgoAttributeDataUpdateColumn,
    ArgoAttributeInsertInput,
    ArgoAttributeSchemaDefinitionConstraint,
    ArgoAttributeSchemaTypeEnum,
    GetSchemaDocument,
    InsertSchemaDocument,
    UpdateSchemaDocument,
} from '../../types/db';
import { accessor } from '../store';
import {
    type EntityModifyParameters,
    type ExtendedEntityParameters,
    RouteEntityDetail,
} from '@redviking/argonaut-core-ui/src/components/EntityDetail';
import { Route } from 'vue-router';
import RouterViewSlider from '@redviking/argonaut-core-ui/src/components/RouterViewSlider';
import { messages } from 'src/i18n/i18n';
import {
    validateAttributeData,
    validateAttributeSchemaSelectAcl,
    validateAttributeSchemaUpdateAcl,
    validateAttributeSelectAcl,
} from './attributes.validations';
import { entityDetailRoute } from '../components/EntityDetail';
import { Notify } from '../notifications';
import { entityPageBannerTitle } from 'src/util/formatting';
import { gqlClient } from '../util/gql-client';
import { AttributeDefinition, AttributeForSchemaView, AttributeSchemaEntity } from './attribute.entity';
import { updateEntities } from './util';

export default [
    {
        path: '/attributes',
        name: 'attributesGroup',
        redirect: { name: 'attrSchemaList' },
        component: RouterViewSlider,
        meta: {
            navigationGuard: () => validateAttributeSelectAcl(),
            navbarTitle: messages.attribute.titles.attrs.nav,
            navbarIcon: 'mdi-vector-polyline',
            transition: 'vertical',
        },
        children: [
            {
                path: 'schemas',
                name: 'attrSchemas',
                redirect: (to: Route) => ({ name: 'attrSchemaList', params: to.params }),
                component: RouterViewSlider,
                meta: {
                    navigationGuard: () => validateAttributeSchemaSelectAcl(),
                    transition: 'horizontal',
                },
                children: [
                    {
                        name: 'attrSchemaList',
                        path: '',
                        component: () => import(/* webpackChunkName: "attributes" */ './Schemas.view.vue'),
                        meta: {
                            pageBannerSubtitle: messages.attribute.titles.schemas.pageBannerSubtitle,
                            pageBannerTitle: messages.attribute.titles.schemas.pageBannerTitle,
                        },
                    },
                    entityDetailRoute<'attributeschema'>({
                        path: ':schemaId',
                        name: 'attribute-schema-maintenance',
                        component: RouteEntityDetail,
                        redirect: to => ({ name: 'attrSchemaSettings', params: to.params }),
                        meta: {
                            returnRoute: (route: Route) => ({ name: 'attrSchemaList', query: route.query }),
                            canEdit: validateAttributeSchemaUpdateAcl,
                            entityType: 'attributeschema',
                            async getEntity (to) {
                                if (to.query.mode === 'create') {
                                    const ret: AttributeSchemaEntity = {
                                        entitiesToUpdateOnSave: [],
                                        schema: {
                                            meta: {},
                                            enabled: true,
                                            attributes: [],
                                            definitions: [],
                                            description: '',
                                            id: to.params.schemaId,
                                            name: 'New Attribute Schema',
                                            type: ArgoAttributeSchemaTypeEnum.Material,
                                        },
                                    };
                                    return {
                                        entity: ret,
                                    };
                                }
                                const { schema } = await gqlClient.request({
                                    document: GetSchemaDocument,
                                    variables: {
                                        schemaId: to.params.schemaId,
                                    },
                                });
                                if (schema) {
                                    const ret: AttributeSchemaEntity = {
                                        entitiesToUpdateOnSave: [],
                                        schema: {
                                            id: schema.id,
                                            name: schema.name,
                                            type: schema.type,
                                            meta: schema.meta,
                                            enabled: schema.enabled,
                                            description: schema.description,
                                            definitions: schema.definitions.map(def => ({
                                                ...def,
                                                new: false,
                                            })),
                                            attributes: schema.attributes.map(attr => {
                                                const attrRet: AttributeForSchemaView = {
                                                    new: false,
                                                    id: attr.id,
                                                    name: attr.name,
                                                    meta: attr.meta,
                                                    enabled: attr.enabled,
                                                    created_at: attr.created_at,
                                                    description: attr.description,
                                                    attribute_data: attr.attribute_data,
                                                    root_attribute_id: attr.root_attribute_id,
                                                    location_attributes_aggregate: attr.location_attributes_aggregate,
                                                    material_attributes_aggregate: attr.material_attributes_aggregate,
                                                    material_class_attributes_aggregate: attr.material_class_attributes_aggregate,
                                                    material_model_attributes_aggregate: attr.material_model_attributes_aggregate,
                                                };
                                                return attrRet;
                                            }),
                                        },
                                    };
                                    return {
                                        entity: ret,
                                    };
                                } else {
                                    const err = new Error('Unknown Attribute Schema');
                                    Notify.error(err);
                                    throw err;
                                }
                            },
                            createEntity: async (payload: EntityModifyParameters<'attributeschema'>) => {
                                const { entity } = payload;
                                try {
                                    validateAttributeData(entity);
                                    await gqlClient.request({
                                        document: InsertSchemaDocument,
                                        variables: {
                                            schemas: [
                                                {
                                                    id: entity.schema.id,
                                                    type: entity.schema.type,
                                                    name: entity.schema.name,
                                                    meta: entity.schema.meta,
                                                    enabled: entity.schema.enabled,
                                                    description: entity.schema.description,
                                                    attributes: {
                                                        data: entity.schema.attributes.map(attr => ({
                                                            id: attr.id,
                                                            meta: attr.name,
                                                            name: attr.name,
                                                            enabled: attr.enabled,
                                                            description: attr.description,
                                                            root_attribute_id: attr.root_attribute_id,
                                                            attribute_data: {
                                                                data: attr.attribute_data.map(ad => ({
                                                                    id: ad.id,
                                                                    str_val: ad.str_val,
                                                                    num_val: ad.num_val,
                                                                    attribute_schema_definition_id: ad.attribute_schema_definition_id,
                                                                })),
                                                            },
                                                        })),
                                                    },
                                                    attribute_schema_definitions: {
                                                        data: entity.schema.definitions.map(asd => ({
                                                            id: asd.id,
                                                            enabled: true,
                                                            name: asd.name,
                                                            type: asd.type,
                                                            meta: {},
                                                            validation_api_check: false,
                                                            description: asd.description,
                                                            validation_min: asd.validationMin,
                                                            validation_max: asd.validationMax,
                                                            validation_pattern: asd.validationPattern,
                                                            validation_required: asd.validationRequired,
                                                        })),
                                                        on_conflict: {
                                                            constraint: ArgoAttributeSchemaDefinitionConstraint.AttributeSchemaDefinitionPkey,
                                                            update_columns: [],
                                                        },
                                                    },
                                                },
                                            ],
                                        },
                                    }).then(result => {
                                        const newEntity: AttributeSchemaEntity = {
                                            entitiesToUpdateOnSave: [],
                                            schema: {
                                                id: result.insertResults?.schema[0].id || entity.schema.id,
                                                name: result.insertResults?.schema[0].name || entity.schema.name,
                                                type: result.insertResults?.schema[0].type || entity.schema.type,
                                                meta: result.insertResults?.schema[0].meta || entity.schema.meta,
                                                enabled: result.insertResults?.schema[0].enabled || entity.schema.enabled,
                                                description: result.insertResults?.schema[0].description || entity.schema.description,
                                                definitions: result.insertResults?.schema[0].definitions.map(def => ({
                                                    ...def,
                                                    new: false,
                                                })) || [],
                                                attributes: result.insertResults?.schema[0].attributes.map(attr => {
                                                    const attrRet: AttributeForSchemaView = {
                                                        new: false,
                                                        id: attr.id,
                                                        name: attr.name,
                                                        meta: attr.meta,
                                                        enabled: attr.enabled,
                                                        created_at: attr.created_at,
                                                        description: attr.description,
                                                        attribute_data: attr.attribute_data,
                                                        root_attribute_id: attr.root_attribute_id,
                                                        location_attributes_aggregate: attr.location_attributes_aggregate,
                                                        material_attributes_aggregate: attr.material_attributes_aggregate,
                                                        material_class_attributes_aggregate: attr.material_class_attributes_aggregate,
                                                        material_model_attributes_aggregate: attr.material_model_attributes_aggregate,
                                                    };
                                                    return attrRet;
                                                }) || [],
                                            },
                                        };
                                        accessor.setPageEntity({ type: 'attributeschema', entity: newEntity });
                                    });
                                    Notify.win(messages.attribute.schemas.saved);
                                } catch (err) {

                                    Notify.error(err as Error);
                                    throw err;
                                }
                            },
                            saveEntity: async (payload: ExtendedEntityParameters<'attributeschema'>) => {
                                const {
                                    entity,
                                    oldEntity: originalEntity,
                                } = payload;
                                if (!entity.schema.name || entity.schema.name.length <= 0) {
                                    const error = new Error('Schema missing "name" property');
                                    Notify.error(error.message, error);
                                    return;
                                }
                                try {
                                    validateAttributeData(entity);
                                    await gqlClient.request({
                                        document: UpdateSchemaDocument,
                                        variables: {
                                            schemaId: entity.schema.id,
                                            schema: {
                                                meta: entity.schema.meta,
                                                name: entity.schema.name,
                                                enabled: entity.schema.enabled,
                                                description: entity.schema.description,
                                            },
                                            attributeIdsToDelete: originalEntity?.schema.attributes.filter(attr => !entity.schema.attributes.find(a => a.id === attr.id)).map(a => a.id) || [],
                                            schemaDefs: entity.schema.definitions.map(def => ({
                                                meta: {},
                                                id: def.id,
                                                enabled: true,
                                                type: def.type,
                                                name: def.name,
                                                validation_api_check: false,
                                                description: def.description,
                                                attribute_schema_id: entity.schema.id,
                                                validation_min: def.validationMin,
                                                validation_max: def.validationMax,
                                                validation_pattern: def.validationPattern,
                                                validation_required: def.validationRequired,
                                            })),
                                            attributes: entity.schema.attributes.map<ArgoAttributeInsertInput>(attr => {
                                                const ret: ArgoAttributeInsertInput = {
                                                    id: attr.id,
                                                    meta: attr.meta,
                                                    name: attr.name,
                                                    enabled: attr.enabled,
                                                    description: attr.description,
                                                    attribute_schema_id: entity.schema.id,
                                                    root_attribute_id: attr.root_attribute_id,
                                                    attribute_data: {
                                                        data: attr.attribute_data.map(ad => ({
                                                            id: ad.id,
                                                            str_val: ad.str_val,
                                                            num_val: ad.num_val,
                                                            attribute_schema_definition_id: ad.attribute_schema_definition_id,
                                                        })),
                                                        on_conflict: {
                                                            constraint: ArgoAttributeDataConstraint.AttributeDataPkey,
                                                            update_columns: [
                                                                ArgoAttributeDataUpdateColumn.Enabled,
                                                                ArgoAttributeDataUpdateColumn.NumVal,
                                                                ArgoAttributeDataUpdateColumn.StrVal,
                                                            ],
                                                        },
                                                    },
                                                };
                                                return ret;
                                            }),
                                        },
                                    }).then(result => {
                                        if (entity.entitiesToUpdateOnSave.length) {
                                            for (const entityToUpdate of entity.entitiesToUpdateOnSave) {
                                                updateEntities(entityToUpdate, entity.schema.attributes).then(res => {
                                                    if (res.success) {
                                                        Notify.win(res.message as string);
                                                    } else {
                                                        Notify.error(res.message as Error);
                                                    }
                                                });
                                            }
                                        }

                                        const newDefinitions: AttributeDefinition[] = [];
                                        if (result.insert_argo_attribute_schema_definition?.returning.length) {
                                            newDefinitions.push(...result.insert_argo_attribute_schema_definition.returning.map(def => ({
                                                ...def,
                                                new: false,
                                            })));
                                        }
                                        const newEntity: AttributeSchemaEntity = {
                                            entitiesToUpdateOnSave: [],
                                            schema: {
                                                definitions: newDefinitions,
                                                id: result.updateResults?.schema[0].id || entity.schema.id,
                                                name: result.updateResults?.schema[0].name || entity.schema.name,
                                                type: result.updateResults?.schema[0].type || entity.schema.type,
                                                meta: result.updateResults?.schema[0].meta || entity.schema.meta,
                                                enabled: result.updateResults?.schema[0].enabled || entity.schema.enabled,
                                                description: result.updateResults?.schema[0].description || entity.schema.description,
                                                attributes: result.insert_argo_attribute?.returning.map(attr => {
                                                    let location_attributes_aggregate = attr.location_attributes_aggregate;
                                                    let material_attributes_aggregate = attr.material_attributes_aggregate;
                                                    let material_class_attributes_aggregate = attr.material_class_attributes_aggregate;
                                                    let material_model_attributes_aggregate = attr.material_model_attributes_aggregate;

                                                    // if the attribute was updated we need to fetch the aggregate data from the entity in order to keep the count consistent with the DB
                                                    // this is because the aggregate data is not returned in the update response since the update mutation was done in a separate request
                                                    // NOTE: this means that whatever is in the entity on save is what will be shown on the UI which is ok because the data in the entity should be managed correctly
                                                    // NOTE: refreshing the page will cause these values to get updated from the DB, this is just a temporary fix to keep the UI consistent until the page is refreshed
                                                    if (entity.entitiesToUpdateOnSave.find(e => e.newAttributeId === attr.id)) {
                                                        const attrDataFromEntity = entity.schema.attributes.find(entAttr => entAttr.id === attr.id);
                                                        location_attributes_aggregate = {
                                                            aggregate: {
                                                                count: attrDataFromEntity?.location_attributes_aggregate?.aggregate?.count || 0,
                                                            },
                                                        };
                                                        material_attributes_aggregate = {
                                                            aggregate: {
                                                                count: attrDataFromEntity?.material_attributes_aggregate?.aggregate?.count || 0,
                                                            },
                                                        };
                                                        material_class_attributes_aggregate = {
                                                            aggregate: {
                                                                count: attrDataFromEntity?.material_class_attributes_aggregate?.aggregate?.count || 0,
                                                            },
                                                        };
                                                        material_model_attributes_aggregate = {
                                                            aggregate: {
                                                                count: attrDataFromEntity?.material_model_attributes_aggregate?.aggregate?.count || 0,
                                                            },
                                                        };
                                                    }
                                                    const attrRet: AttributeForSchemaView = {
                                                        new: false,
                                                        id: attr.id,
                                                        name: attr.name,
                                                        meta: attr.meta,
                                                        enabled: attr.enabled,
                                                        created_at: attr.created_at,
                                                        description: attr.description,
                                                        location_attributes_aggregate,
                                                        material_attributes_aggregate,
                                                        material_class_attributes_aggregate,
                                                        attribute_data: attr.attribute_data,
                                                        material_model_attributes_aggregate,
                                                        root_attribute_id: attr.root_attribute_id,
                                                    };
                                                    return attrRet;
                                                }) || [],
                                            },
                                        };
                                        accessor.setPageEntity({ type: 'attributeschema', entity: newEntity });
                                    });

                                    Notify.win(messages.attribute.schemas.saved);
                                } catch (err) {
                                    Notify.error(err as Error);
                                    throw err;
                                }
                            },
                            pageBannerTitle: () => entityPageBannerTitle('attributeschema', accessor.entityAsType('attributeschema')?.schema.name),
                        },
                        children: [
                            {
                                path: 'settings',
                                name: 'attrSchemaSettings',
                                component: () => import(/* webpackChunkName: "attributes" */ './SchemaMaintenance.view.vue'), // a component for the settings page content
                                meta: {
                                    tab: { // the presence of this prop indicates this is a tab
                                        label: 'Settings',
                                        icon: 'mdi-cog',
                                    },
                                    navigationGuard: () => true,
                                },
                            },
                            {
                                path: 'attributes',
                                name: 'attrSchemaAttributes',
                                component: () => import(/* webpackChunkName: "attributes" */ './Schema-Attributes.view.vue'),
                                meta: {
                                    tab: {
                                        label: 'Attributes',
                                        icon: 'mdi-vector-polyline',
                                    },
                                    pageBannerSubtitle: 'Schema Attributes',
                                    navigationGuard: () => validateAttributeSelectAcl(),
                                },
                            },
                        ],
                    }),
                ],
            },
        ],
    },
];
