/**
 * possible values for `route.query.mode` on an entity detail page
 */
export enum EntityDetailMode {
    View = 'view',
    Edit = 'edit',
    Create = 'create',
    Fork = 'fork',
    Copy = 'copy',
}
