export const messages = {
    attrs: {
        saved: 'Attributes Saved!',
    },
    modelNumber: {
        addNew: 'Add Model Number',
        confirmDelete: 'Are you sure you want to delete this model number?',
        create: 'Create New Model Number',
        entityName: 'Model Number',
        hide: 'Hide Model Number',
        inspect: 'Inspect Model Number',
        name: 'Model Number',
        noneFound: 'No Model Numbers Found',
        notifySaved: 'Model Number Saved!',
        plural: 'Model Numbers',
        required: 'Name Required!',
        saved: 'Model Number Saved!',
        search: 'Search Model Numbers',
    },
    model_number: 'Model Number',
    titles: {
        nav: 'Model Numbers',
        noName: 'Unnamed Model Number',
        pageBannerSubtitle: 'Browse and edit model numbers',
        pageBannerTitle: 'Model Numbers',
    },
};
