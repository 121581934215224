import { z } from 'zod';
import { DeployParamKind, deployParamSchema } from '@redviking/argonaut-util/src/mes/deployParam.zod';
import { varNameSchema, varValSchema } from '../../var.zod';
import { varpConfigBase_v3 } from './var-provider.v3.zod';

export const localVarCfgSchema_v3 = z.object({
    name: varNameSchema,
    type: z.enum([ 'str', 'num', 'null' ]).default('str'),
    initialVal: varValSchema,
});

const localVarCfgDeployParamSchema_v3 = deployParamSchema(localVarCfgSchema_v3.extend({
    type: z.enum([ 'str', 'num' ]), // omit `null` type, because then the only allowed deployment param value would be `null`
    deployParamKind: z.literal(DeployParamKind.localVarValue),
}));


export const localProviderConfigSchema_v3 = varpConfigBase_v3.extend({
    type: z.literal('local'),
    attrs: z.object({
        vars: z.array(z.union([
            localVarCfgDeployParamSchema_v3,
            localVarCfgSchema_v3.strict(), // strict to prevent using this schema to parse the deploy param version
        ])),
    }),
});

