import { z } from 'zod';
import { varNameSchema } from '../../var.zod';
import { localVarCfgSchema_v3 } from './local.v3.zod';
import { varpConfigBase_v3 } from './var-provider.v3.zod';

export const udfSourceSchema_v3 = z.object({
    /** must be unique across sources */
    matClassName: z.string(),
    matClassId: z.string(),
    udfColumnId: z.string(),
    udfName: z.string(),
});

export const materialIdProviderSchema_v3 = varpConfigBase_v3.extend({
    type: z.literal('materialId'),
    inputs: z.array(varNameSchema),
    attrs: z.object({
        inputVar: z.string(),
        outputVar: z.string(),
        outputVarModelNumber: z.string().optional(),
        outputVarClassName: z.string().optional(),
        udfOutputs: z.array(z.object({
            /** first column in the config ui */
            outputVar: z.string(),
            /** second column in the config ui */
            default: localVarCfgSchema_v3.omit({ name: true }),
            /** each corresponds to an additional column in the config ui */
            sources: z.array(udfSourceSchema_v3),
        })),
    }),
});
