export const messages = {
    attrs: {
        saved: 'Attributes Saved!',
    },
    materialClass: {
        addNew: 'Add New Material Class',
        confirmDelete: 'Are you sure you want to delete this data element?',
        create: 'Create Material Class',
        entityName: 'Material Class',
        hide: 'Hide Material Class',
        inspect: 'Inspect Material Class',
        name: 'Material Class Name',
        noneFound: 'No Material Classes Found',
        notifySaved: 'Material Class Saved!',
        process: {
            buttonText: 'Reassign Processes',
            disclaimer: 'Processes that are removed from this list will not be deleted from previously created material.',
            explaination: 'These are the processes that will be assigned to a material when it is created. If the material was created before a new process is linked, the material will not have the process and they will need to be reassigned.',
            prompt: 'Are you sure you want to reassign processes?',
        },
        search: 'Search Material Class by Name or Description',
        syncProcessesAffectedMaterial: 'Affected Material',
        syncProcessesDialog: 'By syncing processes, all materials created in the last {timeInput} will have their processes updated to match the selected processes. This will not affect materials that have been created before {timeInputValue} ago.',
        totalMaterial: 'Total Material',
        validation: 'Validations',
        validationApiCheck: 'API Validation',
        validationMax: 'Max',
        validationMin: 'Min',
        validationPattern: 'Pattern',
        validationRegex: 'Validation Regex',
        validationRequired: 'Required',
        validationUnique: 'Unique',
        validationUniqueByClass: 'By Class',
        validationUniqueNone: 'None',
    },
    selectionText: (ctx:{ values:{ name: string } }) => {
        return `Select processes to run for ${ctx.values.name}`;
    },
    titles: {
        nav: 'Material Class',
        noName: 'Unnamed Material Class',
        pageBannerSubtitle: 'View Material Class',
        pageBannerTitle: 'Material Classes',
    },
    udfColumn: 'Udf Column',
};
