import { validateAcls } from '@redviking/argonaut-core-ui/src/auth';
import type { RouteConfig } from 'vue-router';
import {
    type ExtendedEntityParameters,
    entityDetailRoute,
} from '@redviking/argonaut-core-ui/src/components/EntityDetail/entity-route-util';
import { accessor } from '@redviking/argonaut-core-ui/src/store';
import {
    generalTabRoute as generalTabRouteDeployment,
    jsonTabRoute,
    paramsTabRoute as paramsTabRouteDeployment,
    sparkplugTabRoute,
} from './util/consts';
import {
    getAppletDeploymentVersion,
    saveAppletDeploymentVersion,
} from './deployment.entity';
import RouteEntityDetail from '@redviking/argonaut-core-ui/src/components/EntityDetail/RouteEntityDetail.view.vue';
import { EntityDetailMode } from '@redviking/argonaut-core-ui/src/components/EntityDetail/mode';
import { CausalError } from '@redviking/causal-error';
import { validateAppletDeployment } from './validations/deployment.validations';
import { Notify } from '@redviking/argonaut-core-ui/src/notifications';

export const appletDeploymentRoute: RouteConfig = entityDetailRoute<'appletDeployment'>({
    path: 'deployment/:appletDeploymentId',
    name: 'appletDeployment',
    component: RouteEntityDetail,
    redirect: to => ({ name: generalTabRouteDeployment, params: to.params }),
    meta: {
        pageBannerTitle: () => {
            const deployment = accessor.entityAsType('appletDeployment');
            if (!deployment) {
                return 'Unknown Deployment';
            }
            const runnerId = deployment.runnerId;
            const deploymentName = deployment.name;
            return `${runnerId} > ${deploymentName}`;
        },
        returnRoute: { name: 'appletRunnerList' },
        canEdit: () => {
            return validateAcls([ 'applet_manager' ]);
        },
        entityType: 'appletDeployment',
        getEntity: getAppletDeploymentVersion,
        saveEntity: async (payload: ExtendedEntityParameters<'appletDeployment'>) => {
            const {
                entity,
            } = payload;
            try {
                validateAppletDeployment(entity);
            } catch (err) {
                Notify.error(err);
                return {
                    status: 'error',
                };
            }

            try {
                return {
                    status: 'success',
                    newEntity: await saveAppletDeploymentVersion(entity),
                    route: {
                        params: {
                            appletRunnerId: entity.runnerId,
                            appletDeploymentId: entity.id,
                        },
                        query: {
                            mode: EntityDetailMode.View,
                        },
                    },
                };
            } catch (err) {
                Notify.error(CausalError.from({
                    message: 'Error saving Applet Deployment',
                    cause: err,
                }));
                return {
                    status: 'error',
                };
            }
        },
    },
    children: [
        {
            path: 'general',
            name: generalTabRouteDeployment,
            component: () => import(/* webpackChunkName: "appletDeployment" */ './General.tab.view.vue'),
            meta: {
                tab: {
                    label: 'General',
                    icon: 'mdi-note-text',
                },
            },
        },
        {
            path: 'params',
            name: paramsTabRouteDeployment,
            component: () => import(/* webpackChunkName: "appletDeployment" */ './Params.tab.view.vue'),
            meta: {
                tab: {
                    label: 'Parameters',
                    icon: 'mdi-cog',
                },
            },
        },
        {
            path: 'sparkplug',
            name: sparkplugTabRoute,
            component: () => import(/* webpackChunkName: "appletDeployment" */ './Sparkplug.tab.view.vue'),
            meta: {
                tab: {
                    label: 'Sparkplug',
                    icon: 'mdi-flash',
                },
            },
        },
        {
            path: 'json',
            name: jsonTabRoute,
            component: () => import(/* webpackChunkName: "appletDeployment" */ './Json.tab.view.vue'),
            meta: {
                tab: {
                    label: 'JSON',
                    icon: 'mdi-code-braces',
                },
            },
        },
    ],
});
