import { Ref, WritableComputedRef, computed } from 'vue';
import { toDatetimeLocalFormat } from './useDatetimeInput';

/**
 * @param input ISO format string (YYYY-MM-DD HH:MM) or Date
 */
export function tsToIsoDateParts (input: Ref<Date | string>, opts?: {
    /**
     * postgres ts does not contain tz info, which means this is a confusing area. A stored TS can represent an absolute UTC time, or a time for the user's locale.
     * this option controls whether or not the input should be assumed as absolute UTC time.
     *
     * @example `true` // (calendar scenario) an input of '1/1/2020 12:00' will be interpreted as '1/1/2020 12:00 UTC' (which would correspond to 7am EST)
     *
     * @example `false` // (schedule scenario) an input of '1/1/2020 12:00' will be interpreted as '1/1/2020 12:00 EST' (input value is converted to EST (if its not already))
     *
     * @default false
     */
    utcInput?: boolean,
}): { isoParts: WritableComputedRef<{ date: string, time: string }>, date: WritableComputedRef<Date> } {
    const utcInput = opts?.utcInput || false;
    const dateObj = computed({
        get: () => {
            let inputStr: string;
            if (typeof input.value === 'string') {

                // if what we're getting is utc time...
                if (utcInput) {
                    inputStr = `${input.value}Z`;
                } else {
                    inputStr = input.value;
                }
            } else /** if instance of Date */{
                // example: Date input is '1/1/2020 13:00UTC'. when `utcInput == true`, we change nothing
                inputStr = input.value.toLocaleString('UTC', utcInput ? { timeZone: 'UTC' } : {});
                inputStr = toDatetimeLocalFormat(new Date(inputStr));
            }

            const inputDate = new Date(inputStr);
            return inputDate;
        },
        set: (dateInp: Date) => {
            if (typeof input.value === 'string') {
                const dateIso = `${toDatetimeLocalFormat(dateInp)}`;
                // if what we're getting is in utc time...
                if (utcInput) {
                    input.value = new Date(dateIso).toISOString().substring(0, 16);
                } else {
                    input.value = toDatetimeLocalFormat(new Date(dateIso));
                }
            } else if (input.value instanceof Date) {
                input.value = dateInp;
            }

        },
    });

    const isoPartsRef = computed({
        get: () => {
            let inputStr: string;
            if (typeof input.value === 'string') {

                // if what we're getting is utc time...
                if (utcInput) {
                    inputStr = `${input.value}Z`;
                } else {
                    inputStr = input.value;
                }
            } else /** if instance of Date */{
                // example: Date input is '1/1/2020 13:00UTC'. when `utcInput == true`, we change nothing
                inputStr = input.value.toLocaleString('UTC', utcInput ? { timeZone: 'UTC' } : {});
                inputStr = toDatetimeLocalFormat(new Date(inputStr));
            }

            const inputDate = new Date(inputStr);
            return {
                date: toDatetimeLocalFormat(inputDate).substring(0, 10),
                time: toDatetimeLocalFormat(inputDate).substring(11, 16),
            };
        },
        set: ({ date: inpDate, time: inpTime }) => {
            if (typeof input.value === 'string') {

                const dateIso = `${inpDate} ${inpTime}`;
                // if what we're getting is utc time...
                if (utcInput) {
                    input.value = new Date(dateIso).toISOString();
                } else {
                    input.value = toDatetimeLocalFormat(new Date(dateIso));
                }
            } else if (input.value instanceof Date) {
                // if what we're getting is utc time...
                if (utcInput) {
                    const dateIso = `${inpDate} ${inpTime}Z`;
                    input.value = new Date(dateIso);
                } else {
                    input.value = new Date(`${inpDate} ${inpTime}`);
                }
            }
        },
    });
    return { isoParts: isoPartsRef, date: dateObj };
}
