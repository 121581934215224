import {
    varpConfigBase_v3 as varpConfigBase,
} from '@redviking/argonaut-util/types/mes/applet-designs/v3/var-providers/var-provider.v3.zod';
import { z } from 'zod';
import { optionalVarNameSchema, varNameSchema } from '../../var.zod';

export const udpReceiveProviderConfigSchema = varpConfigBase.extend({
    type: z.literal('udpReceive'),
    attrs: z.object({
        encoding: z.union([ z.literal('utf8'), z.literal('hex') ]),
        hostInterface: z.string(),
        port: z.number().min(1),
        payloadVar: varNameSchema,
        tsVar: optionalVarNameSchema,
    }),
});

export interface UdpReceiveProviderConfig extends z.infer<typeof udpReceiveProviderConfigSchema> {}
