import { cellCfgAttrsBaseSchema_v3, cellCfgBaseSchema_v3 } from './cell-base.v3.zod';

import z from 'zod';

export const mediaCellSchema_v3 = cellCfgBaseSchema_v3.extend({
    type: z.literal('media'),
    attrs: cellCfgAttrsBaseSchema_v3.extend({
        items: z.array(z.object({
            url: z.string(),
            /** seconds */
            timeout: z.number().optional(),
            caption: z.string().optional(),
            /** name of the variable that will set whether the image is shown or not */
            variable: z.string().optional(),
        })),
    }),
});
