import { z } from 'zod';
import { varNameSchema } from '../../var.zod';
import { varpConfigBase_v3 } from './var-provider.v3.zod';

export const lockingProviderSchema_v3 = varpConfigBase_v3.extend({
    type: z.literal('locking'),
    attrs: z.object({
        vars: z.array(z.object({
            inVarName: varNameSchema,
            outVarName: varNameSchema,
        })),
        lockingVar: varNameSchema,
    }),
});
