import { validateAcls } from '@redviking/argonaut-core-ui/src/auth';
import { EntityTypeMap } from 'types';

export function validateLocationSelectAcl (): boolean {
    return validateAcls([ 'location_select' ]);
}

export function validateLocationInsertAcl (): boolean {
    return validateAcls([ 'location_insert' ]);
}

export function validateLocationUpdateAcl (): boolean {
    return validateAcls([ 'location_update' ]);
}

export function validateLocationDeleteAcl (): boolean {
    return validateAcls([ 'location_delete' ]);
}

export function validateLocation (entityData: EntityTypeMap['location']): boolean {
    const ongoingCalendars = entityData.calendarLinks.filter(e => e.active_range.includes('infinity'));
    if (ongoingCalendars.length > 1) {
        throw new Error(`Only one calendar can be active at a time; Please see\n ${ongoingCalendars.map(e => `${e.calendar.name} at ${e.active_range}`).join(',\n ')}`);
    }
    return true;
}
