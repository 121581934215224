import Vue from 'vue';
import VueRouter, {
    RouteConfig,
} from 'vue-router';
import { messages } from 'src/i18n/i18n';
import NotFound from './NotFound.vue';
import { DebugModePlugin } from '@redviking/argonaut-core-ui/src/vue-plugins/debug-mode';
import { routes } from './routes';

// TODO this class doesn't really do much anymore and can probably be removed
/**
 * A VueRouter that puts its routes config and current route info into a vuex store
 */
export class ArgoRouter extends VueRouter {
    addRoutes (routesToAdd: RouteConfig[]): void {
        // TODO check for redundant routes being added
        for (const routeToAdd of routesToAdd) {
            this.addRoute('main', routeToAdd);
        }
    }
}

Vue.use(ArgoRouter); // Vue automatically protects against installing the same plugin twice

function createRouter () {
    return new ArgoRouter({
        mode: 'history',
        routes,
    });
}

export const router = createRouter();

// give the apps a chance to add their routes
window.addEventListener('load', async () => {
    const { Notify } = await import('../notifications');
    router.addRoute({
        path: '/:pathMatch(.*)*',
        name: '404',
        component: NotFound,
        meta: {
            pageBannerTitle: messages.pageNotFound,
        },
    });

    // router might have not updated yet from the newly added app routes. force a recheck
    const resolvedCurrentRoute = router.resolve(router.currentRoute.fullPath);
    if (resolvedCurrentRoute.route.matched.length === 0) {
        if (DebugModePlugin.enabled) {
            console.trace('initial bounce');
        }
        router.replace({ name: '404' });
    }

    router.afterEach((to) => {
        if (to.matched.length === 0) {
            Notify.error({
                message: 'Page does not exist',
                to: {
                    name: to.name,
                    fullPath: to.fullPath,
                },
            });
            router.replace({ name: '404', path: to.fullPath });
        }
    });
});

// TODO EntityDetail routes should check the meta.canEdit in route configs in the global `beforeResolve` hook, which happens after the `beforeEach` hooks load entity data. Use this to prevent edits on staged configs, for example (which can't be checked until loaded)

if (import.meta.webpackHot) {
    import.meta.webpackHot.accept('src/i18n/i18n'); // no action should be needed
    import.meta.webpackHot.accept('./routes', () => {
        const currentRouteName = router.currentRoute.name;
        // we can't do HMR unless we're on a named route
        if (!currentRouteName) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            import.meta.webpackHot!.decline(); // full reload
            return;
        }

        // we want to replace the routes, but that's only supported in vue-router@^4, which requires vue@^3 (https://github.com/vuejs/vue-router/issues/1234#issuecomment-733841731)
        // luckily, we can use a workaround and simply replace the route matcher, then reset the current route.
        // create a new router, but we'll only use the `matcher`.
        // lovingly ripped from https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
        // @ts-expect-error matcher is not officially exposed
        router.matcher = createRouter().matcher;

        // reset the current route
        router.replace({
            name: currentRouteName,
            params: router.currentRoute.params,
            query: router.currentRoute.query,
            hash: router.currentRoute.hash,
        });
    });
}
