import {
    Ref,
    computed,
    getCurrentInstance,
    inject,
    isRef,
    provide,
    ref,
} from 'vue';

function provideInjectComposable<T> (name: string, defaultSharedValue?: T | Ref<T>) {
    // we create a ref for the global default here so all injectors will have the same instance
    let defaultSharedRef: Ref<T> | undefined;
    if (defaultSharedValue) {
        // if it's already a ref, use that directly. otherwise wrap with `ref`
        defaultSharedRef = isRef(defaultSharedValue) ? defaultSharedValue : ref(defaultSharedValue) as Ref<T>;
    }

    // either a default will be given and we will have a ref, or we will have `undefined`

    return {
        useProvide: (providedRef: Ref<T>) => {
            const vm = getCurrentInstance()!.proxy;
            provide(name, { source: vm, ref: providedRef });
        },
        /**
       * if source is `null`, then the global default was used
       */
        useInject: (injectedDefault?: T | Ref<T>): {
          source: Vue | null,
          ref: Ref<T>
      } => {
            const injected = inject<{ source: Vue, ref: Ref<T> } | undefined>(name, undefined);
            if (injected) {
                return injected;
            } else if (injectedDefault !== undefined) {
                const vm = getCurrentInstance()!.proxy;
                return { source: vm, ref: isRef(injectedDefault) ? injectedDefault : computed(() => injectedDefault) };
            } else if (defaultSharedRef) {
                return { source: null, ref: defaultSharedRef };
            } else {
                throw new Error(`Required ref "${name}" was not provided`);
            }
        },
    };
}

export { provideInjectComposable };
