import { RouteEntityDetail } from 'src/components/EntityDetail';
import { entityDetailRoute } from 'src/components/EntityDetail/entity-route-util';
import { RouterViewSlider } from 'src/components/RouterViewSlider';
import { messages } from 'src/i18n/i18n';
import { createSerialNumFmtEntity, getSerialNumFmtEntity, saveSerialNumFmtEntity } from './serial-number.entity';
import { validateSerialNumberFmtEdit, validateSerialNumberFmtSelect } from './serial-number.validations';
import { entityPageBannerTitle } from 'src/util/formatting';
import { accessor } from 'src/store';
import { createdOrUpdatedByUser } from 'src/util';

export const serialNumberRoutes = [
    {
        name: 'snNav',
        path: 'serial-numbers',
        component: RouterViewSlider,
        redirect: { name: 'snList' },
        meta: {
            navigationGuard: () => validateSerialNumberFmtSelect(),
            navbarTitle: messages.serialNum.titles.nav,
            navbarIcon: '$argo-serial-number',
            transition: 'horizontal',
        },
        children: [
            {
                name: 'snList',
                path: '',
                component: () => import(/* webpackChunkName: "sn" */ './SerialNumFmts.view.vue'),
                meta: {
                    pageBannerTitle: messages.serialNum.titles.pageBannerTitle,
                    pageBannerSubtitle: messages.serialNum.titles.pageBannerSubtitle,
                },
            },
            entityDetailRoute<'serialNumFmt'>({
                path: ':snFmtId',
                name: 'snFmtMaintenence',
                component: RouteEntityDetail,
                redirect: to => ({ name: 'snFmtMaintenenceSettings', params: to.params }),
                meta: {
                    canEdit: validateSerialNumberFmtEdit,
                    entityType: 'serialNumFmt',
                    getEntity: getSerialNumFmtEntity,
                    saveEntity: saveSerialNumFmtEntity,
                    createEntity: createSerialNumFmtEntity,
                    returnRoute: { name: 'snList' },
                    pageBannerTitle: () => entityPageBannerTitle('serialNumFmt', accessor.entityAsType('serialNumFmt')?.name),
                },
                children: [
                    {
                        path: 'settings',
                        name: 'snFmtMaintenenceSettings',
                        component: () => import(/* webpackChunkName: "sn" */ './SerialNumFmtSettings.view.vue'),
                        meta: {
                            tab: {
                                label: messages.settings,
                                icon: 'mdi-cog',
                            },
                            pageBannerSubtitle: () => createdOrUpdatedByUser(accessor.entityAsType('serialNumFmt')),
                        },
                    },
                    {
                        path: 'instances',
                        name: 'snInstances',
                        component: () => import(/* webpackChunkName: "sn" */ './SerialNumInstances.view.vue'),
                        meta: {
                            tab: {
                                label: messages.serialNum.serialNumber.instances,
                                icon: 'mdi-format-list-bulleted',
                            },
                            pageBannerSubtitle: messages.serialNum.titles.instances.pageBannerSubtitle,
                        },
                    },
                ],
            }),
        ],
    },
];
