import { ThemeColors, vuetify } from '../vue-plugins';

enum VariantName {
    base,
    lighten5,
    lighten4,
    lighten3,
    lighten2,
    lighten1,
    darken1,
    darken2,
    darken3,
    darken4,
}

/**
 * Gets a rgb color string from the current theme
 * @param name - name of the color
 * @param variant - variant of the color
 */
export function getThemeColor (
    name: keyof ThemeColors,
    variant: keyof typeof VariantName = 'base',
    silent = false
): string {
    const { currentTheme } = vuetify.framework.theme;
    // themeItem is either a number, string, or object with variants according to vuetify theme.d.ts
    const themeItem = currentTheme[name];

    if (!themeItem) {
        if (!silent) {
            console.warn(`Theme color "${name}" cannot be found`, { currentTheme });
        }
        return '';
    }

    // themeItem is an object?
    if (typeof themeItem === 'object') {
        const themeItemVariant = themeItem[variant];
        if (themeItemVariant) {
            return themeItemVariant;
        }
    }

    // themeItem must be a string or number
    if (variant === 'base') {
        return themeItem as string; // casting here because I can't find a reason to think that this would be anything other than a string
    }

    if (!silent) {
        // if anything other than base was requested, it cannot be used for a string
        console.warn(`Theme color "${name}" variant "${variant}" cannot be found`, { themeItem });
    }
    return '';
}
