import { ColumnApi, GridApi } from '@ag-grid-community/core';
import { mutationTree } from 'typed-vuex';

export type ArgoGridStoreState = {
    persistence: Record<string, {
        rowIdx?: number,
        columnState?: ReturnType<ColumnApi['getColumnState']>,
        filterModel?: ReturnType<GridApi['getFilterModel']>,
    } | undefined>,
};

const argoGridState: ArgoGridStoreState = {
    persistence: {},
};

const mutations = mutationTree(argoGridState, {
    persist (state, payload: { persistKey: string, state: Partial<NonNullable<ArgoGridStoreState['persistence'][string]>> }) {
        if (!state.persistence[payload.persistKey]) {
            state.persistence[payload.persistKey] = {};
        }
        Object.assign(state.persistence[payload.persistKey]!, payload.state);
    },
});

export default {
    namespaced: true,
    state: argoGridState,
    mutations,
};
