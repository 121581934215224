export const messages = {
    assets: {
        coreState: 'Core State',
        entityName: 'Asset',
        new: {
            asset_type: 'Unnamed Asset Type',
            cycle_time: 'Cycle Time',
            cycle_time_hint: 'The ideal time (in seconds) for this asset to produce 1 part.',
            description: 'Unnamed Asset Description',
            name: 'Unnamed Asset',
        },
        plural: 'Assets',

        types: {
            label: 'Asset Type',
            name: 'Asset Type Name',
        },

    },
    catReport: {
        blocked: 'Blocked (m)',
        blocked_secondary: 'Blocked Sec (m)',
        cycling: 'Cycling (m)',
        down_line: 'Down Time (Line)',
        down_station: 'Down Time (Station)',
        graph: {
            title: 'Time Spent in Each State',
            xAxis: 'Assets',
            yAxis: 'Time (s)',
        },
        starved: 'Starved (m)',
        starved_secondary: 'Starved Sec (m)',
        title: 'Constraint Analysis Report',
    },
    headers: {
        actualTime: 'Actual Time',
        assetName: 'Asset',
        availability: 'Availability',
        badCount: 'Bad Count',
        code: 'Code',
        deviceName: 'Device Name',
        downTimeDuration: 'Down Time Duration',
        duration: 'Duration (m)',
        erc: 'ERC',
        ercFull: 'ERC (Expected Report Count)',
        event: 'Event',
        externalOccurrence: 'External Occurrence',
        fault: 'Fault',
        faultTime: 'Fault Time',
        goodCount: 'Good Count',
        jph: 'JPH',
        jphFull: 'JPH (Jobs Per Hour)',
        locationName: 'Location Name',
        meanTimeBetweenFaults: 'Mean Time Between Faults',
        mfOccurrence: 'Fault Occurrence',
        miOccurrence: 'Intervention Occurrence',
        mttr: 'MTTR',
        nonProdTime: 'Non Prod Time',
        occurrence: 'Occurrence',
        prodTime: 'Prod Time',
        shift: 'Shift',
        shiftDay: 'Shift Day',
        stationName: 'Station Name',
        totalCount: 'Total Count',
        totalDownTime: 'Total Down Time',
        totalProductiveTime: 'Total Productive Time',
        utilizationTime: 'Utilization Time',
    },
    mainPage: {
        description: 'This feature shows real-time data from the factory floor, tracked to a configured Asset.',
        settingsTooltip: 'FIS Global Settings',
        title: 'Monitoring',
    },
    settings: {
        assetTypeAssetsCount: 'Assets',
        assetTypeCodesCount: 'Code Definitions',
        assetTypeCreatedAt: 'Created At',
        assetTypeName: 'Name',
        assetTypeUpdatedAt: 'Last Updated',
        assetTypes: 'Asset Type Definitions',
    },
    stateReport: {
        svg: {
            headers: {
                duration: 'Duration',
                endTime: 'End Time',
                info: 'Viewing asset {assetName} from {startTime} to {endTime}',
                startTime: 'Start Time',
                state: 'State',
            },
            title: 'Asset State Reports',
            zoom: {
                title: 'Zoomed In View',
            },
        },
        toolTips: {
            colorLegend: 'State Color Legend',
            detail: 'View Breakdown',
        },

    },
    titles: {
        constraintAnalysis: 'Constraint Analysis',
        faultReport: 'Fault Report',
        monitoring: 'Monitoring',
        mtbfReport: 'MTBF/MTTR',
        oeeReport: 'OEE Report',
    },
};
