import { Notify } from 'src/notifications';
import { gqlClient } from '@redviking/argonaut-core-ui/src/util/gql-client';
import { EntityTypeMap } from 'types';
import { messages } from 'src/i18n/i18n';
import { AddEventDocument, GetEventDocument } from 'types/db';
import { Route } from 'vue-router';
import { type ExtendedEntityParameters } from 'src/components/EntityDetail/entity-route-util';


export async function saveEvent (payload: ExtendedEntityParameters<'event'>) {
    const { entity } = payload;
    if (!entity.event.name) {
        Notify.error('Event Name is required!');
        return;
    }

    await gqlClient.request({
        document: AddEventDocument,
        variables: {
            event: [
                {
                    description: entity.event.description,
                    id: entity.event.id,
                    event_type_id: entity.event.event_type_id,
                    name: entity.event.name,
                    meta: entity.meta,
                },
            ],
        },
    });

    Notify.win(messages.schedule.event.notifySaved);
}


export async function fetchEventEntity (to: Route): Promise<{ entity: EntityTypeMap['event'], originalEntity?: EntityTypeMap['event'] }> {
    const defaultBackgroundColor = '#FFFFFF';
    const defaultTextColor = '#000000';

    if (to.query.mode === 'create') {
        return {
            entity: {
                event: {
                    __typename: 'argo_event',
                    id: to.params.eventId,
                    name: 'Unnamed Event',
                    description: '',
                    enabled: true,
                    createdAt: Date.now(),
                },
                meta: {
                    identifier: {
                        backgroundColor: defaultBackgroundColor,
                        textColor: defaultTextColor,
                    },
                },
            },
        };
    }

    const { event: [ event ] } = await gqlClient.request({
        document: GetEventDocument,
        variables: {
            eventId: to.params.eventId,
        },
    });

    if (event) {
        const meta: {
            identifier?: Record<string, unknown>,
        } = typeof event.meta === 'string' ? JSON.parse(event.meta) : event.meta;

        return {
            entity: {
                event: {
                    createdAt: event.createdAt,
                    description: event.description,
                    enabled: event.enabled,
                    event_type_id: event.event_type_id,
                    id: event.id,
                    name: event.name,
                },
                meta: {
                    identifier: {
                        textColor: (meta?.identifier?.textColor || defaultTextColor) as string,
                        backgroundColor: (meta?.identifier?.backgroundColor || defaultBackgroundColor) as string,
                    },
                },
            },
        };
    } else {
        const err = new Error('Unknown Event');
        Notify.error(err);
        throw err;
    }
}
