export const messages = {
    desc: 'Subscribe to an MQTT topic and store received payloads in a variable',
    errors: {
        _other: 'Config is invalid',
        payloadVar: 'Payload variable is invalid',
        topic: 'Topic is invalid',
    },
    payloadVar: {
        label: 'Variable to store the received payload in',
    },
    qos: {
        label: 'QOS (Quality of Service)',
    },
    title: 'MQTT Subscribe',
    topic: {
        hint: 'Topic wildcard characters are not supported',
        label: 'Topic to subscribe to',
    },
    tsVar: {
        label: 'Store the timestamp of the last received payload in a variable (optional)',
    },
};
