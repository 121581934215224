import { validateAcls } from '@redviking/argonaut-core-ui/src/auth';

export function validateMaterialModelSelectAcl (): boolean {
    return validateAcls([ 'material_model_select' ]);
}

export function validateMaterialModelInsertAcl (): boolean {
    return validateAcls([ 'material_model_insert' ]);
}

export function validateMaterialModelUpdateAcl (): boolean {
    return validateAcls([ 'material_model_update' ]);
}

export function validateMaterialModelDeleteAcl (): boolean {
    return validateAcls([ 'material_model_delete' ]);
}
