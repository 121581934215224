export const messages = {
    titles: {
        nav: 'Units of Measure',
        noName: 'Unnamed',
        pageBannerSubtitle: 'Browse and Manage Units of Measure',
        pageBannerTitle: 'UOMs',
    },
    uom: {
        hide: 'Hide UOM',
        inspect: 'Inspect UOM',
        name: 'Unit Of Measure',
        noneFound: 'No UOMs Found',
    },
};
