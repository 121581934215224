import { CausalError } from '@redviking/causal-error';
import { gqlClient } from '@redviking/argonaut-core-ui/src/util/gql-client';
import { getListChanges } from 'src/util/composables/watch-list-changes';
import { EntityTypeMap } from 'types';
import { GetAllEventTypesDocument } from 'types/db';


export async function validateCalendarEntity (calendar: EntityTypeMap['calendar'], oldCalendar?: EntityTypeMap['calendar'] | null): Promise<CausalError | boolean> {
    let calendarChanges;
    if (oldCalendar) {
        /** Validaitons for updating an already existing calendar */

        calendarChanges = getListChanges([ ...calendar.calendar_events.inserts, ...calendar.calendar_events.updates ], [ ...oldCalendar.calendar_events.inserts, ...oldCalendar.calendar_events.updates ], { deep: true, partialChanges: false, format: 'flat' });
    } else {
        calendarChanges = getListChanges([ ...calendar.calendar_events.inserts, ...calendar.calendar_events.updates ], [], { deep: true, partialChanges: false, format: 'flat' });
    }
    const eventTypes: string[] = [];

    calendarChanges.forEach(event => {
        if (event.item.event_type_id && !eventTypes.includes(String(event.item.event_type_id))) {
            eventTypes.push(String(event.item.event_type_id));
        }
    });

    const data = await gqlClient.request({
        document: GetAllEventTypesDocument,
        variables: {
            filter: {
                id: { _in: eventTypes },
            },
        },
    });

    const calendarEventUdfs: Record<string, {
        colId: string;
        required: boolean;
        name: string;
    }[]> = {};

    data.eventTypes.forEach(event => {
        calendarEventUdfs[event.id] = event.calendar_event_udf_schemas.map(schema => {
            return {
                colId: schema.udf_column!.id,
                required: schema.udf_column?.validation_required ?? false,
                name: schema.udf_column?.name ?? 'Unknown UDF Name',
            };
        });
    });

    for (const change of calendarChanges) {
        for (const udfInfo of calendarEventUdfs[String(change.item.event_type_id)]) {
            if (!change.item.udf_data?.find(udf => udf.udf_column_id === udfInfo.colId) && udfInfo.required) {
                return new CausalError('Event is missing required UDF', `Event type "${change.item.name}" starting at "${change.item.start}" and ending at "${change.item.end}" is missing required UDF "${udfInfo.name}"`);
            }
        }
    }
    return false;
}
