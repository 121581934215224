import z from 'zod';
import { cellCfgAttrsBaseSchema_v3, cellCfgBaseSchema_v3 } from './cell-base.v3.zod';

const baseAttrLookupCondition_v3 = z.object({
    type: z.string(),
    not: z.boolean(),
    source: z.enum([ 'var', 'const' ]).default('const').optional(),
    varOperand: z.string().default('').optional(),
});

const propMatchNumSchema_v3 = baseAttrLookupCondition_v3.extend({
    type: z.literal('propMatchNum'),
    comparison: z.enum([ 'eq', 'lt', 'gt' ]),
    /** ID of the attr prop to be used as an operand */
    attrSchemaPropId: z.string(),
    constOperand: z.number(),
});

const propMatchStrSchema_v3 = baseAttrLookupCondition_v3.extend({
    type: z.literal('propMatchStr'),
    /** ID of the attr prop to be used as an operand */
    attrSchemaPropId: z.string(),
    constOperand: z.string(),
});

const nameMatchSchema_v3 = baseAttrLookupCondition_v3.extend({
    type: z.literal('nameMatch'),
    constOperand: z.string(),
});

const attrLookupConditionSchema_v3 = z.discriminatedUnion('type', [
    propMatchNumSchema_v3,
    propMatchStrSchema_v3,
    nameMatchSchema_v3,
]);

export const attrLookupCellSchema_v3 = cellCfgBaseSchema_v3.extend({
    type: z.literal('attrLookup'),
    attrs: cellCfgAttrsBaseSchema_v3.extend({
        attrSchemaId: z.string(),
        showName: z.boolean().default(false),
        materialIdVar: z.string(),
        cols: z.array(z.string()),
        conds: attrLookupConditionSchema_v3.array(),
    }),
});
