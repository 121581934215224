export const messages = {
    assignCalendar: 'Assign Calendar',
    attrs: {
        saved: 'Attributes Saved!',
    },
    effectiveEnd: 'Effective End',
    effectiveStart: 'Effective Start',
    loc: {
        addNew: 'Add New Location',
        cannotDelete: 'Cannot delete previously linked calendars',
        confirmDelete: 'Are you sure you want to disable this location?',
        create: 'Create Location',
        entityName: 'Location',
        hide: 'Hide Location',
        inspect: 'Inspect Location',
        name: 'Location Name',
        noneFound: 'No Locations Found',
        parentLocation: 'Parent Location',
        parentLocations: 'Parent Locations',
        plural: 'Locations',
        saved: 'Location Saved!',
        search: 'Search Locations by Name or Description',
    },
    locClass: {
        entityName: 'Location Class',
        noneFound: 'No Location Calsses Found',
        search: 'Search Location Classes by Name or Description',
    },
    schedule: {
        end: 'End',
        errs: {
            afterEnd: 'Start date is after end date',
            equalEnd: 'Start date and end date are the same',
            noCal: 'No calendar selected',
        },
        ongoing: 'Ongoing',
        start: 'Start',
    },
    titles: {
        nav: 'Locations',
        noName: 'Unnamed Location',
        pageBannerSubtitle: 'Browse and edit locations',
        pageBannerTitle: 'Locations',
    },
};
