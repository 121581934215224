import { z } from 'zod';
import { varNameSchema } from '../../var.zod';
import { varpConfigBase_v3 } from './var-provider.v3.zod';

export const formattedProviderSchema_v3 = varpConfigBase_v3.extend({
    type: z.literal('formatted'),
    attrs: z.object({
        inputVars: z.array(varNameSchema),
        stringTemplate: z.string(),
        outputVar: varNameSchema,
        /**
         * if true, then the output will always have an ok status,
         * and if any inputs has an error status then that error's
         * message will be used instead of the input's val
         *
         * hidden option, currently only used by the process macro for building error text to display
         *
         * @default false
         */
        usingErrors: z.boolean().optional(),
    }),
});
