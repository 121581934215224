import z from 'zod';
import { sparkplugAppletNodeCfgManualSchema_v3, sparkplugAppletNodeCfgSchema_v3 } from '../util/sparkplug-node-cfg.v3.zod';
import { inputCellAttrsSchema_v3 } from '../cell-types/input.v3.zod';
import { varConditionSchema_v3 } from '../var-providers/conditional.v3.zod';
import { processProviderLocationSchema_v3, processProviderStateVarsSchema_v3 } from '../var-providers/process.v3.zod';
import { baseMacroCfgSchema_v3 } from './base-macro.v3.zod';

const processSparkplugNodeCfg_v3 = z.union([
    sparkplugAppletNodeCfgManualSchema_v3,
    z.literal('default'),
]);

const baseDataCollectSchema_v3 = z.object({
    source: z.string(),
    pdeId: z.string(),
});

/** collect the value from an autogenerated input cell */
const localInputDataCollectSchema_v3 = baseDataCollectSchema_v3.extend({
    source: z.literal('localInput'),
    cellCfg: inputCellAttrsSchema_v3.pick({
        label: true,
        trim: true,
    }),
});

const sparkplugDataCollectionSchema_v3 = baseDataCollectSchema_v3.extend({
    source: z.literal('sparkplug'),
    /** the metric that will source the value for this PRDE */
    varpCfg: z.object({
        metric: z.string(),
        nodeCfg: processSparkplugNodeCfg_v3,
    }),
});

/** collect the value from a variable not managed by the macro */
const varDataCollectSchema_v3 = baseDataCollectSchema_v3.extend({
    source: z.literal('var'),
    var: z.string(),
});

const dataCollectCfgSchema_v3 = z.discriminatedUnion('source', [
    localInputDataCollectSchema_v3,
    sparkplugDataCollectionSchema_v3,
    varDataCollectSchema_v3,
]);

const baseParamWriteSchema_v3 = z.object({
    target: z.string(),
    pdeId: z.string(),
});

/** write the param value to a variable not managed by the macro */
const varParamWriteSchema_v3 = baseParamWriteSchema_v3.extend({
    target: z.literal('var'),
    var: z.string(),
});

/** write the param to a sparkplug metric */
const sparkplugParamWriteSchema_v3 = baseParamWriteSchema_v3.extend({
    target: z.literal('sparkplug'),
    varpCfg: z.object({
        metric: z.string(),
        nodeCfg: processSparkplugNodeCfg_v3,
    }),
});

/** where to write spec params */
const paramWriteCfgSchema_v3 = z.discriminatedUnion('target', [
    varParamWriteSchema_v3,
    sparkplugParamWriteSchema_v3,
]);

const sparkplugCollectTriggerSchema_v3 = z.object({
    type: z.literal('sparkplug'),
    varpCfg: z.object({
        metric: z.string(),
        nodeCfg: processSparkplugNodeCfg_v3,
    }),
});

const varCollectTriggerSchema_v3 = z.object({
    type: z.literal('var'),
    var: z.string(),
});

const buttonPushTriggerSchema_v3 = z.object({
    type: z.literal('btn'),
});

const conditionalTriggerSchema_v3 = z.object({
    type: z.literal('conditional'),
    conditional: varConditionSchema_v3,
});

const collectTriggerCfgSchema_v3 = z.discriminatedUnion('type', [
    sparkplugCollectTriggerSchema_v3,
    varCollectTriggerSchema_v3,
    buttonPushTriggerSchema_v3,
    conditionalTriggerSchema_v3,
]);

/**
 * config for a process macro
 */
export const processMacroCfgSchema_v3 = baseMacroCfgSchema_v3.extend({
    type: z.literal('process'),
    attrs: z.object({
        processRevId: z.string(),
        matIdVar: z.string(),
        defaultSparkplugCfg: sparkplugAppletNodeCfgSchema_v3,
        collectTrigger: collectTriggerCfgSchema_v3,

        /** maps data collection PDEs to sources */
        dataCollection: z.array(dataCollectCfgSchema_v3),

        /** maps spec params to what to do with them */
        params: z.array(paramWriteCfgSchema_v3),

        stateVars: processProviderStateVarsSchema_v3,

        processLocation: processProviderLocationSchema_v3,

        showMedia: z.boolean().optional(),

    }),
});
