import { actionTree, mutationTree } from 'typed-vuex';
import { GetAllUomsDocument, UoMFragment } from 'types/db';
import { gqlClient } from '@redviking/argonaut-core-ui/src/util/gql-client';

const uomsState = {
    uoms: [] as UoMFragment[],
};

const mutations = mutationTree(uomsState, {
    setUoms (state, uoms: UoMFragment[]): void {
        state.uoms = uoms;
    },
});

const actions = actionTree({ state: uomsState, mutations }, {
    async getUoms ({ state, commit }) {
        if (state.uoms.length > 0) {
            return state.uoms;
        }

        const { Notify } = await import('../notifications/notify');
        try {
            const data = await gqlClient.request({
                document: GetAllUomsDocument,
                variables: {
                    filters: {},
                },
            });
            commit('setUoms', data.filtered);
            return data.filtered;
        } catch (err) {
            Notify.error('Failed to fetch Units of Measure', err);
            return [];
        }
    },
});

export default {
    state: uomsState,
    mutations,
    actions,
};
