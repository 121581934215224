import { EntityTypeMap } from '@redviking/argonaut-core-ui/types';
import {
    GetMaterialClassDocument,
} from '@redviking/argonaut-core-ui/types/db';
import { accessor } from '../store';
import { Route } from 'vue-router';
import { RouterViewSlider } from '../components/RouterViewSlider';
import { RouteEntityDetail } from '@redviking/argonaut-core-ui/src/components/EntityDetail';
import { validateMaterialClassSelectAcl, validateMaterialClassUpdateAcl } from '../material-class/material-class.validations';
import { entityDetailRoute } from 'src/components/EntityDetail/entity-route-util';
import { messages } from 'src/i18n/i18n';
import { gqlClient } from '@redviking/argonaut-core-ui/src/util/gql-client';
import { Notify } from 'src/notifications';
import {
    type MaterialClassData,
    type MaterialClassEntity,
    saveMaterialClassEntity,
} from 'src/material-class/material-class.entity';

export default [
    // nav group route
    {
        name: 'materialClassListSlider',
        path: '',
        redirect: { name: 'materialClassList' },
        component: RouterViewSlider,
        meta: {
            navbarTitle: messages.material.material.materialDef,
            navbarIcon: '$argo-material-class',
            transition: 'horizontal',
        },
        children: [
            {

                name: 'materialClassList',
                path: 'class',
                component: () => import(/* webpackChunkName: "material" */ '../material-class/MaterialClassView.vue'),
                meta: {
                    pageBannerTitle: 'Material Classes',
                    pageBannerSubtitle: 'View Material Classes',
                },
                children: [],

            },

            entityDetailRoute<'materialclass'>({
                path: 'class/:materialClassId',
                name: 'materialClassMaintenence',
                component: RouteEntityDetail,
                props: {
                    open: true,
                },
                redirect: to => ({ name: 'materialClassSettings', params: to.params }),
                meta: {
                    returnRoute: (route: Route) => ({ name: 'materialClassList', query: route.query }),
                    canEdit: validateMaterialClassUpdateAcl,
                    entityType: 'materialclass',
                    getEntity: async (to) => {
                        if (to.query.mode === 'create') {
                            const entityData: MaterialClassEntity = {
                                materialClass: {
                                    id: to.params.materialClassId,
                                    description: '',
                                    name: 'Unnamed Material Class',
                                    enabled: true,
                                    created_by: accessor.auth.currentUser!,
                                    created_by_id: accessor.auth.currentUser!.id,
                                    materialClassAttributes: [],
                                    materialsAggregate: {
                                        aggregate: {
                                            count: 0,
                                        },
                                    },
                                    material_models: [],
                                    material_class_processes: [],
                                    materialModelProcesses: [],
                                    udf_schemas: [],
                                    materialClassAttributeSettings: [],
                                    meta: {
                                        udfDefaultData: {},
                                    },
                                },
                                udfDefaultData: {},
                                changedFiles: {
                                    deletes: [],
                                    inserts: [],
                                    updates: [],
                                },
                            };
                            return {
                                entity: entityData,
                            };
                        }

                        const { materialClass: [ materialClass ] } = await gqlClient.request({
                            document: GetMaterialClassDocument,
                            variables: {
                                materialClassId: to.params.materialClassId,
                            },
                        });
                        if (materialClass) {
                            const entityData: MaterialClassData = {
                                id: materialClass.id,
                                name: materialClass.name,
                                meta: materialClass.meta,
                                enabled: materialClass.enabled,
                                created_at: materialClass.created_at,
                                created_by: materialClass.created_by,
                                udf_schemas: materialClass.udf_schemas,
                                description: materialClass.description,
                                created_by_id: materialClass.created_by_id,
                                material_models: materialClass.material_models,
                                materialsAggregate: materialClass.materialsAggregate,
                                materialClassAttributes: materialClass.materialClassAttributes,
                                material_class_processes: materialClass.material_class_processes,
                                materialClassAttributeSettings: materialClass.material_class_attribute_settings,
                                // this stuff comes from a view, and is nullable by default but not really.
                                materialModelProcesses: materialClass.material_model_processes.map(mmp => ({
                                    id: mmp.id!,
                                    material_model: {
                                        id: mmp.material_model!.id,
                                        model_number: mmp.material_model!.model_number,
                                    },
                                    process: {
                                        id: mmp.process_id!,
                                    },
                                })),
                            };
                            return {
                                entity: {
                                    materialClass: entityData,
                                    udfDefaultData: entityData.material_models.reduce<EntityTypeMap['materialclass']['udfDefaultData']>((acc, val) => {
                                        return {
                                            ...acc,
                                            [val.id]: Object.fromEntries(val.material_udf_default_data.map(e => ([ e.udf_column_id, { ...e, model_id: val.id } ]))),
                                        };
                                    }, {}),
                                    changedFiles: {
                                        deletes: [],
                                        inserts: [],
                                        updates: [],
                                    },
                                },
                            };
                        } else {
                            const err = new Error('Unknown Material Class');
                            Notify.error(err);
                            throw err;
                        }


                    },
                    saveEntity: saveMaterialClassEntity,
                    pageBannerTitle: () => {
                        const materialClass = accessor.entityAsType('materialclass');
                        return materialClass ? materialClass.materialClass.name : messages.materialClass.titles.noName;
                    },
                    pageBannerSubtitle: () => messages.entityNames.materialclass,
                },
                children: [
                    {
                        path: 'settings',
                        name: 'materialClassSettings',
                        component: () => import(/* webpackChunkName: "material" */ '../material-class/MaterialClassSettings.view.vue'), // a component for the settings page content
                        meta: {
                            tab: { // the presence of this prop indicates this is a tab
                                label: 'Settings',
                                icon: 'mdi-cog',
                            },
                            navigationGuard: validateMaterialClassSelectAcl,
                        },
                    },
                    {
                        path: 'material-class-model-list',
                        name: 'materialClassModelList',
                        component: () => import(/* webpackChunkName: "material" */ '../material-class/MaterialClassModelList.view.vue'), // a component for the settings page content
                        meta: {
                            tab: { // the presence of this prop indicates this is a tab
                                label: 'Models',
                                icon: '$argo-material-model',
                            },
                            navigationGuard: validateMaterialClassSelectAcl,
                        },
                    },
                    {
                        path: 'material-class-process-list',
                        name: 'materialClassProcessList',
                        component: () => import(/* webpackChunkName: "material" */ '../material-class/MaterialClassProcesses.view.vue'), // a component for the settings page content
                        meta: {
                            tab: { // the presence of this prop indicates this is a tab
                                label: 'Processes',
                                icon: '$argo-process',
                            },
                            navigationGuard: validateMaterialClassSelectAcl,
                        },
                    },
                    {
                        path: 'material-class-file-list',
                        name: 'materialClassFileList',
                        component: () => import(/* webpackChunkName: "material" */ '../material-class/MaterialClassFileManager.vue'), // a component for the settings page content
                        meta: {
                            tab: { // the presence of this prop indicates this is a tab
                                label: 'Files',
                                icon: 'mdi-file-document-multiple-outline',
                            },
                            navigationGuard: validateMaterialClassSelectAcl,
                        },
                    },
                    {
                        path: 'material-class-attribute-settings',
                        name: 'materialClassAttributeSettings',
                        component: () => import(/* webpackChunkName: "material" */ '../material-class/MaterialClassAttributeSettings.view.vue'), // a component for the settings page content
                        meta: {
                            tab: { // the presence of this prop indicates this is a tab
                                label: 'Attribute Settings',
                                icon: 'mdi-animation-outline',
                            },
                            navigationGuard: validateMaterialClassSelectAcl,
                        },
                    },
                ],
            }),
        ],
    },
];
