
import { Component, Vue } from 'vue-property-decorator';
import { accessor } from '@redviking/argonaut-core-ui/src/store';
import { ArgoNotification } from '@redviking/argonaut-core-ui/src/notifications';
import { CausalError } from '@redviking/causal-error';

@Component
export class NotifViewer extends Vue {
    toggleNotifications = false;
    visibleLogLevels = [ 'error', 'warn', 'win', 'info', 'debug', 'silly' ];
    notifVisibility: boolean[] = [];

    closeViewer (): void {
        this.toggleNotifications = false;
        accessor.notify.viewNotifications(this.notifications);
    }

    get errors (): ArgoNotification[] {
        return accessor.notify.notifications.filter((notif: ArgoNotification) => {
            if (notif.level === 'error') {
                return true;
            } else {
                return false;
            }
        });
    }

    get notifications (): Array<ArgoNotification & { show?: boolean }> {
        const notifs = accessor.notify.notifications.filter((notif: ArgoNotification) => {
            if (this.visibleLogLevels.includes(notif.level)) {
                return true;
            } else {
                return false;
            }
        });
        return notifs.reverse();
    }

    get unseenErrorCount (): number {
        const errors = accessor.notify.notifications.filter((notif: ArgoNotification) => {
            if (notif.level === 'error') {
                return true;
            } else {
                return false;
            }
        });
        return errors.filter(err => err.seen === false).length;
    }

    dumpNotif (notif: ArgoNotification) {
        console.log(notif);
        if (notif.meta instanceof CausalError) {
            const cerr: CausalError = notif.meta as CausalError;
            if (cerr.cause) {
                console.error(notif.meta, cerr.cause);
            }
        } else if (notif.meta instanceof Error) {
            console.error(notif.meta);
        }
    }
}

export default NotifViewer;
