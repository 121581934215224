import z from 'zod';
import { baseMacroCfgSchema_v3 } from './base-macro.v3.zod';
import { materialLookupAttrsSchema_v3 } from '../cell-types/material-lookup.v3.zod';

export const materialLookupCfgSchema_v3 = baseMacroCfgSchema_v3.extend({
    type: z.literal('materialLookup'),
    attrs: z.object({
        materialLookupAttrs: materialLookupAttrsSchema_v3,
    }),
});
