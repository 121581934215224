/* eslint-disable max-lines */
import { gqlClient } from '@redviking/argonaut-core-ui/src/util/gql-client';
import {
    GetProcessDocument,
    GetProcessQuery,
    ProcessDataElementCollectionKindEnum,
    ProcessDataElementKindEnum,
} from 'types/db';
import { v4 as uuidv4 } from 'uuid';
import { Notify } from 'src/notifications';
import { validateProcessEntity } from './processes.validations';
import type {
    ProcessConfig,
    ProcessMedia,
    ProcessParamValAttrSource,
    ProcessParamValSource,
    ProcessSpecConfig,
} from '@redviking/argonaut-util/src/process-config.zod';
import { accessor } from 'src/store';
import _cloneDeep from 'lodash.clonedeep';
import { Route } from 'vue-router';
import { SaveResult } from 'types';
import { type ExtendedEntityParameters } from 'src/components/EntityDetail';
import { apiTrpcHttpClient } from '@redviking/argonaut-core-ui/src/util/api-trpc';
import { CausalError } from '@redviking/causal-error';

export {
    ProcessParamValAttrSource,
    ProcessParamValSource,
    ProcessSpecConfig,
    ProcessMedia,
};

export interface ProcessSpec {
    id: string;
    name: string;
    isDefault: boolean;
    parentSpecId: string | null;
    config: ProcessSpecConfig;

    start: 'now' | 'N/A' | number;
    end: 'now' | 'N/A' | number;
}


export type DataElement = {
    id: string;
    name: string;
    kind: ProcessDataElementKindEnum,
    uom: number | string;
    /** the uom name is the abbreviation */
    uomName: string;
    /**
     * if ProcessDataElementCollectionKindEnum.Collect, specs cannot override and this isn't shown as a column in the value matrix
     * TODO something about calcs and params
     */
    collectionKind: ProcessDataElementCollectionKindEnum;
    description?: string;
};

export type ParamKinds = Extract<DataElement['kind'], ProcessDataElementKindEnum.String | ProcessDataElementKindEnum.Number | ProcessDataElementKindEnum.Pass>;

type SpecOrder = {
    start: number;
    nonDefaultSpecIds: string[];
    /** first spec must be active default */
    defaultSpecIds: string[];
};

export type ProcessEntity = {
    id: string;
    name: string;
    description: string;
    dataElements: {
        [dataElementId: string]: DataElement;
    };
    config: ProcessConfig;

    createdAt: string | null;
    updatedAt: string | null;
    specOrder: SpecOrder;
    /** all spec orders, sorted latest first */
    specOrderHistory: SpecOrder[];

    uoms: Record<string, {
        id: string | number;
        name: string;
        description: string;
    }>;

    // these are stored in two places because they are used in different ways even though they have identical types.
    // the best way to enforce that stays true is to have them in two places.
    nonDefaultSpecs: {
        [specId: string]: ProcessSpec;
    };
    defaultSpecs: {
        [specId: string]: ProcessSpec;
    };

    locked: {
        specIds: Record<string, true | undefined>;
        conditionIds: Record<string, true | undefined>;
    };
}

/**
 * Used for copying a process entity, and replacing the uuids.
 *
 * Also used for scrubbing IDs from a process template. In this case, it is assumed that no referenced PDEs (or any other IDs) should be preserved, to prevent ID conflicts
 */
export function scrubIds (process: ProcessEntity): ProcessEntity {
    /**
     * this is used to map the pdes from spec params,
     * calcs and other things to their appropriate new pde ids,
     * as when we regenerate their ids,
     * we need to have everything that references those ids to also get updated
     */
    const idMapping: Record<string, string> = {};

    // helper function to mitigate 🍑
    function mapObjectAss<T> (inp: Record<string, T>, mapFn: ([ k, v ]: [ string, T ]) => [ string, T ]) {
        return Object.fromEntries(Object.entries(inp).map(mapFn));
    }

    /**
     * @param oldId
     * @param preserveIfNotFound - the id will not be mapped if not found in the map. this is useful to set false
     * for input pdes that may or may not reference this process
     */
    function mapNewId (oldId: string) {
        if (idMapping[oldId]) {
            return idMapping[oldId];
        } else {
            idMapping[oldId] = uuidv4();
            return idMapping[oldId];
        }
    }

    function scrubSpecIds (spec: ProcessSpec) {
        const scrubbedSpec: ProcessSpec = {
            ...spec,
            id: mapNewId(spec.id),
            parentSpecId: spec.parentSpecId ? mapNewId(spec.parentSpecId) : null,
            config: {
                ...spec.config,
                paramValues: mapObjectAss(spec.config.paramValues, ([ k, v ]) => ([ mapNewId(k), v ])),
            },
        };
        return scrubbedSpec;
    }

    process.dataElements = mapObjectAss(process.dataElements, ([ k, v ]) => ([ mapNewId(k), { ...v, id: mapNewId(k) } ]));
    process.defaultSpecs = mapObjectAss(process.defaultSpecs, ([ k, v ]) => ([ mapNewId(k), scrubSpecIds(v) ]));
    process.nonDefaultSpecs = mapObjectAss(process.nonDefaultSpecs, ([ k, v ]) => ([ mapNewId(k), scrubSpecIds(v) ]));
    process.specOrder = {
        ...process.specOrder,
        defaultSpecIds: process.specOrder.defaultSpecIds.map(mapNewId),
        nonDefaultSpecIds: process.specOrder.nonDefaultSpecIds.map(mapNewId),
    };
    process.config.validationOpts = mapObjectAss(process.config.validationOpts, ([ k, v ]) => ([ k, {
        ...v,
        inputs: v.inputs.map(input => (
            input.kind === 'dataElement'
                ? { ...input, dataElementId: mapNewId(input.dataElementId) }
                : input
        )),
    } ]));
    process.specOrderHistory = [];


    return {
        ...process,
        id: mapNewId(process.id),
    };
}

/**
 * converts from the query type to the entity type
 */
function getSpecsFromProcessQuery (specIds: string[], processRes: NonNullable<GetProcessQuery['process']>, isDefault: boolean): Record<string, ProcessSpec> {
    return Object.fromEntries(specIds.map<[string, ProcessSpec]>(specId => {
        const spec = processRes.specs.find(s => s.id === specId);

        // validate referenced spec can be found
        if (!spec) {
            const err = new Error(`One of the referenced specs (${specId}) was not found`);
            Notify.error(err);
            throw err;
        }

        const processSpec: ProcessSpec = {
            id: spec.id,
            name: spec.name,
            isDefault,
            parentSpecId: spec.parent_spec_id || null,
            config: spec.config,
            start: spec.time_start ? new Date(spec.time_start).getTime() : 'N/A',
            end: spec.time_end ? new Date(spec.time_end).getTime() : 'N/A',
        };
        return [
            spec.id,
            processSpec,
        ];
    }));
}

function getLockedSpecIds (processRes: NonNullable<GetProcessQuery['process']>): ProcessEntity['locked']['specIds'] {
    const lockedSpecIds: ProcessEntity['locked']['specIds'] = {};
    for (const spec of processRes.specs) {
        if (spec.locked) {
            lockedSpecIds[spec.id] = true;
        }
    }
    return lockedSpecIds;
}

export async function fetchProcessEntity (to: Route): Promise<{ entity: ProcessEntity, originalEntity?: ProcessEntity }> {
    const uoms: ProcessEntity['uoms'] = await accessor.getUoms().then(uoms => {
        return Object.fromEntries(uoms.map<[string | number, ProcessEntity['uoms'][string]]>(uom => ([ uom.id, { id: uom.id, name: uom.name, description: (uom.description || '') } ])));
    });

    if (to.query.mode === 'create') {
        const defaultSpecId = uuidv4();
        const process: ProcessEntity = {
            id: uuidv4(),
            description: '',
            name: 'New Process',
            dataElements: {},
            nonDefaultSpecs: {},
            defaultSpecs: {
                [defaultSpecId]: {
                    id: defaultSpecId,
                    name: 'Default',
                    isDefault: true,
                    parentSpecId: null,
                    config: {
                        counts: {
                            minPass: 1,
                        },
                        conditions: {},
                        validations: {},
                        media: {},
                        paramValues: {},
                    },
                    start: 'N/A',
                    end: 'N/A',
                },
            },
            config: {
                conditionOpts: {},
                schemaVersion: 1,
                validationOpts: {},
                media: {},
                calcs: {},
            },
            createdAt: null,
            specOrder: {
                defaultSpecIds: [ defaultSpecId ],
                nonDefaultSpecIds: [],
                start: Date.now(),
            },
            specOrderHistory: [],
            uoms,
            updatedAt: null,
            locked: {
                specIds: {},
                conditionIds: {},
            },
        };
        return { entity: process };
    } else {
        const { process } = await gqlClient.request({
            document: GetProcessDocument,
            variables: {
                process_id: to.params.processId,
            },
        });

        if (!process) {
            const err = new Error('Unknown Process');
            Notify.error(err);
            throw err;
        }

        if (process.config.schemaVersion !== 1) {
            const err = new Error('Unsupported Process Config Schema Version');
            Notify.error(err);
            throw err;
        }

        const [
            latestSpecOrder,
            ...specOrders
        ] = process.spec_orders.map<SpecOrder>(so => ({
            defaultSpecIds: so.default_spec_ids,
            nonDefaultSpecIds: so.non_default_spec_ids,
            start: new Date(so.time_start).getTime(),
        })).sort((a, b) => b.start - a.start);

        const defaultSpecs = getSpecsFromProcessQuery(latestSpecOrder.defaultSpecIds, process, true);
        const nonDefaultSpecs = getSpecsFromProcessQuery(latestSpecOrder.nonDefaultSpecIds, process, false);
        const allSpecs = {
            ...defaultSpecs,
            ...nonDefaultSpecs,
        };

        const lockedSpecIds = getLockedSpecIds(process);
        const lockedConditionIds: Record<string, true> = {};
        for (const lockedSpecId in lockedSpecIds) {
            const lockedSpec = allSpecs[lockedSpecId];
            for (const conditionId in lockedSpec.config.conditions) {
                const specCondition = lockedSpec.config.conditions[conditionId];
                if (specCondition === 'true' || specCondition === 'false' || specCondition === null) {
                    lockedConditionIds[conditionId] = true;
                }
            }
        }

        // convert the graphql structure to our entity type
        const originalEntity: ProcessEntity = {
            config: process.config,
            createdAt: process.created_at,
            dataElements: Object.fromEntries(process.data_elements.map<[string, DataElement]>(de => ([
                de.id,
                {
                    id: de.id,
                    name: de.name,
                    kind: de.kind,

                    // default unitless
                    uom: de.uom?.id || 543,
                    uomName: de.uom?.name || 'ul',

                    collectionKind: de.collection_kind,
                    description: de.description || '',
                },
            ]))),
            defaultSpecs,
            description: process.description || '',
            id: process.id,
            name: process.name,
            nonDefaultSpecs,
            specOrder: latestSpecOrder,
            specOrderHistory: specOrders,
            uoms,
            updatedAt: process.updated_at || null,
            locked: {
                specIds: lockedSpecIds,
                conditionIds: lockedConditionIds,
            },
        };

        const entity = _cloneDeep(originalEntity);

        return {
            entity,
            originalEntity,
        };
    }
}

export async function saveProcessEntity (payload: ExtendedEntityParameters<'process'>): Promise<void | SaveResult> {
    let { entity } = payload;
    const router = await import('src/routing').then(m => m.router);

    const validationErr = await validateProcessEntity(entity, {
        name: router.currentRoute.name as string,
        params: router.currentRoute.params,
        query: router.currentRoute.query,
    });
    if (validationErr) {
        Notify.error(validationErr.err.toString());
        return { status: 'error', route: validationErr.route };
    }

    if (router.currentRoute.query.mode === 'copy') {
        entity = scrubIds(entity);
    }

    try {
        await apiTrpcHttpClient.process.upsert.mutate({
            process: entity,
        });
    } catch (err) {
        Notify.error(CausalError.from({
            message: 'Failed to save Process',
            cause: err,
        }));
        return { status: 'error' };
    }

    router.push({ name: 'process-general', params: { processId: entity.id } });
}
