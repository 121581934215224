
/* eslint-disable max-lines */
import moment from 'moment';

import { ArgoScheduleDayEnum, useGetAssetsQuery } from 'types/db';
import { Ref, computed, ref } from 'vue';
import { useRoute } from 'vue-router/composables';

export function getAssetsForIds (ids: Ref<string[]>) {
    const { result, ...otherQueryStuff } = useGetAssetsQuery(computed(() => ({
        filter: {
            id: {
                _in: ids.value,
            },
        },
    })));
    return { assets: computed(() => result.value?.assets || []), ...otherQueryStuff };
}
export type FilterValueMap = {
    assetIds?: string[],
    dows?: string[],
    events?: string[],
    locations?: string[],
    dataLabels?: string[],
    from: number, // unix timestamp
    to: number, // unix timestamp
};

export type ReportFilterRouteOptions = {
    loc?: string;
    dows?: string;
    from: string;
    to: string;
    assets?: string;
    locations?: string;
    events?: string;
    dataLabels?: string;
};

export type StateReportDuration = {
    code: number;
    duration: number;
    dataLabelId: string;
    desc: string;
    coreState: string;
    numVal: string;
};

export type StateReportRowData = {
    assetId: string;
    day: ArgoScheduleDayEnum;
    eventName: string;
    eventId: string;
    time: string;
    durations: {
        [desc: string]: StateReportDuration;
    };
};

export function secondsToDhms (seconds: number) {
    const d = Math.floor(seconds / (3600 * 24));
    const h = Math.floor(seconds % (3600 * 24) / 3600);
    const m = Math.floor(seconds % 3600 / 60);
    const s = Math.floor(seconds % 60);

    return {
        day: d,
        hour: h,
        minute: m,
        second: s,
    };
}

function getFullUnit (shortUnit: string): moment.unitOfTime.DurationConstructor {
    const lowercaseUnit = shortUnit.toLowerCase(); // Convert to lowercase

    switch (lowercaseUnit) {
        case 's':
            return 'seconds';
        case 'm':
            return 'minutes';
        case 'h':
            return 'hours';
        case 'd':
            return 'days';
        case 'w':
            return 'weeks';
        default:
            throw new Error('Invalid time unit');
    }
}

export function getLastHourFromUnixTimestamp (timestamp: number): number {
    const currentDate = new Date(timestamp); // Convert seconds to milliseconds
    currentDate.setMinutes(0, 0, 0); // Set minutes, seconds, and milliseconds to 0
    const roundedTimestamp = Math.floor(currentDate.getTime()); // Convert milliseconds to seconds

    return roundedTimestamp;
}
export function parseReportTime (time: string): Date {
    if (time === 'now') {
        return new Date();
    }

    const modifierRegex = /^now-(\d+)([smhdw])$/ui; // Updated to be case-insensitive
    const match = time.match(modifierRegex);

    if (match) {
        const value = parseInt(match[1], 10);
        const unit = match[2];

        const now = moment();
        const modifiedTime = now.subtract(value, getFullUnit(unit));
        return modifiedTime.toDate();
    }

    const timestamp = new Date(parseInt(time, 10)).getTime();

    if (!isNaN(timestamp)) {
        return new Date(timestamp);
    }

    throw new Error('Invalid time parameter');
}

// This is the **ONLY** place that report filters should be intialized
// If the parameters need to be customized per report, add an optional parameter to this function
export function useReportFilters (): Ref<FilterValueMap> {
    const { query } = useRoute();
    const routeQueryParams = query as ReportFilterRouteOptions;
    const filtersRef = ref<FilterValueMap>({
        assetIds: routeQueryParams.assets ? (query.assets as string).split(',') : [],
        dows: routeQueryParams.dows?.length ? (query.dow as string).split(',') : [],
        events: routeQueryParams.events?.length ? (query.events as string).split(',') : [],
        locations: routeQueryParams.locations?.length ? (query.location as string).split(',') : [],
        dataLabels: routeQueryParams.dataLabels?.length ? (query.dataLabels as string).split(',') : [],
        from: getLastHourFromUnixTimestamp(parseReportTime(routeQueryParams.from as string).getTime()),
        to: getLastHourFromUnixTimestamp(parseReportTime(routeQueryParams.to as string).getTime()),
    });
    return filtersRef;
}
