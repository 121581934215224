import z from 'zod';
import { baseMacroCfgSchema_v3 } from './base-macro.v3.zod';
import { pagedAttrsSchemaDef_v3 } from '../cell-types/paged.v3.zod';

export const pagedMacroCfgSchema_v3 = baseMacroCfgSchema_v3.extend({
    type: z.literal('paged'),
    attrs: z.object({
        pagedAttrs: pagedAttrsSchemaDef_v3,
    }),
});
