import { enMessages } from 'src/i18n/en.i18n';
import { I18nResolver } from 'i18n-ts';

type ArgoSupportedLocale = 'en';

/**
 * all of the supported locales and their messages
 */
const resolverLocaleOptions: {
    [ lang in ArgoSupportedLocale ]: typeof enMessages;
} = {
    en: enMessages,
};

// browser locale is automatically determined by I18nResolver, and is available in `language`.
const i18nResolver = new I18nResolver({
    ...resolverLocaleOptions,
    default: enMessages,
});

/**
 * translations object, automatically configured for the user's locale
 */
export const messages = i18nResolver.translation;
export const language = i18nResolver.language;
