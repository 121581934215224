export const messages = {
    context: {
        addNew: 'Add New Context',
        confirmDelete: 'Are you sure you want to delete this context?',
        create: 'Create Context',
        entityName: 'Context',
        hide: 'Hide Context',
        inspect: 'Inspect Context',
        name: 'Context Name',
        noneFound: 'No Contexts Found',
        notifySaved: 'Context Saved!',
        search: 'Search Contexts by Name or Description',
        selectorLabel: 'Select a context',
    },
    titles: {
        name: 'Context',
        nav: 'Context',
        noName: 'Unnamed',
        pageBannerSubtitle: 'View Authentication Contexts',
        pageBannerTitle: 'Authentication Contexts',
    },
};
