import { ArgoNotification, NotifyLevel } from '../notifications/ArgoNotification';
import { accessor } from '../store';
import { GraphQLError } from 'graphql';
import { getCurrentInstance } from 'vue';
import { CausalError } from '@redviking/causal-error';

type PropObj = Record<PropertyKey, unknown> | Error | readonly GraphQLError[];
type ObjWithMsg = PropObj & { message: string };

export class Notify {
    static warn (message: string): void;
    static warn (message: ObjWithMsg): void;
    static warn (message: string, meta: PropObj): void;
    static warn (message: string | ObjWithMsg, meta?: PropObj | Error | unknown): void {
        Notify.custom(NotifyLevel.Warning, message, meta);
    }

    static error (message: string): void;
    static error (message: ObjWithMsg): void;
    static error (message: string, meta: PropObj): void;
    static error (message: string | ObjWithMsg, meta?: PropObj | Error | unknown): void {
        Notify.custom(NotifyLevel.Error, message, meta);
    }

    static win (message: string): void;
    static win (message: ObjWithMsg): void;
    static win (message: string, meta: PropObj): void;
    static win (message: string | ObjWithMsg, meta?: PropObj | Error | unknown): void {
        Notify.custom(NotifyLevel.Success, message, meta);
    }

    static info (message: string): void;
    static info (message: ObjWithMsg): void;
    static info (message: string, meta: PropObj): void;
    static info (message: string | ObjWithMsg, meta?: PropObj | Error | unknown): void {
        Notify.custom(NotifyLevel.Info, message, meta);
    }

    static debug (message: string): void;
    static debug (message: ObjWithMsg): void;
    static debug (message: string, meta: PropObj): void;
    static debug (message: string | ObjWithMsg, meta?: PropObj | Error | unknown): void {
        Notify.custom(NotifyLevel.Debug, message, meta);
    }

    static silly (message: string): void;
    static silly (message: ObjWithMsg): void;
    static silly (message: string, meta: PropObj): void;
    static silly (message: string | ObjWithMsg, meta?: PropObj | Error | unknown): void {
        Notify.custom(NotifyLevel.Silly, message, meta);
    }

    static custom (level: NotifyLevel, message: string | ObjWithMsg, meta?: PropObj | Error | unknown): void {
        let notification: ArgoNotification;
        if (typeof message === 'string') {
            if (typeof meta === 'object' && meta && !('componentTag' in meta)) {
                Object.defineProperty(meta, 'componentTag', {
                    value: getCurrentInstance()?.proxy.$vnode.tag,
                });
            }
            notification = new ArgoNotification({
                level,
                message,
                meta,
            });
        } else {
            if (!('componentTag' in message)) {
                Object.defineProperty(message, 'componentTag', {
                    value: getCurrentInstance()?.proxy.$vnode.tag,
                });
            }
            let notificationMsg: string | null = message.message || null;
            if (message instanceof CausalError) {
                notificationMsg = message.toString();
            }
            notification = new ArgoNotification({
                level,
                message: notificationMsg,
                meta: message,
            });
        }
        accessor.notify.push(notification);
    }
}
