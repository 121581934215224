import {
    AddLocationDocument,
    ArgoCalendarLocationConstraint,
    ArgoCalendarLocationUpdateColumn,
    ArgoLocationAttributeInsertInput,
    GetLocationDocument,
    UpdateLocationDocument,
} from '../../types/db';
import { accessor } from '../store';
import { messages } from 'src/i18n/i18n';
import {
    type EntityModifyParameters,
    type ExtendedEntityParameters,
    RouteEntityDetail,
} from '@redviking/argonaut-core-ui/src/components/EntityDetail';
import { Route } from 'vue-router';
import { RouterViewSlider } from '@redviking/argonaut-core-ui/src/components/RouterViewSlider';
import { validateLocation, validateLocationSelectAcl } from './locations.validations';
import { entityDetailRoute } from '../components/EntityDetail';
import { gqlClient } from '@redviking/argonaut-core-ui/src/util/gql-client';
import { Notify } from '../notifications';
import { entityPageBannerTitle } from 'src/util/formatting';
import { getListChanges } from 'src/util/composables/watch-list-changes';
import { LocationEntity } from './locations.entity';

export default [
    {
        path: '/locations',
        name: 'locations',
        component: RouterViewSlider,
        redirect: { name: 'locationList' },
        meta: {
            navigationGuard: () => validateLocationSelectAcl(),
            navbarTitle: messages.locations.titles.nav,
            navbarIcon: 'mdi-map-marker',
            transition: 'horizontal',
        },
        children: [
            {
                name: 'locationList',
                path: '',
                component: () => import(/* webpackChunkName: "locations" */ './LocationsView.vue'),
                meta: {
                    pageBannerTitle: messages.locations.titles.pageBannerTitle,
                    pageBannerSubtitle: messages.locations.titles.pageBannerSubtitle,
                },
            },
            entityDetailRoute<'location'>({
                path: ':locationId',
                name: 'locationMaintenence',
                component: RouteEntityDetail,
                redirect: to => ({ name: 'locationSettings', params: to.params }),
                meta: {
                    returnRoute: (route: Route) => ({ name: 'locationList', query: route.query }),
                    canEdit: true,
                    pageBannerTitle: () => entityPageBannerTitle('location', accessor.entityAsType('location')?.name),
                    entityType: 'location',
                    saveEntity: async (payload: ExtendedEntityParameters<'location'>) => {

                        const { entity, oldEntity } = payload;

                        if (!entity.name || entity.name.length <= 0) {
                            const error = new Error('Location missing "name" property');
                            Notify.error(error.message, error);
                            return;
                        }
                        try {
                            const calLinkChanges = getListChanges(entity.calendarLinks, oldEntity?.calendarLinks || [], { partialChanges: false, format: 'plc' });
                            const updatedCalLinks = [ ...calLinkChanges.inserts, ...calLinkChanges.updates ];

                            validateLocation(entity);
                            const insert: ArgoLocationAttributeInsertInput[] = entity.location_attributes.filter(attr => !oldEntity?.location_attributes.find(a => a.attribute.id === attr.attribute.id)).map(a => ({
                                location_id: entity.id,
                                attribute_id: a.attribute.id,
                            }));
                            const deleteIds = oldEntity?.location_attributes.filter(attr => !entity.location_attributes.find(a => a.attribute.id === attr.attribute.id)).map(a => a.attribute.id) || [];

                            await gqlClient.request({
                                document: UpdateLocationDocument,
                                variables: {
                                    location: {
                                        id: entity.id,
                                        name: entity.name,
                                        description: entity.description,
                                        enabled: entity.enabled,
                                        parent_location_id: entity.parentLocationId,
                                        location_class_id: entity.location_class_id,
                                        calendar_locations: {
                                            data: updatedCalLinks.map(l => ({
                                                active_range: l.active_range,
                                                id: l.id,
                                                calendar_id: l.calendar.id,
                                                meta: l.meta,
                                            })),
                                            on_conflict: {
                                                constraint: ArgoCalendarLocationConstraint.CalendarLocationPkey,
                                                update_columns: [ 'active_range' ] as ArgoCalendarLocationUpdateColumn[],
                                            },
                                        },
                                    },
                                    locationId: entity.id,
                                    attributesToLink: insert,
                                    attributesToUnlink: deleteIds,
                                },
                            });

                            Notify.win(messages.locations.loc.saved);
                        } catch (err) {
                            Notify.error(err as Error);
                            throw err;
                        }
                    },
                    createEntity: async (payload: EntityModifyParameters<'location'>) => {
                        const { entity } = payload;
                        if (!entity.name || entity.name.length <= 0) {
                            const error = new Error('Location missing "name" property');
                            Notify.error(error.message, error);
                            return;
                        }
                        try {
                            validateLocation(entity);
                            await gqlClient.request({
                                document: AddLocationDocument,
                                variables: {
                                    location: {
                                        id: entity.id,
                                        name: entity.name,
                                        description: entity.description,
                                        enabled: entity.enabled,
                                        parent_location_id: entity.parentLocationId,
                                        location_class_id: entity.location_class_id,
                                        location_attributes: {
                                            data: entity.location_attributes.map(a => ({
                                                attribute_id: a.attribute.id,
                                            })),
                                        },
                                        calendar_locations: {
                                            data: entity.calendarLinks.map(l => ({
                                                active_range: l.active_range,
                                                id: l.id,
                                                calendar_id: l.calendar.id,
                                                meta: l.meta,
                                            })),
                                        },
                                    },
                                },
                            });
                            Notify.win(messages.locations.loc.saved);
                        } catch (err) {
                            Notify.error(err as Error);
                            throw err;
                        }
                    },
                    getEntity: async (to) => {
                        if (to.query.mode === 'create') {
                            const entityData: LocationEntity = {
                                calendarLinks: [],
                                id: to.params.locationId,
                                name: 'Unnamed Location',
                                description: '',
                                parentLocationId: null,
                                enabled: true,
                                location_attributes: [],
                            };
                            return {
                                entity: entityData,
                            };
                        }
                        const { location } = await gqlClient.request({
                            document: GetLocationDocument,
                            variables: {
                                locationId: to.params.locationId,
                            },
                        });
                        if (location) {
                            const ret: LocationEntity = {
                                id: location.id,
                                name: location.name,
                                enabled: location.enabled,
                                description: location.description,
                                calendarLinks: location.calendarLinks,
                                parentLocationId: location.parentLocationId,
                                location_class_id: location.location_class_id,
                                location_attributes: location.location_attributes,
                            };
                            return {
                                entity: ret,
                            };
                        } else {
                            const err = new Error('Unknown Location');
                            Notify.error(err);
                            throw err;
                        }
                    },
                },
                children: [
                    {
                        path: 'settings',
                        name: 'locationSettings',
                        component: () => import(/* webpackChunkName: "locations" */ './LocationSettings.view.vue'), // a component for the settings page content
                        meta: {
                            tab: { // the presence of this prop indicates this is a tab
                                label: 'Settings',
                                icon: 'mdi-cog',
                            },
                            navigationGuard: () => true,
                        },
                        // navigationGuard: () => validateLocationUpdateAcl(),
                    },
                    {
                        path: 'location-attribute-list',
                        name: 'locationAttributeList',
                        component: () => import(/* webpackChunkName: "locations" */ './LocationAttributeList.view.vue'), // a component for the settings page content
                        meta: {
                            tab: { // the presence of this prop indicates this is a tab
                                label: 'Attributes',
                                icon: 'mdi-animation-outline',
                            },
                            navigationGuard: () => true,
                        },
                    },
                    {
                        path: 'location-schedule',
                        name: 'locationSchedule',
                        component: () => import(/* webpackChunkName: "schedule" */ './LocationSchedule.view.vue'), // a component for the settings page content
                        meta: {
                            tab: { // the presence of this prop indicates this is a tab
                                label: 'Calendar',
                                icon: 'mdi-calendar',
                            },
                            navigationGuard: () => true,
                            pageBannerTitle: () => entityPageBannerTitle('location', accessor.entityAsType('location')?.name),
                        },
                    },
                    {
                        path: 'location-attribute-history',
                        name: 'locationAttributeHistory',
                        component: () => import(/* webpackChunkName: "locations" */ './LocationAttributeHistory.view.vue'),
                        meta: {
                            tab: { // the presence of this prop indicates this is a tab
                                label: 'Attribute History',
                                icon: 'mdi-history',
                            },
                            navigationGuard: () => true,
                            pageBannerTitle: () => entityPageBannerTitle('location', accessor.entityAsType('location')?.name),
                        },
                    },
                ],
            }),
        ],
    },
];
