import { PendingListChanges } from 'src/util/composables/use-pending-list-changes';
import { mutationTree } from 'typed-vuex';
import { ArgoMaterial } from '../../types/db';

const materialStates = {
    material: null as ArgoMaterial | null,
    changedAttrs: {
        deletes: [],
        inserts: [],
        updates: [],
    } as PendingListChanges<{ id: string, name: string }, false>,
};

const mutations = mutationTree(materialStates, {
    setPageMaterial (state, material: ArgoMaterial) {
        if (material.__typename !== 'argo_material') {
            console.warn('Unknown Type passed into mutation');
        }
        state.material = { ...state.material, ...material };
    },
    clearMaterial (state): void {
        state.material = null;
    },
    setMaterialChangedAttrs (state, input: PendingListChanges<{ id: string, name: string }, false>) {
        state.changedAttrs = input;
    },
    clearMaterialChangedAttrs (state) {
        state.changedAttrs = {
            deletes: [],
            inserts: [],
            updates: [],
        };
    },
});

export default {
    state: materialStates,
    mutations,
};
