import { validateAcls } from '@redviking/argonaut-core-ui/src/auth';
import type { RouteConfig } from 'vue-router';
import { messages } from '@redviking/argonaut-core-ui/src/i18n/i18n';
import { RouterViewSlider } from '@redviking/argonaut-core-ui/src/components/RouterViewSlider';
import { appletDeploymentRoute } from './deployment/appletDeployment.routes';
import { appletDesignRoutes } from './design/appletDesign.routes';

export const appletRoutes: RouteConfig[] = [
    {
        path: '/applet',
        name: 'appletNavGroup',
        redirect: to => ({ name: 'appletDesigns', params: to.params }),
        component: RouterViewSlider,
        meta: {
            navigationGuard: () => validateAcls([ 'applet_select' ]),
            navbarTitle: messages.applet.navbarTitles.appletNavGroup,
            navbarGroupOnly: true,
            transition: 'vertical',
        },
        children: [
            {
                path: 'designs',
                name: 'appletDesigns',
                redirect: to => ({ name: 'appletDesignList', params: to.params }),
                component: RouterViewSlider,
                meta: {
                    pageBannerIcon: '$argo-design',
                    navbarTitle: messages.applet.navbarTitles.appletDesignList,
                    navbarIcon: '$argo-design',
                },
                children: appletDesignRoutes,
            },
            {
                path: '',
                redirect: to => ({ name: 'appletRunnerList', params: to.params }),
                component: RouterViewSlider,
                meta: {
                    transition: 'horizontal',
                },
                children: [
                    {
                        path: 'runners',
                        name: 'appletRunnerList',
                        component: () => import('./AppletRunnerList.view.vue'),
                        meta: {
                            pageBannerTitle: messages.applet.navbarTitles.appletRunnerList,
                            pageBannerIcon: '$argo-runner',
                            navbarTitle: messages.applet.navbarTitles.appletRunnerList,
                            navbarIcon: '$argo-runner',
                        },
                    },
                    appletDeploymentRoute,
                ],
            },
        ],
    },
];
