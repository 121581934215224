import type { Latest } from '@redviking/argonaut-util/types/mes/applet-designs/appletDesign.latest.zod';
import { gatherComparisonInputs } from '@redviking/argonaut-util/src/mes/gather-comparison-inputs';

export const generatePagedMacroVarps = (cfg: Latest.Macros.PagedMacro): {
    local: Latest.VarProviders.Local.Provider;
    paged: Latest.VarProviders.PagedProvider;
} => {

    const pageNumVarp: Latest.VarProviders.Local.Provider = {
        id: `${cfg.id}_${cfg.name || 'unnamed_macro'}_page_number`,
        type: 'local',
        attrs: {
            vars: [
                {
                    name: cfg.attrs.pagedAttrs.currentPageVar || `${cfg.id}_${cfg.name || 'unnamed_macro'}_page_number`,
                    initialVal: 0,
                    type: 'num',
                },
            ],
        },
        outputs: [ cfg.attrs.pagedAttrs.currentPageVar || `${cfg.id}_${cfg.name || 'unnamed_macro'}_page_number` ],
        inputs: [],
    };

    const pagedVarpInputs = new Set<string>();
    pagedVarpInputs.add(cfg.attrs.pagedAttrs.currentPageVar || `${cfg.id}_${cfg.name || 'unnamed_macro'}_page_number`);
    for (const resetCond of cfg.attrs.pagedAttrs.resetConditions) {
        for (const varName of resetCond.vars) {
            pagedVarpInputs.add(varName);
        }
        for (const varName of gatherComparisonInputs(resetCond.condition.comparisons)) {
            pagedVarpInputs.add(varName);
        }
    }
    for (const page of cfg.attrs.pagedAttrs.pages) {
        const allRules = [ ...page.rules.advanceRules, ...page.rules.disableRules ] as { condition: Latest.VarCondition }[];
        for (const rule of allRules) {
            for (const varName of gatherComparisonInputs(rule.condition.comparisons)) {
                pagedVarpInputs.add(varName);
            }
        }
    }

    const pagedVarp: Latest.VarProviders.PagedProvider = {
        id: cfg.id,
        type: 'paged',
        attrs: {
            resetConditions: cfg.attrs.pagedAttrs.resetConditions,
            pageNumberVar: cfg.attrs.pagedAttrs.currentPageVar || `${cfg.id}_${cfg.name || 'unnamed_macro'}_page_number`,
            btnSize: cfg.attrs.pagedAttrs.btnSize,
            pageCfg: cfg.attrs.pagedAttrs.pages,
        },
        outputs: [],
        inputs: [ ...pagedVarpInputs ],
    };

    // Order here matters. Paged varp uses variables from the pages so it needs to be loaded last
    return {
        local: pageNumVarp,
        paged: pagedVarp,
    };
};
