export const messages = {
    attrs: {
        saved: 'Attributes Saved!',
    },
    labelTemplate: {
        addNew: 'Add New Label Template',
        confirmDelete: 'Are you sure you want to delete this label template?',
        create: 'Create Label Template',
        edit: 'Edit Label Template',
        editBtn: 'Save',
        hide: 'Hide Label Template',
        inspect: 'Inspect Label Template',
        name: 'Data Label Template',
        noneFound: 'No Label Templates Found',
        notifySaved: 'Label Template Saved!',
        saved: 'Label Template Saved!',
        search: 'Search Label Templates by Name or Description',
        template: 'Label Template',
        type: 'Label Template Type',
        typeSearch: 'Select Label Template Type',
        types: {
            intermec: 'Intermec',
            zebra: 'Zebra',
        },
    },
    titles: {
        nav: 'Label Templates',
        noName: 'Unnamed Label Template',
        pageBannerSubtitle: 'View Label Templates',
        pageBannerTitle: 'Label Templates',
    },
};
