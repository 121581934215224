import { AuthorLike } from '../types/entity';
import { messages } from './i18n/i18n';
import { ProcessProcessStatusEnum } from '@redviking/argonaut-util/types/db';

export function userToString<U extends AuthorLike> (user?: U | null) {
    return messages.userToString({ user });
}

export function formatTs (format: keyof typeof messages.tsFormatters, date: unknown) {
    if (typeof date === 'string' || typeof date === 'number' || (typeof date === 'object' && date instanceof Date)) {
        return messages.tsFormatters.short.format(new Date(date));
    }
    return '';
}

export const statusItemMap: {
    [status in ProcessProcessStatusEnum]: {
        text: string;
        icon: string;
        description: string;
        action?: string;
        rank: number;
        value: status;
        color: string;
        allowedTransitions: ProcessProcessStatusEnum[];
    }
} = {
    completed: {
        text: 'Completed',
        icon: 'mdi-check-bold',
        description: 'The process has met the required count and passed validations',
        value: ProcessProcessStatusEnum.Completed,
        color: 'success',
        rank: 5,
        allowedTransitions: [
            ProcessProcessStatusEnum.Failed,
            ProcessProcessStatusEnum.Invalidated,
        ],
    },
    pending: {
        text: 'Pending',
        icon: 'mdi-link-variant',
        value: ProcessProcessStatusEnum.Pending,
        description: 'Process has been linked with a material, but not yet solved.',
        color: 'warning',
        rank: 1,
        allowedTransitions: [
            ProcessProcessStatusEnum.NotRequired,
            ProcessProcessStatusEnum.ManuallyCompleted,
            ProcessProcessStatusEnum.Skipped,
            ProcessProcessStatusEnum.Deleted,
            ProcessProcessStatusEnum.Deleted,
        ],
    },
    failed: {
        text: 'Failed',
        icon: 'mdi-close-thick',
        description: 'The process has failed and cannot be completed without manual intervention',
        value: ProcessProcessStatusEnum.Failed,
        color: 'error',
        rank: 6,
        allowedTransitions: [
            ProcessProcessStatusEnum.ManuallyCompleted,
            ProcessProcessStatusEnum.Deleted,
            ProcessProcessStatusEnum.Invalidated,
        ],
    },
    deleted: {
        text: 'Deleted',
        icon: 'mdi-trash-can',
        description: 'Process is not expected to be completed',
        action: 'Mark the process as not required for this material',
        value: ProcessProcessStatusEnum.Deleted,
        color: 'gray',
        rank: 9,
        allowedTransitions: [],
    },
    incomplete: {
        text: 'Incomplete',
        icon: 'mdi-timer-sand',
        description: 'The number of successful collection events has not reached the amount required by the solved specification',
        value: ProcessProcessStatusEnum.Incomplete,
        color: 'warning lighten-1',
        rank: 3,
        allowedTransitions: [
            ProcessProcessStatusEnum.ManuallyCompleted,
            ProcessProcessStatusEnum.Skipped,
            ProcessProcessStatusEnum.Deleted,
            ProcessProcessStatusEnum.Invalidated,
            ProcessProcessStatusEnum.Failed,
        ],
    },
    invalidated: {
        text: 'Invalidated',
        icon: 'mdi-reload',
        description: 'The process-material link was reset, and will need to be re-solved if required',
        action: 'Reset the process-material link, so it can be re-solved',
        value: ProcessProcessStatusEnum.Invalidated,
        color: 'gray',
        rank: 8,
        allowedTransitions: [],
    },
    manually_completed: {
        text: 'Manually Completed',
        icon: 'mdi-account-check',
        description: 'The process has been manually marked as completed',
        action: 'Mark the process as completed',
        value: ProcessProcessStatusEnum.ManuallyCompleted,
        color: 'success lighten-1',
        rank: 7,
        allowedTransitions: [
            ProcessProcessStatusEnum.Failed,
            ProcessProcessStatusEnum.Deleted,
            ProcessProcessStatusEnum.Invalidated,
        ],
    },
    not_required: {
        text: 'Not Required',
        icon: 'mdi-lock-open',
        description: 'The solved specification deemed that the process is not required for this material',
        value: ProcessProcessStatusEnum.NotRequired,
        color: 'gray',
        rank: 4,
        allowedTransitions: [
            ProcessProcessStatusEnum.Deleted,
            ProcessProcessStatusEnum.Invalidated,
        ],
    },
    skipped: {
        text: 'Skipped',
        icon: 'mdi-skip-next',
        description: 'Completing the process was skipped',
        action: 'Skip completing the process',
        value: ProcessProcessStatusEnum.Skipped,
        color: 'error lighten-1',
        rank: 5,
        allowedTransitions: [
            ProcessProcessStatusEnum.Deleted,
            ProcessProcessStatusEnum.Invalidated,
        ],
    },
    solve_failed: {
        text: 'Solve Failed',
        icon: 'mdi-alert-decagram',
        description: 'Argonaut was unable to solve which specification to use',
        value: ProcessProcessStatusEnum.SolveFailed,
        color: 'error',
        rank: 2,
        allowedTransitions: [
            ProcessProcessStatusEnum.Deleted,
            ProcessProcessStatusEnum.Invalidated,
        ],
    },
};

