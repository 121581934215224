import {
    ClientError,
    GraphQLClient,
} from 'graphql-request';
import {
    ComputedRef,
    Ref,
    computed,
    getCurrentInstance,
    isRef,
    onUnmounted,
    ref,
    watch,
} from 'vue';
import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import {
    useDebounceFn,
} from '@vueuse/core';
import { CausalError } from '@redviking/causal-error';
import isEqual from 'lodash.isequal';

// gql-client types are wack, and tends to pick the wrong overload for `request()`.
// when that happens, the errors are SUPER difficult to troubleshoot.
// this should be a safe type cast to narrow down the overloads to just the things we use.
type SimplifiedGqlClient = {
    setEndpoint (endpoint: string): void;
    setHeaders (headers: Record<string, string>): void;
    request<TResult, TVariables>(requestOptions: {
        document: TypedDocumentNode<TResult, TVariables>,
        variables: TVariables,
        signal?: AbortSignal,
    }): Promise<TResult>;
}

export const gqlClient = new GraphQLClient(`${window.location.origin}/hasura/v1/graphql`, {
    requestMiddleware (request) {
        const operationName = request.operationName;
        request.url += `?${operationName}`;
        return request;
    },
    /**
     * @param _response type correction https://github.com/jasonkuhrt/graphql-request/issues/542#issuecomment-1793697410
     */
    responseMiddleware (responseOrError) {
        if (responseOrError instanceof Error && responseOrError.name !== 'AbortError') {
            if (clientErrorFallbackCb) {
                if (responseOrError instanceof ClientError) {
                    // convert to friendly error, since gql errors can be large
                    const firstErrorOrFullError = responseOrError.response.errors ? responseOrError.response.errors[0] : responseOrError;
                    const cerr = new CausalError('Request failed', CausalError.from({
                        message: firstErrorOrFullError.message,
                        clientError: responseOrError,
                    }));
                    clientErrorFallbackCb(cerr);
                } else {
                    // some other error
                    clientErrorFallbackCb(CausalError.from(responseOrError));
                }
            } else {
                console.error('Unhandled gql client error', responseOrError, { ...responseOrError });
            }
        }
    },
}) as SimplifiedGqlClient;

export function setClientAuth (token: string, endpoint: string) {
    gqlClient.setEndpoint(endpoint);
    if (token) {
        gqlClient.setHeaders({ Authorization: `Bearer ${token}` });
    } else {
        gqlClient.setHeaders({});
    }
}

/** the first error returned, or the whole error returned (GQL can return multiple errors!) */
type FriendlyClientError = CausalError<{ message: string, clientError: ClientError | Error }>;

/** global state for error handling */
let clientErrorFallbackCb: ((error: Error) => void) | null = null;
export function onClientErrorFallback (fn: (error: FriendlyClientError) => void) {
    clientErrorFallbackCb = fn as (error: Error) => void;
}

export type UseGqlRequestReturn<TResult, TVariables extends Record<string, unknown> = Record<string, unknown>> = {
    loading: ComputedRef<boolean>,
    /** call this with a cb to run when a request completes */
    onResult: (onResultCb: (result: TResult) => void) => void,
    /** call this with a cb to run when a request fails */
    onError: (onErrorCb: (err: FriendlyClientError | Error) => void) => void,
    /**
     * manually triggers a request. Can return `null` if the request isn't enabled.
     *
     * this differs slightly from the original apollo composition fn, where it could return null instead of a Promise if the request was disabled.
     */
    execute: (variables?: TVariables) => Promise<TResult>,
    /** the latest result from running the request */
    result: ComputedRef<TResult | null>,
    /** if the latest request failed, the error from the failure */
    error: ComputedRef<FriendlyClientError | Error | null>,
    abortController: Ref<AbortController>,
};

/**
 * - provides an `execute` fn that will automatically use your `variables` ref.
 * - allows attaching cb for result or error
 * - auto manages `loading` ref
 * - exposes result and error ref
 * - provides an abort controller
 * - automatically handles error from gql client and converts to a friendly error
 */
export function useGqlRequest<TResult, TVariables extends Record<string, unknown> = Record<string, unknown>> (
    document: TypedDocumentNode<TResult, TVariables>,
    /** if provided a `ref` and the request is `enabled`, the request will automatically refetch when changed */
    variables: TVariables | Ref<TVariables>
): UseGqlRequestReturn<TResult, TVariables> {
    // normalized options
    const variablesRef = computed(() => (isRef(variables) ? variables.value : variables) ?? {});

    // state
    const loading = ref(false);
    /** stores the latest promise for the request so we know when to set `loading` to `false` */
    let latestRequestPromise: Promise<TResult> | null = null;
    /**
     * this should get reassigned whenever a new request is started.
     * It's ok to abort more than once, it settles like a Promise
    */
    const abortController = ref(new AbortController());
    let onResultCb: ((result: TResult) => void) | null = null;
    let onErrorCb: ((err: FriendlyClientError | Error) => void) | null = null;
    const result = ref<TResult | null>(null) as Ref<TResult | null>;
    const error = ref<FriendlyClientError | Error | null>(null);

    watch(result, newResult => {
        if (onResultCb && newResult) {
            onResultCb(newResult);
        }
    });
    watch(error, newError => {
        if (onErrorCb && newError) {
            onErrorCb(newError);
        }
    });

    return {
        loading: loading as ComputedRef<boolean>, // expose as readonly
        onResult (newOnResultCb: (result: TResult) => void) {
            onResultCb = newOnResultCb;

            // if we already have a value, we can call the cb immediately
            if (result.value) {
                newOnResultCb(result.value);
            }
        },
        onError (newOnErrorCb: (err: FriendlyClientError | Error) => void) {
            onErrorCb = newOnErrorCb;

            // if we already have a value, we can call the cb immediately
            if (error.value) {
                newOnErrorCb(error.value);
            }
        },
        result: result as ComputedRef<TResult | null>, // expose as readonly
        error: error as ComputedRef<FriendlyClientError | null>, // expose as readonly
        abortController,
        /**
         * @param executeVariables overrides value from `variables` if provided
         */
        execute (executeVariables: TVariables = variablesRef.value): Promise<TResult> {
            abortController.value = new AbortController();

            loading.value = true;

            const requestPromise = gqlClient.request<TResult, TVariables>({
                document,
                variables: executeVariables,
                signal: abortController.value.signal,
            }).then(data => {
                result.value = data;
                return data;
            });
            latestRequestPromise = requestPromise;

            // update loading value after request is settled
            requestPromise.finally(() => {
                // only the latest request should switch loading to false
                if (latestRequestPromise === requestPromise) {
                    loading.value = false;
                }
            }).catch(() => {});

            return requestPromise.catch((err: ClientError | Error) => {
                if (err instanceof ClientError) {
                    // convert to friendly error
                    const firstErrorOrFullError = err.response.errors ? err.response.errors[0] : err;
                    const cerr = CausalError.from<{ message: string, clientError: ClientError }>({
                        message: firstErrorOrFullError.message,
                        clientError: err,
                    });

                    error.value = cerr;
                } else if (err.name !== 'AbortError') {
                    error.value = err;
                }
                throw err;
            });
        },
    };
}


/** global state, for things to be refetched when `refetchAllQueries` is called  */
const observableQueryRefetchers = new Set<() => Promise<unknown>>();

export function refetchAllQueries () {
    for (const refetch of observableQueryRefetchers) {
        refetch().catch(() => {}); // we can't handle errors from a global refetch. hopefully the global handler / onError is enough
    }
}

// eslint-disable-next-line unused-imports/no-unused-vars, @typescript-eslint/no-explicit-any
export interface UseQueryOptions<TResult = any, TVariables = any> {
    /** if set, triggers to refetch will be debounced by this value in ms */
    debounce?: number;
    /**
     * @default true
     */
    enabled?: boolean;
}

export interface UseQueryReturn<
    TResult,
    TVariables extends Record<string, unknown> = Record<string, unknown>
> extends Omit<UseGqlRequestReturn<TResult, TVariables>, 'execute' | 'onResult'> {
    /** may return `null` if the query is disabled */
    refetch: (variables?: TVariables) => (Promise<{ data: TResult } | null>),

    // modify return type so that onResultCb is called with { data: TResult }
    onResult: (onResultCb: (result: { data: TResult }) => void) => void
}

/**
 * extends `useGqlRequest`, adding support for `enabled` and query debouncing.
 * Because a query can be disabled or refetched, a request in progress can be aborted.
 * When a request is aborted in this controlled way, we don't expose the error upstream.
 */
export function useQuery<TResult, TVariables extends Record<string, unknown> = Record<string, unknown>> (
    document: TypedDocumentNode<TResult, TVariables>,
    variables: TVariables | (() => TVariables) | Ref<TVariables>,
    options: UseQueryOptions | (() => UseQueryOptions) | Ref<UseQueryOptions> = {}
): UseQueryReturn<TResult, TVariables> {
    const normalizedOptions = computed(typeof options === 'function' ? options : () => isRef(options) ? options.value : options);
    const normalizedVariables = computed(typeof variables === 'function' ? variables : () => isRef(variables) ? variables.value : variables);
    const enabled = computed(() => normalizedOptions.value.enabled ?? true);

    const {
        execute,
        onResult,
        abortController,
        error,
        ...useGqlRequestReturn
    } = useGqlRequest(document, normalizedVariables);

    const debouncedExecute = useDebounceFn((vars: TVariables = normalizedVariables.value) => {
        if (enabled.value) {
            abortController.value.abort(); // abort any previous request
            return execute(vars)
                .then(data => ({ data })); // vestigial apollo format
        } else {
            return null;
        }
    }, computed(() => normalizedOptions.value.debounce ?? 0));

    function refetch (vars: TVariables = normalizedVariables.value) {
        return debouncedExecute(vars)
            .then(p => p); // unwrap the Promise<Promise<TResult>> (mainly making TS happy, I don't think `p` is a promise here)
    }

    // initial fetch, and execute when variables or enabled change
    watch([ enabled, normalizedVariables ], ([ newEnabled, newVars ], [ prevEnabled, prevVars ]) => {
        if (newEnabled && (prevEnabled !== newEnabled || !isEqual(newVars, prevVars))) {
            refetch(newVars)?.catch(() => {}); // we can't handle errors from a watch. hopefully the global handler / onError is enough
        } else {
            // if a query was in progress, abort it.
            abortController.value.abort();
        }
    }, { immediate: true });

    // setup observable query that can be refetched from a global refetch function.
    // this should probably not be used outside of a setup function, so we check and warn.
    if (getCurrentInstance()) {
        observableQueryRefetchers.add(debouncedExecute);

        onUnmounted(() => {
            // remove from observableQueries
            observableQueryRefetchers.delete(debouncedExecute);
        });
    } else {
        console.warn('useQuery should only be used inside a setup function.');
    }

    return {
        ...useGqlRequestReturn,
        onResult (onResultCb) {
            onResult(data => onResultCb({ data })); // vestigial apollo format
        },
        /** wraps the cb with a check for controlled abort */
        onError (cb: (err: FriendlyClientError) => void) {
            useGqlRequestReturn.onError(err => {
                if (err.name !== 'AbortError') {
                    // only call the cb if it's not an abort error
                    cb(err as FriendlyClientError);
                }
            });
        },
        refetch,
        abortController,
        /** wraps the error ref and masks a controlled abort error */
        error: computed(() => {
            if (error.value?.name === 'AbortError') {
                // ignore abort errors
                return null;
            }
            return error.value;
        }),
    };
}

export interface UseMutationOptions<_ = never, TVariables extends Record<string, unknown> = Record<string, unknown>> {
    variables?: TVariables;
}

export type UseMutateReturn<
    TResult,
    TVariables extends Record<string, unknown> = Record<string, unknown>
> = Omit<UseGqlRequestReturn<TResult, TVariables>, 'execute' | 'onResult'> & {
    // mutate is exposed instead of execute
    mutate: UseGqlRequestReturn<TResult, TVariables>['execute'],

    // modify return type so that onResultCb is called with { data: TResult }
    onDone: (onResultCb: (result: { data: TResult }) => void) => void
};

export function useMutation<TResult, TVariables extends Record<string, unknown> = Record<string, unknown>> (
    document: TypedDocumentNode<TResult, TVariables>,
    options: UseMutationOptions<TResult, TVariables> | (() => UseMutationOptions<TResult, TVariables>) | Ref<UseMutationOptions<TResult, TVariables>> = {}
): UseMutateReturn<TResult, TVariables> {
    let variables: ComputedRef<TVariables>;
    // the `{} as TVariables` cast is a fallback, and is possibly a mistake. But I'm not sure how to handle it since apollo types say it's optional.
    if (typeof options === 'function') {
        variables = computed(() => options().variables || ({} as TVariables));
    } else {
        variables = computed(() => (isRef(options) ? options.value.variables : options.variables) || ({} as TVariables));
    }

    const {
        execute,
        onResult,
        ...useGqlRequestReturn
    } = useGqlRequest(document, variables);

    return {
        ...useGqlRequestReturn,
        mutate: execute,
        onDone (onResultCb) {
            onResult(data => onResultCb({ data })); // vestigial apollo format
        },
    };
}
