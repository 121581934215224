import { cellCfgAttrsBaseSchema_v3, cellCfgBaseSchema_v3 } from './cell-base.v3.zod';

import z from 'zod';

export const variableBehaviorTypeSchema_v3 = z.union([
    z.literal('constant'),
    z.literal('timestamp'),
    z.literal('script'),
]);

const baseVarBehaviorSchema_v3 = z.object({
    type: variableBehaviorTypeSchema_v3,
});

const constantVarBehaviorSchema_v3 = baseVarBehaviorSchema_v3.extend({
    type: z.literal('constant'),
    value: z.union([ z.string(), z.number() ]).nullable(),
});

export const scriptVarBehaviorSchema_v3 = baseVarBehaviorSchema_v3.extend({
    type: z.literal('script'),
});

const timestampVarBehaviorSchema_v3 = baseVarBehaviorSchema_v3.extend({
    type: z.literal('timestamp'),
});

const varBehaviorSchema = z.discriminatedUnion('type', [
    scriptVarBehaviorSchema_v3,
    timestampVarBehaviorSchema_v3,
    constantVarBehaviorSchema_v3,
]);

export const btnCellSchema_v3 = cellCfgBaseSchema_v3.extend({
    type: z.literal('btn'),
    attrs: cellCfgAttrsBaseSchema_v3.extend({
        enabled: z.union([ z.string(), z.number() ]),
        label: z.string(),
        color: z.string(),
        variant: z.enum([ 'text', 'outlined' ]).nullable().optional(),
        targetVar: z.string(),
        varBehavior: varBehaviorSchema.optional(),
        linkedScriptId: z.string().optional(),
        size: z.enum([ 'small', 'medium', 'large', 'x-large' ]).default('medium'),
    }),
    modifiers: z.record(z.enum([
        'enabled',
        'label',
        'color',
        'variant',
        'targetVar',
        'size',
        'background',
        'borderColor',
    ]), z.string().optional()).optional(),
});
