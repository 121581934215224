import { mutationTree } from 'typed-vuex';

// FUTURE: I have to have SOME string in the record, hence the `placeholder1` and `placeholder2`. This should be removed when we have actual feature flags.
export type FeatureFlags = Record<'placeholder1' | 'placeholder2', string | undefined>;

const routerState = {
    k8sChartNamespace: '',
    featureFlags: {} as FeatureFlags,
};

const mutations = mutationTree(routerState, {
    setChartNamespace (state, namespace: string) {
        state.k8sChartNamespace = namespace;
    },
    setFeatureFlags (state, flags: FeatureFlags) {
        state.featureFlags = flags;
    },
});

export default {
    state: routerState,
    mutations,
};
