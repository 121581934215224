export const messages = {
    behavior: {
        cond: {
            na: 'N/A',
            prefix: 'Only send the payload if',
            suffix: 'the following comparisons are satisfied:',
        },
        errorVar: {
            hint: 'After a successful send, this variable will be reset',
            label: 'Variable to store any error while sending the payload',
            placeholder: 'Enter variable name',
        },
        title: 'Send Behavior (optional)',
    },
    desc: 'Write one or more metrics to a sparkplug device in a single payload',
    labels: {
        device: 'Sparkplug Device',
        node: 'Sparkplug Node',
        varsToResetOnSend: 'Variables to reset after a successful send',
    },
    payload: {
        add: 'Add Metric',
        metric: 'Metric',
        metricPlaceholder: 'Metric Name',
        title: 'Metrics to Send',
        var: 'Variable for the value',
    },
    targetDevice: {
        deploymentParam: 'Use a deployment parameter',
        deploymentParamPlaceholder: 'Enter deployment parameter name',
        manualDevice: 'Use a specific sparkplug device',
        title: 'Target Sparkplug Device',
    },
    title: 'Sparkplug Commander',
};
