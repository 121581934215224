/* eslint sort-keys: 2 */ // prevents merge conflicts
import { PickByValue } from 'utility-types';
import colors from 'vuetify/es5/util/colors';

export const styleMap = {
    'attribute': {
        color: '#21B8bb',
        icon: 'mdi-vector-polyline',
    },
    'calendar': {
        color: colors.green.base,
        icon: 'mdi-calendar',
    },
    'context': {
        icon: 'mdi-briefcase-account-outline',
    },
    'data-element': {
        color: colors.deepPurple.lighten2,
        icon: 'mdi-network-outline',
    },
    'deployment': {
        color: colors.cyan.darken2,
        icon: 'mdi-hexagon-multiple',
    },
    'design': {
        color: colors.cyan.darken2,
        icon: 'mdi-hexagon',
    },
    'device': {
        color: colors.yellow.darken3,
        icon: 'mdi-television-classic',
    },
    'dhl': {
        color: '#CD163F',
        icon: 'mdi-flash',
    },
    'event': {
        color: colors.indigo.lighten2,
        icon: 'mdi-clock-outline',
    },
    'location': {
        color: colors.green.base,
        icon: 'mdi-map-marker',
    },
    'material': {
        color: colors.blue.lighten2,
        icon: 'mdi-layers',
    },
    'material-class': {
        color: colors.blue.base,
        icon: 'mdi-alpha-c-box-outline',
    },
    'material-model': {
        color: colors.blue.lighten1,
        icon: 'mdi-alpha-m-box-outline',
    },
    'mqtt': {
        color: '#660066',
        icon: 'mdi-broadcast',
    },
    'process': {
        color: colors.deepPurple.base,
        icon: 'mdi-order-bool-descending-variant',
    },
    'role': {
        color: '#21b8bb',
        icon: 'mdi-badge-account',
    },
    'runner': {
        color: '#21b8bb',
        icon: 'mdi-hexagon-multiple-outline',
    },
    'schedule': {
        color: colors.green.base,
        icon: 'mdi-calendar-clock',
    },
    'serial-number': {
        icon: 'mdi-numeric',
    },
    'uom': {
        color: colors.cyan.base,
        icon: 'mdi-tape-measure',
    },
    'user': {
        color: '#21b8bb',
        icon: 'mdi-account',
    },
};
export default styleMap;

export type StyleMap = typeof styleMap;

export type ArgoColors = keyof PickByValue<StyleMap, { color: string }>;
export type ArgoIcons = keyof PickByValue<StyleMap, { icon: string }>;
