import { validateAcls } from '@redviking/argonaut-core-ui/src/auth';

export function validateCalendarSelectAcl (): boolean {
    return validateAcls([ 'calendar_select' ]);
}

export function validateCalendarInsertAcl (): boolean {
    return validateAcls([ 'calendar_insert' ]);
}

export function validateCalendarUpdateAcl (): boolean {
    return validateAcls([ 'calendar_update' ]);
}

export function validateCalendarDeleteAcl (): boolean {
    return validateAcls([ 'calendar_delete' ]);
}

export function validateScheduleDaySelectAcl (): boolean {
    return validateAcls([ 'schedule_day_select' ]);
}

export function validateScheduleDayInsertAcl (): boolean {
    return validateAcls([ 'schedule_day_insert' ]);
}

export function validateScheduleDayUpdateAcl (): boolean {
    return validateAcls([ 'schedule_day_update' ]);
}

export function validateScheduleDayDeleteAcl (): boolean {
    return validateAcls([ 'schedule_day_delete' ]);
}

export function validateEventSelectAcl (): boolean {
    return validateAcls([ 'event_select' ]);
}

export function validateEventInsertAcl (): boolean {
    return validateAcls([ 'event_insert' ]);
}

export function validateEventUpdateAcl (): boolean {
    return validateAcls([ 'event_update' ]);
}

export function validateEventDeleteAcl (): boolean {
    return validateAcls([ 'event_delete' ]);
}

export function validateCalendarEventSelectAcl (): boolean {
    return validateAcls([ 'calendar_event_select' ]);
}

export function validateCalendarEventInsertAcl (): boolean {
    return validateAcls([ 'calendar_event_insert' ]);
}

export function validateCalendarEventUpdateAcl (): boolean {
    return validateAcls([ 'calendar_event_update' ]);
}

export function validateCalendarEventDeleteAcl (): boolean {
    return validateAcls([ 'calendar_event_delete' ]);
}
export function validateEventTypeSelectAcl (): boolean {
    return validateAcls([ 'event_type_select' ]);
}

export function validateEventTypeInsertAcl (): boolean {
    return validateAcls([ 'event_type_insert' ]);
}

export function validateEventTypeUpdateAcl (): boolean {
    return validateAcls([ 'event_type_update' ]);
}

export function validateEventTypeDeleteAcl (): boolean {
    return validateAcls([ 'event_type_delete' ]);
}

export function validateScheduleSelectAcl (): boolean {
    // todo: change to check acl when it is created
    return validateAcls([ 'schedule_select' ]);
}
export function validateScheduleUpdateAcl (): boolean {
    // todo: change to check acl when it is created
    return validateAcls([ 'schedule_update' ]);
}
export function validateScheduleDeleteAcl (): boolean {
    // todo: change to check acl when it is created
    return validateAcls([ 'schedule_delete' ]);
}
export function validateScheduleInsertAcl (): boolean {
    // todo: change to check acl when it is created
    return validateAcls([ 'schedule_insert' ]);
}
