import { messages } from 'src/i18n/i18n';
import type {
    EntityType,
} from '@redviking/argonaut-core-ui/types/entity';
import type {
    AuthorLike,
} from '@redviking/argonaut-util/types/entity';
import { formatTs as formatTsUtil, userToString as userToStringUtil } from '@redviking/argonaut-util/src/formatting';


export function createdOrUpdatedByUser<E extends Partial<{
    updatedAt: string | null,
    updated_at: string | null,
    createdAt: string | null,
    created_at: string | null,
    updatedBy: AuthorLike | null,
    updated_by: AuthorLike | null,
    createdBy: AuthorLike | null,
    created_by: AuthorLike | null,
}>> (entity: E | null) {
    return messages.createdOrUpdatedByUser(entity);
}

export function userToString<U extends AuthorLike> (user?: U | null) {
    return userToStringUtil(user);
}

export function formatTs (format: keyof typeof messages.tsFormatters, date: unknown) {
    return formatTsUtil(format, date);
}

export function entityPageBannerTitle (entityType: EntityType, name: string | null | undefined, opts: { nameOnly?: boolean } = {}) {
    return name ? `${opts.nameOnly ? '' : `${messages.entityNames[entityType]} - `}${name}` : messages.entityNames[entityType];
}
