import { gqlClient } from '@redviking/argonaut-core-ui/src/util/gql-client';
import {
    DeviceFragment,
    UpdateDeviceMutationVariables,
} from 'types/db';
import { Route } from 'vue-router';
import { Notify } from 'src/notifications';
import { GetDeviceDocument, UpdateDeviceDocument } from '@redviking/argonaut-core-ui/types/db';
import { messages } from 'src/i18n/i18n';
import { v4 as uuidv4 } from 'uuid';
import { type ExtendedEntityParameters } from 'src/components/EntityDetail/entity-route-util';

/**
 * device type ids
 * TODO replace with device ability system https://gitlab.com/redviking/argonaut/v4/core/-/issues/588#old-stuff-below
 */
export enum DeviceAbility {
    SPARKPLUG_NODE = 'f7ca8c14-fcb6-11ec-8ba9-0242ac180005',
    LABEL_PRINTER = 'c2cfe9f4-508b-11ed-82d5-0242ac120005',
}
export function deviceHasAbilities (device: Pick<DeviceFragment, 'deviceType'> | null, abilities: DeviceAbility[]) {
    // at the moment, the device type id determines its "ability"
    return abilities.every(ability => device?.deviceType?.id === ability);
}

export async function getDeviceEntity (to: Route) {
    const { device } = await gqlClient.request({
        document: GetDeviceDocument,
        variables: {
            deviceId: to.params.deviceId,
        },
    });
    if (device) {
        if (deviceHasAbilities(device, [ DeviceAbility.SPARKPLUG_NODE ])) {
            device.sparkplug_node_config = {
                config_yaml: '',
                enabled: true,
                group_id: '',
                id: uuidv4(),
                node_id: '',
                version: 'spBv1.0',
                ...device.sparkplug_node_config || {},
            };
        } else if (deviceHasAbilities(device, [ DeviceAbility.LABEL_PRINTER ])) {
            device.label_printer_config = {
                id: uuidv4(),
                host: '',
                port: 0,
                printer_type: 'ZEBRA_NETWORK',
                ...device.label_printer_config || {},
            };
        }
        return { entity: device };
    } else {
        const err = new Error('Unknown Device');
        Notify.error(err);
        throw err;
    }
}

export async function saveDeviceEntity (payload: ExtendedEntityParameters<'device'>) {
    const { entity } = payload;
    if (!entity.name || entity.name.length <= 0) {
        const error = new Error('Device missing "name" property');
        Notify.error(error.message, error);
        return;
    }
    try {
        const variables: UpdateDeviceMutationVariables = {
            device: {
                id: entity.id,
                name: entity.name,
                enabled: entity.enabled,
                description: entity.description,
                location_id: entity.location_id,
                device_type_id: entity.device_type_id,
            },
        };
        if (entity.sparkplug_node_config && deviceHasAbilities(entity, [ DeviceAbility.SPARKPLUG_NODE ])) {
            variables.sparkplugNodeConfig = [
                {
                    id: entity.sparkplug_node_config.id,
                    enabled: entity.enabled,
                    node_id: entity.sparkplug_node_config.node_id,
                    version: entity.sparkplug_node_config.version,
                    group_id: entity.sparkplug_node_config.group_id,
                    config_yaml: entity.sparkplug_node_config.config_yaml,
                    device_id: entity.id,
                },
            ];
        } else if (entity.label_printer_config && deviceHasAbilities(entity, [ DeviceAbility.LABEL_PRINTER ])) {
            variables.labelPrinterConfig = [
                {
                    id: entity.label_printer_config.id,
                    host: entity.label_printer_config.host,
                    port: entity.label_printer_config.port,
                    printer_type: entity.label_printer_config.printer_type,
                    device_id: entity.id,
                },
            ];
        }
        await gqlClient.request({
            document: UpdateDeviceDocument,
            variables,
        });
        Notify.win(messages.device.device.notifySaved);
    } catch (err) {
        Notify.error(err);
        throw err;
    }
}
