export const messages = {
    regexPattern: 'RegEx Pattern',
    titles: {
        noName: 'Unnamed UDF Column',
    },
    udf: {
        addNew: 'Add New UDF Column',
        confirmDelete: 'Are you sure you want to delete this UDF Column?',
        create: 'Create UDF Column',
        editEventTypes: 'Edit Event UDF Types',
        entityName: 'UDF Column',
        hide: 'Hide UDF Column',
        inspect: 'Inspect UDF Column',
        name: 'UDF Column Name',
        noneFound: 'No UDF Columns Found',
        notifySaved: 'UDF Column Saved!',
        plural: 'UDF Columns',
        required: 'UDF Column Required',
        search: 'Search UDF Columns by Column Name or Description',
    },
};
