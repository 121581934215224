import { z } from 'zod';
import { pageCfgSchema_v3 } from '../cell-types/paged.v3.zod';
import { varpConfigBase_v3 } from './var-provider.v3.zod';

const pageProviderAttrsSchema_v3 = z.object({
    pageCfg: pageCfgSchema_v3,
    displayRuleOutputVarColor: z.string(),
    disableRuleOutputVar: z.string(),
    displayRuleOutputVarIcon: z.string(),
    advanceRuleOutputVar: z.string(),
});

export const pageProviderSchema_v3 = varpConfigBase_v3.extend({
    type: z.literal('page'),
    attrs: pageProviderAttrsSchema_v3,
});
