import { z } from 'zod';
import { pageCfgSchema_v3, pagedResetConditionSchema_v3 } from '../cell-types/paged.v3.zod';
import { varpConfigBase_v3 } from './var-provider.v3.zod';

export const pagedProviderSchema_v3 = varpConfigBase_v3.extend({
    type: z.literal('paged'),
    attrs: z.object({
        pageNumberVar: z.string(),
        resetConditions: z.array(pagedResetConditionSchema_v3),
        btnSize: z.enum([ 'small', 'medium', 'large', 'x-large', 'hidden' ]),
        pageCfg: z.array(pageCfgSchema_v3),
    }),
});
