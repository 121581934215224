import Vue from 'vue';
import colors from 'vuetify/lib/util/colors';
import Vuetify from 'vuetify/lib';
import { Ripple } from 'vuetify/lib/directives';
import styleMap, { ArgoColors, ArgoIcons, StyleMap } from 'src/util/style-map';

export type ThemeColors = Record<
    'accent' |
    'add' |
    'background' |
    'debug' |
    'error' |
    'edit' |
    'info' |
    'copy' |
    'primary' |
    'secondary' |
    'success' |
    'warning' |
    'white' |
    'delete' |
    ArgoColors, string>;

// workaround for https://github.com/vuetifyjs/vuetify/issues/12224
Vue.directive('ripple', Ripple);

const argoColors: Partial<Record<ArgoColors, string>> = {};
const argoIcons: Partial<Record<`argo-${ArgoIcons}`, string>> = {};

for (const styleKey in styleMap) {
    const styleMapItem = styleMap[styleKey as keyof StyleMap];
    if ('color' in styleMapItem) {
        argoColors[styleKey as ArgoColors] = styleMapItem.color;
    }
    if ('icon' in styleMapItem) {
        argoIcons[`argo-${styleKey}` as `argo-${ArgoIcons}`] = styleMapItem.icon;
    }
}

export const vuetify = new Vuetify({
    icons: {
        iconfont: 'mdi',
        values: argoIcons,
    },
    theme: {
        options: {
            customProperties: true,
            variations: true,
        },
        themes: {
            // colors are replicated for ag-grid in src/assets/ag-grid-theme.css
            light: {
                // standard
                accent: colors.orange.base,
                error: colors.red.base,
                info: colors.cyan.base,
                primary: '#2f4050',
                secondary: '#21b8bb',
                success: colors.green.base,
                warning: colors.yellow.darken3,

                // actions
                archive: colors.red.base,
                activate: colors.green.base,
                copy: colors.cyan.base,
                add: colors.green.base,
                delete: colors.red.base,
                edit: colors.orange.base,

                ...argoColors,

                // other
                background: '#eeeeee',
                debug: '#3ad678',
                white: '#ffffff',
                silly: `#${Math.floor(Math.random() * 16777215).toString(16).padStart(6, '0')}`,
            } as ThemeColors,
        },
    },
});
