/* eslint-disable complexity */
import { ColDef, IFilterType } from '@ag-grid-community/core';
import { Notify } from 'src/notifications';
import { ArgoUdfColumn, ArgoUdfColumnDataTypeEnum } from 'types/db';
export type UDFColumn = Pick<ArgoUdfColumn, 'id'
    | 'name'
    | 'name_unique'
    | 'enabled'
    | 'validation_pattern'
    | 'validation_unique'
    | 'validation_max'
    | 'validation_min'
    | 'validation_required'
    | 'data_type'
    | 'type'
    | 'description'>;
export type UDFData = {
    id: string;
    udf_column_id: string;
    str_val?: string | null;
    num_val?: number | null;
};

export function getDataPropByDataType (dt: UDFColumn['data_type']) {
    switch (dt) {
        case ArgoUdfColumnDataTypeEnum.Number:
            return 'num_val';
        case ArgoUdfColumnDataTypeEnum.Text:
            return 'str_val';
        default: return '';
    }
}

/** how different schema definition types should be handled */
export const udfDefHandling: {
    [type in ArgoUdfColumnDataTypeEnum]: {
        extractValue: (value: UDFData | null) => string,
        filter: IFilterType,
        valueColumn: 'num_val' | 'str_val',
    }
} = {
    [ArgoUdfColumnDataTypeEnum.Number]: {
        extractValue: value => String(value?.num_val || ''),
        filter: 'agNumberColumnFilter',
        valueColumn: 'num_val',
    },
    [ArgoUdfColumnDataTypeEnum.Text]: {
        extractValue: value => String(value?.str_val || ''),
        filter: 'agTextColumnFilter',
        valueColumn: 'str_val',
    },
};

/**
 * @param udfCols udf column defs to turn into AG cols
 * @param columnFields if set, only pick columns with these field names. By default, this
 * includes all enabled schema definitions, and the `name` and `createdAt` fields
 */
export function udfToAGCol<S extends UDFColumn[]> (udfCols: S, options?: { isDefaultData?: boolean, columnFields?: string[] | null, }): ColDef[] {
    const fields = options?.columnFields || [
        'name',
        ...udfCols.filter((col): col is (UDFColumn & { name_unique: string }) => {
            return Boolean(col.enabled && col.name_unique);
        }).map(d => d.name_unique),
        'createdAt',
    ];

    const result: ColDef[] = [];
    for (const field of fields) {
        // attr data columns
        const udfCol = udfCols.find(def => def.name_unique === field);
        if (udfCol) {
            const defHandler = udfDefHandling[udfCol.data_type];

            result.push({
                field: udfCol.id,
                valueGetter: params => {
                    // find the attr data for the column
                    const udfData: UDFData[] = (options?.isDefaultData ? params.data?.material_udf_default_data : params.data?.udfData) || [];
                    return udfData.find((ad: { udf_column_id: string }) => ad.udf_column_id === udfCol.id) || { udf_column_id: udfCol.id };
                },
                headerName: udfCol.name,
                valueFormatter: params => defHandler.extractValue(params.value),
                filter: defHandler.filter,
                filterParams: {
                    udfCol,
                },
            });
        }
    }
    return result;
}

export function validateUdfData (data: Pick<UDFData, 'str_val' | 'num_val'>, def: UDFColumn, opts: {suppressNotif: boolean} = { suppressNotif: false }): boolean {
    const dataType = def.data_type;
    const required = def.validation_required;
    const min = def.validation_min;
    const max = def.validation_max;
    try {

        if (dataType === ArgoUdfColumnDataTypeEnum.Text) {
            if (!data.str_val && required) {
                const message = `Required Property: ${def.name}`;
                throw new Error(message);
            }
            if (typeof data.str_val !== 'string' && def.validation_required) {
                const message = `Incompatable input type for ${def.name}`;
                throw new Error(message);
            }
            if (data.str_val?.length && def.validation_pattern) {
                const regexp = new RegExp(`${def.validation_pattern}`, 'ug');
                if (!regexp.test(data.str_val || '')) {
                    const message = `Input for ${def.name} doesnt pass validation ${def.validation_pattern}`;
                    throw new Error(message);
                }
            }
        } else if (dataType === ArgoUdfColumnDataTypeEnum.Number) {
            const numVal = data.num_val;
            if (typeof numVal !== 'number' && required) {
                const message = `Required Property: ${def.name}`;
                throw new Error(message);
            }
            if (typeof numVal !== 'number' && def.validation_required) {
                const message = `Incompatable input type for ${def.name}`;
                throw new Error(message);
            }
            if ((min || max) && typeof numVal === 'number' && !isNaN(numVal)) {
                if (typeof min === 'number' && numVal < min) {
                    const message = `Input for ${def.name} is lower than minumum: ${min}`;
                    throw new Error(message);
                }
                if (typeof max === 'number' && numVal > max) {
                    const message = `Input for ${def.name} is higher than maximum: ${max}`;
                    throw new Error(message);
                }
            }
        }
    } catch (err) {
        if (!opts.suppressNotif) {
            Notify.error(`Validation Error: ${err.message}`, err);
        }
        throw err;
    }
    return true;
}
export function validateUdfDataArray (udfData: UDFData[], defs: UDFColumn[], opts?: { throwOnMissingColumn?: boolean, skipRequired?: boolean }): boolean {
    if (!opts?.skipRequired) {
        const requiredDefs = defs.filter(e => e.validation_required);
        if (!requiredDefs.every(def => udfData.some(e => e.udf_column_id === def.id))) {
            const requiredColumn = requiredDefs.find(def => !udfData.find(d => def.id === d.udf_column_id));
            throw new Error(`REQUIRED VALUE FOR FIELD ${requiredColumn?.name} ⚠`);
        }
    }
    return udfData.every(data => {
        const def = defs.find(e => e.id === data.udf_column_id);
        if (!def) {
            console.warn('INVALID COLUMN ID FOR DATA ⚠', { data, udfColumns: defs });
            if (opts?.throwOnMissingColumn) {
                throw new Error('INVALID COLUMN ID FOR DATA ⚠');
            }
            return true;
        }
        return validateUdfData(data, def);
    });
}
