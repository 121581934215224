// Generated automatically by nearley, version 2.20.1
// http://github.com/Hardmath123/nearley
// Bypasses TS6133. Allow declared but unused functions.
// @ts-ignore
function id(d: any[]): any { return d[0]; }

interface NearleyToken {
  value: any;
  [key: string]: any;
};

interface NearleyLexer {
  reset: (chunk: string, info: any) => void;
  next: () => NearleyToken | undefined;
  save: () => any;
  formatError: (token: never) => string;
  has: (tokenType: string) => boolean;
};

interface NearleyRule {
  name: string;
  symbols: NearleySymbol[];
  postprocess?: (d: any[], loc?: number, reject?: {}) => any;
};

type NearleySymbol = string | { literal: any } | { test: (token: any) => boolean };

interface Grammar {
  Lexer: NearleyLexer | undefined;
  ParserRules: NearleyRule[];
  ParserStart: string;
};

const grammar: Grammar = {
  Lexer: undefined,
  ParserRules: [
    {"name": "PROGRAM", "symbols": ["SCOPE"], "postprocess": function(d) {return d[0] }},
    {"name": "SCOPE", "symbols": ["STATEMENTS"], "postprocess": function(d) {return d[0] }},
    {"name": "STATEMENTS", "symbols": ["STATEMENT"]},
    {"name": "STATEMENTS", "symbols": ["STATEMENTS", "STATEMENT"]},
    {"name": "STATEMENT", "symbols": ["EXPRESSION", "TERM"], "postprocess": function(d) {return d[0] }},
    {"name": "STATEMENT", "symbols": ["DECLARATION", "IDENTIFIER", "ASSIGNMENT", "EXPRESSION", "TERM"], "postprocess": function(d) {return d[3] }},
    {"name": "STATEMENT", "symbols": ["IDENTIFIER", "ASSIGNMENT", "EXPRESSION", "TERM"], "postprocess": function(d) {return d[2]}},
    {"name": "STATEMENT", "symbols": ["APPLET_VAR", "ASSIGNMENT", "EXPRESSION", "TERM"], "postprocess": function(d) {return [ d[0], d[2] ]}},
    {"name": "STATEMENT", "symbols": ["IF", "OPEN_PARENS", "BOOL_EXP", "CLOSE_PARENS", "OPEN_BRACKET", "SCOPE", "CLOSE_BRACKET"], "postprocess": function(d) {return [ d[2], d[5]]}},
    {"name": "STATEMENT", "symbols": ["IF", "OPEN_PARENS", "BOOL_EXP", "CLOSE_PARENS", "OPEN_BRACKET", "SCOPE", "CLOSE_BRACKET", "ELSE", "OPEN_BRACKET", "SCOPE", "CLOSE_BRACKET"], "postprocess": function(d) {return [ d[2], d[5], d[9] ] }},
    {"name": "STATEMENT", "symbols": ["IF", "SINGLE_EXPRESSION_AS_BOOL_EXP", "OPEN_BRACKET", "SCOPE", "CLOSE_BRACKET", "ELSE", "OPEN_BRACKET", "SCOPE", "CLOSE_BRACKET"], "postprocess": function(d) {return [ d[2], d[5], d[9] ] }},
    {"name": "STATEMENT", "symbols": ["IF", "SINGLE_EXPRESSION_AS_BOOL_EXP", "OPEN_BRACKET", "SCOPE", "CLOSE_BRACKET"], "postprocess": function(d) {return [ d[2], d[5]]}},
    {"name": "BOOL_EXP", "symbols": ["BOOL_EXP", "AND", "BOOL_EXP"], "postprocess": function(d) {return [ d[0], d[2] ]}},
    {"name": "BOOL_EXP", "symbols": ["BOOL_EXP", "OR", "BOOL_EXP"], "postprocess": function(d) {return [ d[0], d[2] ]}},
    {"name": "BOOL_EXP", "symbols": ["EXPRESSION", "GREATER", "EXPRESSION"], "postprocess": function(d) {return [ d[1], d[2] ] }},
    {"name": "BOOL_EXP", "symbols": ["EXPRESSION", "LESS", "EXPRESSION"], "postprocess": function(d) {return [ d[0], d[2] ]}},
    {"name": "BOOL_EXP", "symbols": ["EXPRESSION", "EQ", "EXPRESSION"], "postprocess": function(d) {return [ d[0], d[2] ]}},
    {"name": "BOOL_EXP", "symbols": ["EXPRESSION", "NOT_EQ", "EXPRESSION"], "postprocess": function(d) {return [ d[0], d[2] ]}},
    {"name": "BOOL_EXP", "symbols": ["EXPRESSION", "GREATER_EQ", "EXPRESSION"], "postprocess": function(d) {return [ d[0], d[2] ]}},
    {"name": "BOOL_EXP", "symbols": ["EXPRESSION", "LESS_EQ", "EXPRESSION"], "postprocess": function(d) {return [ d[0], d[2] ]}},
    {"name": "SINGLE_EXPRESSION_AS_BOOL_EXP", "symbols": ["OPEN_PARENS", "EXPRESSION", "CLOSE_PARENS"]},
    {"name": "EXPRESSION", "symbols": ["OPEN_PARENS", "EXPRESSION", "CLOSE_PARENS"], "postprocess": function(d) {return d[1] }},
    {"name": "EXPRESSION", "symbols": ["EXPRESSION", "MUL", "EXPRESSION"], "postprocess": function(d) {return [ d[0], d[2] ] }},
    {"name": "EXPRESSION", "symbols": ["EXPRESSION", "DIV", "EXPRESSION"], "postprocess": function(d) {return [ d[0], d[2] ] }},
    {"name": "EXPRESSION", "symbols": ["EXPRESSION", "PLUS", "EXPRESSION"], "postprocess": function(d) {return [ d[0], d[2] ] }},
    {"name": "EXPRESSION", "symbols": ["EXPRESSION", "MINUS", "EXPRESSION"], "postprocess": function(d) {return [ d[0], d[2] ]}},
    {"name": "EXPRESSION", "symbols": ["EXPRESSION", "MOD", "EXPRESSION"], "postprocess": function(d) {return [ d[0], d[2] ]}},
    {"name": "EXPRESSION", "symbols": ["IDENTIFIER"], "postprocess": function(d) {return null }},
    {"name": "EXPRESSION", "symbols": ["APPLET_VAR"], "postprocess": function(d) {return d[0] }},
    {"name": "EXPRESSION", "symbols": ["STRING"], "postprocess": function(d) {return null }},
    {"name": "EXPRESSION", "symbols": ["JSON_EXPRESSION"], "postprocess": function(d) {return d[0] }},
    {"name": "EXPRESSION", "symbols": ["MATH_EXPRESSION"], "postprocess": function(d) {return d[0] }},
    {"name": "EXPRESSION", "symbols": ["STR_EXPRESSION"], "postprocess": function(d) {return d[0] }},
    {"name": "EXPRESSION", "symbols": ["NUM_EXPRESSION"], "postprocess": function(d) {return d[0] }},
    {"name": "EXPRESSION", "symbols": ["DATE_EXPRESSION"], "postprocess": function(d) {return null }},
    {"name": "ONE_ARGUEMENT", "symbols": ["EXPRESSION"], "postprocess": function(d) {return d[0] }},
    {"name": "TWO_ARGUEMENTS", "symbols": ["EXPRESSION", {"literal":","}, "EXPRESSION"], "postprocess": function(d) {return [d[0], d[2]] }},
    {"name": "JSON_EXPRESSION", "symbols": ["JSON_KEYWORD", "CHAIN", "JSON_FUNCTION"], "postprocess": function(d) {return d[2] }},
    {"name": "JSON_FUNCTION", "symbols": ["JSON_PARSE"], "postprocess": function(d) {return d[0] }},
    {"name": "JSON_PARSE$string$1", "symbols": [{"literal":"p"}, {"literal":"a"}, {"literal":"r"}, {"literal":"s"}, {"literal":"e"}], "postprocess": (d) => d.join('')},
    {"name": "JSON_PARSE", "symbols": ["JSON_PARSE$string$1", "OPEN_PARENS", "APPLET_VAR", "CLOSE_PARENS", "CHAIN", "IDENTIFIER"], "postprocess": function(d) {return d[2] }},
    {"name": "JSON_PARSE", "symbols": ["JSON_PARSE", "CHAIN", "IDENTIFIER"], "postprocess": function(d) {return d[0] }},
    {"name": "DATE_EXPRESSION", "symbols": ["DATE_KEYWORD", "CHAIN", "DATE_FUNCTION"], "postprocess": function(d) {return null }},
    {"name": "DATE_FUNCTION", "symbols": ["DATE_NOW"], "postprocess": function(d) {return null }},
    {"name": "DATE_NOW$string$1", "symbols": [{"literal":"n"}, {"literal":"o"}, {"literal":"w"}], "postprocess": (d) => d.join('')},
    {"name": "DATE_NOW", "symbols": ["DATE_NOW$string$1", "OPEN_PARENS", "CLOSE_PARENS"], "postprocess": function(d) {return null}},
    {"name": "NUM_EXPRESSION", "symbols": ["IDENTIFIER", "CHAIN", "NUM_FUNCTION"], "postprocess": function(d) {return d[2]}},
    {"name": "NUM_EXPRESSION", "symbols": ["APPLET_VAR", "CHAIN", "NUM_FUNCTION"], "postprocess": function(d) {return [d[0], d[2]]}},
    {"name": "NUM_FUNCTION", "symbols": ["NUM_TOFIXED"], "postprocess": function(d) {return d[0]}},
    {"name": "NUM_FUNCTION", "symbols": ["NUM_TOPRECISION"], "postprocess": function(d) {return d[0]}},
    {"name": "NUM_TOFIXED$string$1", "symbols": [{"literal":"t"}, {"literal":"o"}, {"literal":"F"}, {"literal":"i"}, {"literal":"x"}, {"literal":"e"}, {"literal":"d"}], "postprocess": (d) => d.join('')},
    {"name": "NUM_TOFIXED", "symbols": ["NUM_TOFIXED$string$1", "OPEN_PARENS", "CLOSE_PARENS"], "postprocess": function(d) {return null}},
    {"name": "NUM_TOFIXED$string$2", "symbols": [{"literal":"t"}, {"literal":"o"}, {"literal":"F"}, {"literal":"i"}, {"literal":"x"}, {"literal":"e"}, {"literal":"d"}], "postprocess": (d) => d.join('')},
    {"name": "NUM_TOFIXED", "symbols": ["NUM_TOFIXED$string$2", "OPEN_PARENS", "ONE_ARGUEMENT", "CLOSE_PARENS"], "postprocess": function(d) {return d[2]}},
    {"name": "NUM_TOPRECISION$string$1", "symbols": [{"literal":"t"}, {"literal":"o"}, {"literal":"P"}, {"literal":"r"}, {"literal":"e"}, {"literal":"c"}, {"literal":"i"}, {"literal":"s"}, {"literal":"i"}, {"literal":"o"}, {"literal":"n"}], "postprocess": (d) => d.join('')},
    {"name": "NUM_TOPRECISION", "symbols": ["NUM_TOPRECISION$string$1", "OPEN_PARENS", "CLOSE_PARENS"], "postprocess": function(d) {return null}},
    {"name": "NUM_TOPRECISION$string$2", "symbols": [{"literal":"t"}, {"literal":"o"}, {"literal":"P"}, {"literal":"r"}, {"literal":"e"}, {"literal":"c"}, {"literal":"i"}, {"literal":"s"}, {"literal":"i"}, {"literal":"o"}, {"literal":"n"}], "postprocess": (d) => d.join('')},
    {"name": "NUM_TOPRECISION", "symbols": ["NUM_TOPRECISION$string$2", "OPEN_PARENS", "ONE_ARGUEMENT", "CLOSE_PARENS"], "postprocess": function(d) {return d[2]}},
    {"name": "STR_EXPRESSION", "symbols": ["STRING", "CHAIN", "STR_FUNCTION"], "postprocess": function(d) {return d[2]}},
    {"name": "STR_EXPRESSION", "symbols": ["NEW_STRING", "CHAIN", "STR_FUNCTION"], "postprocess": function(d) {return [ d[0], d[2] ] }},
    {"name": "STR_EXPRESSION", "symbols": ["APPLET_VAR", "CHAIN", "STR_FUNCTION"], "postprocess": function(d) {return [ d[0], d[2] ] }},
    {"name": "STR_FUNCTION", "symbols": ["STR_PAD_END"], "postprocess": function(d) {return d[0] }},
    {"name": "STR_FUNCTION", "symbols": ["STR_PAD_START"], "postprocess": function(d) {return d[0] }},
    {"name": "STR_FUNCTION", "symbols": ["STR_REPEAT"], "postprocess": function(d) {return d[0] }},
    {"name": "STR_FUNCTION", "symbols": ["STR_REPLACE"], "postprocess": function(d) {return d[0] }},
    {"name": "STR_FUNCTION", "symbols": ["STR_SEARCH"], "postprocess": function(d) {return d[0] }},
    {"name": "STR_FUNCTION", "symbols": ["STR_SLICE"], "postprocess": function(d) {return d[0] }},
    {"name": "STR_FUNCTION", "symbols": ["STR_SPLIT"], "postprocess": function(d) {return d[0] }},
    {"name": "STR_FUNCTION", "symbols": ["STR_STARTSWITH"], "postprocess": function(d) {return d[0] }},
    {"name": "STR_FUNCTION", "symbols": ["STR_SUBSTRING"], "postprocess": function(d) {return d[0] }},
    {"name": "STR_FUNCTION", "symbols": ["STR_TOLOCALELOWERCASE"], "postprocess": function(d) {return null }},
    {"name": "STR_FUNCTION", "symbols": ["STR_TOLOCALEUPPERCASE"], "postprocess": function(d) {return null }},
    {"name": "STR_FUNCTION", "symbols": ["STR_TRIM"], "postprocess": function(d) {return null }},
    {"name": "STR_FUNCTION", "symbols": ["STR_LENGTH"], "postprocess": function(d) {return null }},
    {"name": "STR_PAD_END$string$1", "symbols": [{"literal":"p"}, {"literal":"a"}, {"literal":"d"}, {"literal":"E"}, {"literal":"n"}, {"literal":"d"}], "postprocess": (d) => d.join('')},
    {"name": "STR_PAD_END", "symbols": ["STR_PAD_END$string$1", "OPEN_PARENS", "ONE_ARGUEMENT", "CLOSE_PARENS"], "postprocess": function(d) {return d[2] }},
    {"name": "STR_PAD_END$string$2", "symbols": [{"literal":"p"}, {"literal":"a"}, {"literal":"d"}, {"literal":"E"}, {"literal":"n"}, {"literal":"d"}], "postprocess": (d) => d.join('')},
    {"name": "STR_PAD_END", "symbols": ["STR_PAD_END$string$2", "OPEN_PARENS", "TWO_ARGUEMENTS", "CLOSE_PARENS"], "postprocess": function(d) {return d[2] }},
    {"name": "STR_PAD_START$string$1", "symbols": [{"literal":"p"}, {"literal":"a"}, {"literal":"d"}, {"literal":"S"}, {"literal":"t"}, {"literal":"a"}, {"literal":"r"}, {"literal":"t"}], "postprocess": (d) => d.join('')},
    {"name": "STR_PAD_START", "symbols": ["STR_PAD_START$string$1", "OPEN_PARENS", "ONE_ARGUEMENT", "CLOSE_PARENS"], "postprocess": function(d) {return d[2] }},
    {"name": "STR_PAD_START$string$2", "symbols": [{"literal":"p"}, {"literal":"a"}, {"literal":"d"}, {"literal":"S"}, {"literal":"t"}, {"literal":"a"}, {"literal":"r"}, {"literal":"t"}], "postprocess": (d) => d.join('')},
    {"name": "STR_PAD_START", "symbols": ["STR_PAD_START$string$2", "OPEN_PARENS", "TWO_ARGUEMENTS", "CLOSE_PARENS"], "postprocess": function(d) {return d[2] }},
    {"name": "STR_REPEAT$string$1", "symbols": [{"literal":"r"}, {"literal":"e"}, {"literal":"p"}, {"literal":"e"}, {"literal":"a"}, {"literal":"t"}], "postprocess": (d) => d.join('')},
    {"name": "STR_REPEAT", "symbols": ["STR_REPEAT$string$1", "OPEN_PARENS", "ONE_ARGUEMENT", "CLOSE_PARENS"], "postprocess": function(d) {return d[2] }},
    {"name": "STR_REPLACE$string$1", "symbols": [{"literal":"r"}, {"literal":"e"}, {"literal":"p"}, {"literal":"l"}, {"literal":"a"}, {"literal":"c"}, {"literal":"e"}], "postprocess": (d) => d.join('')},
    {"name": "STR_REPLACE", "symbols": ["STR_REPLACE$string$1", "OPEN_PARENS", "TWO_ARGUEMENTS", "CLOSE_PARENS"], "postprocess": function(d) {return d[2] }},
    {"name": "STR_SEARCH$string$1", "symbols": [{"literal":"s"}, {"literal":"e"}, {"literal":"a"}, {"literal":"r"}, {"literal":"c"}, {"literal":"h"}], "postprocess": (d) => d.join('')},
    {"name": "STR_SEARCH", "symbols": ["STR_SEARCH$string$1", "OPEN_PARENS", "ONE_ARGUEMENT", "CLOSE_PARENS"], "postprocess": function(d) {return d[2] }},
    {"name": "STR_SLICE$string$1", "symbols": [{"literal":"s"}, {"literal":"l"}, {"literal":"i"}, {"literal":"c"}, {"literal":"e"}], "postprocess": (d) => d.join('')},
    {"name": "STR_SLICE", "symbols": ["STR_SLICE$string$1", "OPEN_PARENS", "CLOSE_PARENS"], "postprocess": function(d) {return null }},
    {"name": "STR_SLICE$string$2", "symbols": [{"literal":"s"}, {"literal":"l"}, {"literal":"i"}, {"literal":"c"}, {"literal":"e"}], "postprocess": (d) => d.join('')},
    {"name": "STR_SLICE", "symbols": ["STR_SLICE$string$2", "OPEN_PARENS", "ONE_ARGUEMENT", "CLOSE_PARENS"], "postprocess": function(d) {return d[2] }},
    {"name": "STR_SLICE$string$3", "symbols": [{"literal":"s"}, {"literal":"l"}, {"literal":"i"}, {"literal":"c"}, {"literal":"e"}], "postprocess": (d) => d.join('')},
    {"name": "STR_SLICE", "symbols": ["STR_SLICE$string$3", "OPEN_PARENS", "TWO_ARGUEMENTS", "CLOSE_PARENS"], "postprocess": function(d) {return d[2] }},
    {"name": "STR_SPLIT$string$1", "symbols": [{"literal":"s"}, {"literal":"p"}, {"literal":"l"}, {"literal":"i"}, {"literal":"t"}], "postprocess": (d) => d.join('')},
    {"name": "STR_SPLIT", "symbols": ["STR_SPLIT$string$1", "OPEN_PARENS", "ONE_ARGUEMENT", "CLOSE_PARENS"], "postprocess": function(d) {return d[2] }},
    {"name": "STR_SPLIT$string$2", "symbols": [{"literal":"s"}, {"literal":"p"}, {"literal":"l"}, {"literal":"i"}, {"literal":"t"}], "postprocess": (d) => d.join('')},
    {"name": "STR_SPLIT", "symbols": ["STR_SPLIT$string$2", "OPEN_PARENS", "TWO_ARGUEMENTS", "CLOSE_PARENS"], "postprocess": function(d) {return d[2] }},
    {"name": "STR_STARTSWITH$string$1", "symbols": [{"literal":"s"}, {"literal":"t"}, {"literal":"a"}, {"literal":"r"}, {"literal":"t"}, {"literal":"s"}, {"literal":"W"}, {"literal":"i"}, {"literal":"t"}, {"literal":"h"}], "postprocess": (d) => d.join('')},
    {"name": "STR_STARTSWITH", "symbols": ["STR_STARTSWITH$string$1", "OPEN_PARENS", "ONE_ARGUEMENT", "CLOSE_PARENS"], "postprocess": function(d) {return d[2] }},
    {"name": "STR_STARTSWITH$string$2", "symbols": [{"literal":"s"}, {"literal":"t"}, {"literal":"a"}, {"literal":"r"}, {"literal":"t"}, {"literal":"s"}, {"literal":"W"}, {"literal":"i"}, {"literal":"t"}, {"literal":"h"}], "postprocess": (d) => d.join('')},
    {"name": "STR_STARTSWITH", "symbols": ["STR_STARTSWITH$string$2", "OPEN_PARENS", "TWO_ARGUEMENTS", "CLOSE_PARENS"], "postprocess": function(d) {return d[2] }},
    {"name": "STR_SUBSTRING$string$1", "symbols": [{"literal":"s"}, {"literal":"u"}, {"literal":"b"}, {"literal":"s"}, {"literal":"t"}, {"literal":"r"}, {"literal":"i"}, {"literal":"n"}, {"literal":"g"}], "postprocess": (d) => d.join('')},
    {"name": "STR_SUBSTRING", "symbols": ["STR_SUBSTRING$string$1", "OPEN_PARENS", "CLOSE_PARENS"], "postprocess": function(d) {return null }},
    {"name": "STR_SUBSTRING$string$2", "symbols": [{"literal":"s"}, {"literal":"u"}, {"literal":"b"}, {"literal":"s"}, {"literal":"t"}, {"literal":"r"}, {"literal":"i"}, {"literal":"n"}, {"literal":"g"}], "postprocess": (d) => d.join('')},
    {"name": "STR_SUBSTRING", "symbols": ["STR_SUBSTRING$string$2", "OPEN_PARENS", "ONE_ARGUEMENT", "CLOSE_PARENS"], "postprocess": function(d) {return d[2] }},
    {"name": "STR_SUBSTRING$string$3", "symbols": [{"literal":"s"}, {"literal":"u"}, {"literal":"b"}, {"literal":"s"}, {"literal":"t"}, {"literal":"r"}, {"literal":"i"}, {"literal":"n"}, {"literal":"g"}], "postprocess": (d) => d.join('')},
    {"name": "STR_SUBSTRING", "symbols": ["STR_SUBSTRING$string$3", "OPEN_PARENS", "TWO_ARGUEMENTS", "CLOSE_PARENS"], "postprocess": function(d) {return d[2] }},
    {"name": "STR_TRIM$string$1", "symbols": [{"literal":"t"}, {"literal":"r"}, {"literal":"i"}, {"literal":"m"}], "postprocess": (d) => d.join('')},
    {"name": "STR_TRIM", "symbols": ["STR_TRIM$string$1", "OPEN_PARENS", "CLOSE_PARENS"], "postprocess": function(d) {return null }},
    {"name": "STR_LENGTH$string$1", "symbols": [{"literal":"l"}, {"literal":"e"}, {"literal":"n"}, {"literal":"g"}, {"literal":"t"}, {"literal":"h"}], "postprocess": (d) => d.join('')},
    {"name": "STR_LENGTH", "symbols": ["STR_LENGTH$string$1"], "postprocess": function(d) {return null }},
    {"name": "STR_TOLOCALELOWERCASE$string$1", "symbols": [{"literal":"t"}, {"literal":"o"}, {"literal":"L"}, {"literal":"o"}, {"literal":"c"}, {"literal":"a"}, {"literal":"l"}, {"literal":"e"}, {"literal":"L"}, {"literal":"o"}, {"literal":"w"}, {"literal":"e"}, {"literal":"r"}, {"literal":"C"}, {"literal":"a"}, {"literal":"s"}, {"literal":"e"}], "postprocess": (d) => d.join('')},
    {"name": "STR_TOLOCALELOWERCASE", "symbols": ["STR_TOLOCALELOWERCASE$string$1", "OPEN_PARENS", "CLOSE_PARENS"], "postprocess": function(d) {return null }},
    {"name": "STR_TOLOCALEUPPERCASE$string$1", "symbols": [{"literal":"t"}, {"literal":"o"}, {"literal":"L"}, {"literal":"o"}, {"literal":"c"}, {"literal":"a"}, {"literal":"l"}, {"literal":"e"}, {"literal":"U"}, {"literal":"p"}, {"literal":"p"}, {"literal":"e"}, {"literal":"r"}, {"literal":"C"}, {"literal":"a"}, {"literal":"s"}, {"literal":"e"}], "postprocess": (d) => d.join('')},
    {"name": "STR_TOLOCALEUPPERCASE", "symbols": ["STR_TOLOCALEUPPERCASE$string$1", "OPEN_PARENS", "CLOSE_PARENS"], "postprocess": function(d) {return null }},
    {"name": "MATH_EXPRESSION", "symbols": ["MATH_KEYWORD", "CHAIN", "MATH_FUNCTION"], "postprocess": function(d) {return d[1] }},
    {"name": "MATH_FUNCTION", "symbols": ["MATH_ABS"], "postprocess": function(d) {return d[0] }},
    {"name": "MATH_FUNCTION", "symbols": ["MATH_ACOS"], "postprocess": function(d) {return d[0] }},
    {"name": "MATH_FUNCTION", "symbols": ["MATH_ACOSH"], "postprocess": function(d) {return d[0] }},
    {"name": "MATH_FUNCTION", "symbols": ["MATH_ASIN"], "postprocess": function(d) {return d[0] }},
    {"name": "MATH_FUNCTION", "symbols": ["MATH_ASINH"], "postprocess": function(d) {return d[0] }},
    {"name": "MATH_FUNCTION", "symbols": ["MATH_ATAN"], "postprocess": function(d) {return d[0] }},
    {"name": "MATH_FUNCTION", "symbols": ["MATH_ATAN2"], "postprocess": function(d) {return d[0] }},
    {"name": "MATH_FUNCTION", "symbols": ["MATH_ATANH"], "postprocess": function(d) {return d[0] }},
    {"name": "MATH_FUNCTION", "symbols": ["MATH_CBRT"], "postprocess": function(d) {return d[0] }},
    {"name": "MATH_FUNCTION", "symbols": ["MATH_CEIL"], "postprocess": function(d) {return d[0] }},
    {"name": "MATH_FUNCTION", "symbols": ["MATH_CLZ32"], "postprocess": function(d) {return d[0] }},
    {"name": "MATH_FUNCTION", "symbols": ["MATH_COS"], "postprocess": function(d) {return d[0] }},
    {"name": "MATH_FUNCTION", "symbols": ["MATH_COSH"], "postprocess": function(d) {return d[0] }},
    {"name": "MATH_FUNCTION", "symbols": ["MATH_EXP"], "postprocess": function(d) {return d[0] }},
    {"name": "MATH_FUNCTION", "symbols": ["MATH_EXPM1"], "postprocess": function(d) {return d[0] }},
    {"name": "MATH_FUNCTION", "symbols": ["MATH_FLOOR"], "postprocess": function(d) {return d[0] }},
    {"name": "MATH_FUNCTION", "symbols": ["MATH_FROUND"], "postprocess": function(d) {return d[0] }},
    {"name": "MATH_FUNCTION", "symbols": ["MATH_HYPOT"], "postprocess": function(d) {return d[0] }},
    {"name": "MATH_FUNCTION", "symbols": ["MATH_IMUL"], "postprocess": function(d) {return d[0] }},
    {"name": "MATH_FUNCTION", "symbols": ["MATH_LOG"], "postprocess": function(d) {return d[0] }},
    {"name": "MATH_FUNCTION", "symbols": ["MATH_LOG10"], "postprocess": function(d) {return d[0] }},
    {"name": "MATH_FUNCTION", "symbols": ["MATH_LOG1P"], "postprocess": function(d) {return d[0] }},
    {"name": "MATH_FUNCTION", "symbols": ["MATH_LOG2"], "postprocess": function(d) {return d[0] }},
    {"name": "MATH_FUNCTION", "symbols": ["MATH_MAX"], "postprocess": function(d) {return d[0] }},
    {"name": "MATH_FUNCTION", "symbols": ["MATH_MIN"], "postprocess": function(d) {return d[0] }},
    {"name": "MATH_FUNCTION", "symbols": ["MATH_POW"], "postprocess": function(d) {return d[0] }},
    {"name": "MATH_FUNCTION", "symbols": ["MATH_RANDOM"], "postprocess": function(d) {return null }},
    {"name": "MATH_FUNCTION", "symbols": ["MATH_ROUND"], "postprocess": function(d) {return d[0] }},
    {"name": "MATH_FUNCTION", "symbols": ["MATH_SIGN"], "postprocess": function(d) {return d[0] }},
    {"name": "MATH_FUNCTION", "symbols": ["MATH_SIN"], "postprocess": function(d) {return d[0] }},
    {"name": "MATH_FUNCTION", "symbols": ["MATH_SINH"], "postprocess": function(d) {return d[0] }},
    {"name": "MATH_FUNCTION", "symbols": ["MATH_SQRT"], "postprocess": function(d) {return d[0] }},
    {"name": "MATH_FUNCTION", "symbols": ["MATH_TAN"], "postprocess": function(d) {return d[0] }},
    {"name": "MATH_FUNCTION", "symbols": ["MATH_TANH"], "postprocess": function(d) {return d[0] }},
    {"name": "MATH_FUNCTION", "symbols": ["MATH_TRUNC"], "postprocess": function(d) {return d[0] }},
    {"name": "MATH_ABS$string$1", "symbols": [{"literal":"a"}, {"literal":"b"}, {"literal":"s"}], "postprocess": (d) => d.join('')},
    {"name": "MATH_ABS", "symbols": ["MATH_ABS$string$1", "OPEN_PARENS", "ONE_ARGUEMENT", "CLOSE_PARENS"], "postprocess": function(d) {return d[2] }},
    {"name": "MATH_ACOS$string$1", "symbols": [{"literal":"a"}, {"literal":"c"}, {"literal":"o"}, {"literal":"s"}], "postprocess": (d) => d.join('')},
    {"name": "MATH_ACOS", "symbols": ["MATH_ACOS$string$1", "OPEN_PARENS", "ONE_ARGUEMENT", "CLOSE_PARENS"], "postprocess": function(d) {return d[2] }},
    {"name": "MATH_ACOSH$string$1", "symbols": [{"literal":"a"}, {"literal":"c"}, {"literal":"o"}, {"literal":"s"}, {"literal":"h"}], "postprocess": (d) => d.join('')},
    {"name": "MATH_ACOSH", "symbols": ["MATH_ACOSH$string$1", "OPEN_PARENS", "ONE_ARGUEMENT", "CLOSE_PARENS"], "postprocess": function(d) {return d[2] }},
    {"name": "MATH_ASIN$string$1", "symbols": [{"literal":"a"}, {"literal":"s"}, {"literal":"i"}, {"literal":"n"}], "postprocess": (d) => d.join('')},
    {"name": "MATH_ASIN", "symbols": ["MATH_ASIN$string$1", "OPEN_PARENS", "ONE_ARGUEMENT", "CLOSE_PARENS"], "postprocess": function(d) {return d[2] }},
    {"name": "MATH_ASINH$string$1", "symbols": [{"literal":"a"}, {"literal":"s"}, {"literal":"i"}, {"literal":"n"}, {"literal":"h"}], "postprocess": (d) => d.join('')},
    {"name": "MATH_ASINH", "symbols": ["MATH_ASINH$string$1", "OPEN_PARENS", "ONE_ARGUEMENT", "CLOSE_PARENS"], "postprocess": function(d) {return d[2] }},
    {"name": "MATH_ATAN$string$1", "symbols": [{"literal":"a"}, {"literal":"t"}, {"literal":"a"}, {"literal":"n"}], "postprocess": (d) => d.join('')},
    {"name": "MATH_ATAN", "symbols": ["MATH_ATAN$string$1", "OPEN_PARENS", "ONE_ARGUEMENT", "CLOSE_PARENS"], "postprocess": function(d) {return d[2] }},
    {"name": "MATH_ATAN2$string$1", "symbols": [{"literal":"a"}, {"literal":"t"}, {"literal":"a"}, {"literal":"n"}, {"literal":"2"}], "postprocess": (d) => d.join('')},
    {"name": "MATH_ATAN2", "symbols": ["MATH_ATAN2$string$1", "OPEN_PARENS", "TWO_ARGUEMENTS", "CLOSE_PARENS"], "postprocess": function(d) {return d[2] }},
    {"name": "MATH_ATANH$string$1", "symbols": [{"literal":"a"}, {"literal":"t"}, {"literal":"a"}, {"literal":"n"}, {"literal":"h"}], "postprocess": (d) => d.join('')},
    {"name": "MATH_ATANH", "symbols": ["MATH_ATANH$string$1", "OPEN_PARENS", "ONE_ARGUEMENT", "CLOSE_PARENS"], "postprocess": function(d) {return d[2] }},
    {"name": "MATH_CBRT$string$1", "symbols": [{"literal":"c"}, {"literal":"b"}, {"literal":"r"}, {"literal":"t"}], "postprocess": (d) => d.join('')},
    {"name": "MATH_CBRT", "symbols": ["MATH_CBRT$string$1", "OPEN_PARENS", "ONE_ARGUEMENT", "CLOSE_PARENS"], "postprocess": function(d) {return d[2] }},
    {"name": "MATH_CEIL$string$1", "symbols": [{"literal":"c"}, {"literal":"e"}, {"literal":"i"}, {"literal":"l"}], "postprocess": (d) => d.join('')},
    {"name": "MATH_CEIL", "symbols": ["MATH_CEIL$string$1", "OPEN_PARENS", "ONE_ARGUEMENT", "CLOSE_PARENS"], "postprocess": function(d) {return d[2] }},
    {"name": "MATH_CLZ32$string$1", "symbols": [{"literal":"c"}, {"literal":"l"}, {"literal":"z"}, {"literal":"3"}, {"literal":"2"}], "postprocess": (d) => d.join('')},
    {"name": "MATH_CLZ32", "symbols": ["MATH_CLZ32$string$1", "OPEN_PARENS", "ONE_ARGUEMENT", "CLOSE_PARENS"], "postprocess": function(d) {return d[2] }},
    {"name": "MATH_COS$string$1", "symbols": [{"literal":"c"}, {"literal":"o"}, {"literal":"s"}], "postprocess": (d) => d.join('')},
    {"name": "MATH_COS", "symbols": ["MATH_COS$string$1", "OPEN_PARENS", "ONE_ARGUEMENT", "CLOSE_PARENS"], "postprocess": function(d) {return d[2] }},
    {"name": "MATH_COSH$string$1", "symbols": [{"literal":"c"}, {"literal":"o"}, {"literal":"s"}, {"literal":"h"}], "postprocess": (d) => d.join('')},
    {"name": "MATH_COSH", "symbols": ["MATH_COSH$string$1", "OPEN_PARENS", "ONE_ARGUEMENT", "CLOSE_PARENS"], "postprocess": function(d) {return d[2] }},
    {"name": "MATH_EXP$string$1", "symbols": [{"literal":"e"}, {"literal":"x"}, {"literal":"p"}], "postprocess": (d) => d.join('')},
    {"name": "MATH_EXP", "symbols": ["MATH_EXP$string$1", "OPEN_PARENS", "ONE_ARGUEMENT", "CLOSE_PARENS"], "postprocess": function(d) {return d[2] }},
    {"name": "MATH_EXPM1$string$1", "symbols": [{"literal":"e"}, {"literal":"x"}, {"literal":"p"}, {"literal":"m"}, {"literal":"1"}], "postprocess": (d) => d.join('')},
    {"name": "MATH_EXPM1", "symbols": ["MATH_EXPM1$string$1", "OPEN_PARENS", "ONE_ARGUEMENT", "CLOSE_PARENS"], "postprocess": function(d) {return d[2] }},
    {"name": "MATH_FLOOR$string$1", "symbols": [{"literal":"f"}, {"literal":"l"}, {"literal":"o"}, {"literal":"o"}, {"literal":"r"}], "postprocess": (d) => d.join('')},
    {"name": "MATH_FLOOR", "symbols": ["MATH_FLOOR$string$1", "OPEN_PARENS", "ONE_ARGUEMENT", "CLOSE_PARENS"], "postprocess": function(d) {return d[2] }},
    {"name": "MATH_FROUND$string$1", "symbols": [{"literal":"f"}, {"literal":"r"}, {"literal":"o"}, {"literal":"u"}, {"literal":"n"}, {"literal":"d"}], "postprocess": (d) => d.join('')},
    {"name": "MATH_FROUND", "symbols": ["MATH_FROUND$string$1", "OPEN_PARENS", "ONE_ARGUEMENT", "CLOSE_PARENS"], "postprocess": function(d) {return d[2] }},
    {"name": "MATH_HYPOT$string$1", "symbols": [{"literal":"h"}, {"literal":"y"}, {"literal":"p"}, {"literal":"o"}, {"literal":"t"}], "postprocess": (d) => d.join('')},
    {"name": "MATH_HYPOT", "symbols": ["MATH_HYPOT$string$1", "OPEN_PARENS", "APPLET_VAR", "CLOSE_PARENS"], "postprocess": function(d) {return d[2] }},
    {"name": "MATH_HYPOT$string$2", "symbols": [{"literal":"h"}, {"literal":"y"}, {"literal":"p"}, {"literal":"o"}, {"literal":"t"}], "postprocess": (d) => d.join('')},
    {"name": "MATH_HYPOT", "symbols": ["MATH_HYPOT$string$2", "OPEN_PARENS", "IDENTIFIER", "CLOSE_PARENS"], "postprocess": function(d) {return d[2] }},
    {"name": "MATH_IMUL$string$1", "symbols": [{"literal":"i"}, {"literal":"m"}, {"literal":"u"}, {"literal":"l"}], "postprocess": (d) => d.join('')},
    {"name": "MATH_IMUL", "symbols": ["MATH_IMUL$string$1", "OPEN_PARENS", "TWO_ARGUEMENTS", "CLOSE_PARENS"], "postprocess": function(d) {return d[2] }},
    {"name": "MATH_LOG$string$1", "symbols": [{"literal":"l"}, {"literal":"o"}, {"literal":"g"}], "postprocess": (d) => d.join('')},
    {"name": "MATH_LOG", "symbols": ["MATH_LOG$string$1", "OPEN_PARENS", "ONE_ARGUEMENT", "CLOSE_PARENS"], "postprocess": function(d) {return d[2] }},
    {"name": "MATH_LOG10$string$1", "symbols": [{"literal":"l"}, {"literal":"o"}, {"literal":"g"}, {"literal":"1"}, {"literal":"0"}], "postprocess": (d) => d.join('')},
    {"name": "MATH_LOG10", "symbols": ["MATH_LOG10$string$1", "OPEN_PARENS", "ONE_ARGUEMENT", "CLOSE_PARENS"], "postprocess": function(d) {return d[2] }},
    {"name": "MATH_LOG1P$string$1", "symbols": [{"literal":"l"}, {"literal":"o"}, {"literal":"g"}, {"literal":"1"}, {"literal":"p"}], "postprocess": (d) => d.join('')},
    {"name": "MATH_LOG1P", "symbols": ["MATH_LOG1P$string$1", "OPEN_PARENS", "ONE_ARGUEMENT", "CLOSE_PARENS"], "postprocess": function(d) {return d[2] }},
    {"name": "MATH_LOG2$string$1", "symbols": [{"literal":"l"}, {"literal":"o"}, {"literal":"g"}, {"literal":"2"}], "postprocess": (d) => d.join('')},
    {"name": "MATH_LOG2", "symbols": ["MATH_LOG2$string$1", "OPEN_PARENS", "ONE_ARGUEMENT", "CLOSE_PARENS"], "postprocess": function(d) {return d[2] }},
    {"name": "MATH_MAX$string$1", "symbols": [{"literal":"m"}, {"literal":"a"}, {"literal":"x"}], "postprocess": (d) => d.join('')},
    {"name": "MATH_MAX", "symbols": ["MATH_MAX$string$1", "OPEN_PARENS", "APPLET_VAR", "CLOSE_PARENS"], "postprocess": function(d) {return d[2] }},
    {"name": "MATH_MAX$string$2", "symbols": [{"literal":"m"}, {"literal":"a"}, {"literal":"x"}], "postprocess": (d) => d.join('')},
    {"name": "MATH_MAX", "symbols": ["MATH_MAX$string$2", "OPEN_PARENS", "IDENTIFIER", "CLOSE_PARENS"], "postprocess": function(d) {return d[2] }},
    {"name": "MATH_MIN$string$1", "symbols": [{"literal":"m"}, {"literal":"i"}, {"literal":"n"}], "postprocess": (d) => d.join('')},
    {"name": "MATH_MIN", "symbols": ["MATH_MIN$string$1", "OPEN_PARENS", "APPLET_VAR", "CLOSE_PARENS"], "postprocess": function(d) {return d[2] }},
    {"name": "MATH_MIN$string$2", "symbols": [{"literal":"m"}, {"literal":"i"}, {"literal":"n"}], "postprocess": (d) => d.join('')},
    {"name": "MATH_MIN", "symbols": ["MATH_MIN$string$2", "OPEN_PARENS", "IDENTIFIER", "CLOSE_PARENS"], "postprocess": function(d) {return d[2] }},
    {"name": "MATH_POW$string$1", "symbols": [{"literal":"p"}, {"literal":"o"}, {"literal":"w"}], "postprocess": (d) => d.join('')},
    {"name": "MATH_POW", "symbols": ["MATH_POW$string$1", "OPEN_PARENS", "TWO_ARGUEMENTS", "CLOSE_PARENS"], "postprocess": function(d) {return d[2] }},
    {"name": "MATH_RANDOM$string$1", "symbols": [{"literal":"r"}, {"literal":"a"}, {"literal":"n"}, {"literal":"d"}, {"literal":"o"}, {"literal":"m"}], "postprocess": (d) => d.join('')},
    {"name": "MATH_RANDOM", "symbols": ["MATH_RANDOM$string$1", "OPEN_PARENS", "CLOSE_PARENS"], "postprocess": function(d) {return null }},
    {"name": "MATH_ROUND$string$1", "symbols": [{"literal":"r"}, {"literal":"o"}, {"literal":"u"}, {"literal":"n"}, {"literal":"d"}], "postprocess": (d) => d.join('')},
    {"name": "MATH_ROUND", "symbols": ["MATH_ROUND$string$1", "OPEN_PARENS", "ONE_ARGUEMENT", "CLOSE_PARENS"], "postprocess": function(d) {return d[2] }},
    {"name": "MATH_SIGN$string$1", "symbols": [{"literal":"s"}, {"literal":"i"}, {"literal":"g"}, {"literal":"n"}], "postprocess": (d) => d.join('')},
    {"name": "MATH_SIGN", "symbols": ["MATH_SIGN$string$1", "OPEN_PARENS", "ONE_ARGUEMENT", "CLOSE_PARENS"], "postprocess": function(d) {return d[2] }},
    {"name": "MATH_SIN$string$1", "symbols": [{"literal":"s"}, {"literal":"i"}, {"literal":"n"}], "postprocess": (d) => d.join('')},
    {"name": "MATH_SIN", "symbols": ["MATH_SIN$string$1", "OPEN_PARENS", "ONE_ARGUEMENT", "CLOSE_PARENS"], "postprocess": function(d) {return d[2] }},
    {"name": "MATH_SINH$string$1", "symbols": [{"literal":"s"}, {"literal":"i"}, {"literal":"n"}, {"literal":"h"}], "postprocess": (d) => d.join('')},
    {"name": "MATH_SINH", "symbols": ["MATH_SINH$string$1", "OPEN_PARENS", "ONE_ARGUEMENT", "CLOSE_PARENS"], "postprocess": function(d) {return d[2] }},
    {"name": "MATH_SQRT$string$1", "symbols": [{"literal":"s"}, {"literal":"q"}, {"literal":"r"}, {"literal":"t"}], "postprocess": (d) => d.join('')},
    {"name": "MATH_SQRT", "symbols": ["MATH_SQRT$string$1", "OPEN_PARENS", "ONE_ARGUEMENT", "CLOSE_PARENS"], "postprocess": function(d) {return d[2] }},
    {"name": "MATH_TAN$string$1", "symbols": [{"literal":"t"}, {"literal":"a"}, {"literal":"n"}], "postprocess": (d) => d.join('')},
    {"name": "MATH_TAN", "symbols": ["MATH_TAN$string$1", "OPEN_PARENS", "ONE_ARGUEMENT", "CLOSE_PARENS"], "postprocess": function(d) {return d[2] }},
    {"name": "MATH_TANH$string$1", "symbols": [{"literal":"t"}, {"literal":"a"}, {"literal":"n"}, {"literal":"h"}], "postprocess": (d) => d.join('')},
    {"name": "MATH_TANH", "symbols": ["MATH_TANH$string$1", "OPEN_PARENS", "ONE_ARGUEMENT", "CLOSE_PARENS"], "postprocess": function(d) {return d[2] }},
    {"name": "MATH_TRUNC$string$1", "symbols": [{"literal":"t"}, {"literal":"r"}, {"literal":"u"}, {"literal":"n"}, {"literal":"c"}], "postprocess": (d) => d.join('')},
    {"name": "MATH_TRUNC", "symbols": ["MATH_TRUNC$string$1", "OPEN_PARENS", "ONE_ARGUEMENT", "CLOSE_PARENS"], "postprocess": function(d) {return d[2] }},
    {"name": "TERM", "symbols": [{"literal":";"}], "postprocess": function(d) {return null }},
    {"name": "IF$string$1", "symbols": [{"literal":"i"}, {"literal":"f"}], "postprocess": (d) => d.join('')},
    {"name": "IF", "symbols": ["IF$string$1"], "postprocess": function(d) {return null }},
    {"name": "ELSE$string$1", "symbols": [{"literal":"e"}, {"literal":"l"}, {"literal":"s"}, {"literal":"e"}], "postprocess": (d) => d.join('')},
    {"name": "ELSE", "symbols": ["ELSE$string$1"], "postprocess": function(d) {return null }},
    {"name": "OR$string$1", "symbols": [{"literal":"|"}, {"literal":"|"}], "postprocess": (d) => d.join('')},
    {"name": "OR", "symbols": ["OR$string$1"], "postprocess": function(d) {return null }},
    {"name": "AND$string$1", "symbols": [{"literal":"&"}, {"literal":"&"}], "postprocess": (d) => d.join('')},
    {"name": "AND", "symbols": ["AND$string$1"], "postprocess": function(d) {return null }},
    {"name": "GREATER_EQ$string$1", "symbols": [{"literal":">"}, {"literal":"="}, {"literal":"="}], "postprocess": (d) => d.join('')},
    {"name": "GREATER_EQ", "symbols": ["GREATER_EQ$string$1"], "postprocess": function(d) {return null }},
    {"name": "GREATER", "symbols": [{"literal":">"}], "postprocess": function(d) {return null }},
    {"name": "LESS_EQ$string$1", "symbols": [{"literal":"<"}, {"literal":"="}, {"literal":"="}], "postprocess": (d) => d.join('')},
    {"name": "LESS_EQ", "symbols": ["LESS_EQ$string$1"], "postprocess": function(d) {return null }},
    {"name": "LESS", "symbols": [{"literal":"<"}], "postprocess": function(d) {return null }},
    {"name": "EQ$string$1", "symbols": [{"literal":"="}, {"literal":"="}, {"literal":"="}], "postprocess": (d) => d.join('')},
    {"name": "EQ", "symbols": ["EQ$string$1"], "postprocess": function(d) {return null }},
    {"name": "NOT_EQ$string$1", "symbols": [{"literal":"!"}, {"literal":"="}, {"literal":"="}], "postprocess": (d) => d.join('')},
    {"name": "NOT_EQ", "symbols": ["NOT_EQ$string$1"], "postprocess": function(d) {return null }},
    {"name": "MUL", "symbols": [{"literal":"*"}], "postprocess": function(d) {return null }},
    {"name": "DIV", "symbols": [{"literal":"/"}], "postprocess": function(d) {return null }},
    {"name": "MINUS", "symbols": [{"literal":"-"}], "postprocess": function(d) {return null }},
    {"name": "PLUS", "symbols": [{"literal":"+"}], "postprocess": function(d) {return null }},
    {"name": "MOD", "symbols": [{"literal":"%"}], "postprocess": function(d) {return null }},
    {"name": "ASSIGNMENT", "symbols": [{"literal":"="}], "postprocess": function(d) {return null }},
    {"name": "OPEN_PARENS", "symbols": [{"literal":"("}], "postprocess": function(d) {return null }},
    {"name": "CLOSE_PARENS", "symbols": [{"literal":")"}], "postprocess": function(d) {return null }},
    {"name": "OPEN_BRACKET", "symbols": [{"literal":"{"}], "postprocess": function(d) {return null }},
    {"name": "CLOSE_BRACKET", "symbols": [{"literal":"}"}], "postprocess": function(d) {return null }},
    {"name": "JSON_KEYWORD$string$1", "symbols": [{"literal":"J"}, {"literal":"S"}, {"literal":"O"}, {"literal":"N"}], "postprocess": (d) => d.join('')},
    {"name": "JSON_KEYWORD", "symbols": ["JSON_KEYWORD$string$1"], "postprocess": function(d) {return null }},
    {"name": "DATE_KEYWORD$string$1", "symbols": [{"literal":"D"}, {"literal":"a"}, {"literal":"t"}, {"literal":"e"}], "postprocess": (d) => d.join('')},
    {"name": "DATE_KEYWORD", "symbols": ["DATE_KEYWORD$string$1"], "postprocess": function(d) {return null }},
    {"name": "MATH_KEYWORD$string$1", "symbols": [{"literal":"M"}, {"literal":"a"}, {"literal":"t"}, {"literal":"h"}], "postprocess": (d) => d.join('')},
    {"name": "MATH_KEYWORD", "symbols": ["MATH_KEYWORD$string$1"], "postprocess": function(d) {return null }},
    {"name": "CHAIN", "symbols": [{"literal":"."}], "postprocess": function(d) {return null }},
    {"name": "DECLARATION$string$1", "symbols": [{"literal":"c"}, {"literal":"o"}, {"literal":"n"}, {"literal":"s"}, {"literal":"t"}], "postprocess": (d) => d.join('')},
    {"name": "DECLARATION", "symbols": ["DECLARATION$string$1"]},
    {"name": "DECLARATION$string$2", "symbols": [{"literal":"l"}, {"literal":"e"}, {"literal":"t"}], "postprocess": (d) => d.join('')},
    {"name": "DECLARATION", "symbols": ["DECLARATION$string$2"], "postprocess": function(d) {return null }},
    {"name": "APPLET_VAR$string$1", "symbols": [{"literal":"a"}, {"literal":"p"}, {"literal":"p"}, {"literal":"l"}, {"literal":"e"}, {"literal":"t"}], "postprocess": (d) => d.join('')},
    {"name": "APPLET_VAR$string$2", "symbols": [{"literal":"v"}, {"literal":"a"}, {"literal":"r"}, {"literal":"s"}], "postprocess": (d) => d.join('')},
    {"name": "APPLET_VAR$ebnf$1", "symbols": [/[A-Za-z0-9_]/]},
    {"name": "APPLET_VAR$ebnf$1", "symbols": ["APPLET_VAR$ebnf$1", /[A-Za-z0-9_]/], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "APPLET_VAR", "symbols": ["APPLET_VAR$string$1", "CHAIN", "APPLET_VAR$string$2", "CHAIN", "APPLET_VAR$ebnf$1"], "postprocess": function(d) { return { type: 'appletVar', value: d[4].join(""), } }},
    {"name": "IDENTIFIER$ebnf$1", "symbols": [/[A-Za-z0-9_]/]},
    {"name": "IDENTIFIER$ebnf$1", "symbols": ["IDENTIFIER$ebnf$1", /[A-Za-z0-9_]/], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "IDENTIFIER", "symbols": ["IDENTIFIER$ebnf$1"], "postprocess": function(d) {return null }},
    {"name": "STRING", "symbols": [{"literal":"'"}, "WORD", {"literal":"'"}], "postprocess": function(d) {return null }},
    {"name": "NEW_STRING$string$1", "symbols": [{"literal":"S"}, {"literal":"t"}, {"literal":"r"}, {"literal":"i"}, {"literal":"n"}, {"literal":"g"}], "postprocess": (d) => d.join('')},
    {"name": "NEW_STRING", "symbols": ["NEW_STRING$string$1", "OPEN_PARENS", "EXPRESSION", "CLOSE_PARENS"], "postprocess": function(d) {return d[1] }},
    {"name": "WORD$ebnf$1", "symbols": [/[^']/]},
    {"name": "WORD$ebnf$1", "symbols": ["WORD$ebnf$1", /[^']/], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "WORD", "symbols": ["WORD$ebnf$1"], "postprocess": function(d) {return null }}
  ],
  ParserStart: "PROGRAM",
};

export default grammar;
