
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Route, RouteRecord, RouteRecordPublic } from 'vue-router';

export type TransitionType = 'horizontal' | 'vertical';

// TODO: document how to specify transition types
@Component({})
export class RouterViewSlider extends Vue {
    transitionName = 'enter-top';
    /**
     * The transition will not work unless the keys of the items being transitioned have different keys.
     * This value should flipflop between 1 and 0
     */
    routerViewKey = 1;

    /** the route that this component is mounted for */
    get rvsRoute (): RouteRecord | undefined {
        return this.$route.matched.find(r => {
            return (this.rvsRouteName && r.name === this.rvsRouteName) || r.instances.default === this;
        });
    }

    @Prop()
    transitionType?: TransitionType;

    @Prop()
    rvsRouteName?: string;

    get childRoutes (): RouteRecordPublic[] {
        return this.$router.getRoutes().filter(r => r.parent && r.parent === this.rvsRoute);
    }

    mounted (): void {
        /**
         * router navigation event handler.
         * the RouterViewSlider is supposed to determine the correct transition to use, if any.
         *
         * We need to get a key for the router-view so Vue knows when to re-render / perform a transition.
         * We can use the position of descendent child route index to unique id the router-view.
         */
        const onRouterNavigation = (to: Route, from: Route) => {
            const rvsRoute = this.rvsRoute;
            if (!rvsRoute) {
                return;
            }

            // determine the transition type to use, first by a prop, second by the route config
            const transitionType: TransitionType = this.transitionType || rvsRoute.meta?.transition || '';

            // set to "unknown" values, initially, change them if we figure out their real value.
            this.transitionName = '';

            const toChildRouteIdx = this.childRoutes.findIndex(r => to.matched.includes(r));
            const fromChildRouteIdx = this.childRoutes.findIndex(r => from.matched.includes(r));

            if (toChildRouteIdx >= 0 && fromChildRouteIdx >= 0 && toChildRouteIdx !== fromChildRouteIdx) {
                // at this point, we have confirmed that we are navigating between child routes of this RVS, so we can change the key
                this.routerViewKey = Number(!this.routerViewKey);

                /**
                 * the direction of the transition.
                 * if the position of the viewed route is increasing, then this is true, and the view should enter from the right or the bottom.
                 */
                const increasing = toChildRouteIdx > fromChildRouteIdx;

                switch (transitionType) {
                    case 'horizontal':
                        this.transitionName = increasing ? 'enter-right' : 'enter-left';
                        break;
                    case 'vertical':
                        this.transitionName = increasing ? 'enter-bottom' : 'enter-top';
                        break;
                    default:
                        break;
                }
            }

        };

        const teardown = this.$router.afterEach(onRouterNavigation);

        this.$on('hook:unmounted', () => {
            teardown();
        });
    }
}
export default RouterViewSlider;
