export type GetRefreshTokenResult = {
    userId: string
    contextId: string
    tokens: Token[]
};

export type Token = {
    id: string
    endpoint: string
    token: string
    expiresInSeconds: number
};

export enum AuthStatus {
    Pending = 'pending',
    Success = 'success',
    False = 'false',
}
