export const messages = {
    desc: 'Send a variable value as a UDP message',
    errors: {
        _other: 'Config is invalid',
        condition: 'Send condition is invalid',
        host: 'Host is invalid',
        port: 'Port is invalid',
    },
    payload: {
        host: 'Host',
        message: {
            label: 'Message',
        },
        port: 'Port',
        sendTsVar: {
            hint: 'Variable will be set to the current timestamp on success, or an error message on failure',
            label: 'Store the result of the send as a variable (optional)',
        },
        title: 'Payload',
    },
    sendBehavior: {
        condition: {
            prefix: 'Only send the payload when',
            suffix: 'the following comparisons are satisfied:',
        },
        title: 'Send Behavior',
    },
    title: 'Send UDP',
};
