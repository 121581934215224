import { ArgoDevice, DeviceFragment } from '@redviking/argonaut-core-ui/types/db';
import { mutationTree } from 'typed-vuex';

export type DeviceStoreState = {
    device: DeviceFragment | null;
};

const deviceStoreState: DeviceStoreState = {
    device: null,
};

export const DeviceStoreOpts = {
    state: deviceStoreState,
    mutations: mutationTree(deviceStoreState, {
        setPageDevice (state, device: ArgoDevice) {
            if (device.__typename !== 'argo_device') {
                console.warn('Unknown Type passed into mutation');
            }
            state.device = { ...state.device, ...device };
        },
        clearDevice (state: DeviceStoreState): void {
            state.device = null;
        },
    }),
};
export default DeviceStoreOpts;
