
import { accessor } from '../store';
import { messages } from 'src/i18n/i18n';
import { RouterViewSlider } from '../components/RouterViewSlider';
import { RouteEntityDetail, legacyEntityDetailRoute } from '@redviking/argonaut-core-ui/src/components/EntityDetail';
import {
    validateFileSelectAcl,
    validateFileUpdateAcl,
} from './file.validations';
import { RouteConfig } from 'vue-router';

export const routes: RouteConfig[] = [
    {
        path: '/files',
        name: 'files',
        component: RouterViewSlider,
        redirect: { name: 'file-list' },
        meta: {
            navigationGuard: () => validateFileUpdateAcl(),
            navbarTitle: messages.file.titles.file.nav,
            navbarIcon: 'mdi-file-image',
            transition: 'vertical',
        },
        children: [
            {
                path: 'list',
                name: 'file-list',
                redirect: to => ({ name: 'fileList', params: to.params }),
                component: RouterViewSlider,
                meta: {
                    transition: 'horizontal',
                },
                children: [
                    {
                        name: 'fileList',
                        path: '',
                        component: () => import(/* webpackChunkName: "file" */ './FileView.vue'),
                        meta: {
                            navigationGuard: () => validateFileSelectAcl(),
                            pageBannerSubtitle: messages.file.titles.file.pageBannerSubtitle,
                            pageBannerTitle: messages.file.titles.file.pageBannerTitle,
                        },
                    },
                    legacyEntityDetailRoute({
                        path: ':fileId',
                        name: 'fileMaintenance',
                        component: RouteEntityDetail,
                        redirect: to => ({ name: 'fileSettings', params: to.params }),
                        meta: {
                            canEdit: validateFileUpdateAcl,
                            pageBannerTitle () {
                                const { file } = accessor;
                                return file && file.name ? file.name : messages.file.titles.file.noName;
                            },
                        },
                        children: [
                            {
                                path: 'settings',
                                name: 'fileSettings',
                                component: () => import(/* webpackChunkName: "file" */ './FileSettings.view.vue'), // a component for the settings page content
                                meta: {
                                    tab: { // the presence of this prop indicates this is a tab
                                        label: 'Settings',
                                        icon: 'mdi-cog',
                                    },
                                },
                            },
                        ],
                    }),
                ],
            },
        ],
    },
];

export default routes;
