import { userIsAdmin, validateAcls } from '@redviking/argonaut-core-ui/src/auth';
import { Notify } from '@redviking/argonaut-core-ui/src/notifications';
import { EntityTypeMap } from '@redviking/argonaut-core-ui/types';

export function validateRoleInsertAcl (): boolean {
    return validateAcls([ 'role_insert' ]);
}

export function validateRoleSelectAcl (): boolean {
    return validateAcls([ 'role_select' ]);
}

export function validateRoleUpdateAcl (): boolean {
    return validateAcls([ 'role_update' ]);
}

export function validateRoleDeleteAcl (): boolean {
    return validateAcls([ 'role_delete' ]);
}

export function validateRoleUserSelectAcl (): boolean {
    return validateAcls([ 'user_role_select' ]);
}

export function validateRole (role: Partial<EntityTypeMap['role']>) {
    let message: string;
    if (role.context_id === null && !userIsAdmin()) {
        message = 'Cannot edit a core Argonaut Role';
        Notify.error(message);
        throw new Error(message);
    }
    if (!role.name || (role.name && role.name.trim().length < 3)) {
        message = 'Role name must be longer than 3 characters';
        Notify.error(message);
        throw new Error(message);
    }
}
