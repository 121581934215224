export enum Encodings {
    numerics = '0123456789',
    alphabetics = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
    safeAlphabetics = 'ABCDEFGHJKMNPQRTUVWXYZ',
    alphanumerics = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ',
    safeAlphanumerics = '0123456789ABCDEFGHJKMNPQRTUVWXYZ',
}

export type SnfToken = {
    type: 'fixed' | 'seq' | 'input' | 'datetime';
    name: string;
    /**
     * @default false
     * whether or not the token appears in the format.
     * useful for grouping with sequences
     */
    hidden?: boolean; // This is preferable to `len: 0` because the date token does not have configurable length but can still be hidden
};

export interface SnfTokenFixed extends SnfToken {
    type: 'fixed';
    content: string;
    /** not configurable */
    hidden?: false;
}

export interface SnfTokenSeq extends SnfToken {
    type: 'seq';
    /** @default 1 */
    start?: number;
    end?: number;
    /** @default 1 */
    inc?: number;
    /**
     * whether the sequence should wrap
     * @default false
     */
    wrap?: boolean;
    /** @default '0123456789' */
    chars: string;
    /** not configurable */
    hidden?: false;
    len: number;
}

export interface SnfTokenInput extends SnfToken {
    type: 'input';
    /**
     * regex, with implied ^ and $
     */
    validation?: string;
    len: number;
    /**
     * @default '0'
     * single character
     */
    fill?: string;
    /**
     * @default 'right'
     */
    align?: 'left' | 'right';
}

export interface SnfTokenDatetime extends SnfToken {
    type: 'datetime';
    format: DatetimeFormat
    /** @default `Encodings.numerics` */
    encoding?: Encodings;
}

export enum DatetimeFormat {
    /** 01-12 */
    Month = 'm',
    /** 0-4 */
    Quarter = 'q',
    /** 01-31 */
    MonthDay = 'md',
    /**
     * 001-366 (leap year has an extra day)
     * normalized so March 1st is always 060
     */
    YearDay = 'yd',
    /**
     * 001-366 (leap year has an extra day)
     */
    Julian = 'j',
    /** 1-7 (starts on sunday) */
    WeekDay = 'wd',
    /** 1-7 (starts on monday) */
    WeekDayMonday = 'wdm',
    /** full 4 digit year */
    Year4 = 'yyyy',
    /** double digit year */
    Year2 = 'yy',
    /** single digit year */
    Year = 'y',

    /** 00-23 */
    Hour = 'h',
    /** 00-59 */
    Minute = 'n',
    /** 00-59 */
    Second = 's',
}

export interface SnfTokenMap {
    fixed: SnfTokenFixed;
    seq: SnfTokenSeq;
    input: SnfTokenInput;
    datetime: SnfTokenDatetime;
}

export function assertTokenType<T extends keyof SnfTokenMap> (token: SnfToken, ...types: T[]): token is SnfTokenMap[T] {
    return (types as string[]).includes(token.type);
}

export type TokenSwitchFns<RETURN = void> = {
    [ type in keyof SnfTokenMap ]: (token: SnfTokenMap[type]) => RETURN;
};

export function mapTokenByType<RETURN = void> (token: SnfToken, cases: TokenSwitchFns<RETURN>): RETURN {
    const switchCase = cases[token.type] as ((t: SnfToken) => RETURN) | undefined;
    if (!switchCase) {
        throw new Error(`Unknown token type: ${token.type}`);
    }
    return switchCase(token);
}
