// Generated automatically by nearley, version 2.20.1
// http://github.com/Hardmath123/nearley
// Bypasses TS6133. Allow declared but unused functions.
// @ts-ignore
function id(d: any[]): any { return d[0]; }

interface NearleyToken {
  value: any;
  [key: string]: any;
};

interface NearleyLexer {
  reset: (chunk: string, info: any) => void;
  next: () => NearleyToken | undefined;
  save: () => any;
  formatError: (token: never) => string;
  has: (tokenType: string) => boolean;
};

interface NearleyRule {
  name: string;
  symbols: NearleySymbol[];
  postprocess?: (d: any[], loc?: number, reject?: {}) => any;
};

type NearleySymbol = string | { literal: any } | { test: (token: any) => boolean };

interface Grammar {
  Lexer: NearleyLexer | undefined;
  ParserRules: NearleyRule[];
  ParserStart: string;
};

const grammar: Grammar = {
  Lexer: undefined,
  ParserRules: [
    {"name": "MetricExpr", "symbols": ["Name"], "postprocess": ([ name ]) => ({ name })},
    {"name": "MetricExpr", "symbols": ["Name", {"literal":"."}, "MetricExtra"], "postprocess": ([ name, _, key ]) => ({ name, extra: 'metric', key })},
    {"name": "MetricExpr$string$1", "symbols": [{"literal":"."}, {"literal":"m"}, {"literal":"e"}, {"literal":"t"}, {"literal":"a"}, {"literal":"d"}, {"literal":"a"}, {"literal":"t"}, {"literal":"a"}, {"literal":"."}], "postprocess": (d) => d.join('')},
    {"name": "MetricExpr", "symbols": ["Name", "MetricExpr$string$1", "MetricMetadataExtra"], "postprocess": ([ name, _, key ]) => ({ name, extra: 'meta', key })},
    {"name": "MetricExpr$string$2", "symbols": [{"literal":"."}, {"literal":"p"}, {"literal":"r"}, {"literal":"o"}, {"literal":"p"}, {"literal":"e"}, {"literal":"r"}, {"literal":"t"}, {"literal":"i"}, {"literal":"e"}, {"literal":"s"}, {"literal":"."}], "postprocess": (d) => d.join('')},
    {"name": "MetricExpr", "symbols": ["Name", "MetricExpr$string$2", "PropExtraExpr"], "postprocess": ([ name, _, propExtraExpr ]) => ({ name, ...propExtraExpr })},
    {"name": "MetricExpr$string$3", "symbols": [{"literal":"."}, {"literal":"d"}, {"literal":"a"}, {"literal":"t"}, {"literal":"a"}, {"literal":"s"}, {"literal":"e"}, {"literal":"t"}], "postprocess": (d) => d.join('')},
    {"name": "MetricExpr", "symbols": ["Name", "MetricExpr$string$3", "DatasetExtraExpr"], "postprocess": ([ name, _, datasetExtraExpr ]) => ({ name, extra: 'dataset', ...datasetExtraExpr })},
    {"name": "MetricExpr$string$4", "symbols": [{"literal":"."}, {"literal":"t"}, {"literal":"e"}, {"literal":"m"}, {"literal":"p"}, {"literal":"l"}, {"literal":"a"}, {"literal":"t"}, {"literal":"e"}, {"literal":"."}, {"literal":"m"}, {"literal":"e"}, {"literal":"t"}, {"literal":"r"}, {"literal":"i"}, {"literal":"c"}, {"literal":"."}], "postprocess": (d) => d.join('')},
    {"name": "MetricExpr", "symbols": ["Name", "MetricExpr$string$4", "MetricExpr"], "postprocess": ([ name, _, metricExtra ]) => ({ name, extra: 'template', templateExtra: 'metric', metricExtra })},
    {"name": "Name$ebnf$1", "symbols": [/[a-zA-Z0-9_/]/]},
    {"name": "Name$ebnf$1", "symbols": ["Name$ebnf$1", /[a-zA-Z0-9_/]/], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "Name", "symbols": ["Name$ebnf$1"], "postprocess": ([ chars ]) => chars.join('')},
    {"name": "Name$ebnf$2", "symbols": [/[a-zA-Z0-9_/ ]/]},
    {"name": "Name$ebnf$2", "symbols": ["Name$ebnf$2", /[a-zA-Z0-9_/ ]/], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "Name", "symbols": [{"literal":"\""}, "Name$ebnf$2", {"literal":"\""}], "postprocess": ([ q1, chars, q2 ]) => chars.join('')},
    {"name": "MetricExtra$subexpression$1$string$1", "symbols": [{"literal":"a"}, {"literal":"l"}, {"literal":"i"}, {"literal":"a"}, {"literal":"s"}], "postprocess": (d) => d.join('')},
    {"name": "MetricExtra$subexpression$1", "symbols": ["MetricExtra$subexpression$1$string$1"]},
    {"name": "MetricExtra$subexpression$1$string$2", "symbols": [{"literal":"t"}, {"literal":"i"}, {"literal":"m"}, {"literal":"e"}, {"literal":"s"}, {"literal":"t"}, {"literal":"a"}, {"literal":"m"}, {"literal":"p"}], "postprocess": (d) => d.join('')},
    {"name": "MetricExtra$subexpression$1", "symbols": ["MetricExtra$subexpression$1$string$2"]},
    {"name": "MetricExtra$subexpression$1$string$3", "symbols": [{"literal":"d"}, {"literal":"a"}, {"literal":"t"}, {"literal":"a"}, {"literal":"t"}, {"literal":"y"}, {"literal":"p"}, {"literal":"e"}], "postprocess": (d) => d.join('')},
    {"name": "MetricExtra$subexpression$1", "symbols": ["MetricExtra$subexpression$1$string$3"]},
    {"name": "MetricExtra$subexpression$1$string$4", "symbols": [{"literal":"i"}, {"literal":"s"}, {"literal":"H"}, {"literal":"i"}, {"literal":"s"}, {"literal":"t"}, {"literal":"o"}, {"literal":"r"}, {"literal":"i"}, {"literal":"c"}, {"literal":"a"}, {"literal":"l"}], "postprocess": (d) => d.join('')},
    {"name": "MetricExtra$subexpression$1", "symbols": ["MetricExtra$subexpression$1$string$4"]},
    {"name": "MetricExtra$subexpression$1$string$5", "symbols": [{"literal":"i"}, {"literal":"s"}, {"literal":"T"}, {"literal":"r"}, {"literal":"a"}, {"literal":"n"}, {"literal":"s"}, {"literal":"i"}, {"literal":"e"}, {"literal":"n"}, {"literal":"t"}], "postprocess": (d) => d.join('')},
    {"name": "MetricExtra$subexpression$1", "symbols": ["MetricExtra$subexpression$1$string$5"]},
    {"name": "MetricExtra", "symbols": ["MetricExtra$subexpression$1"], "postprocess": ([ [ x ] ]) => x},
    {"name": "MetricMetadataExtra$subexpression$1$string$1", "symbols": [{"literal":"i"}, {"literal":"s"}, {"literal":"M"}, {"literal":"u"}, {"literal":"l"}, {"literal":"t"}, {"literal":"i"}, {"literal":"P"}, {"literal":"a"}, {"literal":"r"}, {"literal":"t"}], "postprocess": (d) => d.join('')},
    {"name": "MetricMetadataExtra$subexpression$1", "symbols": ["MetricMetadataExtra$subexpression$1$string$1"]},
    {"name": "MetricMetadataExtra$subexpression$1$string$2", "symbols": [{"literal":"c"}, {"literal":"o"}, {"literal":"n"}, {"literal":"t"}, {"literal":"e"}, {"literal":"n"}, {"literal":"t"}, {"literal":"T"}, {"literal":"y"}, {"literal":"p"}, {"literal":"e"}], "postprocess": (d) => d.join('')},
    {"name": "MetricMetadataExtra$subexpression$1", "symbols": ["MetricMetadataExtra$subexpression$1$string$2"]},
    {"name": "MetricMetadataExtra$subexpression$1$string$3", "symbols": [{"literal":"s"}, {"literal":"i"}, {"literal":"z"}, {"literal":"e"}], "postprocess": (d) => d.join('')},
    {"name": "MetricMetadataExtra$subexpression$1", "symbols": ["MetricMetadataExtra$subexpression$1$string$3"]},
    {"name": "MetricMetadataExtra$subexpression$1$string$4", "symbols": [{"literal":"s"}, {"literal":"e"}, {"literal":"q"}], "postprocess": (d) => d.join('')},
    {"name": "MetricMetadataExtra$subexpression$1", "symbols": ["MetricMetadataExtra$subexpression$1$string$4"]},
    {"name": "MetricMetadataExtra$subexpression$1$string$5", "symbols": [{"literal":"f"}, {"literal":"i"}, {"literal":"l"}, {"literal":"e"}, {"literal":"N"}, {"literal":"a"}, {"literal":"m"}, {"literal":"e"}], "postprocess": (d) => d.join('')},
    {"name": "MetricMetadataExtra$subexpression$1", "symbols": ["MetricMetadataExtra$subexpression$1$string$5"]},
    {"name": "MetricMetadataExtra$subexpression$1$string$6", "symbols": [{"literal":"f"}, {"literal":"i"}, {"literal":"l"}, {"literal":"e"}, {"literal":"T"}, {"literal":"y"}, {"literal":"p"}, {"literal":"e"}], "postprocess": (d) => d.join('')},
    {"name": "MetricMetadataExtra$subexpression$1", "symbols": ["MetricMetadataExtra$subexpression$1$string$6"]},
    {"name": "MetricMetadataExtra$subexpression$1$string$7", "symbols": [{"literal":"m"}, {"literal":"d"}, {"literal":"5"}], "postprocess": (d) => d.join('')},
    {"name": "MetricMetadataExtra$subexpression$1", "symbols": ["MetricMetadataExtra$subexpression$1$string$7"]},
    {"name": "MetricMetadataExtra$subexpression$1$string$8", "symbols": [{"literal":"d"}, {"literal":"e"}, {"literal":"s"}, {"literal":"c"}, {"literal":"r"}, {"literal":"i"}, {"literal":"p"}, {"literal":"t"}, {"literal":"i"}, {"literal":"o"}, {"literal":"n"}], "postprocess": (d) => d.join('')},
    {"name": "MetricMetadataExtra$subexpression$1", "symbols": ["MetricMetadataExtra$subexpression$1$string$8"]},
    {"name": "MetricMetadataExtra", "symbols": ["MetricMetadataExtra$subexpression$1"], "postprocess": ([ [ x ] ]) => x},
    {"name": "PropExtraExpr", "symbols": ["Name"], "postprocess": ([ propName ]) => ({ extra: 'propset', propName, propExtra: null })},
    {"name": "PropExtraExpr$string$1", "symbols": [{"literal":"."}, {"literal":"t"}, {"literal":"y"}, {"literal":"p"}, {"literal":"e"}], "postprocess": (d) => d.join('')},
    {"name": "PropExtraExpr", "symbols": ["Name", "PropExtraExpr$string$1"], "postprocess": ([ propName ]) => ({ extra: 'propset', propName, propExtra: 'type' })},
    {"name": "PropExtraExpr$string$2", "symbols": [{"literal":"."}, {"literal":"i"}, {"literal":"s"}, {"literal":"N"}, {"literal":"u"}, {"literal":"l"}, {"literal":"l"}], "postprocess": (d) => d.join('')},
    {"name": "PropExtraExpr", "symbols": ["Name", "PropExtraExpr$string$2"], "postprocess": ([ propName ]) => ({ extra: 'propset', propName, propExtra: 'isNull' })},
    {"name": "PropExtraExpr$string$3", "symbols": [{"literal":"."}, {"literal":"p"}, {"literal":"r"}, {"literal":"o"}, {"literal":"p"}, {"literal":"e"}, {"literal":"r"}, {"literal":"t"}, {"literal":"i"}, {"literal":"e"}, {"literal":"s"}, {"literal":"."}], "postprocess": (d) => d.join('')},
    {"name": "PropExtraExpr", "symbols": ["Name", "PropExtraExpr$string$3", "PropExtraExpr"], "postprocess": ([ propName, _, propExtra ]) => ({ extra: 'propset', propName, propExtra })},
    {"name": "PropExtraExpr$ebnf$1", "symbols": [/[0-9]/]},
    {"name": "PropExtraExpr$ebnf$1", "symbols": ["PropExtraExpr$ebnf$1", /[0-9]/], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "PropExtraExpr$string$4", "symbols": [{"literal":"]"}, {"literal":"."}], "postprocess": (d) => d.join('')},
    {"name": "PropExtraExpr", "symbols": ["Name", {"literal":"["}, "PropExtraExpr$ebnf$1", "PropExtraExpr$string$4", "PropExtraExpr"], "postprocess": 
        ([ propName, _1, idx, _2, propSetExtra ]) => ({
        	extra: 'propset',
        	propName,
        	propExtra: {
        		extra: 'propsetlist',
        		idx: Number(idx.join('')),
        		propExtra: propSetExtra
        	}
        })
        },
    {"name": "DatasetExtraExpr$ebnf$1", "symbols": [/[0-9]/]},
    {"name": "DatasetExtraExpr$ebnf$1", "symbols": ["DatasetExtraExpr$ebnf$1", /[0-9]/], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "DatasetExtraExpr$string$1", "symbols": [{"literal":"]"}, {"literal":"."}, {"literal":"c"}, {"literal":"o"}, {"literal":"l"}, {"literal":"u"}, {"literal":"m"}, {"literal":"n"}], "postprocess": (d) => d.join('')},
    {"name": "DatasetExtraExpr", "symbols": [{"literal":"["}, "DatasetExtraExpr$ebnf$1", "DatasetExtraExpr$string$1"], "postprocess": ([ _1, colIdx ]) => ({ datasetExtra: 'col', colIdx: Number(colIdx.join('')) })},
    {"name": "DatasetExtraExpr$ebnf$2", "symbols": [/[0-9]/]},
    {"name": "DatasetExtraExpr$ebnf$2", "symbols": ["DatasetExtraExpr$ebnf$2", /[0-9]/], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "DatasetExtraExpr$string$2", "symbols": [{"literal":"]"}, {"literal":"."}, {"literal":"t"}, {"literal":"y"}, {"literal":"p"}, {"literal":"e"}], "postprocess": (d) => d.join('')},
    {"name": "DatasetExtraExpr", "symbols": [{"literal":"["}, "DatasetExtraExpr$ebnf$2", "DatasetExtraExpr$string$2"], "postprocess": ([ _1, colIdx ]) => ({ datasetExtra: 'type', colIdx: Number(colIdx.join('')) })},
    {"name": "DatasetExtraExpr$ebnf$3", "symbols": [/[0-9]/]},
    {"name": "DatasetExtraExpr$ebnf$3", "symbols": ["DatasetExtraExpr$ebnf$3", /[0-9]/], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "DatasetExtraExpr$string$3", "symbols": [{"literal":"]"}, {"literal":"["}], "postprocess": (d) => d.join('')},
    {"name": "DatasetExtraExpr$ebnf$4", "symbols": [/[0-9]/]},
    {"name": "DatasetExtraExpr$ebnf$4", "symbols": ["DatasetExtraExpr$ebnf$4", /[0-9]/], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "DatasetExtraExpr", "symbols": [{"literal":"["}, "DatasetExtraExpr$ebnf$3", "DatasetExtraExpr$string$3", "DatasetExtraExpr$ebnf$4", {"literal":"]"}], "postprocess": ([ _1, colIdx, _2, rowIdx ]) => ({ datasetExtra: null, colIdx: Number(colIdx.join('')), rowIdx: Number(rowIdx.join('')) })}
  ],
  ParserStart: "MetricExpr",
};

export default grammar;
