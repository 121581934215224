import { cellCfgAttrsBaseSchema_v3, cellCfgBaseSchema_v3 } from './cell-base.v3.zod';

import z from 'zod';
import { varNameSchema } from '../../var.zod';

export const processMediaCellSchema_v3 = cellCfgBaseSchema_v3.extend({
    type: z.literal('processMedia'),
    attrs: cellCfgAttrsBaseSchema_v3.extend({
        specIdVar: varNameSchema,
        passVar: varNameSchema,
    }),
});
