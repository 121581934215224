import nearley from 'nearley';
import grammar from './grammar';

const parser = new nearley.Parser(nearley.Grammar.fromCompiled(grammar));
const initialState = parser.save();

export function getMetricExpr (metricExprStr: string): MetricExpr {
    parser.restore(initialState); // this reuses the parser instance and resets it
    parser.feed(metricExprStr);
    const result = parser.results[0];
    if (!result) {
        throw new Error('No result');
    }
    return result as MetricExpr;
}

export type BaseMetricExpr = {
    name: string;
};

interface MetricExtra extends BaseMetricExpr {
    extra: 'metric';
    key: 'alias' | 'timestamp' | 'datatype' | 'isHistorical' | 'isTransient';
}

interface MetricMetadataExtra extends BaseMetricExpr {
    extra: 'meta';
    key: 'isMultiPart' | 'contentType' | 'size' | 'seq' | 'fileName' | 'fileType' | 'md5' | 'description';
}

export interface PropSetExtra extends BaseMetricExpr {
    extra: 'propset';
    propName: string;
    propExtra: null | 'type' | 'isNull' | PropSetExtra | PropSetListExtra;
}

export interface PropSetListExtra {
    extra: 'propsetlist';
    idx: number;
    propName: string;
    propExtra: null | 'type' | 'isNull' | PropSetExtra | PropSetListExtra;
}

interface BaseDatasetExtra extends BaseMetricExpr {
    extra: 'dataset';
}

interface DatasetColExtra extends BaseDatasetExtra {
    datasetExtra: 'col';
    colIdx: number;
}

interface DatasetTypeExtra extends BaseDatasetExtra {
    datasetExtra: 'type';
    colIdx: number;
}

interface DatasetElementExtra extends BaseDatasetExtra {
    datasetExtra: null;
    colIdx: number;
    rowIdx: number;
}

type DatasetExtra = DatasetColExtra | DatasetTypeExtra | DatasetElementExtra;

interface BaseTemplateExtra extends BaseMetricExpr {
    extra: 'template';
    templateExtra: string;
}

interface TemplateMetricExtra extends BaseTemplateExtra {
    templateExtra: 'metric';
    metricExtra: MetricExpr;
}

// skipping TemplateParamExtra for now

export type MetricExpr = BaseMetricExpr | MetricExtra | MetricMetadataExtra | PropSetExtra | DatasetExtra | TemplateMetricExtra;
