import { validateAcls } from '@redviking/argonaut-core-ui/src/auth';

export function validateLinkSelectAcl (): boolean {
    // todo: change to check acl when it is created
    return validateAcls([ 'link_manage_personal' ]);
}

export function validateLinkInsertAcl (): boolean {
    // todo: change to check acl when it is created
    return validateAcls([ 'link_manage_personal' ]);
}

export function validateLinkUpdateAcl (): boolean {
    // todo: change to check acl when it is created
    return validateAcls([ 'link_manage_personal' ]);
}

export function validateLinkDeleteAcl (): boolean {
    // todo: change to check acl when it is created
    return validateAcls([ 'link_manage_personal' ]);
}

export function validateLinkAdminAcl (): boolean {
    // todo: change to check acl when it is created
    return validateAcls([ 'link_manage_shared' ]);
}
