import type { Latest } from '../../types/mes/applet-designs/appletDesign.latest.zod';

export function gatherComparisonInputs (comparisons: Latest.VarComparison[] = []): Set<string> {
    const inputs = new Set<string>();
    for (const comparison of comparisons) {
        for (const operand of comparison.operands) {
            // if the operand is a var, and the input is new
            if ('varName' in operand && operand.varName) {
                inputs.add(operand.varName);
            }
        }
    }
    return inputs;
}
