import { z } from 'zod';
import { macroTypeEnum_v3 } from '../macros/base-macro.v3.zod';
import { varpConfigBase_v3 } from './var-provider.v3.zod';

export const macroVarpTargetSchema_v3 = varpConfigBase_v3.extend({
    type: z.literal('macroTarget'),
    attrs: z.object({
        macroType: macroTypeEnum_v3,
        macroId: z.string(),
    }),
});
