import { mutationTree } from 'typed-vuex';
import Vue from 'vue';
import { ArgoLocation } from '../../types/db';


const locationsState = {
    location: null as ArgoLocation | null,
};

const mutations = mutationTree(locationsState, {
    setPageLocation (state, location: ArgoLocation) {
        if (location.__typename !== 'argo_location') {
            console.warn('Unknown Type passed into mutation');
        }
        if (state.location) {
            Vue.set(state, 'location', { ...state.location, ...location });
        } else {
            Vue.set(state, 'location', location);
        }
    },
    clearLocation (state): void {
        state.location = null;
    },
});

export default {
    state: locationsState,
    mutations,
};
