export const messages = {
    configYaml: 'Config yaml',
    device: {
        addNew: 'Add New Device',
        confirmDelete: 'Are you sure you want to delete this device?',
        create: 'Create Device',
        entityName: 'Device',
        hide: 'Hide Device',
        inspect: 'Inspect Device',
        location: 'Device Location',
        name: 'Device Name',
        noneFound: 'No Devices Found',
        notifySaved: 'Device Saved!',
        saved: 'Device Saved',
        search: 'Search Devices by Name or Description!',
        type: 'Device Type',
        typeWarning: 'WARNING: Once a device type is set, it cannot be changed!',
    },
    labelPrinter: {
        created_at: 'Timestamp',
        created_by: 'Created By',
        device: 'Device',
        host: 'Host',
        label_template: 'Template',
        material: 'Material',
        message: 'Message',
        port: 'Port',
        printerType: 'Printer Type',
        status: 'Status',
    },
    titles: {

        nav: 'Devices',
        noName: 'Unnamed Device',
        pageBannerSubtitle: 'View Device',
        pageBannerTitle: 'Devices',
    },
};
