import {
    ComputedRef,
    computed,
} from 'vue';
import {
    VarProvider,
    VarProviderOpts,
} from './VarProvider';
import { getMergedStatus } from '../vars';
import type { AppletRuntime } from '../AppletRuntime';
import type { Latest } from '@redviking/argonaut-util/types/mes/applet-designs/appletDesign.latest.zod';

export enum TimeFormat {
    LongDateTime = 'l',
    ShortDateTime = 's',
    LongDate = 'ld',
    ShortDate = 'sd',
    Time = 't'
}
export enum DurationFormat {
    Seconds = 's',
    Minutes = 'm',
    Hours = 'h',
    StopWatch = 'sw',
}

type FormattedParserResultMap = {
    t: TimeFormat;
    td: DurationFormat;
};

type FormattedParserResult<T extends keyof FormattedParserResultMap> = [string, T, string, FormattedParserResultMap[T] | undefined];

export function formatTime (time: number, format: TimeFormat): string {
    let formattedTime = '';
    const date = new Date(time);

    switch (format) {
        case TimeFormat.LongDateTime:
            formattedTime = new Intl.DateTimeFormat('en', {
                day: 'numeric',
                hour: 'numeric',
                hourCycle: 'h12',
                minute: 'numeric',
                month: 'short',
                timeZoneName: 'short',
                weekday: 'short',
                year: 'numeric',
            }).format(date);
            break;
        case TimeFormat.ShortDateTime:
            formattedTime = new Intl.DateTimeFormat('en', {
                day: 'numeric',
                hour: 'numeric',
                hourCycle: 'h12',
                minute: 'numeric',
                month: 'numeric',
                year: '2-digit',
            }).format(date);
            break;
        case TimeFormat.LongDate:
            formattedTime = new Intl.DateTimeFormat('en', {
                day: 'numeric',
                month: 'long',
                year: 'numeric',
            }).format(date);
            break;
        case TimeFormat.ShortDate:
            formattedTime = new Intl.DateTimeFormat('en', {
                day: 'numeric',
                month: 'numeric',
                year: '2-digit',
            }).format(date);
            break;
        case TimeFormat.Time:
            formattedTime = new Intl.DateTimeFormat('en', {
                hour: 'numeric',
                hourCycle: 'h12',
                minute: 'numeric',
                second: 'numeric',
            }).format(date);
            break;
        default:
            formattedTime = date.toString();
    }

    return formattedTime;
}
export function formatDuration (time: number, format: DurationFormat): string {
    let formattedTime = '';

    switch (format) {
        case DurationFormat.Seconds:
            formattedTime = `${String(time % 60).padStart(2, '0')}s`;
            break;
        case DurationFormat.Minutes:
            formattedTime = `${String(Math.trunc(time / 60) % 60).padStart(2, '0')}:${String(time % 60).padStart(2, '0')}`;
            break;
        case DurationFormat.Hours:
            formattedTime = `${Math.trunc(time / 3600)}h`;
            break;
        case DurationFormat.StopWatch:
            formattedTime = `${Math.trunc(time / 3600)}:${String(Math.trunc(time / 60) % 60).padStart(2, '0')}:${String(time % 60).padStart(2, '0')}`;
            break;
        default:
            formattedTime = `${Math.trunc(time / 3600)}:${String(Math.trunc(time / 60) % 60).padStart(2, '0')}:${String(time % 60).padStart(2, '0')}`;
            break;
    }

    return formattedTime;
}

export class FormattedProvider extends VarProvider<Latest.VarProviders.FormattedProvider> {
    inputVars: ComputedRef<Latest.AppletVar[]>;

    constructor (opts: VarProviderOpts<Latest.VarProviders.FormattedProvider>, runtime: AppletRuntime) {
        super(opts, runtime);

        this.inputVars = computed(() => this.cfg.attrs.inputVars.map(e => this.getInputValue(e)));

        this.watch(this.inputVars, inputVars => {
            let newOutputVal = this.cfg.attrs.stringTemplate;
            const varUses = Array.from(this.cfg.attrs.stringTemplate.matchAll(/\{(.*?)\}/gu)).map(e => e[1]);

            varUses.forEach((varReplacementString) => {
                if (!varReplacementString) {
                    return;
                }
                const replacementParseResult = (/(t|td):([A-Za-z0-9_ ]+):?(.*)?/igu).exec(varReplacementString!) as [string] | FormattedParserResult<keyof FormattedParserResultMap> | null;

                let replaceValue = '';
                if (replacementParseResult && replacementParseResult.length > 1) {
                    // eslint-disable-next-line unused-imports/no-unused-vars
                    const [ fullMatch, modifier, varName, format ] = replacementParseResult as FormattedParserResult<keyof FormattedParserResultMap>;
                    const inp = this.getInputValue(varName);
                    if (inp.status === 'ok') {
                    switch (modifier) {
                        case 't':
                            replaceValue = formatTime(parseInt(String(inp.val), 10), format as TimeFormat);
                            break;
                        case 'td':
                            replaceValue = formatDuration(parseInt(String(inp.val), 10), format as DurationFormat);
                            break;
                        default:
                            replaceValue = String(inp.val);
                        }
                    } else if (inp.status instanceof Error && opts.cfg.attrs.usingErrors) {
                        // if the input is errored, and `usingErrors` is true, then we can use
                        // the error's message
                        replaceValue = inp.status.message;
                    }
                } else {
                    const inp = this.getInputValue(varReplacementString!);
                    if (inp.status === 'ok') {
                        replaceValue = String(inp.val);
                    } else if (inp.status instanceof Error && opts.cfg.attrs.usingErrors) {
                        // if the input is errored, and `usingErrors` is true, then we can use
                        // the error's message
                        replaceValue = inp.status.message;
                    }
                }
                newOutputVal = newOutputVal.replace(`{${varReplacementString}}`, replaceValue);
            });

            const status = getMergedStatus(...inputVars);
            if (status === 'ok' || (status instanceof Error && opts.cfg.attrs.usingErrors)) {
                this.setOutputVar(this.cfg.attrs.outputVar, { val: newOutputVal, status: 'ok' });
            } else {
                this.setOutputVar(this.cfg.attrs.outputVar, { val: null, status });
            }
        }, { immediate: true });
    }

}

