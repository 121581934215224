import { MetricExpr } from '@redviking/argonaut-util/src/metric-parser/metric-expr';
import type { Latest } from '@redviking/argonaut-util/types/mes/applet-designs/appletDesign.latest.zod';

/**
 * validator functions user inputs
 */
export const rules = {
    required () {
        return (value: string | null | number): string | boolean => typeof value === 'number' ? true : Boolean(value) || 'Required';
    },
    /**
     * @param n minimum length required
     */
    minLength (n: number) {
        return (value: string | null): string | boolean => value
            ? value.length >= n || 'Too short'
            : false;
    },
    sparkplugMetricExpr (expr: MetricExpr, allowed: {
        /** allows expression reading metric extras */
        metric: boolean,
        /** allows expression reading meta */
        meta: boolean,
        propset: {
            /** allows expression reading a property within a property */
            propset: boolean;
            /** allows expression reading a property list */
            propsetlist: boolean;
        } | false,
        /** allows expression reading a dataset */
        dataset: boolean,
        /** allows expression reading a template */
        template: boolean,
    }) {
        if (!('extra' in expr)) {
            return;
        }

        if (allowed[expr.extra] === true) {
            return;
        } else if (expr.extra === 'propset') {
            const propsetAllowed = allowed[expr.extra];
            if (propsetAllowed !== false) {
                if (typeof expr.propExtra !== 'object' || expr.propExtra === null) {
                    // reading a direct property value
                    return;
                } else if (propsetAllowed[expr.propExtra.extra] === false) {
                    return `"${expr.propExtra}" from a metric property is not supported here`;
                }
                return '';
            }
        }

        if (allowed[expr.extra] === false) {
            if (expr.extra === 'metric') {
                return `"${expr.key}" of a metric is not supported here`;
            }
            return `"${expr.extra}" is not supported here`;
        }

        return 'The metric expression is not supported'; // it's important to return with the generic error in this fn. earlier conditions should check if it's ok and return early.
    },
    conditionalVarRequired (conditional: Latest.VarCondition) {
        for (const condition of conditional!.comparisons) {
            for (const operand of condition.operands) {
                if (operand.type === 'var' && !operand.varName) {
                    return false;
                }
            }
        }
        return true;
    },
};
