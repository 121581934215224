/* eslint-disable max-lines */
/* eslint-disable complexity */
import { AttributeSettingForAttributeLinker, keyedLimitAttributeSettingOperators } from '../../types/attribute-settings/attribute-settings-config.zod';

export type AttributeRangeSettingDataPayload = {
    minValue: number;
    maxValue: number;
    schemaName: string;
};

export type AttributeLimitSettingDataPayload = {
    amount: number;
    operator: string;
    schemaName: string;
};

type SchemaStatusType = 'warning' | 'error' | 'success';

export type InterpolateData = {
    name: string;
    type: string;
    value: string;
};

type BaseSchemaStatus = {
    icon: string;
    message: string;
    type: SchemaStatusType;
    messagePlaintext: string;
    interpolates: InterpolateData[];
}

interface SchemaWarning extends BaseSchemaStatus {
    type: 'warning';
    icon: 'mdi-alert-outline';
}

interface SchemaError extends BaseSchemaStatus {
    type: 'error';
    icon: 'mdi-alert-octagon-outline';
}

interface SchemaSuccess extends BaseSchemaStatus {
    type: 'success';
    icon: 'mdi-check';
}

export type SchemaStatus = SchemaWarning | SchemaError | SchemaSuccess;

export const rangeInterpolates = (payload: AttributeRangeSettingDataPayload): InterpolateData[] => [
    {
        type: 'b',
        name: 'suggests',
        value: 'suggests',
    },
    {
        type: 'b',
        name: 'requires',
        value: 'requires',
    },
    {
        name: 'min',
        type: 'code',
        value: payload.minValue.toString(),
    },
    {
        name: 'max',
        type: 'code',
        value: payload.maxValue.toString(),
    },
    {
        type: 'code',
        name: 'schemaName',
        value: payload.schemaName,
    },
];

const generateWarningRangeStatus = (payload: AttributeRangeSettingDataPayload): SchemaStatus => ({
    type: 'warning',
    icon: 'mdi-alert-outline',
    interpolates: rangeInterpolates(payload),
    message: 'Setting {suggests} range of at least {min} and at most {max} attributes from the {schemaName} schema to be linked',
    messagePlaintext: `Setting suggests range of at least ${payload.minValue} and at most ${payload.maxValue} attributes from the ${payload.schemaName} schema to be linked`,
});

const generateRequiredRangeStatus = (payload: AttributeRangeSettingDataPayload): SchemaStatus => ({
    type: 'error',
    icon: 'mdi-alert-octagon-outline',
    interpolates: rangeInterpolates(payload),
    message: 'Setting {requires} range of at least {min} and at most {max} attributes from the {schemaName} schema to be linked',
    messagePlaintext: `Setting suggests range of ${payload.minValue} ${payload.maxValue} attributes from the ${payload.schemaName} schema to be linked`,
});

const generateLimitInterpolates = (payload: AttributeLimitSettingDataPayload): InterpolateData[] => [
    {
        type: 'b',
        name: 'suggests',
        value: 'suggests',
    },
    {
        type: 'b',
        name: 'requires',
        value: 'requires',
    },
    {
        type: 'code',
        name: 'operator',
        value: payload.operator,
    },
    {
        type: 'code',
        name: 'amount',
        value: payload.amount.toString(),
    },
    {
        type: 'span',
        name: 'attribute',
        value: payload.amount === 1 ? 'attribute' : 'attributes',
    },
    {
        type: 'code',
        name: 'schemaName',
        value: payload.schemaName,
    },
];

const generateWarningLimitStatus = (payload: AttributeLimitSettingDataPayload): SchemaStatus => ({
    type: 'warning',
    icon: 'mdi-alert-outline',
    interpolates: generateLimitInterpolates(payload),
    message: 'Setting {suggests} {operator} {amount} {attribute} from the {schemaName} schema should be linked',
    messagePlaintext: `Setting suggests ${payload.operator} ${payload.amount} ${payload.amount === 1 ? 'attribute' : 'attributes'} from the ${payload.schemaName} schema to be linked`,
});

const generateRequiredLimitStatus = (payload: AttributeLimitSettingDataPayload): SchemaStatus => ({
    type: 'error',
    icon: 'mdi-alert-octagon-outline',
    interpolates: generateLimitInterpolates(payload),
    message: 'Setting {requires} {operator} {amount} {attribute} from the {schemaName} schema to be linked',
    messagePlaintext: `Setting requires ${payload.operator} ${payload.amount} ${payload.amount === 1 ? 'attribute' : 'attributes'} from the ${payload.schemaName} schema to be linked`,
});

export const validateAttributeSetting = (attributes: {
    attribute: {
        attribute_schema: {
            id: string;
        };
    };
}[], attributeSetting: AttributeSettingForAttributeLinker): SchemaStatus => {
    switch (attributeSetting.config.type) {
        case 'range': {
            const schemaName = attributeSetting.attributeSchema.name;

            const amountLinked = attributes.filter(attr => attr.attribute.attribute_schema.id === attributeSetting.attributeSchema.id).length;
            let isLowerBoundSatified = false;
            if (amountLinked >= attributeSetting.config.min.value) {
                isLowerBoundSatified = true;
            }
            let isUpperBoundSatified = false;
            if (amountLinked <= attributeSetting.config.max.value) {
                isUpperBoundSatified = true;
            }
            let ret: SchemaStatus = isLowerBoundSatified && isUpperBoundSatified
                ? {
                    type: 'success',
                    icon: 'mdi-check',
                    message: 'Allow range of at least {min} and at most {max} attributes from the {schemaName} schema to be linked',
                    messagePlaintext: `Allow range of at least ${attributeSetting.config.min.value} and at most ${attributeSetting.config.max.value} attributes from the ${schemaName} schema to be linked`,
                    interpolates: rangeInterpolates({
                        schemaName,
                        maxValue: attributeSetting.config.max.value,
                        minValue: attributeSetting.config.min.value,
                    }),
                }
                : generateWarningRangeStatus({
                    schemaName,
                    maxValue: attributeSetting.config.max.value,
                    minValue: attributeSetting.config.min.value,
                });
            if (ret.type === 'warning' && attributeSetting.config.strict) {
                ret = generateRequiredRangeStatus({
                    schemaName,
                    maxValue: attributeSetting.config.max.value,
                    minValue: attributeSetting.config.min.value,
                });
            }
            return ret;
        }
        case 'limit': {
            const amount = attributeSetting.config.amount.toString();
            const schemaName = attributeSetting.attributeSchema.name;
            const attribute = attributeSetting.config.amount === 1 ? 'attribute' : 'attributes';
            const operator = keyedLimitAttributeSettingOperators.find(op => attributeSetting.config.type === 'limit' && op.value === attributeSetting.config.operator)?.text || 'Unknown op';

            let isSatified = false;
            const attributesFromSchema = attributes.filter(attr => attr.attribute.attribute_schema.id === attributeSetting.attributeSchema.id);
            if (attributeSetting.config.operator === 'min') {
                isSatified = attributesFromSchema.length >= attributeSetting.config.amount;
            } else if (attributeSetting.config.operator === 'max') {
                isSatified = attributesFromSchema.length <= attributeSetting.config.amount;
            } else if (attributeSetting.config.operator === 'exactly') {
                isSatified = attributesFromSchema.length === attributeSetting.config.amount;
            }
            let ret: SchemaStatus = isSatified
                ? {
                    type: 'success',
                    icon: 'mdi-check',
                    message: 'Link {operator} {amount} {attribute} from the {schemaName} schema',
                    messagePlaintext: `Link ${operator} ${amount} ${attribute} from the ${schemaName} schema`,
                    interpolates: [
                        {
                            type: 'code',
                            value: operator,
                            name: 'operator',
                        },
                        {
                            type: 'code',
                            value: amount,
                            name: 'amount',
                        },
                        {
                            type: 'span',
                            value: attribute,
                            name: 'attribute',
                        },
                        {
                            type: 'code',
                            value: schemaName,
                            name: 'schemaName',
                        },
                    ],
                }
                : generateWarningLimitStatus({
                    amount: attributeSetting.config.amount,
                    operator: attributeSetting.config.operator,
                    schemaName: attributeSetting.attributeSchema.name,
                });
            if (ret.type === 'warning' && attributeSetting.config.strict) {
                ret = generateRequiredLimitStatus({
                    amount: attributeSetting.config.amount,
                    operator: attributeSetting.config.operator,
                    schemaName: attributeSetting.attributeSchema.name,
                });
            }
            return ret;
        }
        case 'none': {
            const schemaName = attributeSetting.attributeSchema.name;

            const atLeastNone = attributes.find(attr => attr.attribute.attribute_schema.id === attributeSetting.attributeSchema.id) === undefined;
            let ret: SchemaStatus = atLeastNone
                ? {
                    type: 'success',
                    icon: 'mdi-check',
                    message: '{doNotAllow} any attribute from the {schemaName} schema to be linked',
                    messagePlaintext: `Do not allow any attribute from the ${schemaName} schema to be linked`,
                    interpolates: [
                        {
                            type: 'code',
                            name: 'doNotAllow',
                            value: 'Do not allow',
                        },
                        {
                            type: 'code',
                            value: schemaName,
                            name: 'schemaName',
                        },
                    ],
                }
                : {
                    type: 'warning',
                    icon: 'mdi-alert-outline',
                    message: 'Setting {suggests} {no} attributes from the {schemaName} schema should be linked',
                    messagePlaintext: `Setting suggests no attributes from the ${schemaName} schema to be linked`,
                    interpolates: [
                        {
                            type: 'b',
                            name: 'suggests',
                            value: 'suggests',
                        },
                        {
                            name: 'no',
                            value: 'no',
                            type: 'code',
                        },
                        {
                            type: 'code',
                            value: schemaName,
                            name: 'schemaName',
                        },
                    ],
                };
            if (ret.type === 'warning' && attributeSetting.config.strict) {
                ret = {
                    type: 'error',
                    icon: 'mdi-alert-octagon-outline',
                    message: 'Setting {requires} {no} attributes from the {schemaName} schema should be linked',
                    messagePlaintext: `Setting requires no attributes from the ${schemaName} schema to be linked`,
                    interpolates: [
                        {
                            type: 'b',
                            name: 'requires',
                            value: 'requires',
                        },
                        {
                            name: 'no',
                            value: 'no',
                            type: 'code',
                        },
                        {
                            type: 'code',
                            value: schemaName,
                            name: 'schemaName',
                        },
                    ],
                };
            }
            return ret;
        }
        default: {
            throw new Error(`Unknown attribtue setting type: ${attributeSetting}`);
        }
    }
};
