import type { z } from 'zod';
import type { DeployParam } from './mes/deployParam.zod';

/**
 * things that can have hooks
 */
export enum SchemaHookKind {
    VarName = 'varName',
    DeployParam = 'deployParam',
    ScriptId = 'scriptId',
}

/** the property to store the hook cfg */
export const schemaHookProp = '__schemaHooks';

type TransformFn<T> = (val: T) => T;

export type SchemaHookCfgBase = {
    [SchemaHookKind.VarName]?: TransformFn<string>;
    [SchemaHookKind.DeployParam]?: TransformFn<DeployParam>;
    [SchemaHookKind.ScriptId]?: TransformFn<string>;
};

/**
 * takes an input schema, and returns a new schema that will trigger the specified hook.
 */
export function schemaWithHook<SCHEMA extends z.ZodTypeAny> (opts: {
    schema: SCHEMA,
    hook: SchemaHookKind
}): SCHEMA {
    return opts.schema.transform((val, ctx) => {
        // @ts-expect-error - no way to type `ctx.extra`
        const hookCfg: SchemaHookCfgBase | undefined = ctx.extra?.[schemaHookProp];
        const hook = hookCfg?.[opts.hook]; // if this has a ts error, we might need to add a type to SchemaHookCfgBase
        if (hook) {
            return hook(val);
        }
        return val;
    }) as unknown as SCHEMA;
}

export function parseWithHookCfg<SCHEMA extends z.ZodTypeAny> (opts: {
    schema: SCHEMA,
    hookCfg: SchemaHookCfgBase,
}, input: z.input<SCHEMA>): z.output<SCHEMA> {
    // parse the schema, but include hook cfg in the refinement context
    return opts.schema.parse(input, {
        refinementCtxExtra: { [schemaHookProp]: opts.hookCfg },
    });
}
