import {
    Ref,
    computed,
    ref,
} from 'vue';
import { toWritableComputedProps } from './writable-computed-prop';
import { useComputedList } from './use-computed-list';
import merge from 'lodash.merge';

export type ListItem = { id: string | number };
export type UpdateChange<ItemType extends ListItem, PartialChanges extends boolean = true> = PartialChanges extends true ? ListItem & Partial<ItemType> : ItemType;
export type DeleteChange<ItemType extends ListItem, PartialChanges extends boolean = true> = PartialChanges extends true ? Pick<ItemType, 'id'> : ItemType;

export type PendingListChanges<T extends ListItem, PartialChanges extends boolean = true> = {
    inserts: T[],
    /** partial updates. merge with original item with lodash.merge */
    updates: UpdateChange<T, PartialChanges>[],
    deletes: DeleteChange<T, PartialChanges>[],
};

export type ChangeMapVal<T extends ListItem, PartialChanges extends boolean = true> =
    | { changeGroup: 'deletes', item: DeleteChange<T, PartialChanges> }
    | { changeGroup: 'inserts', item: T }
    | { changeGroup: 'updates', item: UpdateChange<T, PartialChanges> };

export type FlatListChanges<T extends ListItem, PartialChanges extends boolean = true> = ChangeMapVal<T, PartialChanges>[];

/**
 * - repeated inserts replace
 * - repeated updates merge
 * - inserts replaces deletes
 * - deletes replaces all
 * - updates after inserts merge
 * - inserts after updates replace, but stay in updates
 *
 * this works best with item types that do not have partial properties.
 * that is, unsetting or "removing" a property is just setting it to null.
 */
export function usePendingListChanges<
    T extends ListItem,
    PartialChanges extends boolean = true,
> (initialChanges?: PendingListChanges<T, PartialChanges>) {
    const changes: Ref<PendingListChanges<T, PartialChanges>> = ref({
        inserts: [],
        updates: [],
        deletes: [],
    });

    const {
        inserts: insertChanges,
        updates: updateChanges,
        deletes: deleteChanges,
    } = toWritableComputedProps(changes);

    const insertChangesControl = useComputedList(insertChanges);
    const updateChangesControl = useComputedList(updateChanges);
    const deleteChangesControl = useComputedList<T[] | Pick<T, 'id'>>(deleteChanges);

    const changeControlMap = {
        inserts: insertChangesControl,
        updates: updateChangesControl,
        deletes: deleteChangesControl,
    };

    const changeMap = new Map<string | number, ChangeMapVal<T, PartialChanges>>();

    function getExisting (id: string | number): null | ({ idx: number } & ChangeMapVal<T, PartialChanges>) {
        const changeMapEntry = changeMap.get(id);
        if (changeMapEntry?.changeGroup === 'inserts') {
            const idx = changes.value.inserts.findIndex(c => c.id === id);

            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            return { idx, changeGroup: 'inserts', item: changes.value.inserts[idx]! };
        } else if (changeMapEntry?.changeGroup === 'updates') {
            const idx = changes.value.updates.findIndex(c => c.id === id);

            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            return { idx, changeGroup: 'updates', item: changes.value.updates[idx]! };
        } else if (changeMapEntry?.changeGroup === 'deletes') {
            const idx = changes.value.deletes.findIndex(c => c.id === id);

            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            return { idx, changeGroup: 'deletes', item: changes.value.deletes[idx]! };
        } else {
            return null;
        }
    }

    const externalChanges = computed({
        get: () => changes.value,
        set: v => {
            if (v.inserts !== changes.value.inserts || v.updates !== changes.value.updates || v.deletes !== changes.value.deletes) {
                changes.value = {
                    inserts: v.inserts === changes.value.inserts ? changes.value.inserts : v.inserts,
                    updates: v.updates === changes.value.updates ? changes.value.updates : v.updates,
                    deletes: v.deletes === changes.value.deletes ? changes.value.deletes : v.deletes,
                };
            }

            changeMap.clear();
            for (const insert of v.inserts) {
                changeMap.set(insert.id, { changeGroup: 'inserts', item: insert });
            }
            for (const update of v.updates) {
                changeMap.set(update.id, { changeGroup: 'updates', item: update });
            }
            for (const deleted of v.deletes) {
                changeMap.set(deleted.id, { changeGroup: 'deletes', item: deleted });
            }
        },
    });

    if (initialChanges) {
        externalChanges.value = initialChanges;
    }

    return {
        changeMap,
        /**
         * inefficient way to show all the changes.
         * the original items are needed to show the full objects for deleted and updated items.
         *
         * Useful for a "pending changes" overview page.
         */
        // TODO would it be more efficient if we passed the original item when we call plc.update() and plc.delete()?
        getItemsWithChanges (originalItems: T[] | Map<T['id'], T>) {
            const result: { changeGroup: ChangeMapVal<T>['changeGroup'] | null, item: T }[] = [];

            // normalize input arg
            const originalItemMap = new Map<T['id'], T>(originalItems instanceof Map ? originalItems : originalItems.map(i => [ i.id, i ]));

            for (const [ id, originalItem ] of originalItemMap) {
                const changedMapEntry = changeMap.get(id);
                if (changedMapEntry) {
                    if (changedMapEntry.changeGroup === 'deletes') {
                        // puts all original items that were deleted in the result
                        result.push({ item: originalItem, changeGroup: 'deletes' });
                    } else if (changedMapEntry.changeGroup === 'updates') {
                        // puts all updated items in the result, after applying the update to the original item
                        result.push({ item: merge({}, originalItem, changedMapEntry.item), changeGroup: 'updates' });
                    }
                    // skip inserted items, they are added later since they shouldn't be in the original items
                } else {
                    // puts all unchanged items in the result
                    result.push({ changeGroup: null, item: originalItem });
                }
            }
            result.push(...insertChanges.value.map<Extract<ChangeMapVal<T>, { changeGroup: 'inserts' }>>(e => ({ changeGroup: 'inserts', item: e })));
            return result;
        },
        changes: externalChanges,
        insert: (item: T): void => {
            const existingEntry = getExisting(item.id);
            if (existingEntry) {
                if (existingEntry.changeGroup === 'inserts') {
                    // insert replaces preexisting insert
                    changeMap.set(item.id, { changeGroup: 'inserts', item });
                    insertChangesControl.updateItem(item, existingEntry.idx);
                } else if (existingEntry.changeGroup === 'deletes') {
                    // insert replaces preexisting delete
                    changeMap.set(item.id, { changeGroup: 'inserts', item });
                    deleteChangesControl.deleteItem(existingEntry.idx);
                    insertChangesControl.insertItem(item);
                } else if (existingEntry.changeGroup === 'updates') {
                    // insert merges preexisting update, but stays in updates
                    changeMap.set(item.id, { changeGroup: 'updates', item });
                    updateChangesControl.updateItem(item, existingEntry.idx);
                }
            } else {
                changeMap.set(item.id, { changeGroup: 'inserts', item });
                insertChangesControl.insertItem(item);
            }
        },
        update: (item: UpdateChange<T, PartialChanges>): void => {
            const existingEntry = getExisting(item.id);
            if (existingEntry) {
                const mergedItem = existingEntry.changeGroup === 'deletes' ? item : merge({}, existingEntry.item, item);
                if (existingEntry.changeGroup === 'inserts') {
                    // update merges with preexisting insert
                    changeMap.set(item.id, { changeGroup: 'inserts', item: mergedItem as T });
                    insertChangesControl.updateItem(mergedItem as T, existingEntry.idx);
                } else if (existingEntry.changeGroup === 'deletes') {
                    // update replaces preexisting delete
                    changeMap.set(item.id, { changeGroup: 'updates', item });
                    deleteChangesControl.deleteItem(existingEntry.idx);
                    updateChangesControl.insertItem(item);
                } else if (existingEntry.changeGroup === 'updates') {
                    // repeated updates merge
                    changeMap.set(item.id, { changeGroup: 'updates', item: mergedItem });
                    updateChangesControl.updateItem(mergedItem, existingEntry.idx);
                }
            } else {
                changeMap.set(item.id, { changeGroup: 'updates', item });
                updateChangesControl.insertItem(item);
            }
        },
        /**
         * tracks the change that an item was deleted.
         * changes aren't modified if this change is already tracked
         */
        delete: (item: DeleteChange<T, PartialChanges>): void => {
            const existingEntry = getExisting(item.id);
            if (existingEntry) {
                if (existingEntry.changeGroup === 'inserts') {
                    // delete replaces preexisting insert
                    insertChangesControl.deleteItem(existingEntry.idx);
                    deleteChangesControl.insertItem(item);
                    changeMap.set(item.id, { changeGroup: 'deletes', item });
                } else if (existingEntry.changeGroup === 'updates') {
                    // delete replaces preexisting update
                    updateChangesControl.deleteItem(existingEntry.idx);
                    deleteChangesControl.insertItem(item);
                    changeMap.set(item.id, { changeGroup: 'deletes', item });
                }
            } else {
                deleteChangesControl.insertItem(item);
                changeMap.set(item.id, { changeGroup: 'deletes', item });
            }
        },
        /**
         * @param id if set, only reset the change related to ID
         */
        reset (id?: T['id']) {
            if (id) {
                const existingEntry = getExisting(id);
                if (existingEntry) {
                    changeControlMap[existingEntry.changeGroup].deleteItem(existingEntry.idx);
                    changeMap.delete(id);
                }
            } else {
                changes.value = {
                    inserts: [],
                    updates: [],
                    deletes: [],
                };
                changeMap.clear();
            }
        },
    };
}
