import { v4 as uuidv4 } from 'uuid';
import { getMacroTargetVarpId } from '@redviking/argonaut-core-ui/src/applet/design/macros/get-target-id';
import type { Latest } from '@redviking/argonaut-util/types/mes/applet-designs/appletDesign.latest.zod';
import { ScreenDesigner } from '@redviking/argonaut-util/types/mes/screen-designer/screen-design';

function mapId (originalId: string, idMap: Map<string, string>) {
    let mappedId = idMap.get(originalId);
    if (!mappedId) {
        mappedId = uuidv4();
        idMap.set(originalId, mappedId);
    }
    return mappedId;
}

export const copyAppletDesignConfig = (originalConfig: Latest.AppletDesignVersionConfig): Latest.AppletDesignVersionConfig => {
    const idMap = new Map<string, string>();
    const ret = {
        ...originalConfig,
        macros: originalConfig.macros.map(ogMacro => ({
            ...ogMacro,
            id: mapId(ogMacro.id, idMap),
        })),
        varProviders: originalConfig.varProviders.map(ogVarp => {
            const copiedVarp = { ...ogVarp };
            if (copiedVarp.type === 'macroTarget') {
                copiedVarp.attrs.macroId = mapId(copiedVarp.attrs.macroId, idMap);
                copiedVarp.id = getMacroTargetVarpId(copiedVarp.attrs.macroId);
            } else {
                copiedVarp.id = mapId(ogVarp.id, idMap);
            }
            return copiedVarp;
        }),
        screens: originalConfig.screens.map(ogScreen => {
            const newGridLayoutAspectRatios = { ...ogScreen.gridLayoutAspectRatios };
            const cells = ogScreen.cells.map(ogCell => {
                const newCell = {
                    ...ogCell,
                    id: mapId(ogCell.id, idMap),
                };
                if (newCell.type === 'macroTarget') {
                    newCell.attrs = {
                        ...newCell.attrs,
                        macroId: mapId(newCell.attrs.macroId, idMap),
                    };
                }

                // NOTE: not maping new ids for linked grid cells. Do not see the value in doing so since the ids are not used in the database as we store the config as a json blob
                Object.entries(newGridLayoutAspectRatios).forEach(([ aspectRatio, gridLayout ]) => {
                    const newGridLayout = gridLayout;
                    newGridLayout.linkedGridCells = newGridLayout.linkedGridCells.map(lgc => {
                        if (lgc.argoCellId === ogCell.id) {
                            return {
                                ...lgc,
                                argoCellId: newCell.id,
                            };
                        }
                        return lgc;
                    });
                    newGridLayoutAspectRatios[aspectRatio as ScreenDesigner.AspectRatioType] = newGridLayout;
                });

                return newCell;
            });
            const copiedScreen: Latest.Screen.Config = {
                ...ogScreen,
                cells,
                id: mapId(ogScreen.id, idMap),
                gridLayoutAspectRatios: newGridLayoutAspectRatios,
            };
            return copiedScreen;
        }),
    };
    return ret;
};
