import { messages } from 'src/i18n/i18n';
import { CausalError } from '@redviking/causal-error';
import type { Latest } from '@redviking/argonaut-util/types/mes/applet-designs/appletDesign.latest.zod';

export function validateComparisons (comparisons: Latest.VarComparison[]) {
    for (let cIdx = 0; cIdx < comparisons.length; cIdx++) {
        const comparison = comparisons[cIdx];
        const validationErrs = messages.mes.varProviders.comparative.validationErrs;
        const firstOperand = comparison.operands[0];
        const secondOperand = comparison.operands[1];
        if (firstOperand.type === 'var' && !firstOperand.varName) {
            throw new CausalError(validationErrs.assignment.invalidComparison(cIdx), validationErrs.assignment.variableOperand);
        }
        if (secondOperand && secondOperand.type === 'var' && !secondOperand.varName) {
            throw new CausalError(validationErrs.assignment.invalidComparison(cIdx), validationErrs.assignment.variableOperand);
        }
    }
}
