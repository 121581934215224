import { actionTree, getStoreType, getterTree, mutationTree } from 'typed-vuex';

export type MonitoringState = {
    coreStateColors: Record<string, string>;
};

export const monitoringState = (): MonitoringState => {
    const initMonitoringState: MonitoringState = {
        coreStateColors: {
            active: '#00FF00',
        },
    };
    return initMonitoringState;
};

export const mutations = mutationTree(monitoringState, {
});


export const getters = getterTree(monitoringState, {
});

const monitoringActions = actionTree({
    state: monitoringState,
    mutations,
    getters,
}, {
});

const actions = actionTree({
    state: monitoringState,
    mutations,
    getters,
}, {
    ...monitoringActions,
});

export const monitoringStore = {
    namespaced: true,
    state: monitoringState,
    getters,
    mutations,
    actions,
};

const storeType = getStoreType(monitoringStore);
export type MonitoringStore = typeof storeType;

export default monitoringStore;
