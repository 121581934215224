export const messages = {
    desc: 'Measures how many seconds have passed since a start / reset condition was met',
    dir: {
        down: 'Timer',
        title: 'Behavior:',
        up: 'Stopwatch',
    },
    max: 'MAX',
    min: 'MIN',
    outputVarLabel: 'Output Variable',
    pauseCond: {
        overview: 'If {boolOperation} of the following comparisons are satisfied, the timer will be paused:',
        title: 'Pause Condition',
    },
    resetCond: {
        overview: 'When {boolOperation} of the following comparisons become satisfied, the timer will start / be reset:',
        title: 'Reset / Start Condition',
    },
    title: 'Timer',
};
