import {
    ComputedRef,
    Ref,
    WritableComputedRef,
    computed,
} from 'vue';
import { EntityType, EntityTypeMap } from '@redviking/argonaut-core-ui/types/entity';
import { useCoreAccessor } from '../../store/use-core-accessor';
import { Notify } from '../../notifications';
import { useRoute } from '@redviking/argonaut-core-ui/src/util/composables';
import { provideInjectComposable } from 'src/util/composables/use-provide-inject';
import { typedKeys } from '@redviking/argonaut-util/src/typed-keys';
import { EntityDetailMode } from './mode';

export type EditableEntityDetailMode = Omit<EntityDetailMode, EntityDetailMode.View>;

// we omit the default values here so injectors that also provide can detect when an ancestor component is providing
export const useEditing = provideInjectComposable<boolean>('editing');
export const useCopying = provideInjectComposable<boolean>('copying');
export const useForking = provideInjectComposable<boolean>('forking');
export const useCreating = provideInjectComposable<boolean>('creating');
export const useDisabled = provideInjectComposable<boolean>('disabled');

/**
 * determines mode for entity maintenance, defaults to `'view'`
 */
export function useEntityMode () {
    const currentRoute = useRoute();
    const validModes: string[] = typedKeys(EntityDetailMode).map(m => EntityDetailMode[m]);
    const mode = computed(() => validModes.includes(currentRoute.value.query.mode as string)
        ? currentRoute.value.query.mode as EntityDetailMode
        : EntityDetailMode.View);

    // if ancestor provided values for editing and disabled, use those. otherwise, use the mode
    const editing = useEditing.useInject(computed(() => mode.value !== EntityDetailMode.View)).ref;
    const forking = useForking.useInject(computed(() => mode.value === EntityDetailMode.Fork)).ref;
    const copying = useCopying.useInject(computed(() => mode.value === EntityDetailMode.Copy)).ref;
    const creating = useCreating.useInject(computed(() => mode.value === EntityDetailMode.Create)).ref;
    const disabled = useDisabled.useInject(computed(() => !editing.value)).ref;

    return {
        mode,
        editing: computed(() => editing.value),
        forking,
        copying,
        creating,
        disabled: computed(() => disabled.value),
    };
}

export function useEntityDetail<E extends EntityType> (entityType: E): {
    entityData: WritableComputedRef<EntityTypeMap[E]>,
    readonly originalEntityData: ComputedRef<EntityTypeMap[E] | null>,
    editing: ComputedRef<boolean>,
    forking: Ref<boolean>,
    creating: Ref<boolean>,
    disabled: ComputedRef<boolean>,
    readonly mode: ComputedRef<EntityDetailMode>
} {
    const accessor = useCoreAccessor();
    const currentRoute = useRoute();

    const originalEdcRoute = currentRoute.value.matched.find(mr => mr.meta.entityType === entityType);
    if (originalEdcRoute?.meta?.entityType !== entityType) {
        const err = new Error(`Entity Detail Store: Mismatched entity type; expected type ${entityType}, got ${originalEdcRoute?.meta?.type}`);
        Notify.error(err);
        throw err;
    }

    let oldEntityData: EntityTypeMap[E];

    const entityData = computed<EntityTypeMap[E]>({
        get: () => {
            // TODO FIXME: refactor edc to return keyed objects
            const temp = accessor.entity;
            if (temp && temp.type === entityType) {
                oldEntityData = temp.entity as EntityTypeMap[E];
                return temp.entity as EntityTypeMap[E];
            } else if (oldEntityData) {
                const tempOld = oldEntityData;
                return tempOld;
            } else {
                const err = new Error(`Entity Detail Store: Mismatched entity type; expected type ${entityType}, got ${temp?.type}`);
                Notify.error(err);
                throw err;
            }
        },
        set: inp => accessor.setPageEntity({ type: entityType as E, entity: inp }),
    });

    const originalEntityData = computed<EntityTypeMap[E] | null>(() => {
        // FIXME: refactor edc to return keyed objects
        const edcRoute = currentRoute.value.matched.find(mr => mr.meta.entityType === entityType);
        const temp = accessor.originalEntity;
        if (!temp) {
            return null;
        }
        if (temp.type === entityType || edcRoute?.meta.entityType === entityType) {
            return temp.entity as EntityTypeMap[E];
        } else {
            const err = new Error(`Entity Detail Store: Mismatched original entity type; expected type ${entityType}, got ${temp?.type}`);
            Notify.error(err);
            throw err;
        }
    });

    return {
        entityData,
        originalEntityData,
        ...useEntityMode(),
    };
}
