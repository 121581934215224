import { mutationTree } from 'typed-vuex';
import {
    ArgoAttribute,
    ArgoAttributeSchema,
} from '../../types/db';

const attrState = {
    attribute: null as ArgoAttribute | null,
    schema: null as ArgoAttributeSchema | null,
    errors: [] as string[],
};

const mutations = mutationTree(attrState, {
    setPageAttribute (state, attribute: ArgoAttribute) {
        if (attribute.__typename !== 'argo_attribute') {
            console.warn('Unknown type passed into mutation');
        }
        state.attribute = { ...state.attribute, ...attribute };
    },
    clearAttribute (state) {
        state.attribute = null;
    },
    addErrorLog (state, error: string) {
        state.errors.push(error);
    },
});

export default {
    state: attrState,
    mutations,
};
