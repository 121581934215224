import { mutationTree } from 'typed-vuex';
import { ArgoLink } from '@redviking/argonaut-core-ui/types/db';


const linkState = {
    link: null as ArgoLink | null,
};

const mutations = mutationTree(linkState, {
    setPageLink (state, link: ArgoLink) {
        if (link.__typename !== 'argo_link') {
            console.warn('Unknown Type passed into mutation');
        }
        state.link = { ...state.link, ...link };
    },
    clearLink (state): void {
        state.link = null;
    },
});

export default {
    state: linkState,
    mutations,
};
