import {
    computed,
    getCurrentInstance,
} from 'vue';

/**
 * Provides reactive access to the current route
 */
export function useRoute () {
    const vm = getCurrentInstance()?.proxy;
    if (!vm) {
        throw new Error('This must be called from within a setup fn');
    }
    return computed(() => vm.$route);
}
