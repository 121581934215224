import { cellCfgAttrsBaseSchema_v3, cellCfgBaseSchema_v3 } from './cell-base.v3.zod';

import z from 'zod';
import { varConditionSchema_v3 } from '../var-providers/conditional.v3.zod';

export const inputCellAttrsSchema_v3 = cellCfgAttrsBaseSchema_v3.extend({
    /**
     * the local/custom variable name to write the scanner input to.
     */
    writeVar: z.string(),
    /** (only controlled by modifier) */
    errorMsg: z.string().optional(),
    /** (only controlled by modifier) */
    enabled: z.union([ z.number(), z.string() ]),
    captureFocus: z.union([
        z.boolean(),
        z.object({
            onlyIfEmpty: z.boolean().default(false),
        }),
    ]).default(false),
    label: z.string(),
    btnText: z.string(),
    trim: z.boolean(),
    clearCondition: varConditionSchema_v3.nullable().optional(),
});

export const inputCellSchema_v3 = cellCfgBaseSchema_v3.extend({
    type: z.literal('input'),
    attrs: inputCellAttrsSchema_v3,
    modifiers: z.record(z.enum([
        'errorMsg',
        'enabled',
        'label',
        'btnText',
        'background',
        'borderColor',
    ]), z.string().optional()).optional(),
});
