import { type AttributeSetting } from 'src/components/AttributeSettings/types';
import { validateAttributeSettings } from 'src/components/AttributeSettings/util';
import { argoFilePreviewToBase64DataUrl } from 'src/files/file.util';
import { ArgoFile, ArgoMaterialModelAttributeSettingInsertInput, ArgoMaterialModelAttributeSettingUpdates, ModelNumberFragment } from 'types/db';

export type MaterialModelEntity = Pick<ModelNumberFragment,
    'id' |
    'meta' |
    'enabled' |
    'sn_format' |
    'created_by' |
    'model_icon' |
    'context_id' |
    'description' |
    'sn_format_id' |
    'model_number' |
    'label_template' |
    'material_class' |
    'profile_file_id' |
    'material_class_id' |
    'label_template_id' |
    'modelNumberAttributes' |
    'label_printer_device_id' |
    'material_udf_default_data' |
    'material_class_udf_schemas'
> & {
    created_at?: string;
    materialModelAttributeSettings: AttributeSetting[];
};

export function getModelPreviewImage (fileIcon: Pick<ArgoFile, 'preview_subtype' | 'preview_type' | 'preview'>) {
    if (fileIcon) {
        return argoFilePreviewToBase64DataUrl(fileIcon);
    }
    return '';
}

export const validateMaterialModelAttributeSettings = (
    materialModelId: string,
    newSettings: MaterialModelEntity['materialModelAttributeSettings'],
    oldSettings: MaterialModelEntity['materialModelAttributeSettings']
): {
    inserts: ArgoMaterialModelAttributeSettingInsertInput[];
    updates: ArgoMaterialModelAttributeSettingUpdates[];
    deletes: string[];
} => {

    const validatedAttributeSettings = validateAttributeSettings(newSettings, oldSettings);

    const ret: {
        inserts: ArgoMaterialModelAttributeSettingInsertInput[];
        updates: ArgoMaterialModelAttributeSettingUpdates[];
        deletes: string[];
    } = {
        inserts: validatedAttributeSettings.inserts.map(setting => ({
            id: setting.id,
            config: setting.config,
            material_model_id: materialModelId,
            attribute_schema_id: setting.attribute_schema.id,
        })),
        updates: validatedAttributeSettings.updates.map(setting => ({
            where: {
                id: {
                    _eq: setting.id,
                },
            },
            _set: {
                config: setting.config,
            },
        })),
        deletes: validatedAttributeSettings.deletes,
    };
    return ret;
};
