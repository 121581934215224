import { mutationTree } from 'typed-vuex';
import {
    UserFragment,
} from '../../types/db';

const usersState = {
    user: null as UserFragment | null,
    errors: [] as string[],
};

const mutations = mutationTree(usersState, {
    setPageUser (state, user: UserFragment): void {
        if (user.__typename !== 'argo_user') {
            console.warn('Unknown Type passed into mutation');
        }
        state.user = { ...state.user, ...user };
    },
    clearUser (state): void {
        state.user = null;
    },
    addErrorLog (state, error: string): void {
        state.errors.push(error);
    },
});

export default {
    state: usersState,
    mutations,
};
