import { mutationTree } from 'typed-vuex';
import { GetModelNumberQuery } from '../../types/db';

const modelNumberState = {
    modelNumber: null as GetModelNumberQuery['modelNumber'] | null,
};

const mutations = mutationTree(modelNumberState, {
    setPageModelNumber (state, modelNumber: Exclude<typeof modelNumberState['modelNumber'], null>): void {
        if (modelNumber?.__typename !== 'argo_material_model') {
            console.warn('Unknown Type passed into mutation');
        }
        state.modelNumber = modelNumber;
    },
    clearModelNumber (state): void {
        state.modelNumber = null;
    },
});

export default {
    state: modelNumberState,
    mutations,
};
