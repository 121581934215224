import { mutationTree } from 'typed-vuex';
import { ArgoFile } from '@redviking/argonaut-core-ui/types/db';


const fileState = {
    file: null as ArgoFile | null,
};

const mutations = mutationTree(fileState, {
    setPageFile (state, file: ArgoFile) {
        if (file.__typename !== 'argo_file') {
            console.warn('Unknown Type passed into mutation');
        }
        state.file = { ...state.file, ...file };
    },
    clearFile (state): void {
        state.file = null;
    },
});

export default {
    state: fileState,
    mutations,
};
