import { z } from 'zod';
import { varConditionSchema_v3 } from '../../v3/var-providers/conditional.v3.zod';
import { localVarCfgSchema_v3 } from '../../v3/var-providers/local.v3.zod';
import { scriptProviderAttrsSchema_v3, scriptProviderSchema_v3 } from '../../v3/var-providers/script.v3.zod';

const customExecutionSchema_v5 = z.object({
    type: z.literal('custom'),
    condition: varConditionSchema_v3,
    appletVariables: z.array(z.string()),
});

const defaultExecutionSchema_v5 = z.object({
    type: z.literal('default'),
    outputVars: z.array(localVarCfgSchema_v3),
    includedVars: z.array(z.object({
        tracked: z.boolean(),
        name: z.string(),
    })),
});


export const scriptProviderAttrsSchema_v5 = scriptProviderAttrsSchema_v3.omit({
    executeCfg: true,
    includedVars: true,
    outputVars: true,
}).extend({
    executeCfg: z.discriminatedUnion('type', [
        customExecutionSchema_v5,
        defaultExecutionSchema_v5,
    ]),
});

export const scriptProviderSchema_v5 = scriptProviderSchema_v3.omit({
    attrs: true,
}).extend({
    attrs: scriptProviderAttrsSchema_v5,
});
