import Home from './Home.vue';

export default [
    {
        path: '',
        name: 'home',
        component: Home,
        meta: {
            navbarTitle: 'Home',
            pageBannerTitle: 'Welcome to Argonaut',
            navbarIcon: 'mdi-home',
        },
    },
];
