import z from 'zod';
import { btnCellSchema_v3 } from '../v3/cell-types/btn.v3.zod';
import { pageCellSchema_v3 } from '../v3/cell-types/page.v3.zod';
import { textCellSchema_v3 } from '../v3/cell-types/text.v3.zod';
import { timerCellSchema_v3 } from '../v3/cell-types/timer.v3.zod';
import { inputCellSchema_v3 } from '../v3/cell-types/input.v3.zod';
import { mediaCellSchema_v3 } from '../v3/cell-types/media.v3.zod';
import { pagedCellSchema_v3 } from '../v3/cell-types/paged.v3.zod';
import { dialogCellSchema_v3 } from '../v3/cell-types/dialog.v3.zod';
import { processCellSchema_v3 } from '../v3/cell-types/process.v3.zod';
import { dateTimeCellSchema_v3 } from '../v3/cell-types/datetime.v3.zod';
import { attrLookupCellSchema_v3 } from '../v3/cell-types/attr-lookup.v3.zod';
import { macroTargetCellSchema_v3 } from '../v3/cell-types/macro-target.v3.zod';
import { processMediaCellSchema_v3 } from '../v3/cell-types/process-media.v3.zod';
import { materialLookupCellSchema_v3 } from '../v3/cell-types/material-lookup.v3.zod';
import { circularProgressCellSchema_v5 } from './cell-types/circular-progress.v5.zod';

// NOTE: a discriminated union requires all options to be of type ZodObject.
export const cellSchema_v5 = z.lazy(() => z.discriminatedUnion('type', [
    btnCellSchema_v3,
    pageCellSchema_v3,
    textCellSchema_v3,
    timerCellSchema_v3,
    inputCellSchema_v3,
    mediaCellSchema_v3,
    pagedCellSchema_v3,
    dialogCellSchema_v3,
    processCellSchema_v3,
    dateTimeCellSchema_v3,
    attrLookupCellSchema_v3,
    macroTargetCellSchema_v3,
    processMediaCellSchema_v3,
    materialLookupCellSchema_v3,
    circularProgressCellSchema_v5,
]));

