import { accessor } from '@redviking/argonaut-core-ui/src/store';

export function userIsAdmin () {
    const userContexts = accessor.auth.currentUser?.user_contexts || [];

    return userContexts.some((context) => {
        return context?.context?.name === 'admin';
    });
}
export function userIsInAdminContext () {
    const currentContext = accessor.auth.contextId;

    return currentContext === '6e8e9f0c-4c51-4f36-887c-7be7b32bd9a2'; // Admin context uuid
}

export function validateAcls (acls: string[]) {
    const userAcls = accessor.auth.acls;

    // User "admin" is the super admin and should have access to everything
    // without having to assign the ACLs
    // instead of checking for the username of "admin", here we are checking to see if the user has the "admin" context
    if (userIsAdmin()) {
        return true;
    }

    if (userAcls) {
        return acls.every((aclId: string) => {
            return userAcls.global.some((acl: any) => acl.acl_id === aclId);
        });
    }
    return false;
}
