import { mutationTree } from 'typed-vuex';
import { ArgoContext } from '../../types/db';


const contextsState = {
    context: null as ArgoContext | null,
};

const mutations = mutationTree(contextsState, {
    setPageContext (state, context: ArgoContext) {
        if (context.__typename !== 'argo_context') {
            console.warn('Unknown Type passed into mutation');
        }
        state.context = { ...state.context, ...context };
    },
    clearContext (state): void {
        state.context = null;
    },
});

export default {
    state: contextsState,
    mutations,
};
