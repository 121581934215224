import { GetRefreshTokenResult } from '../types';

/**
 * Gets the available contexts from the Auth Server
 */
export async function getAvailableContexts () {
    const response = await fetch('/api/auth/context', {
        method: 'GET',
    });
    if (!response.ok) {
        throw new Error(response.statusText);
    }
    return { contexts: (await response.json()).filter((c: any) => c.enabled) };
}

/**
 * Gets a refresh token from the Auth Server
 */
export async function getRefreshToken (): Promise<GetRefreshTokenResult> {
    const response = await fetch('/api/auth/token/refresh', {
        method: 'GET',
        credentials: 'include',
    });
    if (!response.ok) {
        throw new Error(response.statusText);
    }
    return response.json();
}

/**
 * Revokes a refresh token from the Auth Server
 */
export async function refreshTokenRevoke () {
    const response = await fetch('/api/auth/token/revoke', {
        method: 'POST',
        credentials: 'include',
    });
    if (!response.ok) {
        throw new Error(response.statusText);
    }
}

// Quick & Dirty
async function parseNetworkError (response: Response) {
    let errorResponse = null;
    try {
        errorResponse = await response.json();
        if (typeof errorResponse === 'object' && typeof errorResponse.message === 'string') {
            throw new Error(errorResponse.message);
        }
    } catch {
        throw new Error(response.statusText);
    }
    throw new Error(response.statusText);
}

/**
 * Logs into the Auth Service using a Local provider
 */
export async function providerLocalLogin ({ params = {} }) {
    const response = await fetch('/api/auth/provider/local/login', {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(params),
    });
    if (!response.ok) {
        await parseNetworkError(response);
    }
    return response.json();
}
