import { cellCfgAttrsBaseSchema_v3, cellCfgBaseSchema_v3 } from './cell-base.v3.zod';
import z from 'zod';
import { macroTypeEnum_v3 } from '../macros/base-macro.v3.zod';

const macroTargetCellAttrsSchema_v3 = cellCfgAttrsBaseSchema_v3.extend({
    macroId: z.string(),
    macroType: macroTypeEnum_v3,
    targetName: z.string(),
});

/**
 * defines a cell that is configured by the output of a macro
 */
export const macroTargetCellSchema_v3 = cellCfgBaseSchema_v3.extend({
    type: z.literal('macroTarget'),
    attrs: macroTargetCellAttrsSchema_v3,
});
