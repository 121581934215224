import { z } from 'zod';
import { pagedMacroCfgSchema_v3 } from './macros/paged.v3.zod';
import { processMacroCfgSchema_v3 } from './macros/process.v3.zod';
import { materialLookupCfgSchema_v3 } from './macros/material-lookup.v3.zod';

export const macroSchema_v3 = z.lazy(() => z.discriminatedUnion('type', [
    pagedMacroCfgSchema_v3,
    processMacroCfgSchema_v3,
    materialLookupCfgSchema_v3,
]));
