import { validateAcls } from '@redviking/argonaut-core-ui/src/auth';

export function validateContextSelectAcl (): boolean {
    // todo: change to check acl when it is created
    // return true;
    return validateAcls([ 'context_select' ]);
}

export function validateContextInsertAcl (): boolean {
    // todo: change to check acl when it is created
    // return true;
    return validateAcls([ 'context_insert' ]);
}

export function validateContextUpdateAcl (): boolean {
    // todo: change to check acl when it is created
    // return true;
    return validateAcls([ 'context_update' ]);
}

export function validateContextDeleteAcl (): boolean {
    // todo: change to check acl when it is created
    // return true;
    return validateAcls([ 'context_delete' ]);
}
