export const messages = {
    attrs: {
        addNew: 'Add New Attribute',
        attrCount: 'Total Attributes',
        confirmDelete: 'Are you sure you want to delete this attribute?',
        create: 'Create New Attribute',
        entityName: 'Attribute',
        hide: 'Hide Attribute',
        name: 'Attribute Name',
        saved: 'Attributes Saved!',
        search: 'Search Attributes',
    },
    generic: {
        preview: 'Preview',
        search: 'Search Name or Description',
        write: 'Write',
    },
    schemas: {
        addNew: 'Add New Schema',
        confirmDelete: 'Are you sure you want to delete this schema?',
        create: 'Create New Schema',
        dataSchema: 'Data Schema',
        entityName: 'Attribute Schema',
        hide: 'Hide Schema',
        name: 'Schema Name',
        saved: 'Attribute Schema Saved!',
        schema: 'Schema',
        search: 'Search Attribute Schemas',
        select: 'Select Schema',
        type: 'Schema Type',
        uiSchema: 'Ui Schema',
        validation: 'Validations',
        validationApiCheck: 'API Validation',
        validationMax: 'Max',
        validationMin: 'Min',
        validationPattern: 'Pattern',
        validationRegex: 'Validation Regex',
        validationRequired: 'Required',
    },
    tags: {
        addNew: 'Add New Tag',
        confirmDelete: 'Are you sure you want to delete this tag?',
        create: 'Create New Tag',
        delete: 'Delete Tag',
        name: 'Tag name',
        saved: 'Tag Saved!',
        search: 'Search Tag',
        select: 'Select Tags',
    },
    titles: {
        attrs: {
            attributeList: 'Attribute List',
            nav: 'Attributes',
            noName: 'Unnamed Attribute',
            pageBannerSubtitle: 'View and manage the list of attributes',
            pageBannerTitle: 'Attributes',
        },
        schemas: {
            nav: 'Schemas',
            newSchemaProp: 'New Schema Property',
            noName: 'Unnamed Schema',
            pageBannerSubtitle: 'View and manage the list of Schemas',
            pageBannerTitle: 'Attribute Schemas',
        },
        tags: {
            nav: 'Tags',
            noName: 'Unnamed Tag',
            pageBannerSubtitle: 'View and manage the list of tags',
            pageBannerTitle: 'Tags',
        },
    },
};

export const messagesMX = {
    attrs: {
        addNew: 'Agregar un Atributo Nuevo',
        confirmDelete: 'Están Seguro Que Quieren Eliminar Esto',
        create: 'Crear un Atributo Nuevo',
        hide: 'Esconder Atributo',
        name: 'Nombre del Atributo',
        saved: 'Atributos Salvado',
    },
    generic: {
        close: 'Cerrar',
        delete: 'Eliminar',
        description: 'Descripción',
        excludeHidden: 'Excluir Oculto',
        includeHidden: 'Incluir Oculto',
        name: 'Nombre',
        preview: 'Previsualizar',
        save: 'Salvar',
        search: 'Buscar Nombre o Descripción',
        write: 'Escribir',
    },
};
