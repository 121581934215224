import z from 'zod';
import { pageProviderSchema_v3 } from './var-providers/page.v3.zod';
import { sliceProviderSchema_v3 } from './var-providers/slice.v3.zod';
import { timerProviderSchema_v3 } from './var-providers/timer.v3.zod';
import { pagedProviderSchema_v3 } from './var-providers/paged.v3.zod';
import { scriptProviderSchema_v3 } from './var-providers/script.v3.zod';
import { lockingProviderSchema_v3 } from './var-providers/locking.v3.zod';
import { processProviderSchema_v3 } from './var-providers/process.v3.zod';
import { localProviderConfigSchema_v3 } from './var-providers/local.v3.zod';
import { calendarProviderSchema_v3 } from './var-providers/calendar.v3.zod';
import { formattedProviderSchema_v3 } from './var-providers/formatted.v3.zod';
import { sparkplugProviderSchema_v3 } from './var-providers/sparkplug.v3.zod';
import { macroVarpTargetSchema_v3 } from './var-providers/macro-target.v3.zod';
import { varStatusProviderSchema_v3 } from './var-providers/var-status.v3.zod';
import { materialIdProviderSchema_v3 } from './var-providers/material-id.v3.zod';
import { comparativeProviderSchema_v3 } from './var-providers/comparative.v3.zod';
import { createMaterialProviderSchema_v3 } from './var-providers/create-material.v3.zod';
import { mqttPublishProviderConfigSchema_v3 } from './var-providers/mqtt-publish.v3.zod';
import { mqttSubscribeProviderConfigSchema_v3 } from './var-providers/mqtt-subscribe.v3.zod';
import { sparkplugDeviceWriteProviderSchema_v3 } from './var-providers/sparkplug-device-write.v3.zod';

export const varpSchema_v3 = z.discriminatedUnion('type', [
    pageProviderSchema_v3,
    pagedProviderSchema_v3,
    timerProviderSchema_v3,
    sliceProviderSchema_v3,
    scriptProviderSchema_v3,
    processProviderSchema_v3,
    macroVarpTargetSchema_v3,
    lockingProviderSchema_v3,
    calendarProviderSchema_v3,
    varStatusProviderSchema_v3,
    sparkplugProviderSchema_v3,
    formattedProviderSchema_v3,
    materialIdProviderSchema_v3,
    comparativeProviderSchema_v3,
    localProviderConfigSchema_v3,
    createMaterialProviderSchema_v3,
    mqttPublishProviderConfigSchema_v3,
    mqttSubscribeProviderConfigSchema_v3,
    sparkplugDeviceWriteProviderSchema_v3,
]);

export const varpTypes_v3 = {
    page: pageProviderSchema_v3,
    paged: pagedProviderSchema_v3,
    timer: timerProviderSchema_v3,
    slice: sliceProviderSchema_v3,
    script: scriptProviderSchema_v3,
    process: processProviderSchema_v3,
    locking: lockingProviderSchema_v3,
    calendar: calendarProviderSchema_v3,
    local: localProviderConfigSchema_v3,
    varStatus: varStatusProviderSchema_v3,
    sparkplug: sparkplugProviderSchema_v3,
    formatted: formattedProviderSchema_v3,
    materialId: materialIdProviderSchema_v3,
    comparative: comparativeProviderSchema_v3,
    macroVarpTarget: macroVarpTargetSchema_v3,
    createMaterial: createMaterialProviderSchema_v3,
    mqttPublish: mqttPublishProviderConfigSchema_v3,
    mqttSubscribe: mqttSubscribeProviderConfigSchema_v3,
    sparkplugDeviceWrite: sparkplugDeviceWriteProviderSchema_v3,
};
