import { accessor } from '../store';
import { messages } from 'src/i18n/i18n';
import { RouterViewSlider } from '../components/RouterViewSlider';
import { RouteEntityDetail } from '@redviking/argonaut-core-ui/src/components/EntityDetail';
import { validateDeviceSelectAcl, validateDeviceUpdateAcl } from './device.validations';
import { entityDetailRoute } from 'src/components/EntityDetail/entity-route-util';

import { entityPageBannerTitle } from 'src/util/formatting';
import { DeviceAbility, deviceHasAbilities, getDeviceEntity, saveDeviceEntity } from './device.entity';

export default [
    {
        path: '/devices',
        name: 'deviceHardwareNavGroup',
        component: RouterViewSlider,
        redirect: { name: 'deviceList' },
        meta: {
            navigationGuard: () => validateDeviceSelectAcl(),
            navbarTitle: messages.device.titles.nav,
            navbarIcon: '$argo-device',
            transition: 'vertical',
        },
        children: [
            {
                name: 'deviceNavGrp',
                path: 'devices',
                component: RouterViewSlider,
                redirect: { name: 'deviceList' },
                meta: {
                    transition: 'horizontal',
                },
                children: [
                    {
                        name: 'deviceList',
                        path: '',
                        component: () => import(/* webpackChunkName: "device" */ './DevicesView.vue'),
                        meta: {
                            pageBannerTitle: messages.device.titles.pageBannerTitle,
                            pageBannerSubtitle: messages.device.titles.pageBannerSubtitle,
                        },
                        children: [
                        ],
                    },
                    entityDetailRoute<'device'>({
                        path: ':deviceId',
                        name: 'deviceMaintenence',
                        component: RouteEntityDetail,
                        redirect: to => ({ name: 'deviceAppList', params: to.params }),
                        meta: {
                            canEdit: validateDeviceUpdateAcl,
                            returnRoute: { name: 'deviceList' },
                            entityType: 'device',
                            pageBannerTitle: () => entityPageBannerTitle('device', accessor.entityAsType('device')?.name),
                            getEntity: getDeviceEntity,
                            saveEntity: saveDeviceEntity,
                        },
                        children: [
                            {
                                path: 'settings',
                                name: 'deviceAppList',
                                component: () => import(/* webpackChunkName: "device" */ './DeviceMaintenance.vue'), // a component for the settings page content
                                meta: {
                                    tab: { // the presence of this prop indicates this is a tab
                                        label: 'Device',
                                        icon: 'mdi-cog',
                                    },
                                    navigationGuard: () => true,
                                },
                            },
                            {
                                path: 'deviceSparkplugConfig',
                                name: 'deviceSparkplugConfig',
                                component: () => import(/* webpackChunkName: "device" */ './DeviceSparkplugNodeConfig.vue'), // a component for the settings page content
                                meta: {
                                    tab: { // the presence of this prop indicates this is a tab
                                        label: 'Sparkplug Config',
                                        icon: '$argo-dhl',
                                    },
                                    navigationGuard: () => deviceHasAbilities(accessor.entityAsType('device'), [ DeviceAbility.SPARKPLUG_NODE ]),
                                },
                            },
                            {
                                path: 'deviceLabelPrinterConfig',
                                name: 'deviceLabelPrinterConfig',
                                component: () => import(/* webpackChunkName: "device" */ './DeviceLabelPrinterConfig.vue'), // a component for the settings page content
                                meta: {
                                    tab: { // the presence of this prop indicates this is a tab
                                        label: 'Label Printer',
                                        icon: 'mdi-barcode',
                                    },
                                    navigationGuard: () => deviceHasAbilities(accessor.entityAsType('device'), [ DeviceAbility.LABEL_PRINTER ]),
                                },
                            },
                        ],
                    }),
                ],
            },
            // ...hardwareRoutes,
        ],
    },
];
