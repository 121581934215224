import { varConditionSchema_v3 as varConditionSchema } from '@redviking/argonaut-util/types/mes/applet-designs/v3/var-providers/conditional.v3.zod';
import {
    varOrConstSchema_v3 as varOrConstSchema,
    varpConfigBase_v3 as varpConfigBase,
} from '@redviking/argonaut-util/types/mes/applet-designs/v3/var-providers/var-provider.v3.zod';
import { z } from 'zod';
import { optionalVarNameSchema } from '../../var.zod';

export const udpSendProviderSchema = varpConfigBase.extend({
    type: z.literal('udpSend'),
    attrs: z.object({
        host: varOrConstSchema(z.string().min(1)),
        port: varOrConstSchema(z.number().min(1)),
        message: varOrConstSchema(z.string()),
        sentTsVar: optionalVarNameSchema,
        condition: varConditionSchema.refine(val => {
            return val.comparisons.length > 0;
        }, { message: 'condition must have at least one comparison' }),
    }),
});
export interface UdpSendProviderConfig extends z.infer<typeof udpSendProviderSchema> {}
