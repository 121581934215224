import {
    Ref,
} from 'vue';

export const replacerListUtils = {
    moveItem<T> (list: T[], oldIndex: number, newIndex: number) {
        const newList = list.slice();
        if (oldIndex === newIndex) {
            return newList;
        }
        const temp: T | undefined = newList[oldIndex];
        if (!temp) {
            throw new Error ('Invalid Old Index!');
        }
        newList.splice(oldIndex, 1);
        newList.splice(newIndex, 0, temp);

        return newList;
    },
    updateItem<T> (list: T[], itemVal: T, idx: number) {
        const newList = list.slice();
        newList[idx] = itemVal;
        return newList;
    },
    updateItems<T> (list: T[], itemVals: T[], startIdx: number) {
        const newList = list.slice();
        newList.splice(startIdx, itemVals.length, ...itemVals);
        return newList;
    },
    insertItem<T> (list: T[], newItem: T, idx: number = list.length) {
        const newList = list.slice();
        newList.splice(idx, 0, newItem);
        return newList;
    },
    insertItems<T> (list: T[], newItems: T[], idx: number = list.length) {
        return list.slice(0, idx).concat(newItems, list.slice(idx));
    },
    deleteItem<T> (list: T[], idx: number) {
        const newList = list.slice();
        newList.splice(idx, 1);
        return newList;
    },
};

export function useComputedList<T> (listRef: Ref<T[]>) {
    function updateItem (itemVal: T, idx: number) {
        listRef.value = replacerListUtils.updateItem(listRef.value, itemVal, idx);
    }

    function updateItems (itemVals: T[], startIdx: number) {
        listRef.value = replacerListUtils.updateItems(listRef.value, itemVals, startIdx);
    }

    function insertItem (newItem: T, idx?: number) {
        listRef.value = replacerListUtils.insertItem(listRef.value, newItem, idx);
    }

    function insertItems (newItems: T[], idx?: number) {
        listRef.value = replacerListUtils.insertItems(listRef.value, newItems, idx);
    }

    function deleteItem (idx: number) {
        listRef.value = replacerListUtils.deleteItem(listRef.value, idx);
    }

    return {
        updateItem,
        updateItems,
        insertItem,
        insertItems,
        deleteItem,
    };
}
