import {
    createTRPCProxyClient,
    httpBatchLink,
    loggerLink,
} from '@trpc/client';
import type { ApiRouter } from '@redviking/argonaut-api/src/routes/router';
import { accessor } from '@redviking/argonaut-core-ui/src/store/store';
import DebugModePlugin from 'src/vue-plugins/debug-mode';

const apiEndpoint = `${location.origin}/api/trpc`;
/**
 * This is intended for one-shot requests. Don't try to subscribe with it!
 */
export const apiTrpcHttpClient = createTRPCProxyClient<ApiRouter>({
    links: [
        loggerLink({
            enabled () {
                return DebugModePlugin.enabled;
            },
        }),
        httpBatchLink({
            url: apiEndpoint,
            headers () {
                return {
                    authorization: `Bearer ${accessor.auth.gqlToken}`,
                };
            },
        }),
    ],
});
