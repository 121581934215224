import {
    AddMaterialModelAttributeSettingsDocument,
    AddModelNumberDocument,
    ArgoMaterialModelInsertInput,
    GetModelNumberDocument,
    UpdateModelNumberDocument,
} from 'types/db';
import { accessor } from '../store';
import { RouterViewSlider } from '../components/RouterViewSlider';
import { RouteEntityDetail } from '@redviking/argonaut-core-ui/src/components/EntityDetail';
import { messages } from 'src/i18n/i18n';
import { validateMaterialModelSelectAcl, validateMaterialModelUpdateAcl } from './model-number.validations';
import {
    type EntityModifyParameters,
    type ExtendedEntityParameters,
    entityDetailRoute,
} from 'src/components/EntityDetail/entity-route-util';
import { gqlClient } from '@redviking/argonaut-core-ui/src/util/gql-client';
import { Notify } from 'src/notifications';
import { MaterialModelEntity, validateMaterialModelAttributeSettings } from './model-number.entity';

export default [
    {
        path: 'model-numbers',
        name: 'model-number',
        redirect: { name: 'modelNumberList' },
        component: RouterViewSlider,
        meta: {
            navigationGuard: () => validateMaterialModelSelectAcl(),
            // navbarTitle: 'Model Numbers',
            transition: 'horizontal',
        },
        children: [
            entityDetailRoute<'materialmodel'>({
                path: ':modelNumberId',
                name: 'modelNumberMaintenance',
                component: RouteEntityDetail,
                redirect: to => ({ name: 'modelNumberMaterials', params: to.params }),

                meta: {
                    entityType: 'materialmodel',
                    canEdit: validateMaterialModelUpdateAcl,
                    getEntity: async (to) => {
                        if (to.query.mode === 'create') {
                            const entityData: MaterialModelEntity = {
                                id: to.params.modelNumberId,
                                description: '',
                                model_number: 'Unnamed ModelNumber',
                                enabled: true,
                                created_by: accessor.auth.currentUser!,
                                material_class_id: to.query?.materialClassId as string,
                                material_class: {
                                    id: to.query?.materialClassId as string,
                                    name: '',
                                    material_class_attribute_settings: [],
                                }, // NOTE: This is not used anywhere
                                label_printer_device_id: '',
                                label_template_id: '', // NOTE: This is not used anywhere
                                meta: {}, // NOTE: This is not used anywhere
                                material_class_udf_schemas: [],
                                material_udf_default_data: [],
                                modelNumberAttributes: [],
                                materialModelAttributeSettings: [],
                                context_id: '', // NOTE: This is not used anywhere
                            };
                            return { entity: entityData };
                        }


                        const { modelNumber } = await gqlClient.request({
                            document: GetModelNumberDocument,
                            variables: {
                                modelNumberId: to.params.modelNumberId,
                            },
                        });
                        if (modelNumber) {
                            const ret: MaterialModelEntity = {
                                ...modelNumber,
                                created_at: modelNumber.created_at,
                                materialModelAttributeSettings: modelNumber.material_model_attribute_settings,
                            };
                            return {
                                entity: ret,
                            };
                        } else {
                            const err = new Error('Unknown Material Class');
                            Notify.error(err);
                            throw err;
                        }
                    },
                    saveEntity: async (payload: ExtendedEntityParameters<'materialmodel'>) => {
                        const {
                            entity,
                            oldEntity: originalOldEntity,
                        } = payload;
                        const modelIcon = entity.model_icon;

                        if (!entity.model_number || entity.model_number.length <= 0) {
                            const error = new Error('Model Number is required.');
                            Notify.error(error.message, error);
                            return;
                        }
                        try {

                            const attributeMutations = validateMaterialModelAttributeSettings(entity.id, entity.materialModelAttributeSettings, originalOldEntity?.materialModelAttributeSettings || []);

                            const mi = entity.model_icon;
                            await gqlClient.request({
                                document: UpdateModelNumberDocument,
                                variables: {
                                    modelNumberId: entity.id,
                                    modelNumber: {
                                        enabled: entity.enabled,
                                        description: entity.description,
                                        model_number: entity.model_number,
                                        sn_format_id: entity.sn_format_id,
                                        profile_file_id: entity.profile_file_id,
                                        label_printer_device_id: entity.label_printer_device_id || null,
                                        label_template_id: entity.label_template?.id || null,
                                        meta: entity.meta,
                                    },
                                    modelIcon: {
                                        content: mi?.content,
                                        preview: mi?.preview,
                                        content_subtype: mi?.content_subtype,
                                        content_type: mi?.content_type,
                                        preview_subtype: mi?.preview_subtype,
                                        preview_type: mi?.preview_type,
                                        storage: mi?.storage,
                                        id: mi?.id,
                                        url: mi?.url,
                                        name: `material-model-${entity.id}`,
                                        description: '',
                                    },
                                    updateImage: (modelIcon?.md5 || null) !== (originalOldEntity?.model_icon?.md5 || null),
                                    matModUdfDefaults: entity.material_udf_default_data.map(e => ({
                                        material_model_id: entity.id,
                                        udf_column_id: e.udf_column_id,
                                        str_val: e.str_val,
                                        num_val: e.num_val,
                                        id: e.id,
                                    })),
                                    addMaterialModelAttributeSettings: attributeMutations.inserts,
                                    updateMaterialModelAttributeSettings: attributeMutations.updates,
                                    deleteMaterialModelAttributeSettings: attributeMutations.deletes,
                                },
                            });
                            Notify.win(messages.modelNum.modelNumber.notifySaved);
                        } catch (err) {
                            Notify.error(err);
                            throw err;
                        }
                    },
                    createEntity: async (payload: EntityModifyParameters<'materialmodel'>) => {
                        const entity = payload.entity;
                        if (!entity.model_number || entity.model_number.length <= 0) {
                            const error = new Error('Model Number is required.');
                            Notify.error(error.message, error);
                            return;
                        }
                        try {
                            const modelNumber: ArgoMaterialModelInsertInput = {
                                enabled: entity.enabled,
                                description: entity.description,
                                material_class_id: entity.material_class_id,
                                model_number: entity.model_number,
                                sn_format_id: entity.sn_format_id,
                                material_udf_default_data: {
                                    data: entity.material_udf_default_data,
                                },
                                label_printer_device_id: entity.label_printer_device_id || null,
                                label_template_id: entity.label_template?.id || null,
                                meta: entity.meta,
                            };
                            if (entity.model_icon?.preview) {
                                modelNumber.model_icon = { data: { ...entity.model_icon, name: `material-model-${entity.id}` } };
                            }
                            await gqlClient.request({
                                document: AddModelNumberDocument,
                                variables: {
                                    modelNumber,
                                },
                            }).then(matModelData => gqlClient.request({
                                document: AddMaterialModelAttributeSettingsDocument,
                                variables: {
                                    addMaterialModelAttributeSettings: validateMaterialModelAttributeSettings(matModelData.modelNumberMutation?.id || '', entity.materialModelAttributeSettings, []).inserts,
                                },
                            }));
                            Notify.win(messages.modelNum.modelNumber.notifySaved);
                        } catch (err) {
                            Notify.error(err);
                            throw err;
                        }
                    },
                    pageBannerTitle: () => {
                        const modelNumber = accessor.entityAsType('materialmodel');
                        return modelNumber ? modelNumber.model_number : messages.modelNum.titles.noName;
                    },
                    pageBannerSubtitle: 'Model Number',
                },
                children: [
                    {
                        path: 'material-list',
                        name: 'modelNumberMaterials',
                        component: () => import(/* webpackChunkName: "material" */ '../model-number/ModelMaterialList.view.vue'), // a component for the settings page content
                        meta: {
                            tab: { // the presence of this prop indicates this is a tab
                                label: 'Materials',
                                icon: '$argo-material',
                            },
                            navigationGuard: validateMaterialModelUpdateAcl,
                        },
                    },
                    {
                        path: 'settings',
                        name: 'modelNumberSettings',
                        component: () => import(/* webpackChunkName: "material" */ '../model-number/ModelNumberMaintenance.view.vue'), // a component for the settings page content
                        meta: {
                            tab: { // the presence of this prop indicates this is a tab
                                label: 'Settings',
                                icon: 'mdi-cog',
                            },
                            navigationGuard: validateMaterialModelUpdateAcl,
                        },
                    },
                    {
                        path: 'material-model-attribute-settings',
                        name: 'materialModelAttributeSettings',
                        component: () => import(/* webpackChunkName: "material" */ '../model-number/MaterialModelAttributeSettings.view.vue'), // a component for the settings page content
                        meta: {
                            tab: { // the presence of this prop indicates this is a tab
                                label: 'Attribute Settings',
                                icon: 'mdi-animation-outline',
                            },
                            navigationGuard: validateMaterialModelSelectAcl,
                        },
                    },
                ],
            }),
        ],
    },
];
