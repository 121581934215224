import { z } from 'zod';
import { appletDesignVersionConfigSchema_v4, baseComparisonSchema_v4, baseOperandSchema_v4, cellCfgBaseSchema_v4, screenSchema_v4, varComparisonOperandSchema_v4, varConditionSchema_v4, varpTypes_v4 } from '../v4/appletDesign.v4.zod';
import { cellSchema_v5 } from './cells.v5.zod';
import { varpSchema_v5 } from './var-providers/var-providers.v5.zod';
import { udpSendProviderSchema } from './var-providers/udp-send.v5.zod';
import { udpReceiveProviderConfigSchema } from './var-providers/udp-receive.v5.zod';

const appletDesignScreenSchema_v5 = screenSchema_v4.omit({
    cells: true,
}).extend({
    cells: z.array(z.lazy(() => cellSchema_v5)),
});

export const appletDesignVersionConfigSchema_v5 = appletDesignVersionConfigSchema_v4.omit({
    screens: true,
    varProviders: true,
    schemaVersion: true,
}).extend({
    schemaVersion: z.literal(5),
    varProviders: z.array(varpSchema_v5),
    screens: z.array(appletDesignScreenSchema_v5),
});

export type AppletDesignVersionConfig_V5 = z.infer<typeof appletDesignVersionConfigSchema_v5>;

export const cellCfgBaseSchema_v5 = cellCfgBaseSchema_v4;

export const varConditionSchema_v5 = varConditionSchema_v4;

export const varpTypes_v5 = {
    ...varpTypes_v4,
    udpSend: udpSendProviderSchema,
    udpReceive: udpReceiveProviderConfigSchema,
};

export const varComparisonOperandSchema_v5 = varComparisonOperandSchema_v4;

export const baseOperandSchema_v5 = baseOperandSchema_v4;

export const baseComparisonSchema_v5 = baseComparisonSchema_v4;
