/* eslint-disable max-lines */
import { messages as fisMessages } from 'src/fis/fis.i18n';
import { messages as mesMessages } from 'src/mes/mes.i18n';
import { messages as udfMessages } from 'src/udf/udf.i18n';
import { messages as uomMessages } from 'src/uoms/uom.i18n';
import { messages as authMessages } from 'src/auth/auth.i18n';
import { messages as linkMessages } from 'src/links/link.i18n';
import { messages as fileMessages } from 'src/files/file.i18n';
import { messages as mediaMessages } from 'src/media/media.i18n';
import { messages as rolesMessages } from 'src/roles/roles.i18n';
import { messages as usersMessages } from 'src/users/users.i18n';
import { messages as deviceMessages } from 'src/device/device.i18n';
import { messages as contextMessages } from 'src/context/context.i18n';
import { messages as materialMessages } from 'src/material/material.i18n';
import { messages as scheduleMessages } from 'src/schedules/schedule.i18n';
import { messages as conditionMessages } from 'src/condition/condition.i18n';
import { messages as locationsMessages } from 'src/locations/locations.i18n';
import { messages as processesMessages } from 'src/processes/processes.i18n';
import { messages as attributesMessages } from 'src/attributes/attributes.i18n';
import { messages as dataElementMessages } from 'src/data-element/data-element.i18n';
import { messages as modelNumberMessages } from 'src/model-number/model-number.i18n';
import { messages as serialNumberMessages } from 'src/serial-numbers/serial-number.i18n';
import { messages as labelTemplateMessages } from 'src/label-template/label-template.i18n';
import { messages as materialClassMessages } from 'src/material-class/material-class.i18n';
import { messages as createMaterialMessages } from 'src/applet/design/varps/create-material/create-material.mes.i18n';
import { appletMessages } from 'src/applet/applet.i18n';
import type {
    AuthorLike,
    EntityType,
} from 'types/entity';

import { enMessages as enMessagesUtil } from '@redviking/argonaut-util/src/i18n/en.i18n';

const affirmations = [
    'Affirmative',
    'Go!',
    'OK',
    'Yes',
    'Proceed',
    'Ready',
];

const entityNames: {
    [e in EntityType]: string;
} = {
    appletDeployment: 'Applet Deployment',
    appletDesignVersion: 'Applet Design Version',
    assetType: 'Asset Type',
    attribute: 'Attribute',
    attributeschema: 'Attribute Schema',
    calendar: 'Calendar',
    condition: 'Condition',
    context: 'Context',
    dataelement: 'Data Element',
    device: 'Device',
    event: 'Event',
    labeltemplate: 'Label Template',
    link: 'Link',
    location: 'Location',
    material: 'Material',
    materialclass: 'Material Class',
    materialmodel: 'Model Number',
    process: 'Process',
    role: 'Role',
    schedule: 'Schedule',
    serialNumFmt: 'Serial Number Format',
    user: 'User',
};

const entityNamesPlural: {
    [e in EntityType]: string;
} = {
    appletDeployment: 'Applet Deployments',
    appletDesignVersion: 'Applet Design Versions',
    assetType: 'Asset Types',
    attribute: 'Attributes',
    attributeschema: 'Attribute Schemas',
    calendar: 'Calendars',
    condition: 'Conditions',
    context: 'Contexts',
    dataelement: 'Data Elements',
    device: 'Devices',
    event: 'Events',
    labeltemplate: 'Label Templates',
    link: 'Link',
    location: 'Locations',
    material: 'Materials',
    materialclass: 'Material Classes',
    materialmodel: 'Model Numbers',
    process: 'Processes',
    role: 'Roles',
    schedule: 'Schedules',
    serialNumFmt: 'Serial Number Formats',
    user: 'Users',
};

const externalEnMessages = {
    attribute: attributesMessages,
    auth: authMessages,
    condition: conditionMessages,
    context: contextMessages,
    createMaterial: createMaterialMessages,
    dataElement: dataElementMessages,
    device: deviceMessages,
    file: fileMessages,
    fis: fisMessages,
    labelTemplate: labelTemplateMessages,
    link: linkMessages,
    locations: locationsMessages,
    material: materialMessages,
    materialClass: materialClassMessages,
    media: mediaMessages,
    mes: mesMessages,
    modelNum: modelNumberMessages,
    processes: processesMessages,
    roles: rolesMessages,
    schedule: scheduleMessages,
    serialNum: serialNumberMessages,
    udf: udfMessages,
    uom: uomMessages,
    users: usersMessages,
};

const tsFormatters = enMessagesUtil.tsFormatters;

/**
 * Translations that can be used by multiple pages
 */
export const enMessages = {
    ...externalEnMessages,
    actions: 'Actions',
    activate: 'Activate',
    addNew: 'Add New',
    affirmation: () => affirmations[Math.floor(Math.random() * 1840) % affirmations.length],
    applet: appletMessages.en,
    apply: 'Apply',
    archive: 'Archive',
    attrSchemaProp: 'Attribute Schema Property',
    attributes: 'Attributes',
    audit: 'Audit',
    back: 'Back',
    baseType: 'Base Type',
    cancel: 'Cancel',
    changesDetected: 'Changes detected',
    close: 'Close',
    comparisonType: 'Comparison Type',
    copy: 'Copy',
    createdByUserOn: (values: { userText?: string, time?: string }) => {
        if (!values.userText && !values.time) {
            return '';
        }
        return `Created ${
            values.userText
                ? `by ${values.userText}`
                : ''
        } ${
            values.time
                ? `on ${values.time}`
                : ''
        }`;
    },
    createdOrUpdatedByUser<E extends Partial<{
        updatedAt: string | null,
        updated_at: string | null,
        createdAt: string | null,
        created_at: string | null,
        updatedBy: AuthorLike | null,
        updated_by: AuthorLike | null,
        createdBy: AuthorLike | null,
        created_by: AuthorLike | null,
    }>> (entity: E | null) {
        if (!entity) {
            return '';
        }
        const updatedAt = entity.updatedAt || entity.updated_at;
        const updatedBy = entity.updatedBy || entity.updated_by;
        const createdAt = entity.createdAt || entity.created_at;
        const createdBy = entity.createdBy || entity.created_by;
        if ((updatedAt || updatedBy) && createdAt !== updatedAt) {
            return this.updatedByUserOn({
                time: updatedAt ? this.tsFormatters.short.format(new Date(updatedAt)) : '',
                userText: this.userToString({ user: updatedBy }),
            });
        } else {
            return this.createdByUserOn({
                time: createdAt ? this.tsFormatters.short.format(new Date(createdAt)) : '',
                userText: this.userToString({ user: createdBy }),
            });
        }
    },
    cut: 'Cut',
    dateCreated: 'Date Created',
    defaultDisablePrompt: 'Are you sure you want to disable this?',
    defaultValue: 'Default Value',
    definition: 'Definition',
    delete: 'Delete',
    description: 'Description',
    disable: 'Disable',
    edit: 'Edit',
    editing: 'Editing',
    enabled: 'Enabled',
    entityNames,
    entityNamesPlural,
    example: 'Example',
    excludeHidden: 'Exclude Hidden',
    filters: 'Filters',
    generalSettings: 'General Settings',
    hidden: 'Hidden',
    hide: 'hide',
    includeHidden: 'Include Hidden',
    invalidConfig: 'Invalid configuration',
    max: 'Max',
    mediaSettings: 'Media Settings',
    meta: 'Metadata',
    min: 'Min',
    mode: 'Mode',
    modelNumber: 'Model Number',
    modifier: 'Modifier',
    name: 'Name',
    next: 'Next',
    none: '',
    notSelected: 'Not Selected',
    notes: 'Notes',
    notifications: 'Notifications',
    ok: 'OK',
    operationDescription: 'Operation Description',
    options: 'Additional Columns',
    pageNotFound: 'Page not found',
    pageNotFoundBody: 'Couldn\'t find the requested page',
    params: 'Parameters',
    preview: 'Preview',
    previous: 'Previous',
    regexpInput: {
        hint: 'A {regexpLink} matching pattern',
    },
    regularExpression: 'Regular Expression',
    remove: 'Remove',
    required: 'Required',
    reset: 'Reset',
    save: 'Save',
    saved: 'Saved',
    schema: 'Schema',
    search: 'Search',
    serialNumber: 'Serial Number',
    settings: 'Settings',
    status: 'Status',
    template: 'Template',
    tsFormatters,
    type: 'Type',
    updatedByUserOn (values: { userText?: string, time?: string }) {
        if (!values.userText && !values.time) {
            return '';
        }
        return `Last updated ${
            values.userText
                ? `by ${values.userText}`
                : ''
        } ${
            values.time
                ? `on ${values.time}`
                : ''
        }`;
    },
    userToString<U extends AuthorLike> (values: { user?: U | null }) {
        return enMessagesUtil.userToString(values);
    },
    username: 'Username',
    validation: 'Validation',
    value: 'Value',
    version: 'Version',
    viewTheDocs: 'View The Docs',
    viewing: 'Viewing',
    warning: 'Warning',
    welcomeUser (values: { currentUser: AuthorLike }) {
        return `Welcome, ${this.userToString({ user: values.currentUser })}`;
    },
    workOrderName: 'Work Order Name',
    workOrderNum: 'Work Order #',
};
