export const messages = {
    attributes: {
        pending: 'The following list of attributes will be applied to the material on creation',
        title: 'Link Attributes',
    },
    desc: 'Creates a material and provides the ID and serial number',
    general: {
        configMessage: '{getOrCreate} a Material if {conditionType} the following comparisons are satisfied...',
        createMaterial: 'Create',
        getMaterial: 'Get or create',
        title: 'General',
        toggleConditional: (current: boolean) => current
            ? 'Remove conditional'
            : 'Add conditional',
    },
    labelPrinter: {
        caption: 'Adds an entry to the label printer queue',
        labelPrint: 'Label Print',
        title: 'Label Printer',
    },
    labels: {
        addMaterialButton: 'Add Material',
        allowExistingMaterial: 'Get material if it already exists',
        attributes: 'Material Attributes',
        class: 'Material Class (optional)',
        defaultMaterial: 'Default Material',
        materialDesc: 'Specify requirements that are needed in order to create a material as well as the properties of that material. If none of the conditions are met for any of the given materials then no material will be created.',
        materialIdVar: 'Output varaible to hold the new material ID (optional)',
        materialInfo: 'Material To Create',
        models: {
            conditional: 'Create a material of model {materialModel} if {conditionType} the following comparisons are satisfied{dropDownBtn}{deleteBtn}',
            label: 'Target Material Model',
            title: 'Material Model',
        },
        removeMaterialButton: 'Remove Material',
    },
    locationItems: {
        config: 'this config\'s location',
        device: 'the app device location',
    },
    serialNumber: {
        direct: 'The selected material model does not have a serial number format. It must be provided manually, or by a variable',
        nonDirect: 'The SN of the selected material model is generated by the format:',
        nonDirectWarning: 'This create material provider is configured to "get or create" materials. However, this model uses a generated serial number format and thus cannot be fetched. If the condition for this material is true the create material provider will only attempt to create this material.',
    },
    title: 'Create Material',
    validationErrs: {
        required: {
            condition: 'The general condition to create a material cannot be blank',
            locationId: 'The location where the material is created is required',
            materialClassId: 'Material class is required',
            materialModelId: 'Material model is required',
            materialModelList: 'At least one material model definition is required',
            serialNumber: 'Serial number is required',
            snInput: (tokenName: string) => `SN format input token "${tokenName}" is required`,
            udf: (udfName: string) => `UDF input "${udfName}" is required`,
        },
    },
};
