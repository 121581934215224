import { textCellAttrsSchema_v3 } from './text.v3.zod';
import z from 'zod';
import { cellCfgBaseSchema_v3 } from './cell-base.v3.zod';

export const dateTimeCellSchema_v3 = cellCfgBaseSchema_v3.extend({
    type: z.literal('datetime'),
    attrs: textCellAttrsSchema_v3.omit({
        content: true,
    }).extend({
        format: z.enum([ 'date', 'time', 'datetime' ]),
    }),
});
