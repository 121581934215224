export const messages = {
    file: {
        addNew: 'Add New File',
        confirmHide: 'Are you sure you want to hide this File?',
        create: 'Create File',
        hide: 'Hide File',
        inspect: 'Inspect File',
        name: 'File Name',
        noneFound: 'No Files Found',
        notifySaved: 'File Saved!',
        search: 'Search Files by Name or Description',
    },
    titles: {
        file: {
            nav: 'Files',
            noName: 'Unnamed File',
            pageBannerSubtitle: 'Browse and Manage Files',
            pageBannerTitle: 'Files',
        },
    },
};
