import { ArgoMaterialClass } from '@redviking/argonaut-core-ui/types/db';
import { mutationTree } from 'typed-vuex';

export type MaterialClassStoreState = {
    materialClass: ArgoMaterialClass | null;
};

const materialClassStoreState: MaterialClassStoreState = {
    materialClass: null,
};

const mutations = mutationTree(materialClassStoreState, {
    setPageMaterialClass (state, materialClass: ArgoMaterialClass) {
        if (materialClass.__typename !== 'argo_material_class') {
            console.warn('Unknown Type passed into mutation');
        }
        state.materialClass = { ...state.materialClass, ...materialClass };
    },
    clearMaterialClass (state: MaterialClassStoreState): void {
        state.materialClass = null;
    },
});

export const MaterialClassStoreOpts = {
    state: materialClassStoreState,
    mutations,
};
export default MaterialClassStoreOpts;
