import { z } from 'zod';
import { varNameSchema } from '../../var.zod';
import { varConditionSchema_v3 } from './conditional.v3.zod';
import { localVarCfgSchema_v3 } from './local.v3.zod';
import { varpConfigBase_v3 } from './var-provider.v3.zod';

const customExecutionSchema_v3 = z.object({
    type: z.literal('custom'),
    condition: varConditionSchema_v3,
});

const defaultExecutionSchema_v3 = z.object({
    type: z.literal('default'),
});

export const scriptProviderAttrsSchema_v3 = z.object({
    /**
     * applet variables that are referenced by the script.
     * this can include outputs of the varp itself, and custom variables in another varp that are read or written to.
     */
    includedVars: z.array(z.object({
        /** whether the referenced variable can be tracked (is an input, not an output) */
        tracked: z.boolean(),
        name: z.string(),
    })),
    outputVars: z.array(localVarCfgSchema_v3),
    scriptId: z.string(),
    executeCfg: z.discriminatedUnion('type', [
        defaultExecutionSchema_v3,
        customExecutionSchema_v3,
    ]),
});

export const scriptProviderSchema_v3 = varpConfigBase_v3.extend({
    type: z.literal('script'),
    attrs: scriptProviderAttrsSchema_v3,
    inputs: z.array(varNameSchema),
});
