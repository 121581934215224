
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-alpine.css';
import '@ag-grid-community/styles/ag-theme-quartz.css';
import '@ag-grid-community/styles/ag-theme-material.css';
import 'src/styles/ag-grid-theme.css';

import { ModuleRegistry } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { StatusBarModule } from '@ag-grid-enterprise/status-bar';
import { MultiFilterModule } from '@ag-grid-enterprise/multi-filter';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { LicenseManager } from '@ag-grid-enterprise/core';
import { MasterDetailModule } from '@ag-grid-enterprise/master-detail';
import { InfiniteRowModelModule } from '@ag-grid-community/infinite-row-model';
import { RichSelectModule } from '@ag-grid-enterprise/rich-select';
import { GridChartsModule } from '@ag-grid-enterprise/charts';
import { ClipboardModule } from '@ag-grid-enterprise/clipboard';

LicenseManager.setLicenseKey('Using_this_AG_Grid_Enterprise_key_( AG-046759 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( RedViking )_is_granted_a_( Single Application )_Developer_License_for_the_application_( Argonaut )_only_for_( 2 )_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_( Argonaut )_need_to_be_licensed___( Argonaut )_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 13 October 2024 )____[v2]_MTcyODc3NDAwMDAwMA==7dd91a33eb853eb5179b019436a1552b');
ModuleRegistry.registerModules([
    ClipboardModule,
    ClientSideRowModelModule,
    StatusBarModule,
    ServerSideRowModelModule,
    ColumnsToolPanelModule,
    RowGroupingModule,
    MenuModule,
    FiltersToolPanelModule,
    MultiFilterModule,
    SetFilterModule,
    MasterDetailModule,
    InfiniteRowModelModule,
    RichSelectModule,
    GridChartsModule,
]);
