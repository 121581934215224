import { cellCfgAttrsBaseSchema_v3, cellCfgBaseSchema_v3 } from './cell-base.v3.zod';

import z from 'zod';

export const timerCellSchema_v3 = cellCfgBaseSchema_v3.extend({
    type: z.literal('timer'),
    attrs: cellCfgAttrsBaseSchema_v3.extend({
        format: z.enum([ 'hh:mm:ss', 'XXhXXmXXs' ]),
        /** name of the var that will drive the input */
        sourceVar: z.string(),
        /** supports css color values (`#RGB`, `#RGBA`, `rgb()`, `rgba()`) or vuetify text color classes */
        dialColor: z.string(),
        /** minimum percentage beyond which the timer will begin flashing */
        flashingThreshold: z.number(),
        /** maximum value that is allowed for the sourceVar to return */
        maxValue: z.number(),
    }),
    modifiers: z.record(z.enum([
        'dialColor',
        'maxValue',
        'flashingThreshold',

        'background',
        'borderColor',
    ]), z.string().optional()).optional(),
});
