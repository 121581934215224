import { z } from 'zod';
import { sparkplugAppletNodeCfgSchema_v3 } from '../util/sparkplug-node-cfg.v3.zod';
import { varpConfigBase_v3 } from './var-provider.v3.zod';

export const sparkplugProviderSchema_v3 = varpConfigBase_v3.extend({
    type: z.literal('sparkplug'),
    attrs: z.object({
      nodeCfg: sparkplugAppletNodeCfgSchema_v3,
      vars: z.object({
        varName: z.string(),
        metric: z.string(),
      }).array(),
    }),
  });
