import { z } from 'zod';
import { cellCfgAttrsBaseSchema_v3, cellCfgBaseSchema_v3 } from './cell-base.v3.zod';

export const pageCellSchema_v3 = cellCfgBaseSchema_v3.extend({
    attrs: cellCfgAttrsBaseSchema_v3.extend({
        pageMacroId: z.string(),
    }),
    order: z.number().optional(),
    type: z.literal('page'),
});

