import { mutationTree } from 'typed-vuex';
import { ArgoCalendarEvent, ArgoEvent, ArgoEventType, ArgoScheduleDay, ArgoScheduleEvent, ScheduleFragment } from '@redviking/argonaut-core-ui/types/db';


const schedulesState = {
    scheduleDay: null as ArgoScheduleDay | null,
    event: null as ArgoEvent | null,
    calendarEvent: null as ArgoCalendarEvent | null,
    scheduleType: null as ArgoEventType | null,
    templateTimes: null as ArgoScheduleEvent[] | null,
    schedule: null as ScheduleFragment | null,
};

const mutations = mutationTree(schedulesState, {

    setPageSchedule (state, schedule: ScheduleFragment) {
        if (schedule.__typename !== 'argo_schedule') {
            console.warn('Unknown Type passed into mutation');
        }
        state.schedule = { ...state.schedule, ...schedule };
    },
    clearSchedule (state): void {
        state.schedule = null;
    },
    setPageScheduleDay (state, scheduleDay: ArgoScheduleDay) {
        if (scheduleDay.__typename !== 'argo_schedule_day') {
            console.warn('Unknown Type passed into mutation');
        }
        state.scheduleDay = { ...state.scheduleDay, ...scheduleDay };
    },
    clearScheduleDay (state): void {
        state.scheduleDay = null;
    },
    setPageEvent (state, event: ArgoEvent) {
        if (event.__typename !== 'argo_event') {
            console.warn('Unknown Type passed into mutation');
        }
        state.event = { ...state.event, ...event };
    },
    clearEvent (state): void {
        state.event = null;
    },
    setPageCalendarEvent (state, calendarEvent: ArgoCalendarEvent) {
        if (calendarEvent.__typename !== 'argo_calendar_event') {
            console.warn('Unknown Type passed into mutation');
        }
        state.calendarEvent = { ...state.calendarEvent, ...calendarEvent };
    },
    clearCalendarEvent (state): void {
        state.calendarEvent = null;
    },
    setPageEventType (state, scheduleType: ArgoEventType) {
        if (scheduleType.__typename !== 'argo_event_type') {
            console.warn('Unknown Type passed into mutation');
        }
        state.scheduleType = { ...state.scheduleType, ...scheduleType };
    },
    clearEventType (state): void {
        state.scheduleType = null;
    },
    setPageScheduleTimes (state, templateTimes: ArgoScheduleEvent[]) {
        if (templateTimes[0].__typename !== 'argo_schedule_event') {
            console.warn('Unknown Type passed into mutation');
        }
        state.templateTimes = { ...state.templateTimes, ...templateTimes };
    },
});

export default {
    state: schedulesState,
    mutations,
};
