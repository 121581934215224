import { validateAcls } from 'src/auth';
import { RouteEntityDetail } from 'src/components/EntityDetail';
import { entityDetailRoute } from 'src/components/EntityDetail/entity-route-util';
import RouterViewSlider from 'src/components/RouterViewSlider';
import { accessor } from 'src/store';
import { createdOrUpdatedByUser } from 'src/util';
import { messages } from 'src/i18n/i18n';
import { validateSpecificationSelectAcl } from './processes.validations';
import _isEqual from 'lodash.isequal';
import { fetchProcessEntity, saveProcessEntity } from './process.entity';

export const processRoutes = [
    {
        name: 'process-config-nav',
        path: 'processes',
        redirect: { name: 'process-list' },
        meta: {
            navigationGuard: () => (validateAcls([ 'process_select' ]) || validateSpecificationSelectAcl()),
            navbarTitle: 'Processes',
            navbarIcon: 'mdi-order-bool-descending-variant',
            transition: 'vertical',
        },
        component: RouterViewSlider,
        children: [
            {
                name: 'processes-nav',
                path: '',
                redirect: { name: 'process-list' },
                navigationGuard: () => validateAcls([ 'process_select' ]),
                meta: {
                    transition: 'horizontal',
                },
                component: RouterViewSlider,
                children: [
                    {
                        name: 'process-list',
                        path: 'list',
                        component: () => import(/* webpackChunkName: "process" */ './ProcessList.view.vue'),
                        meta: {
                            pageBannerTitle: messages.processes.process.processes,
                        },
                    },
                    entityDetailRoute<'process'>({
                        name: 'process',
                        path: ':processId',
                        component: RouteEntityDetail,
                        redirect: to => ({ name: 'process-general', params: to.params, query: to.query }),
                        meta: {
                            transition: 'horizontal',
                            entityType: 'process',
                            getEntity: fetchProcessEntity,
                            saveEntity: saveProcessEntity,
                            canEdit: () => true, // accessor.entityAsType('process')?.status !== ArgoProcessRevStatusEnum.Archived,
                            pageBannerTitle: () => 'Process', // entityPageBannerTitle('process', accessor.entityAsType('process')?.process?.name, { nameOnly: true }),
                            pageBannerSubtitle: () => createdOrUpdatedByUser(accessor.entityAsType('process')),
                            pageBannerIcon: '$argo-process',
                            hasChanges: () => {
                                return !_isEqual({
                                    ...accessor.entityAsType('process'),
                                    status: null,
                                }, {
                                    ...accessor.originalEntityAsType('process'),
                                    status: null,
                                });
                            },
                            returnRoute: { name: 'process-list' },
                            routeParamsForFetch: route => ({ processId: route.params.processId }),
                        },
                        children: [
                            {
                                path: 'general',
                                name: 'process-general',
                                component: () => import(/* webpackChunkName: "process" */ './ProcessGeneral.view.vue'),
                                meta: {
                                    tab: {
                                        label: messages.generalSettings,
                                        icon: 'mdi-cog',
                                    },
                                },
                            },
                            {
                                path: 'specs',
                                name: 'process-specs',
                                component: () => import(/* webpackChunkName: "process" */ './ProcessSpecs.view.vue'),
                                meta: {
                                    tab: { label: messages.processes.tab.specs, icon: 'mdi-set-square' },
                                },
                            },
                            {
                                path: 'params',
                                name: 'process-params',
                                component: () => import(/* webpackChunkName: "process" */ './ProcessParams.view.vue'),
                                meta: {
                                    tab: {
                                        label: messages.processes.tab.params,
                                        icon: 'mdi-import',
                                    },
                                },
                            },
                            {
                                path: 'validations',
                                name: 'process-validations',
                                component: () => import(/* webpackChunkName: "process" */ './ProcessValidations.view.vue'),
                                meta: {
                                    tab: {
                                        label: 'Validations',
                                        icon: 'mdi-check-circle',
                                    },
                                },
                            },
                            {
                                path: 'media',
                                name: 'process-media',
                                component: () => import(/* webpackChunkName: "process" */ './ProcessMedia.view.vue'),
                                meta: {
                                    tab: {
                                        label: messages.processes.tab.media,
                                        icon: 'mdi-image',
                                    },
                                },
                            },
                        ],
                    }),
                    {
                        name: 'process-report',
                        path: ':processId/report',
                        component: () => import(/* webpackChunkName: "process" */ './ProcessReport.view.vue'),
                        meta: {
                            pageBannerTitle: 'Process Report',
                        },
                    },
                ],
            },
        ],
    },
];
