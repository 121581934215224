import Vue, { PluginFunction } from 'vue';

let currentDebugMode = localStorage.getItem('debug-mode');
if (currentDebugMode === 'on') {
    Vue.config.devtools = true;
}

export const DebugModePlugin = {
    name: 'debug-mode-plugin',
    enabled: currentDebugMode === 'on',
    install: ((vue: Parameters<PluginFunction<never>>[0] & { _installedPlugins: PluginFunction<unknown>[] }) => {
        if (vue._installedPlugins && vue._installedPlugins.length > 0 && vue._installedPlugins.some(fn => fn.name === DebugModePlugin.name)) {
            return; // already installed
        }


        let firstComponent: Vue;
        function logDebugMode () {
            console.log('debug mode is activated', {
                [firstComponent.$options.name as string]: firstComponent,
            });
        }

        // capture the first named component to be instantiated
        vue.mixin({
            created (this: Vue) {
                if (!firstComponent && this.$options.name) {
                    // eslint-disable-next-line consistent-this
                    firstComponent = this;
                    if (currentDebugMode === 'on') {
                        logDebugMode();
                    }
                }
            },
        });

        // lovingly ripped from https://github.com/azzra/vue-konami-code/blob/35b2697236f85394adec339009e127ac02fdc5a2/src/vue-konami-code.js
        const kode = [ 38, 38, 40, 40, 37, 39, 37, 39, 66, 65 ];
        let pos = 0;
        document.addEventListener('keydown', event => {
            if (event.keyCode === kode[pos]) {
                pos++;
                if (pos === kode.length) { // entire code entered
                    if (currentDebugMode === 'on') {
                        currentDebugMode = 'off';
                        console.log('debug mode deactivated');
                    } else {
                        currentDebugMode = 'on';
                        logDebugMode();
                    }
                    localStorage.setItem('debug-mode', currentDebugMode);
                    DebugModePlugin.enabled = currentDebugMode === 'on';
                    pos = 0; // ability to start over
                }
            } else {
                pos = 0;
            }
        }, false);
    }) as PluginFunction<never>,
};

export default DebugModePlugin;
