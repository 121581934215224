import attributesRoutes from '../attributes/attributes.routes';
import homeRoutes from '../home/home.routes';
import locationsRoutes from '../locations/locations.routes';
import usersRoutes from '../users/users.routes';
import rolesRoutes from '../roles/roles.routes';
import { RouterViewSlider } from '../components/RouterViewSlider';
import contextRoutes from '../context/context.routes';
import linkRoutes from '../links/link.routes';
import fileRoutes from '../files/file.routes';
import materialRoutes from '../material/material.routes';
import scheduleRoutes from '../schedules/schedule.routes';
import deviceRoutes from '../device/device.routes';
import { authInitialized, validateAcls } from '../auth';
import { processRoutes } from 'src/processes/processes.routes';
import { validateUserSelectAcl } from 'src/users/user.validations';
import { validateContextSelectAcl } from 'src/context/context.validations';
import { validateRoleSelectAcl } from 'src/roles/roles.validations';
import { serialNumberRoutes } from 'src/serial-numbers/serial-number.routes';
import fisRoutes from 'src/fis/fis.routes';
import { RouteConfig } from 'vue-router';
import { accessor } from 'src/store';
import { appletRoutes } from 'src/applet/applet.routes';

// collect and merge routes from core pages
export const routes: RouteConfig[] = [
    {
        path: '/',
        component: () => import(/* webpackChunkName: "main" */ '../DefaultLayout.vue'),
        name: 'main',
        redirect: { name: 'home' },
        meta: {
            transition: 'vertical',
        },
        beforeEnter (to, from, next) {
            authInitialized.then(() => { // TODO cancel if auth fails, but should probably allow `home`
                next();
            });
        },
        children: [
            ...homeRoutes,
            ...appletRoutes,
            ...attributesRoutes,
            ...deviceRoutes,
            ...locationsRoutes,
            ...serialNumberRoutes,
            ...materialRoutes,
            ...processRoutes,
            ...scheduleRoutes,
            ...fisRoutes,
            {
                name: 'adminNavGrp',
                path: 'admin',
                redirect: { name: 'contextList' },
                component: RouterViewSlider,
                meta: {
                    navbarGroupOnly: true,
                    navigationGuard: () => validateUserSelectAcl() || validateContextSelectAcl() || validateRoleSelectAcl(),
                    navbarTitle: 'Admin',
                    navbarIcon: 'mdi-shield-star-outline',
                    transition: 'vertical',
                },
                children: [
                    ...contextRoutes,
                    ...rolesRoutes,
                    ...usersRoutes,
                    ...fileRoutes,
                    {
                        name: 'apiPanel',
                        path: '/redirect-api-panel',
                        redirect () {
                            return '/api/trpc/panel';
                        },
                        meta: {
                            navbarIcon: 'mdi-engine',
                            navbarTitle: 'API Panel',
                            navigationGuard: () => validateAcls([ 'k8s_dashboard_ro' ]),
                            onClick () {
                                window.open('/api/trpc/panel', '_blank');
                            },
                        },
                    },
                    {
                        name: 'clusterDashboard',
                        path: '/k8s-dashboard',
                        meta: {
                            navbarIcon: 'mdi-kubernetes',
                            navbarTitle: 'Cluster Dashboard',
                            navigationGuard: () => accessor.k8sChartNamespace && validateAcls([ 'k8s_dashboard_ro' ]),
                            onClick () {
                                location.href = `/k8s-dashboard/#/workloads?namespace=${accessor.k8sChartNamespace}`;
                            },
                        },
                    },
                    {
                        name: 'gqlPlayground',
                        path: '/gql-playground',
                        meta: {
                            navbarIcon: 'mdi-graphql',
                            navbarTitle: 'GQL Playground',
                            pageBannerTitle: 'GQL',
                            pageBannerSubtitle: 'Playground',
                            navigationGuard: () => validateAcls([ 'gql_playground_visible' ]),
                        },
                        component: () => import(/* webpackChunkName: "main" */ '../components/GqlPlayground/GqlPlayground.vue'),
                    },
                ],
            },
            ...linkRoutes,
            {
                name: 'helpLink',
                path: '/docs',
                meta: {
                    navbarIcon: 'mdi-help-box',
                    navbarTitle: 'Documentation',
                    onClick: () => location.href = '/docs/',
                },
            },
        ],
    },
    {
        path: '/applet-runtime-inspect/:runtimeId/:runnerId/:versionId',
        name: 'AppletInspection',
        component: () => import(/* webpackChunkName: "main" */ '../applet/inspection/AppletInspection.vue'),
    },
];
