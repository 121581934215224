import {
    Ref,
    WritableComputedRef,
    computed,
} from 'vue';

/**
 * converts a date to [datetime-local format](https://developer.mozilla.org/en-US/docs/Web/HTML/Date_and_time_formats#local_date_and_time_strings) with a `' '` instead of `'T'` (for consistency with postgres ts range)
 */
export function toDatetimeLocalFormat (inp: Date) {
    return `${inp.getFullYear()}-${String(inp.getMonth() + 1).padStart(2, '0')}-${String(inp.getDate()).padStart(2, '0')} ${String(inp.getHours()).padStart(2, '0')}:${String(inp.getMinutes()).padStart(2, '0')}`;
}

/**
 * Converts a date value to a string formatted for use in an [`<input type="datetime-local"/>`](https://developer.mozilla.org/en-US/docs/Web/HTML/Date_and_time_formats#local_date_and_time_strings) or v-text-field
 */
export function useDatetimeLocal (dateRef: Ref<Date | null>): WritableComputedRef<string | null> {
    return computed({
        get: () => {
            if (dateRef.value === null) {
                return null;
            }
            return toDatetimeLocalFormat(dateRef.value);
        },
        set: v => {
            if (v === null) {
                dateRef.value = null;
            } else {
                dateRef.value = new Date(v);
            }
        },
    });
}
