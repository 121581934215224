import z from 'zod';
import { cellCfgAttrsBaseSchema_v3, cellCfgBaseSchema_v3 } from '../../v3/cell-types/cell-base.v3.zod';
import { varConditionSchema_v3 } from '../../v3/var-providers/conditional.v3.zod';

export const circularProgressCellSchema_v5 = cellCfgBaseSchema_v3.extend({
    type: z.literal('circularProgress'),
    attrs: cellCfgAttrsBaseSchema_v3.extend({
        minValue: z.number(),
        maxValue: z.number(),
        innerText: z.string(),
        dialColor: z.string(),
        sourceVar: z.string(),
        flashingCondition: varConditionSchema_v3.nullable().optional(),
    }),
    modifiers: z.record(z.enum([
        'minValue',
        'maxValue',
        'innerText',
        'dialColor',
        'background',
        'borderColor',
    ]), z.string().optional()).optional(),
});
