import { validateAcls } from '@redviking/argonaut-core-ui/src/auth';

export function validateMaterialClassSelectAcl (): boolean {
    // todo: change to check acl when it is created
    // return true;
    return validateAcls([ 'material_class_select' ]);
}

export function validateMaterialClassInsertAcl (): boolean {
    // todo: change to check acl when it is created
    // return false;
    return validateAcls([ 'material_class_insert' ]);
}

export function validateMaterialClassUpdateAcl (): boolean {
    // todo: change to check acl when it is created
    // return false;
    return validateAcls([ 'material_class_update' ]);
}

export function validateMaterialClassDeleteAcl (): boolean {
    // todo: change to check acl when it is created
    // return false;
    return validateAcls([ 'material_class_delete' ]);
}

export function validateMaterialClassFileSelectAcl (): boolean {
    return validateAcls([ 'material_class_file_select' ]) || validateAcls([ 'material_class_select' ]);
}

export function validateMaterialClassFileInsertAcl (): boolean {
    return validateAcls([ 'material_class_file_insert' ]) || validateAcls([ 'material_class_insert' ]);
}

export function validateMaterialClassFileUpdateAcl (): boolean {
    return validateAcls([ 'material_class_file_update' ]) || validateAcls([ 'material_class_update' ]);
}

export function validateMaterialClassFileDeleteAcl (): boolean {
    return validateAcls([ 'material_class_file_delete' ]) || validateAcls([ 'material_class_delete' ]);
}
