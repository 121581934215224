import { z } from 'zod';
import { appletDesignVersionConfigSchema_v3 } from './v3/applet-design.v3.zod';
import { appletDesignVersionConfigSchema_v4 } from './v4/appletDesign.v4.zod';
import { appletDesignVersionConfigSchema_v5 } from './v5/appletDesign.v5.zod';

export const appletDesignSchema = z.discriminatedUnion('schemaVersion', [
    appletDesignVersionConfigSchema_v3,
    appletDesignVersionConfigSchema_v4,
    appletDesignVersionConfigSchema_v5,
]);

export type AppletDesignConfig = z.infer<typeof appletDesignSchema>;
