import { z } from 'zod';
import { SchemaHookKind, parseWithHookCfg, schemaWithHook } from '../schemaWithHook';
import type { LocationDeployParam } from '@redviking/argonaut-util/types/mes/location.deployParam';
import { AppletDesignConfig } from '../../types/mes/applet-designs/applet-design-config.zod';
import { type Latest } from '@redviking/argonaut-util/types/mes/applet-designs/appletDesign.latest.zod';
import { varValSchema } from '../../types/mes/applet-designs/var.zod';

export enum DeployParamKind {
    localVarValue = 'localVarValue',
    location = 'location',
    sparkplugNodeCfg = 'sparkplugNodeCfg',
}

const deployParamValueBase = z.object({
    kind: z.nativeEnum(DeployParamKind),
    val: z.unknown(),
});

/** keyed by deployParamId */
export const deployParamValuesSchema = z.record(z.discriminatedUnion('kind', [
    deployParamValueBase.extend({
        kind: z.literal(DeployParamKind.localVarValue),
        val: varValSchema,
    }),
    deployParamValueBase.extend({
        kind: z.literal(DeployParamKind.location),
        val: z.string().uuid(),
    }),
    // TODO: THIS DEVICE STRING NEEDS TO BE AN OBJECT
    deployParamValueBase.extend({
        kind: z.literal(DeployParamKind.sparkplugNodeCfg),
        val: z.object({
            nodeId: z.string(),
            version: z.string(),
            groupId: z.string(),
            deviceId: z.string(),
        }),
    }),
]));

export type DeployParamValue = z.infer<typeof deployParamValuesSchema>[string];

/**
 * creates a schema that can be captured by `getDeployParams`
 */
export function deployParamSchema<SCHEMA extends z.ZodType<{ deployParamKind: DeployParamKind }>> (mySchema: SCHEMA): SCHEMA {
    return schemaWithHook({
        schema: mySchema,
        hook: SchemaHookKind.DeployParam,
    });
}

export type DeployParam = Latest.VarProviders.Local.VarCfgDeployParam | LocationDeployParam | Latest.VarProviders.Sparkplug.AppletNodeCfgDeployParam;

/**
 * this function exists because some deploy param kinds have a deployParamId that is derived
 */
export function getDeployParamId (paramCfg: DeployParam): string {
    if (paramCfg.deployParamKind === DeployParamKind.localVarValue) {
        return `${paramCfg.name} - Initial Value`;
    }
    if (paramCfg.deployParamKind === DeployParamKind.location) {
        return paramCfg.deployParamId;
    }
    if (paramCfg.deployParamKind === DeployParamKind.sparkplugNodeCfg) {
        return paramCfg.deployParamId;
    }
    // @ts-expect-error - ensures cases are implemented
    const unknownDeployParamKind: string = paramCfg.deployParamKind;
    throw new Error(`unknown deploy param kind: ${unknownDeployParamKind}`);
}

export function getDeployParams (cfg: AppletDesignConfig, schema: z.ZodType) {
    const deployParams: (DeployParam & { deployParamId: string })[] = [];
    parseWithHookCfg({
        schema,
        hookCfg: {
            [SchemaHookKind.DeployParam]: deployParam => {
                // only add the deploy param if it's not already in the list
                const deployParamId = getDeployParamId(deployParam);
                if (!deployParams.some(dp => dp.deployParamId === deployParamId && dp.deployParamKind === deployParam.deployParamKind)) {
                    deployParams.push({
                        ...deployParam,
                        deployParamId: getDeployParamId(deployParam),
                    });
                }
                return deployParam;
            },
        },
    }, cfg);
    return deployParams;
}
