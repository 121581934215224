import { EntityType, EntityTypeMap } from '@redviking/argonaut-core-ui/types';
import { getterTree, mutationTree } from 'typed-vuex';
import _cloneDeep from 'lodash.clonedeep';

export type StoredEntityMap = {
    [ entityType in EntityType ]: {
        type: entityType;
        entity: EntityTypeMap[entityType];
    }
};


const entityDetailsState = {
    originalEntity: null as { type: string, entity: unknown } | null,
    // must be unknown or else type discrimination becomes too complex
    entity: null as { type: string, entity: unknown } | null,
};

const mutations = mutationTree(entityDetailsState, {
    initializeMaintenance (state, payload: { type: string, entity: unknown, originalEntity?: unknown }) {
        state.originalEntity = {
            type: payload.type,
            entity: payload.originalEntity || _cloneDeep(payload.entity),
        };
        state.entity = {
            type: payload.type,
            entity: payload.entity,
        };
    },
    setPageEntity<T extends EntityType> (state: typeof entityDetailsState, entity: { type: T, entity: EntityTypeMap[T] }) {
        state.entity = entity;
    },
    clearEntity (state): void {
        state.entity = null;
    },
});

const getters = getterTree(entityDetailsState, {
    entityAsType: state => <T extends EntityType>(type: T): EntityTypeMap[T] | null => {
        if (state.entity?.type === type) {
            return state.entity.entity as EntityTypeMap[T];
        }
        return null;
    },
    originalEntityAsType: state => <T extends EntityType>(type: T): EntityTypeMap[T] | null => {
        if (state.originalEntity?.type === type) {
            return state.originalEntity.entity as EntityTypeMap[T];
        }
        return null;
    },
});

export default {
    state: entityDetailsState,
    mutations,
    getters,
};
