import { validateAcls } from '@redviking/argonaut-core-ui/src/auth';

export function validateDeviceSelectAcl (): boolean {
    return validateAcls([ 'device_select' ]);
}

export function validateDeviceInsertAcl (): boolean {
    return validateAcls([ 'device_insert' ]);
}

export function validateDeviceUpdateAcl (): boolean {
    return validateAcls([ 'device_update' ]);
}

export function validateDeviceDeleteAcl (): boolean {
    return validateAcls([ 'device_delete' ]);
}
