
import { computed, defineComponent, watch } from 'vue';
import { accessor } from '@redviking/argonaut-core-ui/src/store';
import type { FeatureFlags } from '@redviking/argonaut-core-ui/src/routing/router.store';
import { onClientErrorFallback } from './util/gql-client';
import { Notify } from './notifications';

export default defineComponent({
    name: 'App',
    setup () {
        // test if k8s dashboard is available
        watch(computed(() => accessor.auth.currentUser), () => {
            fetch('/k8s-dashboard/').then(response => {
                const chartNamespace = response.headers.get('chart-namespace') || '';
                accessor.setChartNamespace(chartNamespace);
            });
        });

        fetch('/feature-flags.json').then<FeatureFlags>(response => response.json()).then(featureFlags => {
            accessor.setFeatureFlags(featureFlags);
        })
        .catch(err => {
            console.error('Failed to fetch feature flags', err);
        });

        onClientErrorFallback((error) => {
            Notify.error(error);
        });
    },
    async created () {
        // A "silent" refresh in case already authenticated
        await accessor.auth.refreshToken();
    },
});

