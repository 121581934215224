import '@mdi/font/css/materialdesignicons.css';
import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import { DebugModePlugin } from './debug-mode';
import PortalVue from 'portal-vue';
import './ag-grid';
import './cropper';
import Interpolate from 'src/components/Interpolate/Interpolate.vue';
import { messages } from 'src/i18n/i18n';

Vue.use(Vuetify);
Vue.use(DebugModePlugin);
Vue.use(PortalVue);

Vue.component('Interpolate', Interpolate);
Vue.prototype.$messages = messages; // make messages available in any template

export * from './vuetify';

if (import.meta.webpackHot) {
    import.meta.webpackHot.accept('src/i18n/i18n', () => {
        Vue.prototype.$messages = messages;
    });
}
