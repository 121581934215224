export const messages = {
    condition: {
        addNew: 'Add New Condition',
        confirmDelete: 'Are you sure you want to delete this condition?',
        create: 'Create Condition',
        entityName: 'Condition',
        hide: 'Hide Condition',
        inspect: 'Inspect Condition',
        modifier: 'Modifier',
        modifierDescription: 'This is how your selection will be compared',
        modifierSelector: 'Modifier {icon}',
        noneFound: 'No Conditions Found',
        saved: 'Condition Saved',
        search: 'Search Conditions by Name or Description',
        type: 'Condition Type',
    },
    titles: {
        nav: 'Conditions',
        noName: 'Unnamed Condition',
        pageBannerSubtitle: 'View Condition',
        pageBannerTitle: 'Conditions',
    },
};
