import { FisCoreStateEnum } from '@redviking/argonaut-util/types/db';
import colors from 'vuetify/lib/util/colors';

export const CORE_STATE_DATA_LABEL_ID = '74127943-ce8a-47c0-89fd-c65d174072f2';
export const coreStates: {
    [key in FisCoreStateEnum]: {
        color: string;
    };
} = {
    fault: { color: String(colors.red.base) },
    none: { color: String(colors.shades.white) },
    blocked: { color: String(colors.cyan.base) },
    cycling: { color: String(colors.green.base) },
    starved: { color: String(colors.yellow.base) },
    no_comm: { color: String(colors.grey.lighten1) },
    bypassed: { color: String(colors.purple.lighten1) },
    down_external: { color: String(colors.red.lighten3) },
    blocked_secondary: { color: String(colors.cyan.darken2) },
    starved_secondary: { color: String(colors.yellow.darken2) },
    manual_intervention: { color: String(colors.amber.darken4) },
};
