import {
    UserFragment,
    UserMaintenanceFragment,
} from 'types/db';

export type UserEntity = Pick<UserFragment, 'username' | 'description' | 'firstName' | 'lastName' | 'enabled' | 'id'> & {
    userContexts: { id: string, context_id: string }[];
    userRoles: { id: string, context_id: string, role_id: string }[];
    localAuth?: { id: string, email: string, password: string, currentPassword: string };
    badgeAuth?: { id: string, badge: string };
    userAccessTokens?: { id: string, acls: string[], name: string, created_at?: string }[];
    createdAt?: string;
    createdBy: Pick<UserFragment, 'firstName' | 'lastName' | 'username'> | null;
    updatedAt?: string;
    updatedBy: Pick<UserFragment, 'firstName' | 'lastName' | 'username'> | null;
};

export function userFragmentToEntityType (userFragment: UserMaintenanceFragment): UserEntity {
    return {
        description: userFragment.description,
        enabled: userFragment.enabled,
        firstName: userFragment.firstName,
        id: userFragment.id,
        lastName: userFragment.lastName,
        userContexts: userFragment.user_contexts.map(uc => ({
            id: uc.id,
            context_id: uc.context_id,
        })),
        userRoles: userFragment.user_roles.map(ur => ({
            id: ur.id,
            context_id: ur.context_id,
            role_id: ur.role_id,
        })),
        username: userFragment.username || '',
        createdBy: userFragment.createdBy || null,
        createdAt: userFragment.createdAt,
        updatedBy: userFragment.updatedBy || null,
        updatedAt: userFragment.updatedAt || undefined,
        localAuth: {
            id: userFragment.localAuth?.id || '',
            email: userFragment.localAuth?.email || '',
            password: '',
            currentPassword: '',
        },
        badgeAuth: {
            id: userFragment.badgeAuth?.id || '',
            badge: userFragment.badgeAuth?.badge || '',
        },
        userAccessTokens: userFragment.userAccessTokens?.map(uat => ({
            id: uat.id || '',
            acls: Array.isArray(uat.acls) ? uat.acls : [],
            name: uat.name || '',
            created_at: uat.created_at || '',
        })) || [],
    };
}
