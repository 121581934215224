import { Notify } from 'src/notifications';
import { gqlClient } from '@redviking/argonaut-core-ui/src/util/gql-client';
import { messages } from 'src/i18n/i18n';
import { ArgoFile, ArgoMaterialClassAttributeSettingInsertInput, ArgoMaterialClassAttributeSettingUpdates, ArgoMaterialUdfDefaultDataInsertInput, ArgoUdfColumnConstraint, ArgoUdfColumnUpdateColumn, FileFragment, MaterialClassFragment, UpdateMaterialClassDocument } from 'types/db';
import { getListChanges } from 'src/util/composables/watch-list-changes';
import cloneDeep from 'lodash.clonedeep';
import { argoFilePreviewToBase64DataUrl } from 'src/files/file.util';
import { UDFData } from 'src/udf/udf.util';
import { PendingListChanges } from 'src/util/composables/use-pending-list-changes';
import { type ExtendedEntityParameters } from 'src/components/EntityDetail';
import { type AttributeSetting } from 'src/components/AttributeSettings/types';
import { validateAttributeSettings } from 'src/components/AttributeSettings/util';

export type MaterialModelProcesses = {
    id: string;
    material_model: {
        id: string;
        model_number: string;
    };
    process: {
        id: string;
    };
};

export type MaterialClassData = Pick<MaterialClassFragment,
    'id' |
    'name' |
    'meta' |
    'enabled' |
    'created_by' |
    'class_icon' |
    'description' |
    'udf_schemas' |
    'created_by_id' |
    'material_models' |
    'profile_file_id' |
    'materialsAggregate' |
    'materialClassAttributes' |
    'material_class_processes'
> & {
    created_at?: string;
    materialModelProcesses: MaterialModelProcesses[];
    materialClassAttributeSettings: AttributeSetting[];
};

export type MaterialClassEntity = {
    materialClass: MaterialClassData;
    udfDefaultData: {
      [ material_model_id: string ]: {
        [udf_col_id: string]: UDFData & {model_id: string}
      }
    },
    changedFiles: PendingListChanges<FileFragment, true>;
  };

export function getClassPreviewImage (fileIcon: Pick<ArgoFile, 'preview_subtype' | 'preview_type' | 'preview'>) {
    if (fileIcon) {
        return argoFilePreviewToBase64DataUrl(fileIcon);
    }
    return '';
}

export const acceptedFileTypes = [ '.pdf', '.png', '.jpeg', '.jpg', '.jpeg' ];

const validateMaterialClassAttributeSettings = (
    materialClassId: string,
    newSettings: MaterialClassEntity['materialClass']['materialClassAttributeSettings'],
    oldSettings: MaterialClassEntity['materialClass']['materialClassAttributeSettings']
): {
    inserts: ArgoMaterialClassAttributeSettingInsertInput[];
    updates: ArgoMaterialClassAttributeSettingUpdates[];
    deletes: string[];
} => {

    const validatedAttributeSettings = validateAttributeSettings(newSettings, oldSettings);

    const ret: {
        inserts: ArgoMaterialClassAttributeSettingInsertInput[];
        updates: ArgoMaterialClassAttributeSettingUpdates[];
        deletes: string[];
    } = {
        inserts: validatedAttributeSettings.inserts.map(setting => ({
            id: setting.id,
            config: setting.config,
            material_class_id: materialClassId,
            attribute_schema_id: setting.attribute_schema.id,
        })),
        updates: validatedAttributeSettings.updates.map(setting => ({
            where: {
                id: {
                    _eq: setting.id,
                },
            },
            _set: {
                config: setting.config,
            },
        })),
        deletes: validatedAttributeSettings.deletes,
    };
    return ret;
};

export async function saveMaterialClassEntity (payload: ExtendedEntityParameters<'materialclass'>) {
    const {
        entity,
        oldEntity: originalOldEntity,
    } = payload;

    const oldEntity = cloneDeep(originalOldEntity);
    if (!entity.materialClass.name || entity.materialClass.name.length <= 0) {
        const error = new Error('Material Class missing "name" property');
        Notify.error(error.message, error);
        return;
    }

    const oldEntityModelDefaultData = Object.entries(oldEntity?.udfDefaultData ?? {}).flatMap(([ mod_id, val ]) => {
        return Object.values(val).map(e => ({
            id: e.id,
            material_model_id: mod_id,
            str_val: e.str_val,
            num_val: e.num_val,
            udf_column_id: e.udf_column_id,
        }));
    });
    const entityModelDefaultData = Object.entries(entity?.udfDefaultData ?? {}).flatMap(([ mod_id, val ]) => {
        return Object.values(val).map(e => ({
            id: e.id,
            material_model_id: mod_id,
            str_val: e.str_val,
            num_val: e.num_val,
            udf_column_id: e.udf_column_id,
        }));
    });

    const modelDefaultData = getListChanges(entityModelDefaultData, oldEntityModelDefaultData, { partialChanges: false, deep: true, format: 'plc' });

    try {
        const modelProcessChanges = getListChanges(entity.materialClass.materialModelProcesses, oldEntity?.materialClass?.materialModelProcesses || [], { partialChanges: false, deep: true, format: 'plc' });
        const classProcessChanges = getListChanges(entity.materialClass.material_class_processes, oldEntity?.materialClass?.material_class_processes || [], { partialChanges: false, deep: true, format: 'plc' });
        const classIcon = entity.materialClass.class_icon;

        const attributeMutations = validateMaterialClassAttributeSettings(entity.materialClass.id, entity.materialClass.materialClassAttributeSettings, oldEntity?.materialClass.materialClassAttributeSettings || []);

        const schemaNameMap = entity.materialClass.udf_schemas.reduce((acc, val) => {
            if (acc[val.udf_column.name]) {
                acc[val.udf_column.name]++;
            } else {
                acc[val.udf_column.name] = 1;
            }
            return acc;
        }, {} as {[key: string]: number});
        if (Object.values(schemaNameMap).some(e => e > 1)) {
            throw new Error('Duplicate UDF column names detected!');
        }
        await gqlClient.request({
            document: UpdateMaterialClassDocument,
            variables: {
                materialClassId: entity.materialClass.id,
                materialClass: {
                    name: entity.materialClass.name,
                    enabled: entity.materialClass.enabled,
                    description: entity.materialClass.description,
                    profile_file_id: entity.materialClass.profile_file_id,
                    meta: entity.materialClass.meta,
                },
                updateImage: (classIcon?.md5 || null) !== (originalOldEntity?.materialClass.class_icon?.md5 || null),
                classIcon: {
                    content: classIcon?.content,
                    preview: classIcon?.preview,
                    content_subtype: classIcon?.content_subtype,
                    content_type: classIcon?.content_type,
                    preview_subtype: classIcon?.preview_subtype,
                    preview_type: classIcon?.preview_type,
                    storage: classIcon?.storage,
                    id: classIcon?.id,
                    url: classIcon?.url,
                    name: `material-class-${entity.materialClass.id}`,
                    description: '',
                },
                udfSchema: entity.materialClass.udf_schemas.map(mudf => ({
                    material_class_id: entity.materialClass.id,
                    udf_column: {
                        on_conflict: {
                            constraint: ArgoUdfColumnConstraint.UdfColumnPkey,
                            update_columns: [
                                ArgoUdfColumnUpdateColumn.Id,
                                ArgoUdfColumnUpdateColumn.DataType,
                                ArgoUdfColumnUpdateColumn.Name,
                                ArgoUdfColumnUpdateColumn.NameUnique,
                                ArgoUdfColumnUpdateColumn.Description,
                                ArgoUdfColumnUpdateColumn.ValidationMax,
                                ArgoUdfColumnUpdateColumn.ValidationMin,
                                ArgoUdfColumnUpdateColumn.ValidationPattern,
                                ArgoUdfColumnUpdateColumn.ValidationRequired,
                                ArgoUdfColumnUpdateColumn.ValidationUnique,
                                ArgoUdfColumnUpdateColumn.Enabled,
                                ArgoUdfColumnUpdateColumn.Type,
                            ],
                        },
                        data: {
                            id: mudf.udf_column!.id,
                            data_type: mudf.udf_column!.data_type,
                            name: mudf.udf_column!.name,
                            name_unique: mudf.udf_column!.name_unique,
                            description: mudf.udf_column!.description,
                            validation_max: mudf.udf_column!.validation_max,
                            validation_min: mudf.udf_column!.validation_min,
                            validation_pattern: mudf.udf_column!.validation_pattern,
                            validation_required: mudf.udf_column!.validation_required,
                            validation_unique: mudf.udf_column!.validation_unique,
                            enabled: mudf.udf_column!.enabled,
                            type: mudf.udf_column!.type,
                        },
                    },
                })),
                deleteMatClassProcessInputs: classProcessChanges.deletes.map(e => e.id),
                deleteMatModelProcessInputs: modelProcessChanges.deletes.map(e => e.id),
                deleteMatUdfDefaultDataInputs: modelDefaultData.deletes.map(e => e.id),
                matModUdfDefaults: modelDefaultData.inserts.map<ArgoMaterialUdfDefaultDataInsertInput>(e => ({
                    id: e.id,
                    udf_column_id: e.udf_column_id,
                    material_model_id: e.material_model_id,
                    num_val: e.num_val,
                    str_val: e.str_val,
                })),
                materialClassProcesses: classProcessChanges.inserts.map(e => ({
                    id: e.id,
                    process_id: e.process_id,
                    material_class_id: entity.materialClass.id,
                })),
                materialModelProcesses: modelProcessChanges.inserts.map(e => ({
                    id: e.id,
                    process_id: e.process.id,
                    material_model_id: e.material_model.id,
                })),
                addMaterialClassAttributeSettings: attributeMutations.inserts,
                updateMaterialClassAttributeSettings: attributeMutations.updates,
                deleteMaterialClassAttributeSettings: attributeMutations.deletes,
            },
        });
        Notify.win(messages.materialClass.materialClass.notifySaved);
    } catch (err) {
        Notify.error(err);
        throw err;
    }
}
