export const messages = {
    calendar: {
        addNew: 'Add New Calendar',
        confirmDelete: 'Are you sure you want to delete this Calendar?',
        create: 'Create Calendar',
        endDate: 'End Date',
        entityName: 'Calendar',
        hide: 'Hide Calendar',
        inspect: 'Inspect Calendar',
        name: 'Calendar',
        noneFound: 'No Calendars Found',
        notifySaved: 'Calendar Saved!',
        saved: 'Calendar Saved!',
        search: 'Search Calendars by Name or Description',
        select: 'Select a Calendar',
        startDate: 'Start Date',
    },
    calendarEvent: {
        addNew: 'Add New Calendar Event',
        confirmDelete: 'Are you sure you want to delete this Calendar Event?',
        create: 'Create Calendar Event',
        hide: 'Hide Calendar Event',
        inspect: 'Inspect Calendar Event',
        name: 'Calendar Event Name',
        noneFound: 'No Calendar Events Found',
        notifySaved: 'Calendar Event Saved!',
        search: 'Search Calendar Events by Name or Description',
    },
    event: {
        addNew: 'Add New Event',
        confirmDelete: 'Are you sure you want to delete this Event?',
        create: 'Create Event',
        entityName: 'Event',
        hide: 'Hide Event',
        inspect: 'Inspect Event',
        name: 'Event Name',
        noneFound: 'No Events Found',
        notifySaved: 'Event Saved!',
        search: 'Search Events by Name or Description',
    },
    eventType: {
        addNew: 'Add New Event Type',
        confirmDelete: 'Are you sure you want to delete this Event Type?',
        create: 'Create Event Type',
        hide: 'Hide Event Type',
        inspect: 'Inspect Event Type',
        name: 'Event Type Name',
        noneFound: 'No Event Types Found',
        notifySaved: 'Event Type Saved!',
        search: 'Search Event Types by Name or Description',
    },
    schedule: {
        addNew: 'Add New Schedule',
        confirmDelete: 'Are you sure you want to delete this Schedule?',
        create: 'Create Schedule',
        entityName: 'Schedule',
        hide: 'Hide Schedule',
        inspect: 'Inspect Schedule',
        modal: {
            duration: 'Duration',
            endDay: 'End',
            startDay: 'Start',
        },
        mode: 'Schedule Mode',
        name: 'Schedule',
        noneFound: 'No Schedules Found',
        notifySaved: 'Schedule Saved!',
        saved: 'Schedule Saved!',
        search: 'Search Schedules by Name',
    },
    scheduleDay: {
        dayOfWeek: 'Day of the Week',
        friday: 'Friday',
        monday: 'Monday',
        saturday: 'Saturday',
        shiftDay: 'Shift Day',
        sunday: 'Sunday',
        thursday: 'Thursday',
        tuesday: 'Tuesday',
        wednesday: 'Wednesday',
    },
    setTimeToNow: 'Set to now',
    titles: {
        calendar: {
            nav: 'Calendar',
            navList: 'Calendars',
            noName: 'Unnamed Calendar',
            pageBannerSubtitle: 'Browse and Manage Calendars',
            pageBannerTitle: 'Calendars',
        },
        calendarEvent: {
            nav: 'Calendar Event',
            noName: 'Unnamed Calendar Event',
            pageBannerSubtitle: 'Browse and Manage Calendar Events',
            pageBannerTitle: 'Calendar Events',
        },
        event: {
            nav: 'Events',
            noName: 'Unnamed Event',
            pageBannerSubtitle: 'Browse and Manage Events',
            pageBannerTitle: 'Events',
        },
        eventType: {
            nav: 'Event Type',
            noName: 'Unnamed Event Type',
            pageBannerSubtitle: 'Browse and Manage Event Types',
            pageBannerTitle: 'Event Types',
        },
        schedule: {
            nav: 'Schedule',
            navList: 'Schedules',
            noName: 'Unnamed Schedule',
            pageBannerSubtitle: 'Browse and Manage Schedules',
            pageBannerTitle: 'Schedules',
        },
        scheduleDay: {
            nav: 'Schedule Day',
            noName: 'Unnamed Schedule Day',
            pageBannerSubtitle: 'Browse and Manage Schedule Days',
            pageBannerTitle: 'Schedule Days',
        },
    },
};
