import { ArgoNotification, NotifyLevel } from '@redviking/argonaut-core-ui/src/notifications/ArgoNotification';
import _isEqual from 'lodash.isequal';
import { mutationTree } from 'typed-vuex';
import DebugModePlugin from 'src/vue-plugins/debug-mode';

const notifState = {
    notifications: [] as ArgoNotification[],
};

const mutations = mutationTree(notifState, {
    push (state, n: ArgoNotification): void {
        // pick a console method from the notification level
        const consoleMethod = {
            [NotifyLevel.Warning]: console.warn,
            [NotifyLevel.Error]: DebugModePlugin.enabled ? console.trace : console.error,
            [NotifyLevel.Success]: console.info,
            [NotifyLevel.Info]: console.info,
            [NotifyLevel.Debug]: console.debug,
            [NotifyLevel.Silly]: console.debug,
        }[n.level];

        const maxLevelLength = consoleMethod === console.error || consoleMethod === console.warn // these two have an arrow icon preceding their message
            ? 5 // enough to line up with the other
            : 7; // 5 chars, plus 1 space on each side
        let paddedLevel = n.level as string;
        if (paddedLevel.length < maxLevelLength) {
            paddedLevel = paddedLevel.padStart(paddedLevel.length + Math.floor((maxLevelLength - paddedLevel.length) / 2)).padEnd(maxLevelLength);
        }
        const consoleArgs: unknown[] = [
            `%c${paddedLevel}`,
            `background: ${n.color}; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;`,
        ];
        if (n.meta) {
            if (n.message && n.message !== (n.meta as Record<PropertyKey, unknown>).message) {
                consoleArgs.push(n.message); // include the message if it is not the same as meta.message
            }
            consoleArgs.push(n.meta);
            if (n.meta instanceof Error) { // if this is an error obj, it might have meta properties attached!
                const simplifiedMeta = { ...n.meta };
                if (!_isEqual(simplifiedMeta, {})) { // was there meta properties?
                    consoleArgs.push(simplifiedMeta);
                }
            }
        } else {
            consoleArgs.push(n.message);
        }

        consoleMethod(...consoleArgs);
        state.notifications.push(n);
        state.notifications = state.notifications.slice(-500);
    },

    clearUnseenNotifications (state): void {
        state.notifications.forEach(notif => {
            notif.seen = true;
        });
    },
    clearNotifications (state): void {
        state.notifications = [];
    },

    viewNotifications (state, payload: ArgoNotification[]): void {
        payload.forEach(notif => {
            const matchedNotif = state.notifications.find(stateNotif => _isEqual(notif, stateNotif));

            if (matchedNotif) {
                matchedNotif.seen = true;
            }
        });
    },
});

export default {
    namespaced: true,
    state: notifState,
    mutations,
};
