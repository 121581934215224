import { z } from 'zod';
import { sparkplugAppletNodeCfgSchema_v3 } from '../util/sparkplug-node-cfg.v3.zod';
import { varConditionSchema_v3 } from './conditional.v3.zod';
import { varpConfigBase_v3 } from './var-provider.v3.zod';

export const sparkplugDeviceWriteProviderSchema_v3 = varpConfigBase_v3.extend({
    type: z.literal('sparkplugDeviceWrite'),
    attrs: z.object({
        /**
         * which variables should be sent to which metrics
         */
        metrics: z.object({
            metricExpr: z.string(),
            /**
             * the name of the variable to provide the metric value
             */
            varSource: z.string(),
        }).array(),
        nodeCfg: sparkplugAppletNodeCfgSchema_v3,
        /**
         * if specified, and an error occurs while trying to send, the message will be written to this var.
         * after a successful send, the provider will clear the value.
         */
        errorVar: z.string(),

        /**
         * only valid for local/custom vars. after sending the payload, these variable will be reset
         */
        varsToResetOnSend: z.string().array(),
        trigger: z.object({
            conditional: z.union([ varConditionSchema_v3, z.null() ]).optional(),
        }),
    }),
});
