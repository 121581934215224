import { z } from 'zod';
import { varNameSchema } from '../../var.zod';
import { varpConfigBase_v3 } from './var-provider.v3.zod';

export const varStatusProviderSchema_v3 = varpConfigBase_v3.extend({
    type: z.literal('varStatus'),
    inputs: varNameSchema.array(),
    attrs: z.object({
        inputVar: z.string(),
        outputVar: z.string(),
    }),
});
