import nearley from 'nearley';
import grammar from './grammar';

export type ArgoGrammarParserError = {
    msg: string;
    line: number;
    pos: number;
    validTokens: string[];
    invalidToken: string;
};

export type ArgoGrammarParserPayload = {
    err?: ArgoGrammarParserError;
    varsInUse: string[];
};

export interface ScriptContentUpdatePayload extends ArgoGrammarParserPayload {
    scriptContent: string;
}

const parser = new nearley.Parser(nearley.Grammar.fromCompiled(grammar));
const initialState = parser.save();

const getAllAppletVars = (compiledResult: any[]): string[] => {
    const appletVars: string[] = [];
    for (let i = 0; i < compiledResult.length; i++) {
        const obj = compiledResult[i];
        if (obj) {
            if (typeof obj === 'object' && 'type' in obj && obj.type === 'appletVar') {
                appletVars.push(obj.value);
            } else if (Array.isArray(obj)) {
                const result = getAllAppletVars(obj);
                if (result) {
                    appletVars.push(...result);
                }
            }
        }
    }
    return [ ...new Set<string>(appletVars) ];
};

export function compileArgoScript (script: string): ArgoGrammarParserPayload {
    const newScript = sanitizeCode(script);
    try {
        parser.restore(initialState);
        parser.feed(newScript);
    } catch (e) {
        // Casting an error object that was decomposed by simply using JSON.stringify
        const errorObj: {
            offset: number;
            token: {
                value: string;
            };
        } = e as {
            offset: number;
            token: {
                value: string;
            };
        };

        const {
            line,
            col: pos,
        } = getLineAndColFromOffset(errorObj.offset, script);

        const errorStr = (e as Error).message;
        const validTokens: string[] = errorStr.match(/A .*? based on/ugm)?.map(rawToken => {
            const token = rawToken.replace(/A | based on|"/ug, '');
            return token;
        }) || [];
        return {
            err: {
                msg: 'Script does not confirm to Argo grammar.',
                line,
                pos,
                invalidToken: errorObj.token.value,
                validTokens,
            },
            varsInUse: [],
        };
    }
    const result = parser.results[0];
    if (!result) {
        return {
            err: {
                msg: 'Could not complie script',
                line: 0,
                pos: 0,
                invalidToken: '',
                validTokens: [],
            },
            varsInUse: [],
        };
    }

    if (Array.isArray(result)) {
        return {
            varsInUse: getAllAppletVars(result),
        };
    }

    return {
        err: {
            msg: 'Result is in an unexpected format',
            line: 0,
            pos: 0,
            invalidToken: '',
            validTokens: [],
        },
        varsInUse: [],
    };
}

const sanitizeCode = (code: string): string => {
    const newCode = code.replaceAll(/\/\/.*\n|\s/ug, '');
    return newCode;
};

const getLineAndColFromOffset = (offset: number, rawScript: string): { line: number, col: number} => {
    const ret = {
        line: 1,
        col: 1,
    };
    const newCode = rawScript.replaceAll(/\/\/.*|\r| /ug, '');
    for (const str of newCode.split('\n')) {
        if (offset > str.length) {
          ret.line++;
          offset -= str.length;
        } else {
          ret.col = offset + 1;
          break;
        }
    }
    return ret;
};
