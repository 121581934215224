import { validateAcls } from '@redviking/argonaut-core-ui/src/auth';
import { Notify } from '@redviking/argonaut-core-ui/src/notifications';
import { ArgoAttributeSchemaDefinitionTypeEnum } from '@redviking/argonaut-core-ui/types/db';
import { EntityTypeMap } from '@redviking/argonaut-core-ui/types';
import {
    AttributeDefinition,
    type AttributeProperty,
} from './attribute.entity';

export function validateAttributeSelectAcl (): boolean {
    return validateAcls([ 'attribute_select' ]);
}

export function validateAttributeSchemaSelectAcl (): boolean {
    return validateAcls([ 'attribute_schema_select' ]);
}

export function validateAttributeSchemaInsertAcl (): boolean {
    return validateAcls([ 'attribute_schema_insert' ]);
}

export function validateAttributeSchemaUpdateAcl (): boolean {
    return validateAcls([ 'attribute_schema_update' ]);
}

export function validateAttributeSchemaDeleteAcl (): boolean {
    return validateAcls([ 'attribute_schema_delete' ]);
}

function validateData (data: AttributeProperty, def: AttributeDefinition): boolean {
    const dataType = def.type;
    const required = def.validationRequired;
    const min = def.validationMin;
    const max = def.validationMax;

    if (dataType === ArgoAttributeSchemaDefinitionTypeEnum.Text) {
        if (!data.str_val && required) {
            const message = `Required Property: ${def.name}`;
            throw new Error(message);
        }
        if (def.validationPattern) {
            const regexp = new RegExp(`${def.validationPattern}`, 'ug');
            if (!regexp.test(data.str_val || '')) {
                const message = `Input for ${def.name} doesnt pass validation ${def.validationPattern}`;
                throw new Error(message);
            }
        }
    } else if (dataType === ArgoAttributeSchemaDefinitionTypeEnum.Number) {
        const numVal = data.num_val;
        if (numVal === undefined && required) {
            const message = `Required Property: ${def.name}`;
            throw new Error(message);
        }
        if (min || max) {
            if (typeof numVal !== 'number') {
                throw new Error('Number validation requires a number');
            }
            if (typeof min === 'number' && numVal < min) {
                const message = `Input for ${def.name} is lower than minumum: ${min}`;
                throw new Error(message);
            }
            if (typeof max === 'number' && numVal > max) {
                const message = `Input for ${def.name} is higher than maximum: ${max}`;
                throw new Error(message);
            }
        }
    }
    return true;
}

export function validateAttributeData (entityData: EntityTypeMap['attributeschema']): boolean {
    if (entityData.schema.name.length === 0) {
        const message = 'Schema Name is required';
        Notify.error(message);
        throw new Error(message);
    }

    const definitions = entityData.schema.definitions;
    for (let i = 0; i < definitions.length; i++) {
        const def = definitions[i];
        if (def.name.trim().length === 0) {
            const message = `Attribute schema field definition #${i + 1} is missing a name`;
            Notify.error(message);
            throw new Error(message);
        }
    }

    for (const attr of entityData.schema.attributes) {

        for (const reqDef of definitions.filter(d => d.validationRequired)) {
            if (!attr.attribute_data.find(ad => ad.attribute_schema_definition_id === reqDef.id)) {
                const message = `Required Property: ${reqDef.name}`;
                Notify.error(message);
                throw new Error(message);
            }
        }

        for (const ad of attr.attribute_data) {
            const definition = definitions.find(d => d.id === ad.attribute_schema_definition_id);

            // if a definition can't be found...
            if (!definition) {
                const message = `Unknown Attribute definition for data on ${attr.name}`;
                Notify.error(message);
                throw new Error(message);
            }
            try {
                validateData(ad, definition);
            } catch (err) {
                const message = `'${(err as Error).message}' on Attribute: ${attr.name}`;
                throw new Error(message);
            }
        }
    }
    return true;
}
