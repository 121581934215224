// the main webpack entrypoint

import 'roboto-fontface/css/roboto/roboto-fontface.css';
import '@mdi/font/css/materialdesignicons.css';
import 'src/styles/styles.stylus';
import Vue, { Component } from 'vue';
import { vuetify } from './vue-plugins';
import App from './App.vue';
import { router } from './routing/router';
import {
    store,
} from './store';

Vue.config.productionTip = false;

new Vue({
    store,
    vuetify,
    router,
    render: h => h(App as Component),
}).$mount('#app');
