import { CellClassParams, GridOptions } from '@ag-grid-community/core';
import { AttributeForSchemaView, EntitiesToUpdateOnSave } from './attribute.entity';
import { RowDataInstance } from './types';
import { v4 as uuidv4 } from 'uuid';
import { gqlClient } from '../util/gql-client';
import { RetroApplyNewAttributeLinksDocument } from 'types/db';

export const cellStyle = (params: CellClassParams<RowDataInstance>) => {
    const style: Record<string, string | number> = {};
    if (params.node.rowPinned === 'top') {
        return {};
    }
    if (params.data?.new) {
        style['font-style'] = 'italic';
        style['background-color'] = 'lightgray';
    }
    return style;
};

export const scrubIds = (ogAttr: AttributeForSchemaView): AttributeForSchemaView => ({
    ...ogAttr,
    id: uuidv4(),
    // scrubbing ids is for new attributes, so there should be no links to other entities
    location_attributes_aggregate: {
        aggregate: {
            count: 0,
        },
    },
    material_attributes_aggregate: {
        aggregate: {
            count: 0,
        },
    },
    material_class_attributes_aggregate: {
        aggregate: {
            count: 0,
        },
    },
    material_model_attributes_aggregate: {
        aggregate: {
            count: 0,
        },
    },
    attribute_data: ogAttr.attribute_data.map(attrData => ({
        ...attrData,
        id: uuidv4(),
    })),
});

export const baseGridOptions: GridOptions<RowDataInstance> = {
    headerHeight: 30,
    animateRows: true,
    rowDragMultiRow: true,
    rowDragManaged: false,
    undoRedoCellEditing: true,
    undoRedoCellEditingLimit: 1,
    rowClassRules: {
        'font-italic': params => Boolean(params.node.rowPinned),
        'font-weight-bold': params => Boolean(params.node.rowPinned),
    },
};

// Recursive function to update entities
export const updateEntities = async (entitiesToUpdateOnSave: EntitiesToUpdateOnSave, attributes: AttributeForSchemaView[]): Promise<{
    success: boolean;
    message: unknown;
}> => {
    // this is mostly a sanity check to ensure that the attribute ids are still valid
    const oldAttribute = attributes.find(a => a.id === entitiesToUpdateOnSave.oldAttributeId);
    const newAttribute = attributes.find(a => a.id === entitiesToUpdateOnSave.newAttributeId);
    if (!oldAttribute || !newAttribute) {
        throw new Error('Could not find old or new attribute');
    }

    try {
        await gqlClient.request({
            document: RetroApplyNewAttributeLinksDocument,
            variables: {
                oldAttributeId: oldAttribute.id,
                newAttributeId: newAttribute.id,
                includeLocation: entitiesToUpdateOnSave.locationIds.length > 0,
                locationIds: entitiesToUpdateOnSave.locationIds,
                includeMaterial: entitiesToUpdateOnSave.materialIds.length > 0,
                materialIds: entitiesToUpdateOnSave.materialIds,
                includeMaterialModel: entitiesToUpdateOnSave.materialModelIds.length > 0,
                materialModelIds: entitiesToUpdateOnSave.materialModelIds,
                includeMaterialClass: entitiesToUpdateOnSave.materialClassIds.length > 0,
                materialClassIds: entitiesToUpdateOnSave.materialClassIds,
            },
        });
        return {
            success: true,
            message: `Successfully updated the ${newAttribute.name} attribute on ${
                entitiesToUpdateOnSave.locationIds.length +
                entitiesToUpdateOnSave.materialIds.length +
                entitiesToUpdateOnSave.materialModelIds.length +
                entitiesToUpdateOnSave.materialClassIds.length
            } entities`,
        };
    } catch (e) {
        return {
            success: false,
            message: e.message,
        };
    }
};
