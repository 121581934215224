import { validateAcls } from 'src/auth';
import type { SerialNumFmt } from './serial-number.entity';

export function validateSerialNumberFmtSelect () {
    return validateAcls([ 'sn_format_select' ]);
}

export function validateSerialNumberFmtEdit () {
    return validateAcls([ 'sn_format_update' ]);
}

export function validateSerialNumberFmtInsert () {
    return validateAcls([ 'sn_format_insert' ]);
}

/** returns which token names appear more than once, if any */
export function unqTokensCheck (snFmt: SerialNumFmt): string[] {
    const tokenNameCountMap: Record<string, number> = {};
    for (const token of snFmt.config.tokens) {
        if (!tokenNameCountMap[token.name]) {
            tokenNameCountMap[token.name] = 0;
        }

        tokenNameCountMap[token.name] += 1;
    }
    return Object.keys(tokenNameCountMap).filter(name => tokenNameCountMap[name] > 1);
}
