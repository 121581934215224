import { mutationTree } from 'typed-vuex';
import {
    RoleFragment,
} from '../../types/db';

const roleState = {
    role: null as RoleFragment | null,
};

export type RoleState = typeof roleState;

const mutations = mutationTree(roleState, {
    setPageRole (state, role: RoleFragment): void {
        if (!role || role.__typename !== 'argo_role') {
            console.warn('Unknown Type passed into mutation');
        }
        state.role = { ...state.role, ...role };
    },
});

export default {
    state: roleState,
    mutations,
};
