
import { messages } from 'src/i18n/i18n';
import { RouterViewSlider } from '../components/RouterViewSlider';
import {
    validateLinkSelectAcl,
    validateLinkUpdateAcl,
} from './link.validations';
import { RouteConfig } from 'vue-router';
import { RouteEntityDetail } from 'src/components/EntityDetail';
import { entityDetailRoute } from 'src/components/EntityDetail/entity-route-util';
import { entityPageBannerTitle } from 'src/util/formatting';
import { accessor } from 'src/store';

import {
    createLinkEntity,
    getLinkEntity,
    saveLinkEntity,
} from './link.entity';

export const routes: RouteConfig[] = [
    {
        path: '/links',
        name: 'links',
        component: RouterViewSlider,
        redirect: { name: 'link-list' },
        children: [
            {
                name: 'link-list',
                path: '',
                redirect: to => ({ name: 'linkMaintenence', params: to.params }),
                component: () => RouterViewSlider,
                meta: {
                    navigationGuard: () => validateLinkSelectAcl(),
                    pageBannerTitle: messages.link.titles.link.pageBannerTitle,
                    pageBannerSubtitle: messages.link.titles.link.pageBannerSubtitle,
                    transition: 'horizontal',
                },
            },
            entityDetailRoute<'link'>({
                path: ':linkId',
                name: 'linkMaintenence',
                component: RouteEntityDetail,
                redirect: to => ({ name: 'linkSettings', params: to.params }),
                meta: {
                    entityType: 'link',
                    returnRoute: { name: 'home' },
                    canEdit: validateLinkUpdateAcl,
                    getEntity: getLinkEntity,
                    saveEntity: saveLinkEntity,
                    createEntity: createLinkEntity,
                    pageBannerTitle: () => entityPageBannerTitle('link', accessor.entityAsType('link')?.name),
                    pageBannerSubtitle: () => accessor.entityAsType('link')?.group_name || '',
                },
                children: [
                    {
                        path: 'settings',
                        name: 'linkSettings',
                        component: () => import(/* webpackChunkName: "link" */ './LinkSettings.vue'),
                        meta: {
                            tab: { // the presence of this prop indicates this is a tab
                                label: 'Settings',
                                icon: 'mdi-cog',
                            },
                            navigationGuard: () => true,
                        },
                    },
                ],
            }),
        ],
    },
];

export default routes;
