export const messages = {
    desc: 'Open a UDP socket and store received payloads in a variable',
    encoding: {
        items: {
            hex: 'Hex',
            utf8: 'UTF-8',
        },
        label: 'How to interpret the received payload',
    },
    errors: {
        _other: 'Config is invalid',
        payloadVar: 'Payload variable is required',
        port: 'Port is invalid',
    },
    hostInterface: {
        hint: 'If not specified, the system will listen on all available interfaces',
        label: 'The network interface / IP to listen on',
    },
    payloadVar: 'Variable to store the received payload in',
    port: 'Port',
    title: 'UDP Receive',
    tsVar: 'Store the timestamp of the last received payload in a variable (optional)',
};
