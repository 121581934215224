export type HasuraArray<T extends string | number> = T[];

/**
 * this fn is only needed until hasura can support array types (https://github.com/hasura/graphql-engine/issues/348)
 *
 * it works if codegen has overrides such as:
 * ```yaml
 * plugins:
 *   - typescript:
 *       scalars:
 *         _text: HasuraArray<string>
 * ```
 */
export function hasuraArrayify<T extends string | number> (arr: T[]): HasuraArray<T> {
    return `{${arr.join(',')}}` as unknown as HasuraArray<T>;
}
