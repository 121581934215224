export const messages = {
    titles: {
        nav: 'Users',
        noName: 'Unnamed User',
        pageBannerSubtitle: 'Browse and edit users',
        pageBannerTitle: 'Users',
    },
    user: {
        addNew: 'Add User',
        authProviderBadgeBadge: 'Badge',
        authProviderLocalCurrentPassword: 'Current Password',
        authProviderLocalEmail: 'Email',
        authProviderLocalPassword: 'New Password',
        authProviderLocalSaved: 'User Password Saved!',
        authProviderLocalYourPassword: 'Your Password',
        confirmCurrentPassword: 'Confirm Current Password',
        confirmDelete: 'Are you sure you want to delete this user?',
        confirmPassword: 'Confirm Password',
        contextsUpdated: 'Contexts Updated!',
        create: 'Create New User',
        email: 'User Email',
        enabled: 'Enable User',
        firstName: 'First Name',
        hide: 'Hide User',
        inspect: 'Inspect User',
        lastName: 'Last Name',
        noneFound: 'No Users Found',
        password: 'Password',
        plural: 'Users',
        required: 'Username, Password and Email Required!',
        rolesUpdated: 'Roles Updated',
        saved: 'User Saved!',
        search: 'Search Username or Description',
        uatName: 'Token Name',
        uatTitle: 'Access Token',
    },
};
