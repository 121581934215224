/* eslint-disable max-lines */
import moment from 'moment-timezone';
import { Notify } from 'src/notifications';
import { gqlClient } from '@redviking/argonaut-core-ui/src/util/gql-client';
import {
    AddCalendarDocument,
    AddSchedulesDocument,
    ArgoCalendarEventConstraint,
    ArgoCalendarEventInsertInput,
    ArgoCalendarEventUdfDataConstraint,
    ArgoCalendarEventUdfDataInsertInput,
    ArgoCalendarEventUdfDataUpdateColumn,
    ArgoCalendarEventUdfDefaultDataConstraint,
    ArgoCalendarEventUdfDefaultDataUpdateColumn,
    ArgoCalendarEventUpdateColumn,
    ArgoScheduleDayEnum,
    ArgoScheduleEventConstraint,
    ArgoScheduleEventUpdateColumn,
    ArgoScheduleModeEnum,
    CalendarEventFragment,
    GetUdfColumnsDocument,
    ScheduleEventFragment,
    UpdateCalendarDocument,
    UpdateScheduleDocument,
} from 'types/db';
import { CalendarFormatter } from 'vuetify';
import colors from 'vuetify/es5/util/colors';
import { messages } from 'src/i18n/i18n';
import { accessor } from 'src/store';
import { getListChanges } from 'src/util/composables/watch-list-changes';
import { UDFColumn, UDFData } from 'src/udf/udf.util';
import { toDatetimeLocalFormat } from 'src/util/composables/useDatetimeInput';
import { validateCalendarEntity } from './calendars/calendar.validations';
import type Vue from 'vue';
import { type EntityModifyParameters, type ExtendedEntityParameters } from 'src/components/EntityDetail';

export const VCalendarFormat = [ 1, 2, 3, 4, 5, 6, 0 ];
export const scheduleDays = [ 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday' ];

// we use local time as the default for front end so that it makessense for the user to configurer
export const defaultStartDate = '1996-01-01T00:00';

export function formatAMPM (date: any) {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours %= 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? `0${minutes}` : minutes;
    const strTime = `${hours}:${minutes} ${ampm}`;
    return strTime;
}
export type CalendarComponent = Vue & { prev: () => void; next: () => void; checkChange: () => void; getFormatter: (format: any) => CalendarFormatter };

export type VEvent = {
    id: string;
    priority: number;
    textColor?: string;
    name: string;
    start: Date;
    end: Date;
    timed: boolean;
    color?: string;
    meta?: Record<string, unknown>;
};

export type VCalendarEvent = {
    event_id: string;
    event_type_id: number | string;
    udf_data: UDFData[];
    day: ArgoScheduleDayEnum;
} & VEvent;

export function fromVCalToISOTs (date: Date) {
    return date.toISOString().substring(0, 16);
}

export const convertToShortISOFormat = toDatetimeLocalFormat; // aliased to avoid big refactor

export function nth (d: number) {
    return d > 3 && d < 21
        ? 'th'
        : [ 'th', 'st', 'nd', 'rd', 'th', 'th', 'th', 'th', 'th', 'th' ][d % 10];
}

export function extractCalendarEventMeta (event: ScheduleEventFragment['event']) {
    if (event?.meta) {
        if (typeof event.meta === 'object') {
            return event.meta;
        } else if (typeof event.meta === 'string') {
            return JSON.parse(event.meta);
        }
    }
    return {};
}
export function getTotalAvailableDays (scheduleMode: ArgoScheduleModeEnum) {
    switch (scheduleMode) {
        case 'biweekly':
            return 14;
        default:
            return 7;
    }
}

export async function getUDFColumnsForEventType (etypeId : number): Promise<UDFColumn[]> {
    const result = await gqlClient.request({
        document: GetUdfColumnsDocument,
        variables: {
            filter: {
                event_type: {
                    event_type_id: {
                        _eq: etypeId,
                    },
                },
            },
        },
    });
    return result.udfColumns;
}

export function dateOptionsForScheduleMode (scheduleMode: ArgoScheduleModeEnum) {
    const daysOfWeeks = [];
    const availDays = getTotalAvailableDays(scheduleMode);
    for (let i = 0; i < availDays + 1; i++) {
        const day = moment(`${defaultStartDate}`).add(i, 'days');
        daysOfWeeks.push({
            text: `${Math.floor(i / 7) + 1}${nth(Math.floor(i / 7) + 1)} ${day.format('dddd')}`,
            value: i,
        });
    }
    return daysOfWeeks;
}

export function scheduleEventToVCalendarEvent (scheduleEvents: ScheduleEventFragment[]): VCalendarEvent[] {
    return scheduleEvents.map((event) => {
        const timeStart = event.range.replace(')', ']');
        const timeEnd = event.range.replace(')', ']');
        const startDate = new Date(`${JSON.parse(timeStart)[0]}`);
        const endDate = new Date(`${JSON.parse(timeEnd)[1]}`);
        const eventName = event.event?.name || '';
        const meta = extractCalendarEventMeta(event.event);
        const color = meta?.identifier?.backgroundColor;
        const textColor = meta?.identifier?.textColor;
        return {
            id: event.id,
            event_id: event.event?.id || '',
            event_type_id: event.event?.event_type?.id || 0,
            udf_data: event.udf_data,
            name: eventName,
            start: startDate,
            end: endDate,
            color: color ? color : colors.grey.darken2,
            textColor: textColor ? textColor : 'white',
            day: event.day,
            timed: true,
            priority: event.event?.event_type?.priority || 0,
        };
    });
}

function fromVCalEventToCalendarEvent (event: Partial<VCalendarEvent>, timezone: string): ArgoCalendarEventInsertInput {
    // Conversion flow:
    // 1. The Date Class instantiates a date object in the brower's local timezone
    // 2. The timezone of the calendar is substituted in-place of the local timezone
    // 3. The date is converted to ISO string
    const startDate = moment.tz(moment(event.start).format('YYYY-MM-DD HH:mm'), timezone).toISOString().substr(0, 16);
    const endDate = moment.tz(moment(event.end).format('YYYY-MM-DD HH:mm'), timezone).toISOString().substr(0, 16);

    return {
        day: event.day,
        event_id: event.event_id,
        schedule_event_id: event.meta?.fromScheduleEvent as string || null,
        id: event.id,
        range: `["${startDate}", "${endDate}")`,
        udf_data: {
            data: event.udf_data?.map(deeta => ({
                str_val: deeta.str_val,
                num_val: deeta.num_val,
                id: deeta.id,
                udf_column_id: deeta.udf_column_id,
            })) || [],
            on_conflict: {
                constraint: ArgoCalendarEventUdfDataConstraint.CalendarEventUdfDataPkey,
                update_columns: [ 'num_val', 'str_val', 'meta' ] as ArgoCalendarEventUdfDataUpdateColumn[],
            },
        },
    };
}

export function parseArgoCalendarEvents (dbCalendarEvents: CalendarEventFragment[], timezone: string): VCalendarEvent[] {
    return dbCalendarEvents?.map<VCalendarEvent>((event) => {
        const times = event.range.replace(')', ']');

        // Conversion flow:
        // 1. The DB stores dates in UTC
        // 2. The calendar needs to display dates in the calendar's timezone
        // 3. The Date class instantiates a date object in the brower's local timezone
        const startDate = new Date(moment.tz(`${JSON.parse(times)[0]}`, 'UTC').tz(timezone).format('YYYY-MM-DD HH:mm'));
        const endDate = new Date(moment.tz(`${JSON.parse(times)[1]}`, 'UTC').tz(timezone).format('YYYY-MM-DD HH:mm'));

        const eventName = event.event?.name || '';
        const meta = extractCalendarEventMeta(event.event);
        const color = meta?.identifier?.backgroundColor;
        const textColor = meta?.identifier?.textColor;
        const calendarEvent: VCalendarEvent = {
            id: event.id,
            event_id: event.event?.id || '',
            event_type_id: event.event?.event_type?.id || 0,
            udf_data: event.udf_data,
            name: eventName,
            start: startDate,
            end: endDate,
            color: color ? color : 'gray',
            priority: event.event?.event_type?.priority || 0,
            day: event.day,
            timed: true,
            textColor: textColor ? textColor : 'white',
        };
        return calendarEvent;
    });
}


export function availableShiftLabels (start: Date, end: Date) {
    let startDate = moment(start);
    const endDate = moment(end);

    endDate.add(1, 'day');

    const daysOfWeekCount: Record<number, number> = {
        0: 0,
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 0,
        6: 0,
    };

    while (startDate < endDate) {
        daysOfWeekCount[startDate.day()] = daysOfWeekCount[startDate.day()] + 1;
        startDate = startDate.add(1, 'day');
    }

    const labels: string[] = [ 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday' ].filter((dow: string, idx: number) => {
        if (daysOfWeekCount[idx] === 0) {
            return false;
        }
        return true;
    });

    return labels;
}

export async function updateCalendar (payload: ExtendedEntityParameters<'calendar'>) {
    const { entity, oldEntity } = payload;
    if (!entity.calendar.name || entity.calendar.name.length <= 0) {
        const error = new Error('Calendar missing "name" property');
        Notify.error(error.message, error);
        return;
    }
    try {
        const validationErr = await validateCalendarEntity(entity, oldEntity);
        if (validationErr) {
            Notify.error(validationErr.toString());
            return;
        }

        await gqlClient.request({
            document: UpdateCalendarDocument,
            variables: {
                calendar: {
                    name: entity.calendar.name,
                    description: entity.calendar.description,
                    enabled: entity.calendar.enabled,
                    schedule_id: entity.calendar.schedule_id,
                    is_default: entity.calendar.is_default,
                    timezone: entity.calendar.timezone,
                    calendar_events: {
                        data: [ ...entity.calendar_events.inserts.map(e => fromVCalEventToCalendarEvent(e, entity.calendar.timezone)), ...entity.calendar_events.updates.map(e => fromVCalEventToCalendarEvent(e, entity.calendar.timezone)) ],
                        on_conflict: {
                            constraint: 'calendar_event_pkey' as ArgoCalendarEventConstraint,
                            update_columns: [ 'range', 'day' ] as ArgoCalendarEventUpdateColumn[],
                        },
                    },
                },
                deleteCalEvents: entity.calendar_events.deletes.map(e => e.id),
            },
        });

        accessor.setPageEntity({
            type: 'calendar',
            entity: {
                ...entity,
                calendar_events: {
                    inserts: [],
                    updates: [],
                    deletes: [],
                },
            },
        });

        Notify.win(messages.schedule.calendar.saved);
    } catch (err) {
        Notify.error(err);
        throw err;
    }
}
export async function createCalendar (payload: EntityModifyParameters<'calendar'>) {
    const entity = payload.entity;
    if (!entity.calendar.name || entity.calendar.name.length <= 0) {
        const error = new Error('Calendar missing "name" property');
        Notify.error(error.message, error);
        return;
    }
    try {
        const validationErr = await validateCalendarEntity(entity);
        if (validationErr) {
            Notify.error(validationErr.toString());
            return;
        }

        await gqlClient.request({
            document: AddCalendarDocument,
            variables: {
                calendar: {
                    id: entity.calendar.id,
                    name: entity.calendar.name,
                    description: entity.calendar.description,
                    enabled: entity.calendar.enabled,
                    schedule_id: entity.calendar.schedule_id || undefined,
                    is_default: entity.calendar.is_default,
                    timezone: entity.calendar.timezone,
                    calendar_events: {
                        data: [ ...entity.calendar_events.inserts.map(e => fromVCalEventToCalendarEvent(e, entity.calendar.timezone)), ...entity.calendar_events.updates.map(e => fromVCalEventToCalendarEvent(e, entity.calendar.timezone)) ],
                        on_conflict: {
                            constraint: 'calendar_event_pkey' as ArgoCalendarEventConstraint,
                            update_columns: [ 'range', 'day' ] as ArgoCalendarEventUpdateColumn[],
                        },
                    },
                },
            },
        });
        Notify.win(messages.schedule.schedule.saved);
    } catch (err) {
        Notify.error(err);
        throw err;
    }
}

export async function updateSchedule (payload: ExtendedEntityParameters<'schedule'>) {

    const {
        entity,
        oldEntity: originalOldEntity,
    } = payload;

    if (!entity.schedule.name || entity.schedule.name.length <= 0) {
        const error = new Error('Schedule missing "name" property');
        Notify.error(error.message, error);
        return;
    }
    const eventDifferences = getListChanges(entity.schedule.schedule_events, originalOldEntity?.schedule.schedule_events || [], { format: 'plc', deep: true, partialChanges: false });
    try {
        await gqlClient.request({
            document: UpdateScheduleDocument,
            variables: {
                schedule: {
                    id: entity.schedule.id,
                    name: entity.schedule.name,
                    mode: entity.schedule.mode,
                    enabled: entity.schedule.enabled,
                    schedule_events: {
                        data: [ ...eventDifferences.inserts, ...eventDifferences.updates ].map(e => ({
                            id: e.id,
                            event_id: e.event_id,
                            range: e.range,
                            day: e.day as ArgoScheduleDayEnum,
                            udf_default_data: {
                                data: e.udf_data?.map<ArgoCalendarEventUdfDataInsertInput>(deeta => ({
                                    num_val: deeta.num_val,
                                    str_val: deeta.str_val,
                                    id: deeta.id,
                                    udf_column_id: deeta.udf_column_id,
                                    meta: deeta.meta,
                                })) || [],
                                on_conflict: {
                                    constraint: ArgoCalendarEventUdfDefaultDataConstraint.CalendarEventUdfDefaultDataPkey,
                                    update_columns: [ 'num_val', 'str_val', 'meta' ] as ArgoCalendarEventUdfDefaultDataUpdateColumn[],
                                },
                            },
                        })),
                        on_conflict: {
                            constraint: ArgoScheduleEventConstraint.ScheduleEventPkey,
                            update_columns: [ 'day', 'range', 'event_id' ] as ArgoScheduleEventUpdateColumn[],
                        },
                    },
                },
                deleteScheduleEvents: eventDifferences.deletes.map(e => e.id),

            },
        });

        Notify.win(messages.schedule.schedule.saved);
    } catch (err) {
        Notify.error(err);
        throw err;
    }
}

export async function createSchedule (payload: EntityModifyParameters<'schedule'>) {

    const entity = payload.entity;

    if (!entity.schedule.name || entity.schedule.name.length <= 0) {
        const error = new Error('Schedule missing "name" property');
        Notify.error(error.message, error);
        return;
    }
    try {

        await gqlClient.request({
            document: AddSchedulesDocument,
            variables: {
                schedule: {
                    id: entity.schedule.id,
                    name: entity.schedule.name,
                    mode: entity.schedule.mode,
                    enabled: entity.schedule.enabled,
                    schedule_events: {
                        data: entity.schedule.schedule_events.map(e => ({
                            id: e.id,
                            event_id: e.event_id,
                            range: e.range,
                            day: e.day as ArgoScheduleDayEnum,
                            udf_default_data: {
                                data: e.udf_data?.map<ArgoCalendarEventUdfDataInsertInput>(deeta => ({
                                    num_val: deeta.num_val,
                                    str_val: deeta.str_val,
                                    id: deeta.id,
                                    udf_column_id: deeta.udf_column_id,
                                    meta: deeta.meta,
                                })) || [],
                                on_conflict: {
                                    constraint: ArgoCalendarEventUdfDefaultDataConstraint.CalendarEventUdfDefaultDataPkey,
                                    update_columns: [ 'num_val', 'str_val', 'meta' ] as ArgoCalendarEventUdfDefaultDataUpdateColumn[],
                                },
                            },
                        })),
                        on_conflict: {
                            constraint: ArgoScheduleEventConstraint.ScheduleEventPkey,
                            update_columns: [ 'day', 'range', 'event_id' ] as ArgoScheduleEventUpdateColumn[],
                        },
                    },
                },
            },
        });
        Notify.win(messages.schedule.schedule.saved);
    } catch (err) {
        Notify.error(err);
        throw err;
    }
}
