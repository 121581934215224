
import { Component, Vue, Watch } from 'vue-property-decorator';
import { accessor } from '@redviking/argonaut-core-ui/src/store';
import { ArgoNotification } from '@redviking/argonaut-core-ui/src/notifications';

@Component
export class ArgoNotificationSnackbar extends Vue {
    snackbarActive = false;
    currentNotification?: ArgoNotification | null = null;
    notificationQueue: ArgoNotification[] = [];

    @Watch('notificationList', { deep: true })
    onNewNotification (val: ArgoNotification[], oldVal: ArgoNotification[]): void {
        if (val.length > oldVal?.length) {
            const lastNewNotifIndex = oldVal.length === 0 ? 0 : val.indexOf(oldVal.slice(-1)[0]) + 1;
            const newNotifs: ArgoNotification[] = val.slice(lastNewNotifIndex);
            this.notificationQueue.push(...newNotifs);
            this.displayNotification();
        }
    }

    @Watch('snackbarActive')
    onSnackbarToggle (val: boolean): void {
        if (!val) {
            if (this.notificationQueue.length > 0) {
                this.$nextTick(() => {
                    this.displayNotification();
                });
            }
        }
    }

    get notificationList (): ArgoNotification[] {
        return accessor.notify.notifications.slice();
    }

    displayNotification (): void {
        if (this.notificationQueue.length > 0 && !this.snackbarActive) {
            this.currentNotification = this.notificationQueue.shift();
            this.snackbarActive = true;
        }
    }

    truncateMsg (msg: string) {
        const maxLineLength = 100;
        return msg.split('\n').map(line => {
            return line.length > maxLineLength ? `${line.slice(0, maxLineLength - 3)}...` : line;
        }).join('\n');
    }
}
export default ArgoNotificationSnackbar;
