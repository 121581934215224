import z from 'zod';
import { varNameSchema } from '../../var.zod';

export const baseOperandSchema_v3 = z.object({
    type: z.string(),
});

const operandSchemas_v3 = {
    var: baseOperandSchema_v3.extend({ type: z.literal('var'), varName: varNameSchema }),
    str: baseOperandSchema_v3.extend({ type: z.literal('str'), val: z.string() }),
    num: baseOperandSchema_v3.extend({ type: z.literal('num'), val: z.number() }),
    type: baseOperandSchema_v3.extend({ type: z.literal('type'), val: z.enum([ 'number', 'string', 'null' ]) }),
};

export const varComparisonOperandSchema_v3 = z.discriminatedUnion('type', [
    operandSchemas_v3.var,
    operandSchemas_v3.str,
    operandSchemas_v3.num,
    operandSchemas_v3.type,
]);

export const baseComparisonSchema_v3 = z.object({
    not: z.boolean().default(false),
    type: z.string(),
    operands: z.array(baseOperandSchema_v3),
});

const varComparisonSchemas_v3 = {
    eq: baseComparisonSchema_v3.extend({
        type: z.literal('eq'),
        operands: z.tuple([
            operandSchemas_v3.var,
            z.discriminatedUnion('type', [
                operandSchemas_v3.var,
                operandSchemas_v3.str,
                operandSchemas_v3.num,
            ]),
        ]),
    }).describe('resolves only if both operands are not null/NaN, and normalized values match'),
    regexp: baseComparisonSchema_v3.extend({
        type: z.literal('regexp'),
        operands: z.tuple([
            operandSchemas_v3.var,
            operandSchemas_v3.str,
        ]),
    }).describe('resolves only if variable is not null and matches string pattern'),
    isset: baseComparisonSchema_v3.extend({
        type: z.literal('isset'),
        operands: z.tuple([
            operandSchemas_v3.var,
        ]),
    }).describe('resolves only if variable is not null or empty string'),
    lt: baseComparisonSchema_v3.extend({
        type: z.literal('lt'),
        operands: z.tuple([
            operandSchemas_v3.var,
            z.discriminatedUnion('type', [
                operandSchemas_v3.var,
                operandSchemas_v3.num,
            ]),
        ]),
    }),
    gt: baseComparisonSchema_v3.extend({
        type: z.literal('gt'),
        operands: z.tuple([
            operandSchemas_v3.var,
            z.discriminatedUnion('type', [
                operandSchemas_v3.var,
                operandSchemas_v3.num,
            ]),
        ]),
    }),
    type: baseComparisonSchema_v3.extend({
        type: z.literal('type'),
        operands: z.tuple([
            operandSchemas_v3.var,
            operandSchemas_v3.type,
        ]),
    }),
    changed: baseComparisonSchema_v3.extend({
        type: z.literal('changed'),
        okOnly: z.boolean().default(true),
        operands: z.tuple([
            operandSchemas_v3.var,
        ]),
    }),
};

const varComparisonSchema_v3 = z.discriminatedUnion('type', [
    varComparisonSchemas_v3.eq,
    varComparisonSchemas_v3.regexp,
    varComparisonSchemas_v3.isset,
    varComparisonSchemas_v3.lt,
    varComparisonSchemas_v3.gt,
    varComparisonSchemas_v3.type,
    varComparisonSchemas_v3.changed,
]);

const baseConditionSchema_v3 = z.object({
    /** how to evaluate the comparisons. `and` -> "all", `or` -> "any" */
    boolOperation: z.enum([ 'and', 'or' ]),
    comparisons: z.array(baseComparisonSchema_v3),
});

export const varConditionSchema_v3 = baseConditionSchema_v3.extend({
    comparisons: z.array(varComparisonSchema_v3),
});
