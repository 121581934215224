import { z } from 'zod';
import { cellSchema_v3 } from './cells.v3.zod';
import { macroSchema_v3 } from './macros.v3.zod';
import { varpSchema_v3 } from './var-providers.v3.zod';

const rowSchema_v3 = z.object({
    weight: z.number().default(1),
});

const columnSchema_v3 = z.object({
    weight: z.number().default(1),
});

const coordinateSchema_v3 = z.object({
    row: z.number().default(0),
    column: z.number().default(0),
});

const marginPaddingBaseSchema_v3 = z.object({
    top: z.number().default(0),
    right: z.number().default(0),
    bottom: z.number().default(0),
    left: z.number().default(0),
});

const borderSchema_v3 = z.object({
    width: z.number().default(0),
    color: z.string().default('black'),
    style: z.string().default('none'),
});

const borderStyleSchema_v3 = z.object({
    top: borderSchema_v3,
    bottom: borderSchema_v3,
    left: borderSchema_v3,
    right: borderSchema_v3,
});

export const baseGridCellSchema_v3 = z.object({
    elevation: z.number().default(0),
    cssCellId: z.string(), // TODO: Remove this and just use the cell id as the gridArea. Attempted this once, was having trouble rendering the cells in the correct spots within the grid
    id: z.string().uuid(),
    argoCellId: z.string().default(''),
    border: borderStyleSchema_v3,
    margin: marginPaddingBaseSchema_v3,
    padding: marginPaddingBaseSchema_v3,
    coordinates: z.array(coordinateSchema_v3),
    // eslint-disable-next-line newline-per-chained-call
    parentGridId: z.string().uuid().nullable().default(null),
    backgroundColor: z.string().default('#00000000'),
    backgroundColorControlVar: z.string().nullable().default(null),
});

export const regularGridCellSchema_v3 = baseGridCellSchema_v3.extend({
    type: z.literal('regular'),
});

export const subGridCellSchema_v3 = baseGridCellSchema_v3.extend({
    type: z.literal('subGrid'),
    rows: z.array(rowSchema_v3),
    columns: z.array(columnSchema_v3),
    isPageSequenceRoot: z.boolean().default(false),
    pageNumber: z.number().nullable(),
});

const linkedGridCellSchema_v3 = z.discriminatedUnion('type', [ regularGridCellSchema_v3, subGridCellSchema_v3 ]);

export const gridLayoutSchema_v3 = z.object({
    elevation: z.number().default(0),
    rows: z.array(rowSchema_v3),
    border: borderStyleSchema_v3,
    columns: z.array(columnSchema_v3),
    margin: marginPaddingBaseSchema_v3,
    padding: marginPaddingBaseSchema_v3,
    linkedGridCells: z.array(linkedGridCellSchema_v3),
    backgroundColor: z.string().default('#00000000'),
    backgroundColorControlVar: z.string().nullable().default(null),
});

export const screenSchema_v3 = z.object({
    id: z.string().uuid(),
    name: z.string(),
    operatorAuth: z.object({
        /**
         * whether this screen participates in operator auth.
         * @default false
         */
        required: z.boolean().default(false),
    }),
    /**
     * Cells that are used within the screen
     */
    cells: z.array(z.lazy(() => cellSchema_v3)),

    /**
     * The layout of the screen
     */
    gridLayout: gridLayoutSchema_v3,
});
const sparkplugDeviceMetricPermissionsSchema_v3 = z.object({
    write: z.boolean(),
});

const sparkplugDeviceMetricSchema_v3 = z.object({
    name: z.string(),
    permissions: sparkplugDeviceMetricPermissionsSchema_v3,
});

export const runtimeScriptCfgSchema_v3 = z.object({
    id: z.string().uuid(),
    script: z.string(),
    /**
     * disables validation against the "argo lang" grammar.
     *
     * this option is "hidden" and intentionally not made visible to users
     */
    disableGrammarValidation: z.boolean().optional(),
});

export const appletDesignVersionConfigSchema_v3 = z.object({
    header: z.string(),
    /**
     * specifies a list of providers, which provide variables to the config.
     * variables can be defined in terms of other variables.
     * to avoid circular loops, this is an ordered list and variables will only be able to reference earlier defined variables.
     */
    varProviders: z.array(varpSchema_v3),
    /** holds the name of a variable. if set, the config should only be allowed to change when this variable's value changes, or lacks a value entirely */
    lockout: z.string().nullable(),
    screens: z.array(screenSchema_v3),
    /**
     * if null, operator auth in general is not required for this config anywhere
     */
    operatorAuth: z.object({
        /**
         * the provider (from the auth service) that operators should use to authenticate
         * @default 'badge'
         */
        type: z.literal('badge'),
        /**
         * if null, the operator auth will not expire
         * @default null
         */
        expiration: z.object({
            timeoutMinutes: z.number(),
            /**
             * whether re-authentication should only be required after inactivity of `timeoutMinutes`,
             * or just since the last auth
             *
             * @default true
             */
            inactivityOnly: z.boolean().default(true),
        }).nullable().default(null),
    }).nullable(),
    macros: z.array(macroSchema_v3),
    /**
     * The config schema version, should be changed every time there's breaking change in the config typedefs
     */
    schemaVersion: z.literal(3),
    sparkplugDeviceMetrics: z.array(sparkplugDeviceMetricSchema_v3).default([]),
    scripts: z.array(runtimeScriptCfgSchema_v3).default([]),
});

export type AppletDesignVersionConfig_V3 = z.infer<typeof appletDesignVersionConfigSchema_v3>;

const designVarToMetricMapSchema_v3 = z.array(z.object({
    varName: z.string(),
    metricName: z.string(),
    isWritable: z.boolean().default(false),
}));

export const linkedDesignVersionConfigSchema_v3 = z.object({
    sparkplugNodeExpose: z.object({
        designNameAsDeviceId: z.string(),
        vars: designVarToMetricMapSchema_v3,
    }).nullable().default(null),
}).default({});
