import { type AttributeSetting } from './types';

export const validateAttributeSettings = (
    newSettings: AttributeSetting[],
    oldSettings: AttributeSetting[]
): {
    deletes: string[];
    inserts: AttributeSetting[];
    updates: AttributeSetting[];
} => {
    const ret: {
        deletes: string[];
        inserts: AttributeSetting[];
        updates: AttributeSetting[];
    } = {
        inserts: [],
        updates: [],
        deletes: oldSettings.filter(oldSetting => !newSettings.find(newSetting => newSetting.id === oldSetting.id)).map(t => t.id),
    };

    for (const setting of newSettings) {
        if (!setting.config) {
            throw new Error('Material Class Attribute Setting missing "config" property');
        }
        if (!setting.config.type) {
            throw new Error('Material Class Attribute Setting missing "type"');
        }
        if (setting.config.strict === undefined) {
            throw new Error('Material Class Attribute Setting missing "strict"');
        }
        if (setting.config.type === 'limit') {
            if (setting.config.operator === undefined) {
                throw new Error('Material Class Attribute Setting of type "limit" missing "operator"');
            }
            if (setting.config.amount === undefined) {
                throw new Error('Material Class Attribute Setting of type "limit" missing "amount"');
            }
        }
        if (setting.config.type === 'range') {
            if (setting.config.min.value === undefined) {
                throw new Error('Material Class Attribute Setting of type "range" missing minimum value');
            }
            if (setting.config.max.value === undefined) {
                throw new Error('Material Class Attribute Setting of type "range" missing maximum value');
            }
            if (setting.config.max.value < setting.config.min.value) {
                throw new Error('Material Class Attribute Setting of type "range" maximum value must be greater than minimum value');
            }
        }

        const existingSetting = oldSettings.find(oldSetting => oldSetting.id === setting.id);
        if (existingSetting) {
            ret.updates.push(setting);
        } else {
            ret.inserts.push(setting);
        }
    }
    return ret;
};
