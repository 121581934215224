import {
    Ref,
    WritableComputedRef,
    computed,
} from 'vue';
import UtilityTypes from 'utility-types';

declare type ToWritableRefs<T = Record<string, unknown>> = {
    [K in keyof T]: WritableComputedRef<T[K]>;
};

/**
 * @template DEFAULT optional default value for SOURCE[PROP].
 * @template RETURN convenience type. It's the source type if no default is specified
 */
export function useWritableComputedProp<
    SOURCE,
    PROP extends keyof SOURCE,
    DEFAULT extends Required<SOURCE>[PROP] | undefined, // union with `undefined` because the fn param is optional
    RETURN extends
        DEFAULT extends undefined
            ? SOURCE[PROP] // DEFAULT is undefined, so use SOURCE. if SOURCE's is undefined, then there's nothing we can do except return undefined
            : Exclude<SOURCE[PROP], undefined> | DEFAULT, // DEFAULT is NOT defined, so should be safe to union with SOURCE
> (
    source: Ref<SOURCE>,
    prop: PROP,
    defaultValue?: DEFAULT
): WritableComputedRef<RETURN> {
    return computed({
        get: () => {
            return prop in (source.value as Record<string, unknown>) || defaultValue === undefined
                ? source.value[prop] as RETURN
                : defaultValue as RETURN;
        },
        set: v => source.value = {
            ...source.value,
            [prop]: v,
        },
    });
}

type MergeDefaults<
    SOURCE,
    DEFAULTS extends Partial<SOURCE>, // Partial, to avoid requiring defaults
> = {
    [ key in keyof Required<SOURCE> ]: // creates a type with every prop possible in SOURCE (the Required prevents weird stuff from happening)
        key extends (UtilityTypes.RequiredKeys<SOURCE> | keyof DEFAULTS) // is the key required in SOURCE, or provided in DEFAULTS?
            ? Required<SOURCE>[key] // use SOURCE, ensuring optionality is removed (via Required)
            : Required<SOURCE>[key] | undefined // use SOURCE type with optionality removed + undefined (all keys will still be present)
};

export function toWritableComputedProps<
    SOURCE,
    DEFAULTS extends Partial<SOURCE>, // Partial, to avoid requiring `defaults`
> (
    source: Ref<SOURCE>,
    defaults?: DEFAULTS
): ToWritableRefs<MergeDefaults<SOURCE, DEFAULTS>> {
    const refObj = {} as { [key in keyof SOURCE]: WritableComputedRef<SOURCE[key]> };
    const normalizedDefaults: Partial<SOURCE> = defaults || {};

    // use a proxy to avoid created Refs until they are actually accessed / destructured
    const proxy = new Proxy(refObj, {
        get (_refObj, prop) {
            const sourceKey = prop as keyof SOURCE; // type assertion so it's not `string | symbol`
            if (!_refObj[sourceKey]) {
                const defaultVal = normalizedDefaults[sourceKey];
                refObj[sourceKey] = useWritableComputedProp(source, sourceKey, defaultVal);
            }
            return refObj[sourceKey];
        },
    });

    // the proxy ensures all props you attempt to access are available as computed props

    return proxy as ToWritableRefs<MergeDefaults<SOURCE, DEFAULTS>>;
}
