import { CORE_STATE_DATA_LABEL_ID } from '../stateColors';

export type Asset = {
    id: string,
    location_id: string | null,
    asset_type_id: string | null,
    name: string,
    description: string,
    erc: number,
};

export const assetCodeTypes = {
    state: {
        id: CORE_STATE_DATA_LABEL_ID,

    },
    fault: {
        id: '6308b447-386e-4819-b868-72894ad3b678',

    },
    intervention: {
        id: '5bd1d943-3ec8-42fa-9863-772b3431c82d',

    },
    warning: {
        id: '247113ca-5f75-4ced-b2a2-2c6e6300164a',

    },
};
