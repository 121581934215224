import z from 'zod';
import { varConditionSchema_v3 } from '../var-providers/conditional.v3.zod';
import { cellCfgAttrsBaseSchema_v3, cellCfgBaseSchema_v3 } from './cell-base.v3.zod';

const dialogCellAttrsSchema_v3 = cellCfgAttrsBaseSchema_v3.extend({
    showDialog: varConditionSchema_v3,
});

export const dialogCellSchema_v3 = cellCfgBaseSchema_v3.extend({
    type: z.literal('dialog'),
    attrs: dialogCellAttrsSchema_v3,
    modifiers: z.record(z.enum([
        'showDialog',
        'background',
        'borderColor',
    ]), z.string().optional()).optional(),
});
