import {
    AddSnFormatsDocument,
    ArgoSnFormatStatusEnum,
    ArgoSnFormatUniquenessEnum,
    GetAllSnFormatsDocument,
    UpdateSnFormatDocument,
    UserFragment,
} from 'types/db';
import { Route } from 'vue-router';
import { gqlClient } from '@redviking/argonaut-core-ui/src/util/gql-client';
import cloneDeep from 'lodash.clonedeep';
import { Notify } from 'src/notifications';
import { accessor } from 'src/store';
import { hasuraArrayify } from '@redviking/argonaut-util/types/hasura-array';
import {
    DatetimeFormat,
    Encodings,
    SnfToken,
    SnfTokenDatetime,
    SnfTokenFixed,
    SnfTokenInput,
    SnfTokenSeq,
} from '@redviking/argonaut-util/src/serial-numbers';
import { SaveResult } from 'types/routes';
import { unqTokensCheck } from './serial-number.validations';
import { EntityTypeMap } from 'types';
import { type EntityModifyParameters, type ExtendedEntityParameters } from 'src/components/EntityDetail';

export type SerialNumFmt = {
    id: number;
    name: string;
    description: string;
    createdAt: string | null;
    updatedAt: string | null;
    createdBy: UserFragment | null;
    updatedBy: UserFragment | null;
    revision: number;
    enabled: boolean;
    status: ArgoSnFormatStatusEnum;
    /**
     * regex, with implied ^ and $
     */
    validation?: string;
    uniqueness: ArgoSnFormatUniquenessEnum;
    /**
     * token IDs that define distinct sequences. AKA "rollover group"
     */
    seqGrouping: string[];
    config: {
        version: '1.0.0';
        versionCode: 1;
        tokens: SnfToken[];
    };
};

export type SerialNumberInstance = {
    id: string | number;
    meta: {
        tokenValues: Record<string, string>;
    } | unknown;
    sn_seq_id?: string | number | null;
    content: string;
    created_at: string;
    material?: {
      id: string
    } | null;
    created_by: UserFragment
}

const fixedToken: SnfTokenFixed = {
    name: 'prefix',
    type: 'fixed',
    content: 'T52',
};
const julianToken: SnfTokenDatetime = {
    name: 'julianDay',
    type: 'datetime',
    format: DatetimeFormat.YearDay,
};
const yearToken: SnfTokenDatetime = {
    name: 'year',
    type: 'datetime',
    format: DatetimeFormat.Year,
};
const ipnToken: SnfTokenInput = {
    name: 'internalPartNumber',
    type: 'input',
    len: 2,
};
const shiftToken: SnfTokenInput = {
    name: 'shift',
    type: 'input',
    len: 1,
    hidden: true,
};
const seqToken: SnfTokenSeq = {
    name: 'seq',
    type: 'seq',
    chars: Encodings.numerics,
    len: 4,
};

export const sampleSnf: EntityTypeMap['serialNumFmt'] = {
    id: Math.trunc(Math.random() * 3000),
    name: 'Sample SN Fmt',
    description: '',
    createdAt: null,
    createdBy: null,
    updatedAt: null,
    updatedBy: null,
    revision: 1,
    enabled: true,
    status: ArgoSnFormatStatusEnum.Editing,
    uniqueness: ArgoSnFormatUniquenessEnum.U,
    seqGrouping: [],
    config: {
        version: '1.0.0',
        versionCode: 1,
        tokens: [
            fixedToken,
            julianToken,
            yearToken,
            ipnToken,
            shiftToken,
            seqToken,
        ],
    },
};

// placeholder validations for rn
export function validateSnFmt (snFmt: SerialNumFmt): boolean {
    if (!snFmt.name) {
        Notify.error('Name is required');
        return false;
    }
    const duplicateTokens = unqTokensCheck(snFmt);
    if (duplicateTokens.length > 0) {
        Notify.error(`Format token names must be unique, but duplicates were found: ${duplicateTokens.join(', ')}`);
        return false;
    }
    return true;
}

export async function getSerialNumFmtEntity (to: Route): Promise<{ entity: EntityTypeMap['serialNumFmt'] }> {
    if (to.query.mode === 'create') {
        return { entity: cloneDeep(sampleSnf) };
    }
    const { SerialNumberFormats } = await gqlClient.request({
        document: GetAllSnFormatsDocument,
        variables: {
            filter: {
                id: {
                    _eq: parseInt(String(to.params.snFmtId) || '0', 10) || null,
                },
            },
        },
    });

    if (SerialNumberFormats[0]) {
        const snFmt = SerialNumberFormats[0];
        return {
            entity: {
                createdAt: snFmt.created_at,
                createdBy: snFmt.created_by,
                enabled: snFmt.enabled,
                id: snFmt.id,
                config: snFmt.config as SerialNumFmt['config'],
                name: snFmt.name,
                revision: snFmt.revision,
                seqGrouping: snFmt.seq_grouping,
                status: snFmt.status,
                uniqueness: snFmt.unq,
                description: snFmt.description,
                updatedAt: snFmt.updated_at || null,
                updatedBy: accessor.auth.currentUser,
            },
        };
    } else {
        const err = new Error('Unknown Serial Number Format');
        Notify.error(err);
        throw err;
    }
}

export async function saveSerialNumFmtEntity (payload: ExtendedEntityParameters<'serialNumFmt'>): Promise<void | SaveResult> {
    const entity = payload.entity;
    if (validateSnFmt(entity)) {

        const data = await gqlClient.request({
            document: UpdateSnFormatDocument,
            variables: {
                id: entity.id,
                rev: entity.revision,
                sn_format: {
                    config: entity.config,
                    name: entity.name,
                    revision: entity.revision + 1,
                    enabled: entity.enabled,
                    seq_grouping: hasuraArrayify(entity.seqGrouping),
                    status: entity.status,
                    unq: entity.uniqueness,
                    description: entity.description,
                },
            },
        });
        if (Number(data?.serialNumberMutation?.affected_rows) !== 1) {
            throw new Error('Failed to save Serial Number format');
        }

        return {
            status: 'success',
            newEntity: {
                ...entity,
                revision: entity.revision + 1,
            },
        };
    } else {
        return { status: 'error' };
    }
}
export async function createSerialNumFmtEntity (payload: EntityModifyParameters<'serialNumFmt'>): Promise<SaveResult | void> {
    const entity = payload.entity;
    if (validateSnFmt(entity)) {

        const data = await gqlClient.request({
            document: AddSnFormatsDocument,
            variables: {
                sn_format: {
                    config: entity.config,
                    name: entity.name,
                    enabled: entity.enabled,
                    seq_grouping: hasuraArrayify(entity.seqGrouping),
                    status: entity.status,
                    unq: entity.uniqueness,
                    description: entity.description,
                },
            },
        });
        const newId = (data?.serialNumberMutation?.snFormat || [])[0].id;
        return {
            status: 'success',
            route: {
                name: 'snFmtMaintenence',
                params: {
                    snFmtId: String(newId),
                },
                query: {
                    mode: 'view',
                },
            },
        };
    } else {
        return { status: 'error' };
    }
}
