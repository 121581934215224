export const messages = {
    role: {
        aclName: 'ACL Name',
        addNew: 'Add New Role',
        confirmDelete: 'Are you sure you want to delete this Role?',
        create: 'Create Role',
        enabled: 'Enabled',
        entityName: 'Roles',
        hide: 'Hide Role',
        inspect: 'Inspect Role',
        name: 'Role Name',
        noneFound: 'No Roles Found',
        notifySaved: 'Role Saved!',
        parentRole: 'Parent Roles',
        required: 'Role Required',
        search: 'Search Roles by Name or Description',
    },
    titles: {
        nav: 'Roles',
        noName: 'Unnamed Role',
        pageBannerSubtitle: 'Browse and edit locations',
        pageBannerTitle: 'Roles',
    },
};
