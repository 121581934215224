import z from 'zod';
import { DeployParamKind, deployParamSchema } from '@redviking/argonaut-util/src/mes/deployParam.zod';

const locationSchemaBase = z.object({
    id: z.string().uuid(),
});
interface LocationBase extends z.infer<typeof locationSchemaBase> {}

const locationDeployParamSchema = deployParamSchema(z.object({
    deployParamId: z.string(),
    deployParamKind: z.literal(DeployParamKind.location),
}));
export interface LocationDeployParam extends z.infer<typeof locationDeployParamSchema> {}

export const locationSchema: z.ZodType<LocationBase | LocationDeployParam> = z.union([ locationDeployParamSchema, locationSchemaBase.strict() ]);

export type LocationCfg = LocationBase | LocationDeployParam;
