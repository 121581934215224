import { accessor } from '../store';
import { messages } from 'src/i18n/i18n';
import { RouterViewSlider } from '../components/RouterViewSlider';
import {
    type ExtendedEntityParameters,
    RouteEntityDetail,
    entityDetailRoute,
} from '@redviking/argonaut-core-ui/src/components/EntityDetail';
import { validateContextSelectAcl } from './context.validations';
import { GetContextDocument } from 'types/db';
import { entityPageBannerTitle } from 'src/util';
import { gqlClient } from '@redviking/argonaut-core-ui/src/util/gql-client';
import { v4 } from 'uuid';
import { Notify } from 'src/notifications';

export default [
    {
        path: '/context',
        name: 'context',
        component: RouterViewSlider,
        redirect: { name: 'contextList' },
        meta: {
            navigationGuard: () => validateContextSelectAcl(),
            navbarTitle: messages.context.titles.nav,
            navbarIcon: '$argo-context',
            transition: 'horizontal',
        },
        children: [
            {
                name: 'contextList',
                path: '',
                component: () => import(/* webpackChunkName: "context" */ './ContextView.vue'),
                meta: {
                    pageBannerTitle: messages.context.titles.pageBannerTitle,
                    pageBannerSubtitle: messages.context.titles.pageBannerSubtitle,
                },
            },
            entityDetailRoute<'context'>({
                path: ':contextId',
                name: 'contextMaintenence',
                component: RouteEntityDetail,
                redirect: to => ({ name: 'contextSettings', params: to.params }),
                meta: {
                    entityType: 'context',
                    async getEntity (to) {
                        if (to.query.mode === 'create') {
                            return { entity: {
                                id: v4(),
                                name: 'New Context',
                                description: '',
                                enabled: true,
                                created_at: new Date().toISOString(),
                            } };
                        }
                        const { context } = await gqlClient.request({
                            document: GetContextDocument,
                            variables: {
                                contextId: to.params.contextId,
                            },
                        });

                        if (context[0]) {
                            return {
                                entity: {
                                    ...context[0],
                                },
                            };
                        } else {
                            const err = new Error('Unknown Context');
                            Notify.error(err);
                            throw err;
                        }
                    },
                    saveEntity: async (_: ExtendedEntityParameters<'context'>) => {},
                    returnRoute: { name: 'contextList' },
                    pageBannerTitle: () => entityPageBannerTitle('context', accessor.entityAsType('context')?.name),

                    canEdit: false,
                },
                children: [
                    {
                        path: 'settings',
                        name: 'contextSettings',
                        component: () => import(/* webpackChunkName: "context" */ './ContextSettings.view.vue'), // a component for the settings page content
                        meta: {
                            tab: { // the presence of this prop indicates this is a tab
                                label: 'Settings',
                                icon: 'mdi-cog',
                            },
                        },
                    },
                    {
                        path: 'users',
                        name: 'contextUsers',
                        component: () => import(/* webpackChunkName: "context" */ './ContextUsers.view.vue'), // a component for the settings page content
                        meta: {
                            tab: { // the presence of this prop indicates this is a tab
                                label: 'Users',
                                icon: 'mdi-account-box-multiple',
                            },
                        },
                    },
                ],
            }),
        ],
    },
];
