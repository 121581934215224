export const messages = {
    attrs: {
        saved: 'Attributes Saved!',
    },
    dataElement: {
        addNew: 'Add New Data Element',
        confirmDelete: 'Are you sure you want to delete this data element?',
        create: 'Create Data Element',
        hide: 'Hide Data Element',
        inspect: 'Inspect Data Element',
        name: 'Data Element Name',
        noneFound: 'No Data Elements Found',
        notifySaved: 'Data Element Saved!',
        quickAdd: 'Quick Add Data Element',
        saved: 'Data Element Saved!',
        search: 'Search Data Elements by Name or Description',
        type: 'Data Element Type',
        typeSearch: 'Select Data Element Type',
        types: {
            material: 'Material',
            material_class: 'Material Class',
            material_model: 'Material Model',
            number: 'Numeric',
            pass: 'Pass / Fail',
            string: 'Text',
        },
        uom: 'Unit of Measure (UoM)',
    },
    titles: {
        nav: 'Data Elements',
        noName: 'Unnamed Data Element',
        pageBannerSubtitle: 'View Data Elements',
        pageBannerTitle: 'Data Elements',
    },
};
