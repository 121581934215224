export const messages = {
    condition: {
        errors: {
            duplicateName: 'Condition names must be unique',
            invalidType: (type: string) => `Invalid condition type: ${type}`,
        },
        modifiers: {
            all: {
                description: 'All of these must match',
                text: 'All',
            },
            any: {
                description: 'Any of these can match',
                text: 'Any',
            },
            none: {
                description: 'Anything EXCEPT these can match',
                text: 'None',
            },
        },
        new: 'New Condition',
        /** used in the spec condition grid */
        selectionOptions: {
            false: '✘ No',
            na: 'N/A',
            true: '✔ Yes',
        },
        types: {
            location: {
                description: 'Check the location where the process is run',
                text: 'Location',
            },
            locationClass: {
                description: 'Check the class of the location where the process is run',
                text: 'Location Class',
            },
            materialAttribute: {
                description: 'Check the attributes of the material used in the process',
                text: 'Material Attribute',
            },
            materialClass: {
                description: 'Check the class of the material used in the process',
                text: 'Material Class',
            },
            materialModel: {
                description: 'Check the model of the material used in the process',
                text: 'Material Model',
            },
            materialUdf: {
                description: 'Check the UDFs of the material used in the process',
                text: 'Material UDF',
            },
            timeRange: {
                description: 'Check whether the process is run within a certain time range',
                text: 'Time Range',
            },
            user: {
                description: 'Check the user running the process',
                text: 'User',
            },
            userRole: {
                description: 'Check the roles of the user running the process',
                text: 'User Role',
            },
        },
        view: 'View Condition',
    },
    errorText: 'Error Text',
    labels: {
        conditions: 'Conditions',
    },
    newRev: 'New Revision',
    process: {
        addNew: 'Add new process',
        calcs: {
            add: 'Add Calculation',
            de: 'Calculation Data Element',
            defaultValue: 'Default Value',
            desc: 'Compute additional data element values based on process parameters and collected data',
            input1: 'Input 1',
            input2: 'Input 2',
            operator: 'Operator',
            outputDataElementLabel: 'Where to save the calculation result',
            prev: '[Prev]',
            step: {
                add: 'Add Calculation Step',
                header: 'Calculation Steps',
            },
            timingLabel: 'Timing',
            title: 'Calculations',
            unnamed: 'New Calculation',
        },
        config: 'Process Config',
        confirmDelete: 'Are you sure you want to delete this process?',
        create: 'Create Process',
        dataCol: {
            addNew: 'Add new data element to collect',
            cannotDelete: 'Cannot delete a previously added data element',
            desc: 'Required Data Elements to be collected when the process is run',
            title: 'Process Data Collection',
        },
        hide: 'Hide Process',
        inspect: 'Inspect Process',
        meta: 'Process Revision Metadata',
        name: 'Process Name',
        noneFound: 'No Process Found',
        notifySaved: 'Process Saved!',
        params: {
            addNew: 'Add new process parameter',
            cannotDelete: 'Cannot delete a previously added process parameter',
            desc: 'Data Element inputs for the process that will define the parameters of the process\'s operation. The values will be provided by a specification.',
            title: 'Required Parameters',
        },
        process: 'Process',
        processes: 'Processes',
        revSelector: {
            label: (values: { hasValue: boolean, multiple: string }) => {
                const append = values.multiple === undefined || values.multiple === '0' ? '' : 's';
                return (values.hasValue ? 'Process Revision' : 'Select Process Revision') + append;
            },
        },
        search: 'Search Process by Name or Description',
        specs: {
            defaultErrorText: 'Validation Failed',
            defaultValidationName: 'Unnamed Validation',
            desc: 'Conditional constraints for a process, and possible values for process parameters.',
            filters: 'Process Parameter Filters',
            pageTitle: (specName: string) => `Specification - ${specName}`,
            params: 'Parameter Values',
            title: 'Specifications',
            validations: 'Validations',
        },
        template: {
            desc: (templateName: string) => {
                return `Created from template "${templateName}"`;
            },
            fail: 'Failed to create process from template',
            label: 'Create from template',
            success: 'Template loaded successfully',
        },
        type: 'Process Type',
        unnamed: 'New Process',
        view: 'View Process',
    },
    requiredCount: 'Required Count',
    revName: 'Revision Name',
    specification: {
        addNew: 'Add New Specification',
        attributeFilter: {
            addNew: 'Add New Attribute Filter',
            attributeName: 'Validation Pattern For Attribute Name',
            createMenuSubtitle: 'This filter will be applied to any material that runs this specification. The result from the filter will be used as the specification parameter value. The process will fail if this filter does not find exactly 1 result for the given material',
            createMenuTitle: 'Create a Filter for an Attribute Schema',
            filterPreview: {
                attributeName: 'Attribute Name Matching:',
                name: 'Schema Name:',
                putResult: 'Get the Value From:',
                schemaProperty: 'Schema Property {propName} Matching:',
                type: 'Schema Type:',
            },
            output: {
                error: 'None of the selected schema property types match this parameters type.',
                toolTip: 'The value returned for the attribute property given in this field will be the specification parameter value',
            },
            regex: 'Regular Expression',
            returnValue: 'Get Value From',
            schemaType: 'Type of schema',
            unavailable: 'An attribute filter cannot be applied to this parameter type',
            validationPattern: {
                descNumber: 'A {regexpLink} pattern to validate this attribute property. Property is of type number, all values will be temporarily cast to a string when used in pattern matching',
                descText: 'A {regexpLink} pattern to validate this attribute property',
                label: 'Validation Pattern For: ',
            },
        },
        confirmDelete: 'Are you sure you want to delete this specification?',
        create: 'Create Specification',
        desc1: 'Configure different ways that a process can be performed. Each specification is a unique way to perform the process, and can define an alternate required count, validations, parameter values, and media.',
        desc2: 'When a material is assigned to this process, a Spec will be selected by testing the conditions of each spec in this list until one is found with all its conditions satisfied. If no spec is found, the default spec will be used.',
        hide: 'Hide Specification',
        inspect: 'Inspect Specification',
        name: 'Specification Name',
        noneFound: 'No Specification Found',
        notifySaved: 'Specification Saved!',
        search: 'Search Specification by Name or Description',
        solve: {
            newConditionGroup: 'Add New Condition Group',
        },
        titles: {
            nav: 'Specification',
            noName: 'New Specification',
            pageBannerSubtitle: 'View Specification',
            pageBannerTitle: 'Specification',
        },
        type: 'Specification Type',
        validations: 'Specification Validations',
    },
    tab: {
        calcs: 'Calculations',
        dataCol: 'Data Collection',
        media: 'Media',
        params: 'Parameters',
        specs: 'Specifications',
    },
    uom: 'Unit of Measure',
    validation: 'Validation',
};
